var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { checkValue } from 'components/functions';
import React, { useState } from 'react';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";
var GoGuidesIntroComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var _a = __read(useState(false), 2),
    isVisible = _a[0],
    setIsVisible = _a[1];
  var contentData = goGuidesStore.fetchIntrotext(goGuidesStore.pageUrlData);
  if (!checkValue(contentData)) {
    return null;
  }
  var loc = new localization();
  var introText = checkValue(contentData.introText) ? contentData.introText : null;
  var pageType = checkValue(contentData.pageType) ? contentData.pageType : '';
  return React.createElement(React.Fragment, null, introText && (pageType === 'longform' ? React.createElement("div", {
    className: "intro-container"
  }, React.createElement("div", {
    className: "intro-inner",
    dangerouslySetInnerHTML: {
      __html: introText
    }
  })) : pageType === 'listicle' ? React.createElement("div", {
    className: "intro-container"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(UitkExpandoPeek, {
    isVisible: isVisible,
    handleClick: function () {
      return setIsVisible(function (prevIsVisible) {
        return !prevIsVisible;
      });
    },
    lines: 6,
    whitespace: false,
    expandLabel: loc.getLocalizedText('show_more'),
    collapseLabel: loc.getLocalizedText('show_less')
  }, React.createElement("div", {
    className: "intro-inner",
    dangerouslySetInnerHTML: {
      __html: introText
    }
  }))), React.createElement(ViewLarge, null, React.createElement("div", {
    className: "intro-inner",
    dangerouslySetInnerHTML: {
      __html: introText
    }
  })))) : React.createElement(React.Fragment, null, React.createElement("section", {
    className: "intro"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12"
  }, React.createElement("div", {
    className: "intro-container"
  }, React.createElement("div", {
    className: "expandable-wrapper"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(UitkExpandoPeek, {
    isVisible: isVisible,
    handleClick: function () {
      return setIsVisible(function (prevIsVisible) {
        return !prevIsVisible;
      });
    },
    lines: 6,
    whitespace: false,
    expandLabel: loc.getLocalizedText('show_more'),
    collapseLabel: loc.getLocalizedText('show_less')
  }, React.createElement("div", {
    className: "intro-inner",
    dangerouslySetInnerHTML: {
      __html: introText
    }
  }))), React.createElement(ViewLarge, null, React.createElement("div", {
    className: "intro-inner",
    dangerouslySetInnerHTML: {
      __html: introText
    }
  })))))))))));
};
export default GoGuidesIntroComponent;