import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesLocationInfoComponent from 'components/modules/location-info/location-info';
import GoGuidesLongContentsComponent from 'components/modules/long-contents/long-contents';
import GoGuidesRelatedStoriesComponent from 'components/modules/related-stories/related-stories';
import GoGuidesWriterProfileComponent from 'components/modules/writer-profile/writer-profile';
import GoGuidesSearchWizard from '../modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import GoGuidesTableOfContent from '../modules/table-of-content/toc';
import LegalMessage from '../modules/generic/legal-message';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';
var LongFormPage = function () {
  return React.createElement(MasterPage, null, React.createElement("div", {
    className: "page-container"
  }, React.createElement(GoGuidesHeroComponent, null), React.createElement("div", {
    className: "article-body row"
  }, React.createElement("div", {
    className: "content-wrap col-12 col-l8"
  }, React.createElement(GoGuidesTableOfContent, {
    tagElement: "h3",
    parentClassName: "content-wrap"
  }), React.createElement(GoGuidesIntroComponent, null), React.createElement(GoGuidesLongContentsComponent, null), React.createElement(GoGuidesLocationInfoComponent, null), React.createElement(GoGuidesWriterProfileComponent, null), React.createElement(LegalMessage, null)), React.createElement("div", {
    className: "sidebar-wrap col-12 col-l4"
  }, React.createElement("div", {
    className: "side-wrap01"
  }, React.createElement(GoGuidesSearchWizard, null)), React.createElement("div", {
    className: "side-wrap02"
  }, React.createElement(RecommendationModule, {
    pageLayout: PageLayout.TWO_COLUMN
  }))), React.createElement("div", {
    className: "more-article-wrap col-12"
  }, React.createElement(GoGuidesRelatedStoriesComponent, null))), React.createElement("div", {
    className: "bottom-wrap row"
  }, React.createElement("div", {
    className: "bottom-inner col-12"
  }, React.createElement(GoGuidesKeepExploringComponent, null))), React.createElement(GoGuidesBackToTop, null)));
};
export default LongFormPage;