import React from 'react';
import { LodgingSearchForm } from '@shared-ui/retail-search-tools-product';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';
var GoGuidesSearchWizard = function (props) {
  var goGuidesStore = useStores().goGuidesStore;
  var loc = new localization();
  var trackingObject = {
    event: {
      event_name: 'hotel_search_box.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  return React.createElement(EgClickstreamImpressionTracker, {
    eventData: trackingObject
  }, React.createElement("section", {
    className: "sqm-hub sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12"
  }, React.createElement("div", {
    className: "sqm-container"
  }, React.createElement("h2", null, React.createElement(UitkIcon, {
    name: "search",
    size: UitkIconSize.LARGE
  }), loc.getLocalizedText('start_planning_your_trip')), React.createElement(LodgingSearchForm, {
    inputs: {},
    redirectToSearchResultOnSubmit: true
  }))))));
};
export default GoGuidesSearchWizard;