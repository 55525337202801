import React from 'react';
import { checkValue } from 'components/functions';
import { UitkHeading, UitkParagraph, UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkLink } from 'uitk-react-link';
import CarouselComponent from '../generic/render-carousel';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType, UitkImageLink } from 'uitk-react-images';
import { localization } from 'utils/localization';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { Viewport, ViewSmall } from "@shared-ui/viewport-context";
var GoGuidesOtherNeighbourhoodsComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchOtherNeighbourhoods(goGuidesStore.pageUrlData);
  var loc = new localization();
  var moduleName = 'neighbourhoods in';
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName
    }
  };
  var destinations = [];
  var headerText = '';
  var seeAllUrl = '';
  if (checkValue(contentData)) {
    var neighbourhoodsData = contentData.neighbourhoodsData;
    destinations = checkValue(neighbourhoodsData === null || neighbourhoodsData === void 0 ? void 0 : neighbourhoodsData.neighbourhoodsList) ? neighbourhoodsData.neighbourhoodsList : [];
    headerText = checkValue(neighbourhoodsData) ? loc.getLocalizedText('neighbourhoods_in', neighbourhoodsData === null || neighbourhoodsData === void 0 ? void 0 : neighbourhoodsData.neighbourhoodsDestinationName) : '';
    seeAllUrl = checkValue(neighbourhoodsData) ? neighbourhoodsData === null || neighbourhoodsData === void 0 ? void 0 : neighbourhoodsData.neighbourhoodsSeeAllUrl : '';
  }
  var readMoreLabel = loc.getLocalizedText('read_more');
  var seeAllLabel = loc.getLocalizedText('see_all_properties');
  var carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Other Neighbourhoods Carousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3
    },
    pageBy: 3,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience
  };
  var CarouselItem = function (_a) {
    var _b;
    var item = _a.item;
    var seeAllNhoodPropLabel = loc.getLocalizedText('see_all_properties_in', item.name);
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      params: {
        size: {
          small: '&width=600&height=400',
          medium: '&width=600&height=400',
          large: '&width=800&height=533'
        }
      }
    };
    return React.createElement(UitkCard, {
      border: true
    }, React.createElement("div", {
      className: "item-wrap-inner"
    }, React.createElement(UitkFigure, {
      ratio: UitkFigureAspectRatioType.R16_9
    }, React.createElement(RenderImage, {
      imgProps: imageProps
    }), React.createElement(UitkImageLink, null, React.createElement("a", {
      href: item.seeAllPropertiesUrl
    }))), React.createElement("div", {
      className: "detail-wrapper"
    }, React.createElement("div", {
      className: "detail-wrapper-inner"
    }, React.createElement(UitkLink, null, React.createElement("a", {
      href: item.seeAllPropertiesUrl
    }, React.createElement(UitkHeading, {
      tag: "h3",
      size: 7
    }, item.name))), React.createElement(UitkParagraph, null, item.snippetText), React.createElement(UitkLink, {
      className: "read-more",
      inline: true
    }, React.createElement("a", {
      href: item.targetUrl
    }, readMoreLabel))), checkValue(item.seeAllPropertiesUrl) && React.createElement("div", {
      className: "see-all-nh-prop"
    }, React.createElement(UitkLink, {
      className: "nh-prop-link"
    }, React.createElement("a", {
      href: item.seeAllPropertiesUrl,
      target: "_blank",
      rel: "noreferrer"
    }, seeAllNhoodPropLabel))))));
  };
  var carouselData = destinations.map(function (item, index) {
    return React.createElement(CarouselItem, {
      key: index,
      item: item
    });
  });
  return React.createElement(React.Fragment, null, checkValue(destinations) && checkValue(destinations.length > 0) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement("section", {
    className: "other-neighbourhood sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "other-neighbourhood-inner"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement("div", {
    className: "header-wrapper"
  }, React.createElement(UitkSubheading, {
    tag: "h2"
  }, headerText)), checkValue(seeAllUrl) && React.createElement("div", {
    className: "see-all-prop desktop"
  }, React.createElement(UitkLink, {
    className: "see-all-link"
  }, React.createElement("a", {
    href: seeAllUrl,
    target: "_blank",
    rel: "noreferrer"
  }, seeAllLabel)))), React.createElement(CarouselComponent, {
    data: carouselData,
    config: carouselConfigProps
  }), checkValue(seeAllUrl) && React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement("div", {
    className: "see-all-prop mobile"
  }, React.createElement(UitkLink, {
    className: "see-all-link",
    align: 'right'
  }, React.createElement("a", {
    href: seeAllUrl,
    target: "_blank",
    rel: "noreferrer"
  }, seeAllLabel)))))))))));
};
export default GoGuidesOtherNeighbourhoodsComponent;