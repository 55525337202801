import React from 'react';
import MapComponent from 'src/components/modules/generic/maps';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { checkValue } from '../../../functions';
var ListicleItemMap = function (_a) {
  var name = _a.name,
    geo = _a.geo,
    nearbyProperties = _a.nearbyProperties,
    pdpLink = _a.pdpLink,
    i18nKey = _a.i18nKey;
  return React.createElement(React.Fragment, null, geo && geo.coord ? React.createElement(MapComponent, {
    name: name,
    geo: geo,
    i18nKey: i18nKey
  }) : (checkValue(nearbyProperties) || checkValue(pdpLink)) && React.createElement(UitkSpacingHr, {
    blockMargin: "four"
  }));
};
export default ListicleItemMap;