export default {
  "current_deal": "Trenutačna cijena",
  "lbg.section_properties_theme.pet_friendly": "Dobitnici nagrade u kategoriji smještaja za putovanja s kućnim ljubimcima – {0}",
  "address": "Adresa",
  "hub.homepage.h2": "Tako mnogo toga, a tako malo vremena...",
  "search_hotels_near_this_landmark": "Pretražite hotele<span class=\"extra-label\"> u blizini te znamenitosti</span>",
  "hub.category.h1.things_to_do": "{0} – aktivnosti",
  "lbg.h2.vip_access": "VIP Access objekti odabrani su zbog visoko ocijenjenih boravaka i vrhunskog iskustva.",
  "lbg.theme.cleanliness": "Čistoća",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Učitaj još",
  "link_long_form_to_listiicle_multiple": "{0} – jedna od znamenitosti popisa {1} i {2} (pročitajte sve o lokaciji {3} ovdje)",
  "lbg.section_properties_theme.boutique": "Dobitnici nagrade u kategoriji boutique smještaja – {0}",
  "lbg.h2.luxury": "Otkrijte vrhunske luksuzne sadržaje. Zato su gosti oduševljeni ovim objektima.",
  "hub.category.h1.information": "{0} – informacije",
  "families": "obitelji",
  "trending_now": "Popularno",
  "lbg.from": "Od",
  "hub.category.h1.road_trips": "Najbolja putovanja autom – {0}",
  "lbg.h2.cleanliness": "Ti smještajni objekti imaju izvrsne cjelokupne ocjene gostiju i najbolje ocjene gostiju za čistoću.",
  "single": "samce",
  "couples": "parove",
  "trust_module.price_guarantee.find_out_more": "saznajte više",
  "top_destinations_in": "{0} – najpopularnije destinacije",
  "hub.category.h1.couples": "Putovanja za parove – {0}",
  "hub.category.h2.tours": "Najbolji obilasci na lokaciji {0}",
  "hub.category.h1.photos": "Fotogenična odredišta – {0}",
  "category.photos": "Fotografije",
  "hub.category.h1.budget": "Jeftina putovanja – {0}",
  "hub.category.h2.ski": "Najbolja skijaška odredišta – {0}",
  "lbg.section_theme_world_winners.most_wanted": "Dobitnici nagrade za najpopularniji objekt",
  "lbg.theme.home_and_apartments": "Apartmani i kuće za odmor",
  "hub.category.h1.luxury": "Luksuzna putovanja – {0}",
  "hub.category.h2.adventure": "Najbolja aktivna putovanja – {0}",
  "hotels_in": "{0} – hoteli",
  "lbg.section_search_winners": "Pretražite više dobitnika nagrada",
  "see_more_hotels_in": "Pogledajte više hotela – {0}",
  "hub.category.h1.beach": "Plaže − {0}",
  "open": "Radno vrijeme:",
  "enter_your_destination": "Upišite destinaciju",
  "best_of": "{0} – najbolje od najboljeg",
  "top10_hotels_missing_properties": "Neki smještajni objekti možda se trenutačno ne prikazuju. Žao nam je zbog neugodnosti.",
  "adventure": "pustolovine",
  "lbg.h1.destination_theme.sustainable": "Dobitnici nagrade u kategoriji ekološki održivog smještaja – {0}",
  "luxury": "ljubitelje luksuza",
  "expand_map_view": "Proširite prikaz mape",
  "book_now": "Rezervirajte sada",
  "lbg.h1.destination_theme.ski": "Dobitnici nagrade u kategoriji skijaškog smještaja – {0}",
  "hub.category.h1.food": "{0} – hrana i restorani",
  "category.blogs": "Blog",
  "budget": "jeftina putovanja",
  "popular_neighbourhoods_in": "{0} – popularne četvrti",
  "hub.category.h1.unusual": "Skriveni dragulji i neobična mjesta – {0}",
  "see_all_accommodation_in": "Pogledajte smještaj – {0}",
  "hub.category.h1.adventure": "Aktivna putovanja – {0}",
  "category_highlight.see_all.shopping": "Više o kupovini",
  "lbg.section_most_popular_destination": "Najpopularnije destinacije",
  "lbg.section_properties_theme.luxury": "Dobitnici nagrade u kategoriji luksuznog smještaja – {0}",
  "unusual": "ljubitelje neobičnih zanimljivosti",
  "category.shopping": "Kupnja",
  "make_the_most_of_your_trip": "Iskoristite svoje putovanje na najbolji način",
  "x_review": "{0,choice,0#|1#{0} recenzija|2#{0} recenzije|2<{0} recenzije|4<{0} recenzija|10#{0} recenzija|10<Recenzija: {0}}",
  "hub.category.h1.single": "Solo putovanja – {0}",
  "hub.category.h2.custom": "Popularno",
  "lbg.section_theme_world_winners.lgbt": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ+ goste",
  "lbg.section_award_winners_theme_other": "Druge kategorije nagrada – {0}",
  "lbg.supplier_collected_charges": "+ naknada od {0} koju plaćate u objektu",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "Dobitnici nagrade za VIP Access – {0}",
  "link_long_form_to_listiicle_single": "{0} – jedna od znamenitosti popisa {1} (pročitajte sve o lokaciji {2} ovdje)",
  "lbg.section_theme_world_winners.boutique": "Dobitnici nagrade u kategoriji boutique smještaja",
  "lbg.section_properties_theme.spa_hotel": "Dobitnici nagrade u kategoriji smještaja sa spa centrom – {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Dobitnici nagrade u kategoriji all-inclusive smještaja",
  "hub.category.h2": "{0} – putnički vodič",
  "lbg.properties.price_tooltip": "To je najniža cijena noćenja na našim web-stranicama za sljedećih 14 dana.",
  "hub.category.h2.road_trips": "Najbolja putovanja autom – {0}",
  "hub.category.h1.ski": "Skijaška odredišta – {0}",
  "food": "ljubitelje gastronomije",
  "lbg.theme.pet_friendly": "Za putovanja s ljubimcima",
  "lbg.theme.business": "Za poslovna putovanja",
  "hub.category.h1.nature": "Najbolja putovanja za bijeg u prirodu – {0}",
  "map": "Karta",
  "category_highlight.see_all.information": "Više informacija",
  "hotel_prices_in": "{0} – cijene hotela",
  "hub.category.h1.nightlife": "{0} – noćni život",
  "trust_module.free_cancellation.most_hotels": "za većinu hotela*",
  "also_popular_in": "Također popularno u gradu {0}",
  "lbg.h1.destination_theme.business": "Dobitnici nagrade u kategoriji smještaja za poslovna putovanja – {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Dobitnici nagrade u kategoriji smještaja za putovanja s kućnim ljubimcima",
  "hub.category.h1.maps": "Karte – {0}",
  "lbg.section_properties_theme.most_wanted": "Dobitnici nagrade za najpopularniji objekt – {0}",
  "category.nightlife": "Noćni život",
  "search_hotels_in_this_area": "Pretražite hotele<span class=\"extra-label\"> u tom području</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 glas|2#2 glasa|2<{1} glasa|4<{1} glasova|10#10 glasova|10<glasova: {1}})",
  "hub.category.h2.business": "Najbolja poslovna putovanja – {0}",
  "category.things_to_do": "Aktivnosti",
  "destination_travel_guide_by_category": "{0} – putnički vodič po kategorijama",
  "hub.category.h1.families": "Obiteljska putovanja – {0}",
  "lbg.section_theme_world_winners.ski": "Dobitnici nagrade u kategoriji skijaškog smještaja",
  "you_may_also_like": "Moglo bi vam se svidjeti i ovo",
  "lbg.theme.sustainable": "Ekološki održiv smještaj",
  "hub.category.h2.unusual": "Najbolji skriveni dragulji i neobična mjesta – {0}",
  "hub.category.h2.football": "Podržite svoj nogometni tim i uživajte u svom putovanju!",
  "top_hotel": "Najpopularniji hotel",
  "lbg.h1.destination_theme.luxury": "Dobitnici nagrade u kategoriji luksuznog smještaja – {0}",
  "top10_hotels_empty_alert": "Trenutačno imamo tehničkih poteškoća. <span>Žao nam je zbog neugodnosti.</span>",
  "special_deal": "Posebna ponuda",
  "lbg.h2.sustainable": "Važna je održivost! Pogledajte ove objekte za izvrstan boravak.",
  "where_to_stay_in": "Hoteli – {0}",
  "start_planning_your_trip": "Počnite sanjati o putovanju",
  "good_for": "Dobro za:",
  "category.hotels": "Hoteli",
  "x_accommodation_guide": "{0} – vodič za smještaj",
  "lbg.theme.luxury": "S luksuznim sadržajima",
  "hub.category.h2.sports": "Najbolji sportovi i aktivnosti – {0}",
  "lbg.section_theme_world_winners.lgbtq": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ goste",
  "see_more_neighbourhoods": "Pogledajte više četvrti",
  "category_highlight.title.nightlife": "Gdje ići i što raditi noću – {0}",
  "lbg.h1.destination_theme.lgbt": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ+ goste – {0}",
  "hub.category.h1.blogs": "{0} – blog o putovanjima",
  "lbg.h2.business": "Poslovni putnici odlično ocjenjuju ove smještajne objekte.",
  "hotels": "Hoteli",
  "lbg.h2.pet_friendly": "Gosti toplo preporučuju ove objekte koji prihvaćaju i vaše kućne ljubimce.",
  "popular_landmarks_nearby": "Popularne znamenitosti u blizini",
  "popular_extractions_in": "{0} – popularne znamenitosti",
  "lbg.h2.all_inclusive": "Ovi najbolje ocijenjeni objekti potpuno će vas opustiti.",
  "travel_guide_by_category_header": "{1} – {0}",
  "lbg.travel_between": "Putujte između {0} i {1}",
  "keep_exploring": "Nastavite s istraživanjem",
  "what_you_need_to_know_before_you_go": "Što trebate znati prije putovanja?",
  "read_it": "Pročitajte",
  "view_on_map": "Prikaži na karti",
  "lbg.h1.destination_theme.lgbtq": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ goste – {0}",
  "lbg.h2.beach": "Gostima se sviđa boravak na plaži, posebno u ovim objektima.",
  "lbg.h1.destination_theme.vip_access": "Dobitnici nagrade za VIP Access – {0}",
  "see_all_accommodation": "Pogledajte sav smještaj",
  "shoppers": "ljubitelje shoppinga",
  "whats_around": "što se nalazi u blizini četvrti {0}",
  "group": "skupine",
  "hub.category.h2.couples": "Najbolja putovanja za parove – {0}",
  "hub.category.h1.shopping": "{0} – trgovine",
  "travel_community": "{0} <span>&#124;</span> <span>Putnička zajednica</span>",
  "hub.category.h2.group": "Najbolja grupna putovanja – {0}",
  "listicle_legal_msg": "Ovaj članak sadrži mišljenja uredničkog tima za Go Guides. Hotels.com plaća autorima za prikazivanje njihovih tekstova na ovoj web-lokaciji. Takva plaćanja mogu uključivati putne i druge troškove.",
  "lbg.h1.destination_theme.cleanliness": "Dobitnici nagrade u kategoriji čistoće – {0}",
  "menu.more": "Više",
  "lbg.section_properties_theme.lgbtq": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ goste – {0}",
  "lbg.section_award_winners_destination": "Dobitnici nagrade prema odredištu",
  "lbg.terms_conditions": "Uvjeti i odredbe",
  "hub.category.h2.nightlife": "Najbolja mjesta za noćne izlaske na lokaciji {0}",
  "hub.neighbourhood.h2": "Aktivnosti koje nudi {0}",
  "lbg.section_award_winners_theme_destination": "Dobitnici nagrade prema kategoriji – {0}",
  "lbg.section_award_world_winners": "Dobitnici nagrada u cijelome svijetu",
  "things_to_see_and_do_in_other_cities": "Znamenitosti i aktivnosti u drugim gradovima",
  "button.backToTop": "Natrag na vrh",
  "lbg.section_theme_world_winners.family": "Dobitnici nagrade u kategoriji smještaja za obiteljska putovanja",
  "hotels_near": "{0} – hoteli u blizini",
  "category.activity__sports_": "Aktivnosti",
  "trust_module.get_reward_night": "Osvojite nagradno noćenje",
  "hub.category.h1.business": "Poslovna putovanja – {0}",
  "category.food": "Hrana",
  "other_popular_destinations_in": "{0} – druga popularna odredišta",
  "browse_by_category": "Pregledajte destinaciju {0} po kategorijama",
  "see_more_attactions": "Pogledajte više znamenitosti",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Više informacija o destinaciji {0}",
  "lbg.h1.destination_theme.most_wanted": "Dobitnici nagrade za najpopularniji objekt – {0}",
  "see_all_properties_in": "Pogledajte objekte u četvrti {0}",
  "category.tours": "Obilasci",
  "hub.category.h1.sports": "Sportovi i aktivnosti – {0}",
  "where_will_you_go_next": "Koje je vaše sljedeće odredište?",
  "cities": "Gradovi",
  "lbg.theme.spa_hotel": "Sa spa centrom",
  "see_guide": "Pogledajte vodič",
  "lbg.section_properties_theme.ski": "Dobitnici nagrade u kategoriji skijaškog smještaja – {0}",
  "related_stories": "Povezani članci",
  "hub.destination.h2": "Što trebate znati prije putovanja?",
  "show_less": "Prikaži manje",
  "hub.category.h1.football": "Idealni vodič za {0} za prave nogometne navijače",
  "hub.category.h2.food": "{1} – najbolja hrana i restorani",
  "fallback_language_alert": "Stranica još nije prevedena na hrvatski…",
  "category_highlight.title.information": "Korisne informacije",
  "neighbourhood_guides_for_other_cities": "Vodiči po četvrtima drugih gradova",
  "lbg.h2.hubpage": "Ti izvrsni objekti imaju visoke ocjene gostiju i mnogo recenzija.",
  "travel_advisory.title": "Upozorenje za putovanja",
  "lbg.section_properties_theme.all_inclusive": "Dobitnici nagrade u kategoriji all-inclusive smještaja – {0}",
  "lbg.h2.boutique": "Ako volite boutique smještajne objekte, gosti toplo preporučuju boravak u ovima.",
  "lbg.section_properties_theme.family": "Dobitnici nagrade u kategoriji smještaja za obiteljska putovanja – {0}",
  "hub.category.h2.families": "Najbolja obiteljska putovanja – {0}",
  "from": "od",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Dobitnici nagrade u kategoriji idealnog smještaja za LGBTQ+ goste – {0}",
  "x_star": "{0,choice,0#|1#{0} zvjezdica|1.5#{0} zvjezdica|2#{0} zvjezdice|2.5#{0} zvjezdica|3#{0} zvjezdice|3.5#{0} zvjezdica|4#{0} zvjezdice|4.5#{0} zvjezdica|5#{0} zvjezdica}",
  "trust_module.free_cancellation": "Besplatno otkazivanje",
  "lbg.section_properties_theme.home_and_apartments": "Dobitnici nagrade u kategoriji apartmana i kuća za odmor – {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Stalni autor</span>",
  "things_to_see_and_do_in": "Znamenitosti i aktivnosti – <span class=\"next-line\">{0}</span>",
  "lbg.check_price": "Provjeri cijenu",
  "hub.category.h2.blogs": "Pišu iskusni putnici",
  "lbg.h2.home_and_apartments": "Tražite kuću za odmor uz udobnost doma? Pogledajte ove odlično ocijenjene objekte.",
  "hub.category.h2.information": "Korisne informacije o lokaciji {0}",
  "search_hotels_in": "<span class=\"extra-label\">{0}</span> – pretraživanje hotela",
  "hub.category.h2.nature": "Najbolja putovanja u prirodu – {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Dobitnici nagrade u kategoriji apartmana i kuća za odmor – {0}",
  "photo_by": "Autor fotografije: {0}",
  "more_things_to_do": "Više aktivnosti",
  "lbg.section_properties_theme.beach": "Dobitnici nagrade u kategoriji smještaja na plaži – {0}",
  "read_less": "Prikaži manje",
  "lbg.h2.spa_hotel": "Predahnite i opustite se u ovim najbolje ocijenjenim objektima koji su poznati po svojim spa sadržajima.",
  "second_fold_related_stories.see_also": "Pogledajte i ovo",
  "hub.category.h2.history": "Najbolja povijesna mjesta – {0}",
  "lbg.section_properties_theme.cleanliness": "Dobitnici nagrade u kategoriji čistoće – {0}",
  "lbg.h1.destination_theme.boutique": "Dobitnici nagrade u kategoriji boutique smještaja – {0}",
  "nightlife_b595ae4": "noćni život",
  "lbg.h1.destination_theme.beach": "Dobitnici nagrade u kategoriji smještaja na plaži – {0}",
  "trust_module.free_cancellation.disclaimer": "Neki hoteli zahtijevaju otkazivanje najmanje 24 – 48 sati prije prijave. Pojedinosti potražite na našoj web-lokaciji.",
  "lbg.h2.family": "Gosti odlično ocjenjuju ove smještajne objekte i savršeni su za obitelji.",
  "lbg.h2.lgbtq": "Gosti su odlično ocijenili ove objekte za LGBTQ goste.",
  "hub.category.h1.group": "Grupna putovanja – {0}",
  "popular_stories": "Popularne priče",
  "toc": "Sadržaj",
  "hub.category.h2.shopping": "Najbolje mogućnosti kupnje na lokaciji {0}",
  "lbg.section_theme_world_winners.beach": "Dobitnici nagrade u kategoriji smještaja na plaži",
  "page_score": "Ocjena ove stranice",
  "lbg.section_theme_world_winners.sustainable": "Dobitnici nagrade u kategoriji ekološki održivog smještaja",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Naša zajamčena cijena",
  "lbg.h1.destination_theme.all_inclusive": "Dobitnici nagrade u kategoriji all-inclusive smještaja – {0}",
  "top_destinations": "Najpopularnije destinacije",
  "hotel_prices_near": "{0} – cijene hotela u blizini",
  "lbg.score.cleanliness": "Čistoća:",
  "x_stars": "{0,choice,0#|1#{0} zvjezdica|1<{0} zvjezdice|4<{0} zvjezdica|10<{0} zvjezdica}",
  "lbg.theme.all_inclusive": "All-inclusive",
  "hub.category.h1.tours": "{0} – obilasci i jednodnevni izleti",
  "lbg.section_award_winners_theme": "Dobitnici nagrade prema kategoriji",
  "guest_writer": "{0} <span>&#124;</span> <span>Gostujući autor</span>",
  "start_planning_your_x_trip": "{0} – počnite sanjati o putovanju",
  "lbg.section_theme_world_winners.business": "Dobitnici nagrade u kategoriji smještaja za poslovna putovanja",
  "trending_now_in": "{0} – popularni članci",
  "history": "ljubitelje povijesti",
  "search_hotels": "Pretražite hotele",
  "lbg.terms_conditions_details": "Kriterij odabira dobitničkih smještajnih objekata {0}. temelji se na visokim ocjenama gostiju i najmanje 50 recenzija gostiju tijekom {1}.",
  "see_nearby_properties": "Prikaži smještaj u blizini",
  "hub.category.h1.history": "Povijesna mjesta – {0}",
  "location": "Lokacija:",
  "lbg.section_theme_world_winners.luxury": "Dobitnici nagrade u kategoriji luksuznog smještaja",
  "category_highlight.see_all.nightlife": "Više o noćnom životu",
  "also_popular": "Druga popularna odredišta",
  "hub.category.h2.things_to_do": "Najbolje aktivnosti na lokaciji {0}",
  "lbg.theme.family": "Za obiteljska putovanja",
  "lbg.h1.destination_theme.pet_friendly": "Dobitnici nagrade u kategoriji smještaja za putovanja s kućnim ljubimcima – {0}",
  "category_highlight.title.shopping": "Gdje kupovati i što kupiti – {0}",
  "price": "Cijena:",
  "hub.category.h2.beach": "Najbolje plaže − {0}",
  "neighbourhoods_in": "Četvrti grada {0}",
  "business": "poslovna putovanja",
  "photo": "ljubitelje fotografije",
  "blog.see_all_stories": "Pogledajte sve priče",
  "category_highlight.see_all.food": "Više o hrani i restoranima",
  "see_all_properties": "Pogledajte sve objekte",
  "lbg.theme.beach": "Na plaži",
  "what_to_see_and_do_in": "Što razgledati i raditi – {0}",
  "hub.category.h2.single": "Najbolja solo putovanja – {0}",
  "attractions_in": "{0} – znamenitosti",
  "read_more": "Prikaži više",
  "show_more": "Prikaži više",
  "hotels_near_this_landmark": "Hoteli u blizini te znamenitosti",
  "check_dates": "Provjeri datume",
  "featured_stories_and_fun_stuff": "Istaknute priče i zanimljivosti",
  "phone": "Telefon:",
  "lbg.section_search_header": "Pretražite dobitnike nagrada",
  "category_highlight.title.food": "Gdje i što jesti – {0}",
  "see_all_things_to_do": "Pogledajte sve aktivnosti",
  "lbg.section_properties_theme.sustainable": "Dobitnici nagrade u kategoriji ekološki održivog smještaja – {0}",
  "lbg.h1.hubpage": "Dobitnici nagrade „Gosti vole ovaj objekt” {0}.",
  "lbg.section_theme_world_winners.spa_hotel": "Dobitnici nagrade u kategoriji smještaja sa spa centrom",
  "category.maps": "Karte",
  "lbg.h1.destination_theme.family": "Dobitnici nagrade u kategoriji smještaja za obiteljska putovanja – {0}",
  "lbg.section_theme_world_winners.cleanliness": "Dobitnici nagrade u kategoriji čistoće",
  "lbg.section_theme_world_winners.vip_access": "Dobitnici nagrade za VIP Access",
  "trust_module.get_reward_night.night_you_stay": "za svakih 10 noćenja boravka",
  "modified": "preinačena",
  "lbg.section_properties": "Dobitnici nagrade – {0}",
  "trust_module.header": "Zašto rezervirati uz Hotels.com?",
  "hub.category.h2.luxury": "Najbolja luksuzna putovanja – {0}",
  "lbg.theme.ski": "Za skijanje",
  "lbg.h1.destination_theme.spa_hotel": "Dobitnici nagrade u kategoriji smještaja sa spa centrom – {0}",
  "lbg.h2.most_wanted": "Gosti toplo preporučuju ove objekte za savršen boravak.",
  "top10_hotels_incomplete_alert": "Zbog povećanog prometa neki se smještajni objekti možda trenutačno ne prikazuju. <span>Žao nam je zbog neugodnosti.</span>",
  "compulsive_traveller": "{0} <span>|</span> <span>Zaljubljenik u putovanja</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Dobitnici nagrade u kategoriji apartmana i kuća za odmor",
  "loved_by_guests_collection_in": "{0} – smještaj s osvojenom nagradom „Gosti vole ovaj objekt”",
  "title.suffix": "{0} – Go Guides",
  "hub.category.h2.maps": "Korisne karte lokacije {0}",
  "opening_hours": "Radno vrijeme",
  "hub.category.h2.photos": "Najbolja fotogenična odredišta – {0}",
  "lbg.theme.most_wanted": "Najpopularniji objekt",
  "blog.stories_container": "Priče suradnika",
  "hub.category.h2.budget": "Najbolja jeftina putovanja – {0}",
  "lbg.section_properties_theme.business": "Dobitnici nagrade u kategoriji smještaja za poslovna putovanja – {0}",
  "not_sure_where_to_stay": "Niste sigurni gdje odsjesti?",
  "category.information": "Informacije"
};