var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState } from 'react';
import { UitkCarousel, UitkCarouselPaginationDots } from 'uitk-react-carousel';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
var CarouselComponent = function (props) {
  var data = props.data,
    config = props.config,
    pagination = props.pagination;
  var carouselRef = React.useRef(null);
  var _a = __read(useState(0), 2),
    currentIndex = _a[0],
    setCurrentIndex = _a[1];
  var track = useEgClickstream();
  var trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'more_cards.presented',
      event_category: 'go_guides',
      action_location: config.carouselName
    },
    experience: config.experience
  };
  var trackScrollRight = function (id) {
    if (id.match(/\.scrollRight$/)) {
      track(trackObject);
    }
  };
  var setupTracker = function (id, description) {
    var callback = trackScrollRight;
    return {
      callback: callback,
      description: description,
      id: id
    };
  };
  var onIndexChange = function (index) {
    setCurrentIndex(index);
  };
  var analytics = setupTracker('UITK', 'Carousel Interaction');
  var defaultConfig = {
    analytics: analytics,
    carouselName: 'DefaultCarousel',
    carouselHeaderText: 'Default Carousel Header',
    itemsMaxHeight: true,
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4
    },
    pageBy: 4,
    peek: false,
    wrapItems: true,
    shouldSnapOnScroll: true,
    onIndexChange: onIndexChange,
    navigationIconId: {
      next: 'next-button-gallery-inset-pagination-dots',
      prev: 'prev-button-gallery-inset-pagination-dots'
    },
    buttonText: {
      nextButton: 'Show next card',
      prevButton: 'Show previous card'
    }
  };
  var configProps = __assign(__assign({}, defaultConfig), config);
  var cards = data;
  return React.createElement(React.Fragment, null, React.createElement(UitkCarousel, __assign({}, configProps, {
    carouselRef: carouselRef
  }), cards), pagination && carouselRef.current && carouselRef.current.changeIndex && cards.length > configProps.pageBy && React.createElement(UitkCarouselPaginationDots, {
    activeIndex: currentIndex,
    itemCount: cards.length,
    changeIndex: carouselRef.current.changeIndex
  }));
};
export default CarouselComponent;