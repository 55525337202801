var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React from 'react';
import { useState } from 'react';
import { useStores } from 'utils/useStore';
import MobileNavigation from './navigation-mobile';
import { UitkIcon } from 'uitk-react-icons';
import { UitkSticky } from 'uitk-react-sticky';
import { localization } from 'src/utils/localization';
var Navigation = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var _a = __read(useState(false), 2),
    isOpen = _a[0],
    setIsOpen = _a[1];
  var iconMapping = {
    destination: 'place',
    things_to_do: 'camera_alt',
    food: 'local_dining',
    shopping: 'local_shopping',
    nightlife: 'local_bar',
    information: 'info',
    tours: 'directions_bike',
    blogs: 'article',
    hotels: 'lob_hotels'
  };
  var menu = goGuidesStore.fetchNavigation(goGuidesStore.pageUrlData);
  var closeMenu = function () {
    handleClick(false);
  };
  var handleClick = function (status) {
    setIsOpen(status);
  };
  var loc = new localization();
  var categoryMenu = null;
  if (menu && menu.category && menu.category.length > 0) {
    categoryMenu = menu.category.map(function (item) {
      var linkTarget = item.categoryKey == 'hotels' ? '_blank' : '_self';
      var categoryName = loc.getLocalizedText("category.".concat(item.categoryKey));
      return React.createElement("li", {
        key: item.categoryKey
      }, React.createElement("a", {
        className: "nav-menu ".concat(item.categoryKey),
        href: item.targetUrl,
        target: linkTarget
      }, React.createElement(UitkIcon, {
        name: iconMapping[item.categoryKey]
      }), categoryName));
    });
  }
  return React.createElement(React.Fragment, null, menu && React.createElement(UitkSticky, {
    zIndex: 20
  }, React.createElement("div", {
    className: "navigation-wrapper ".concat(menu.category ? '' : 'no-list')
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("nav", {
    className: "main-navi".concat(isOpen ? ' nav-open' : '')
  }, React.createElement(MobileNavigation, {
    menu: menu,
    handleClick: handleClick,
    iconMapping: iconMapping
  }), React.createElement("ul", {
    className: "main"
  }, React.createElement("li", {
    className: "home"
  }, React.createElement("a", {
    className: "nav-menu",
    href: menu.home.targetUrl
  }, React.createElement("h3", null, menu.home.name))), menu.destination && (menu.destination.targetUrl && menu.destination.targetUrl != null ? React.createElement("li", null, React.createElement("a", {
    className: "nav-menu destination ".concat(menu.category ? '' : 'no-cut'),
    href: menu.destination.targetUrl
  }, React.createElement("span", null, React.createElement(UitkIcon, {
    name: "place"
  }), menu.destination.name))) : React.createElement("li", {
    className: "no-hover"
  }, React.createElement("div", {
    className: "nav-menu destination no-link"
  }, React.createElement("span", null, menu.destination.name)))), categoryMenu, React.createElement("a", {
    className: "close-button",
    onClick: closeMenu
  }, React.createElement(UitkIcon, {
    name: "close"
  }))))))));
};
export default Navigation;