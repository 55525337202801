export default {
  "current_deal": "Offre en cours",
  "lbg.section_properties_theme.pet_friendly": "Vainqueurs catégorie « Animaux de compagnie acceptés » à {0}",
  "address": "Adresse",
  "hub.homepage.h2": "Tant d’endroits à découvrir, et si peu de temps...",
  "search_hotels_near_this_landmark": "Recherchez des hôtels<span class=\"extra-label\"> près de ce site</span>",
  "hub.category.h1.things_to_do": "{0} : les activités",
  "lbg.h2.vip_access": "Les hébergements VIP Access ont été sélectionnés pour leurs excellents avis et l’expérience qu’ils procurent.",
  "lbg.theme.cleanliness": "Propreté",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Affichez plus",
  "link_long_form_to_listiicle_multiple": "{0} : l’un des points d’intérêt mis en évidence dans « {1} » et « {2} ». Découvrez tout ce qu’il y à savoir sur {3} ici.",
  "lbg.section_properties_theme.boutique": "Vainqueurs catégorie « Hôtels-boutiques » à {0}",
  "lbg.h2.luxury": "Découvrez le meilleur du luxe. C’est pourquoi les voyageurs adorent séjourner dans ces hébergements.",
  "hub.category.h1.information": "{0} : les informations utiles",
  "families": "Familles",
  "trending_now": "En vogue",
  "lbg.from": "À partir de",
  "hub.category.h1.road_trips": "{0} : les meilleurs road-trips",
  "lbg.h2.cleanliness": "Ces hébergements ont une très bonne note globale des avis voyageurs et se distinguent notamment pour la propreté avec une note excellente.",
  "single": "Célibataire",
  "couples": "Couples",
  "trust_module.price_guarantee.find_out_more": "En savoir plus",
  "top_destinations_in": "{0} : les destinations phares",
  "hub.category.h1.couples": "{0} : voyages en couple",
  "hub.category.h2.tours": "Meilleures excursions - {0}",
  "hub.category.h1.photos": "{0} : voyages photo",
  "category.photos": "Photos",
  "hub.category.h1.budget": "{0} : voyages pas chers",
  "hub.category.h2.ski": "{0} : les meilleures destinations ski",
  "lbg.section_theme_world_winners.most_wanted": "Vainqueurs catégorie « Les plus convoités »",
  "lbg.theme.home_and_apartments": "Maisons et appartements",
  "hub.category.h1.luxury": "{0} : voyages de luxe",
  "hub.category.h2.adventure": "{0} : le meilleur des voyages actifs",
  "hotels_in": "Hôtels : {0}",
  "lbg.section_search_winners": "Plus de vainqueurs",
  "see_more_hotels_in": "Affichez plus d’hôtels à {0}",
  "hub.category.h1.beach": " {0} : les plages",
  "open": "Ouverture :",
  "enter_your_destination": "Saisissez votre destination",
  "best_of": "{0} : les immanquables",
  "top10_hotels_missing_properties": "Certains hébergements pourraient ne pas s’afficher en ce moment. Veuillez nous excuser pour la gêne occasionnée.",
  "adventure": "Aventure",
  "lbg.h1.destination_theme.sustainable": "Vainqueurs catégorie « Écotourisme » à {0}",
  "luxury": "Luxe",
  "expand_map_view": "Agrandir la carte",
  "book_now": "Réserver",
  "lbg.h1.destination_theme.ski": "Vainqueurs catégorie « Ski » à {0}",
  "hub.category.h1.food": "{0} : la gastronomie",
  "category.blogs": "Blog",
  "budget": "Économique",
  "popular_neighbourhoods_in": "Les quartiers populaires de {0}",
  "hub.category.h1.unusual": "{0} : trésors cachés et lieux insolites",
  "see_all_accommodation_in": "Affichez les hébergements dans le quartier de {0}",
  "hub.category.h1.adventure": "{0} : voyages actifs",
  "category_highlight.see_all.shopping": "Plus d’informations sur le shopping",
  "lbg.section_most_popular_destination": "Les destinations les plus populaires",
  "lbg.section_properties_theme.luxury": "Vainqueurs catégorie « Luxe » à {0}",
  "unusual": "Insolite",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Profitez au maximum de votre voyage",
  "x_review": "{0,choice,0#|1#{0} avis|2#{0} avis|2<{0} avis|4<{0} avis|10#{0} avis|10<{0} avis}",
  "hub.category.h1.single": "{0} : voyages en solo",
  "hub.category.h2.custom": "En vogue",
  "lbg.section_theme_world_winners.lgbt": "Vainqueurs catégorie « LGBTQ+ »",
  "lbg.section_award_winners_theme_other": "Autres thèmes pour les vainqueurs à {0}",
  "lbg.supplier_collected_charges": "+ {0} à payer sur place",
  "in_destination": " {0}",
  "lbg.section_properties_theme.vip_access": "Vainqueurs dans la catégorie « VIP Access » - {0}",
  "link_long_form_to_listiicle_single": "{0} : l’un des points d’intérêt mis en évidence dans « {1} ». Découvrez tout ce qu’il y à savoir sur {2} ici.",
  "lbg.section_theme_world_winners.boutique": "Vainqueurs catégorie « Hôtels-boutiques »",
  "lbg.section_properties_theme.spa_hotel": "Vainqueurs catégorie « Spa » à {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Vainqueurs catégorie « Tout compris »",
  "hub.category.h2": "{0} : votre guide de voyage",
  "lbg.properties.price_tooltip": "Il s’agit du prix le plus bas par nuit sur notre site pour les 14 prochains jours.",
  "hub.category.h2.road_trips": "{0} : les meilleurs road-trips",
  "hub.category.h1.ski": "Destinations ski - {0}",
  "food": "Gastronomie",
  "lbg.theme.pet_friendly": "Animaux de compagnie acceptés",
  "lbg.theme.business": "Affaires",
  "hub.category.h1.nature": "{0} : les meilleures escapades en pleine nature",
  "map": "Carte",
  "category_highlight.see_all.information": "Plus d’informations",
  "hotel_prices_in": "Prix des hôtels à {0}",
  "hub.category.h1.nightlife": "{0} : la vie nocturne",
  "trust_module.free_cancellation.most_hotels": "dans la plupart des hôtels*",
  "also_popular_in": "Destination {0} : également en vogue par ici",
  "lbg.h1.destination_theme.business": "Vainqueurs catégorie « Hôtels d’affaires » à {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Vainqueurs catégorie « Animaux de compagnie acceptés »",
  "hub.category.h1.maps": "{0} : cartes",
  "lbg.section_properties_theme.most_wanted": "Vainqueurs catégorie « Les plus convoités » à {0}",
  "category.nightlife": "Vie nocturne",
  "search_hotels_in_this_area": "Recherchez des hôtels<span class=\"extra-label\"> dans cette zone</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 vote|2#2 votes|2<{1} votes|4<{1} votes|10#10 votes|10<{1} votes})",
  "hub.category.h2.business": "{0} : le meilleur des voyages d’affaires",
  "category.things_to_do": "Sites et attractions",
  "destination_travel_guide_by_category": "{0} : guide de voyage par catégories",
  "hub.category.h1.families": "{0} : voyages en famille",
  "lbg.section_theme_world_winners.ski": "Vainqueurs catégorie « Ski »",
  "you_may_also_like": "Vous pourriez aussi aimer...",
  "lbg.theme.sustainable": "Écotourisme",
  "hub.category.h2.unusual": "{0} : le meilleur des trésors cachés et lieux insolites",
  "hub.category.h2.football": "Encouragez votre équipe de foot et profitez à fond de votre voyage !",
  "top_hotel": "Hôtel prisé",
  "lbg.h1.destination_theme.luxury": "Vainqueurs catégorie « Luxe » à {0}",
  "top10_hotels_empty_alert": "Malheureusement, une erreur technique est survenue. <span>Veuillez nous excuser pour la gêne occasionnée.</span>",
  "special_deal": "Promo",
  "lbg.h2.sustainable": "La durabilité est primordiale ! Découvrez ces hébergements qui réunissent tous les critères pour un séjour inoubliable.",
  "where_to_stay_in": "Où séjourner à {0}",
  "start_planning_your_trip": "Préparez votre voyage",
  "good_for": "Recommandé pour :",
  "category.hotels": "Hôtels",
  "x_accommodation_guide": "{0} : guide aux hébergements",
  "lbg.theme.luxury": "Luxe",
  "hub.category.h2.sports": "{0} : le meilleur du sport et des activités",
  "lbg.section_theme_world_winners.lgbtq": "Vainqueurs dans la catégorie « LGBTQ  »",
  "see_more_neighbourhoods": "Affichez plus de quartiers",
  "category_highlight.title.nightlife": "{0} : où sortir et que faire en soirée ?",
  "lbg.h1.destination_theme.lgbt": "{0} : vainqueurs catégorie « LGBTQ+ »",
  "hub.category.h1.blogs": "{0} - Blog de voyage",
  "lbg.h2.business": "Ces hébergements ont reçu d’excellents avis de la part des voyageurs d’affaires.",
  "hotels": "Hôtels",
  "lbg.h2.pet_friendly": "Les voyageurs recommandent ces hébergements qui acceptent les animaux de compagnie.",
  "popular_landmarks_nearby": "Sites populaires à proximité",
  "popular_extractions_in": "Sites d’intérêt populaires à {0}",
  "lbg.h2.all_inclusive": "Passez un séjour sans stress dans ces hébergements très bien notés.",
  "travel_guide_by_category_header": "{1} : {0} à ne pas manquer",
  "lbg.travel_between": "Voyagez entre le {0} et le {1}",
  "keep_exploring": "À la découverte du monde",
  "what_you_need_to_know_before_you_go": "À savoir avant de partir",
  "read_it": "Lire la suite",
  "view_on_map": "Affichez sur la carte",
  "lbg.h1.destination_theme.lgbtq": "Vainqueurs dans la catégorie « LGBTQ » - {0}",
  "lbg.h2.beach": "Les voyageurs adorent les vacances à la plage, surtout dans ces hébergements.",
  "lbg.h1.destination_theme.vip_access": "Vainqueurs dans la catégorie « VIP Access » - {0}",
  "see_all_accommodation": "Consultez tous les hébergements",
  "shoppers": "Shopping",
  "whats_around": "aux alentours de : {0}",
  "group": "Groupe",
  "hub.category.h2.couples": "{0} : le meilleur des voyages en couple",
  "hub.category.h1.shopping": "{0} : le shopping",
  "travel_community": "{0} <span>&#124;</span> <span>Communauté de voyageurs</span>",
  "hub.category.h2.group": "{0} : le meilleur des voyages en groupe",
  "listicle_legal_msg": "Cet article contient les avis de l’équipe éditoriale des guides Go! Hotels.com rémunère les auteurs des articles publiés sur ce site. Cette rémunération peut inclure des frais de voyage et d’autres frais.",
  "lbg.h1.destination_theme.cleanliness": "Vainqueurs dans la catégorie « Propreté » - {0}",
  "menu.more": "Plus",
  "lbg.section_properties_theme.lgbtq": "Vainqueurs dans la catégorie « LGBTQ » - {0}",
  "lbg.section_award_winners_destination": "Vainqueurs par destination",
  "lbg.terms_conditions": "Conditions générales d’utilisation",
  "hub.category.h2.nightlife": "Meilleurs plans vie nocturne - {0}",
  "hub.neighbourhood.h2": "{0} : les activités",
  "lbg.section_award_winners_theme_destination": "Vainqueurs par thème en {0}",
  "lbg.section_award_world_winners": "Vainqueurs dans le monde entier",
  "things_to_see_and_do_in_other_cities": "Activités à faire dans d’autres villes",
  "button.backToTop": "Haut de la page",
  "lbg.section_theme_world_winners.family": "Vainqueurs catégorie « Familles »",
  "hotels_near": "Hôtels vers {0}",
  "category.activity__sports_": "Activités",
  "trust_module.get_reward_night": "Obtenez une nuit bonus",
  "hub.category.h1.business": "{0} : voyages d’affaires",
  "category.food": "Gastronomie",
  "other_popular_destinations_in": "Autres destinations populaires à {0}",
  "browse_by_category": "Destination {0} : explorer par catégorie",
  "see_more_attactions": "Affichez plus de sites d’intérêt",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Destination {0} : en savoir plus",
  "lbg.h1.destination_theme.most_wanted": "Vainqueurs catégorie « Les plus convoités » à {0}",
  "see_all_properties_in": "Afficher tous les hébergements",
  "category.tours": "Activités",
  "hub.category.h1.sports": "{0} : sports et activités",
  "where_will_you_go_next": "Quelle sera votre prochaine escale ?",
  "cities": "Villes",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Consultez le guide",
  "lbg.section_properties_theme.ski": "Vainqueurs catégorie « Ski » à {0}",
  "related_stories": "Articles similaires",
  "hub.destination.h2": "À savoir avant de partir",
  "show_less": "Affichez moins",
  "hub.category.h1.football": "{0} : le guide par excellence des vrais fans de foot",
  "hub.category.h2.food": "Meilleurs plats et restaurants - {0}",
  "fallback_language_alert": "Nous sommes désolés, cette page n’a pas encore été traduite dans votre langue...",
  "category_highlight.title.information": "Informations utiles",
  "neighbourhood_guides_for_other_cities": "Guides des quartiers pour d’autres villes",
  "lbg.h2.hubpage": "Ces hébergements incroyables ont des notes de voyageurs élevées et de nombreux avis.",
  "travel_advisory.title": "Alerte de voyage",
  "lbg.section_properties_theme.all_inclusive": "Vainqueurs catégorie « Tout compris » à {0}",
  "lbg.h2.boutique": "Si vous aimez les hébergements de type boutique, les voyageurs recommandent ceux-ci.",
  "lbg.section_properties_theme.family": "Vainqueurs catégorie « Familles » à {0}",
  "hub.category.h2.families": "{0} : le meilleur des voyages en famille",
  "from": "à partir de",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "{0} : vainqueurs catégorie « LGBTQ+ »",
  "x_star": "{0,choice,0#|1#{0} étoile|1.5#{0} étoile|2#{0} étoiles|2.5#{0} étoiles|3#{0} étoiles|3.5#{0} étoiles|4#{0} étoiles|4.5#{0} étoiles|5#{0} étoiles}",
  "trust_module.free_cancellation": "Annulation gratuite",
  "lbg.section_properties_theme.home_and_apartments": "Vainqueurs catégorie « Maisons & appartements » à {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Auteur collaborateur</span>",
  "things_to_see_and_do_in": "Activités <span class=\"next-line\">à {0}</span>",
  "lbg.check_price": "Affichez le prix",
  "hub.category.h2.blogs": "par des voyageurs expérimentés",
  "lbg.h2.home_and_apartments": "Envie de vous sentir à la maison même en vacances ? Jetez un œil à ces hébergements ayant reçu de très bonnes notes.",
  "hub.category.h2.information": "Infos utiles - {0}",
  "search_hotels_in": "Recherchez des hôtels<span class=\"extra-label\"> à {0}</span>",
  "hub.category.h2.nature": "{0} : les meilleures escapades en pleine nature",
  "lbg.h1.destination_theme.home_and_apartments": "Vainqueurs catégorie « Maisons & appartements » à {0}",
  "photo_by": "photo de {0}",
  "more_things_to_do": "Plus d’activités",
  "lbg.section_properties_theme.beach": "Vainqueurs catégorie « Plage » à {0}",
  "read_less": "Affichez moins d’infos",
  "lbg.h2.spa_hotel": "Oubliez le stress et détendez-vous dans ces hébergements populaires réputés pour leurs spas.",
  "second_fold_related_stories.see_also": "Découvrez aussi...",
  "hub.category.h2.history": "{0} : le meilleur des lieux historiques",
  "lbg.section_properties_theme.cleanliness": "Vainqueurs dans la catégorie « Propreté » - {0}",
  "lbg.h1.destination_theme.boutique": "Vainqueurs catégorie « Hôtels-boutiques » à {0}",
  "nightlife_b595ae4": "Vie nocturne",
  "lbg.h1.destination_theme.beach": "Vainqueurs catégorie « Plage » à {0}",
  "trust_module.free_cancellation.disclaimer": "Dans certains hôtels, les annulations doivent obligatoirement être effectuées au moins 24 à 48 heures avant l’arrivée. Plus de détails sur le site.",
  "lbg.h2.family": "Ces hébergements ont reçu d’excellents avis voyageurs et sont parfaits pour les familles.",
  "lbg.h2.lgbtq": "Ces hébergements ouverts à la communauté LGBTQ ont reçu d’excellents avis voyageurs.",
  "hub.category.h1.group": "{0} : voyages en groupe",
  "popular_stories": "Articles populaires",
  "toc": "Contenu",
  "hub.category.h2.shopping": "Meilleurs plans shopping - {0}",
  "lbg.section_theme_world_winners.beach": "Vainqueurs catégorie « Plage »",
  "page_score": "Cette page a obtenu",
  "lbg.section_theme_world_winners.sustainable": "Vainqueurs catégorie « Écotourisme »",
  "lbg.theme.boutique": "Hôtels-boutiques",
  "trust_module.price_guarantee": "Notre Garantie de prix",
  "lbg.h1.destination_theme.all_inclusive": "Vainqueurs catégorie « Tout compris » à {0}",
  "top_destinations": "Destinations Phares",
  "hotel_prices_near": "Prix des hôtels vers {0}",
  "lbg.score.cleanliness": "Propreté :",
  "x_stars": "{0,choice,0#|1#{0} étoile|1<{0} étoiles|4<{0} étoiles|10<{0} étoiles}",
  "lbg.theme.all_inclusive": "Tout compris",
  "hub.category.h1.tours": "{0} : excursions et visites",
  "lbg.section_award_winners_theme": "Vainqueurs par thème",
  "guest_writer": "{0} <span>&#124;</span> <span>Auteur invité</span>",
  "start_planning_your_x_trip": "Préparez votre voyage à {0}",
  "lbg.section_theme_world_winners.business": "Vainqueurs catégorie « Hôtels d’affaires »",
  "trending_now_in": "En vogue en ce moment à {0}",
  "history": "Histoire",
  "search_hotels": "Recherchez des hôtels",
  "lbg.terms_conditions_details": "Les critères de sélection des hébergements lauréats en {0} tiennent compte des notes élevées des voyageurs en {1} et du nombre d’avis voyageurs laissés (50 minimum).",
  "see_nearby_properties": "Afficher les hébergements voisins",
  "hub.category.h1.history": "{0} : lieux historiques",
  "location": "Emplacement :",
  "lbg.section_theme_world_winners.luxury": "Vainqueurs catégorie « Luxe »",
  "category_highlight.see_all.nightlife": "Plus d’informations sur la vie nocturne",
  "also_popular": "Autres destinations prisées",
  "hub.category.h2.things_to_do": "Meilleures attractions - {0}",
  "lbg.theme.family": "Familles",
  "lbg.h1.destination_theme.pet_friendly": "Vainqueurs catégorie « Animaux de compagnie acceptés » à {0}",
  "category_highlight.title.shopping": "{0} : que faut-il acheter et où ?",
  "price": "Prix :",
  "hub.category.h2.beach": "{0} : les meilleures plages",
  "neighbourhoods_in": "quartiers à {0}",
  "business": "Affaires",
  "photo": "Photographie",
  "blog.see_all_stories": "Consulter tous les articles",
  "category_highlight.see_all.food": "Plus d’informations sur la gastronomie",
  "see_all_properties": "Afficher tous les hébergements",
  "lbg.theme.beach": "Plage",
  "what_to_see_and_do_in": "{0} : quelles sont les activités incontournables ?",
  "hub.category.h2.single": "{0} : le meilleur des voyages en solo",
  "attractions_in": "Sites d’intérêt : {0}",
  "read_more": "Afficher plus d’infos",
  "show_more": "Afficher plus",
  "hotels_near_this_landmark": "Hôtels près de ce site",
  "check_dates": "Vérifiez les dates",
  "featured_stories_and_fun_stuff": "Articles à la une et divertissement",
  "phone": "Téléphone :",
  "lbg.section_search_header": "Recherchez des vainqueurs",
  "category_highlight.title.food": "{0} : que manger et où ?",
  "see_all_things_to_do": "Affichez toutes les activités",
  "lbg.section_properties_theme.sustainable": "Vainqueurs catégorie « Écotourisme » à {0}",
  "lbg.h1.hubpage": "Palmarès des vainqueurs du Prix des voyageurs {0} !",
  "lbg.section_theme_world_winners.spa_hotel": "Vainqueurs catégorie « Spa »",
  "category.maps": "Plans",
  "lbg.h1.destination_theme.family": "Vainqueurs catégorie « Familles » à {0}",
  "lbg.section_theme_world_winners.cleanliness": "Vainqueurs dans la catégorie « Propreté » ",
  "lbg.section_theme_world_winners.vip_access": "Vainqueurs dans la catégorie « VIP Access »",
  "trust_module.get_reward_night.night_you_stay": "chaque fois que vous séjournez 10 nuits",
  "modified": "modifiée",
  "lbg.section_properties": "Vainqueurs en {0}",
  "trust_module.header": "Pourquoi réserver avec Hotels.com ?",
  "hub.category.h2.luxury": "{0} : le meilleur des voyages de luxe",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Vainqueurs catégorie « Spa » à {0}",
  "lbg.h2.most_wanted": "Les voyageurs recommandent ces hébergements pour un séjour parfait.",
  "top10_hotels_incomplete_alert": "En raison de la forte demande, certains hébergements pourraient ne pas s’afficher en ce moment. <span>Veuillez nous excuser pour la gêne occasionnée.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Fan de voyages</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Vainqueurs catégorie « Maisons & appartements »",
  "loved_by_guests_collection_in": "Collection Prix des voyageurs à {0}",
  "title.suffix": "{0} : Guides Go",
  "hub.category.h2.maps": "Cartes pratiques - {0}",
  "opening_hours": "Horaires d’ouverture",
  "hub.category.h2.photos": "{0} : le meilleur des voyages photo",
  "lbg.theme.most_wanted": "Les plus convoités",
  "blog.stories_container": "Articles de blog",
  "hub.category.h2.budget": "{0} : le meilleur des voyages pas chers",
  "lbg.section_properties_theme.business": "Vainqueurs catégorie « Hôtels d’affaires » à {0}",
  "not_sure_where_to_stay": "Des doutes sur l’endroit où vous allez séjourner ?",
  "category.information": "Informations"
};