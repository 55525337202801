import React from 'react';
import { UitkLink } from "uitk-react-link";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { RenderImage } from 'src/components/utils/responsive-image';
var ListicleItemImage = function (_a) {
  var image = _a.image,
    name = _a.name,
    itemTargetUrl = _a.itemTargetUrl,
    linkTargetPdp = _a.linkTargetPdp,
    linkRel = _a.linkRel;
  var imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066',
        large: '&width=1600&height=1066'
      }
    }
  };
  return React.createElement(React.Fragment, null, image.imageUrl && React.createElement("div", {
    className: "img-wrap"
  }, itemTargetUrl ? React.createElement(UitkLink, null, React.createElement("a", {
    href: itemTargetUrl,
    target: linkTargetPdp,
    rel: linkRel
  }, React.createElement(UitkFigure, {
    className: "img",
    ratio: UitkFigureAspectRatioType.R3_2,
    roundcorner: true
  }, React.createElement(RenderImage, {
    imgProps: imageProps
  })))) : React.createElement(UitkFigure, {
    className: "img",
    ratio: UitkFigureAspectRatioType.R3_2
  }, React.createElement(RenderImage, {
    imgProps: imageProps
  }))));
};
export default ListicleItemImage;