import React from 'react';
import { checkValue } from 'components/functions';
import ListicleItemHeader from './item-header';
import ListicleItemImage from './item-image';
import SegmentComponent from '../../generic/segments';
import GoGuidesLocationInfoComponent from '../../location-info/location-info';
import ListicleItemMap from './item-map';
import ButtonNearbyProperties from './item-nearby-properties';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import ImageAttribution from '../../generic/image-attribution';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { localization } from 'src/utils/localization';
var Displayitem = function (props) {
  var _a, _b;
  var item = props.item,
    index = props.index,
    hideNumber = props.hideNumber;
  var itemNumber = index + 1;
  var goGuidesStore = useStores().goGuidesStore;
  var itemTargetUrl = '';
  var itemCtaUrl = '';
  var itemCtaKey = '';
  var loc = new localization();
  if (checkValue(item.targetUrl)) {
    itemTargetUrl = item.targetUrl;
  } else if (checkValue(item.pdpLink)) {
    itemTargetUrl = item.pdpLink.targetUrl;
  }
  if (checkValue(item.nearbyProperties)) {
    itemCtaUrl = item.nearbyProperties.targetUrl;
    itemCtaKey = loc.getLocalizedText('see_nearby_properties');
  } else if (checkValue(item.pdpLink)) {
    itemCtaUrl = item.pdpLink.targetUrl;
    itemCtaKey = loc.getLocalizedText('book_now');
  }
  var linkTargetPdp = checkValue(item.pdpLink) ? '_blank' : undefined;
  var linkRel = checkValue(item.pdpLink) ? 'noreferrer' : undefined;
  var trackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'Listicle Item',
      module_sort_position: itemNumber
    }
  };
  return React.createElement(React.Fragment, null, React.createElement("li", {
    className: "listicle-item\n              ".concat(item.image && checkValue(item.image.imageUrl) ? '' : ' no-img', "\n              ").concat(checkValue(item.tagLine && !hideNumber) ? '' : ' no-tagline', "\n              ").concat(checkValue(item.goodFor) ? '' : ' no-tag', "\n            "),
    key: index
  }, React.createElement(EgClickstreamImpressionTracker, {
    eventData: trackingObject
  }, React.createElement(UitkCard, {
    border: true
  }, React.createElement(UitkCardContentSection, {
    className: "listicle-item-wrap"
  }, React.createElement(UitkCardContentSection, null, React.createElement(ListicleItemHeader, {
    hideNumber: hideNumber,
    number: itemNumber,
    item: item,
    itemTargetUrl: itemTargetUrl,
    linkTargetPdp: linkTargetPdp,
    linkRel: linkRel
  }), item.image && checkValue(item.image.imageUrl) && React.createElement(ListicleItemImage, {
    image: item.image,
    name: item.name,
    itemTargetUrl: itemTargetUrl,
    linkTargetPdp: linkTargetPdp,
    linkRel: linkRel
  })), React.createElement(UitkCardContentSection, {
    className: "item-content-wrap"
  }, checkValue(item.goodFor) && React.createElement(SegmentComponent, {
    data: item.goodFor,
    i18nKey: loc.getLocalizedText('good_for')
  }), React.createElement("div", {
    className: "description-wrap"
  }, React.createElement("div", {
    className: "inner-warp",
    dangerouslySetInnerHTML: {
      __html: item.description
    }
  }), item.targetUrl && React.createElement("a", {
    href: item.targetUrl,
    className: "read-more"
  }, loc.getLocalizedText('read_more'))), React.createElement(GoGuidesLocationInfoComponent, {
    info: item.info,
    isListicle: true
  }), React.createElement(ListicleItemMap, {
    name: item.name,
    geo: (_a = item.info) === null || _a === void 0 ? void 0 : _a.geo,
    i18nKey: loc.getLocalizedText('map'),
    nearbyProperties: item.nearbyProperties,
    pdpLink: item.pdpLink
  }), checkValue(itemCtaUrl) && React.createElement(ButtonNearbyProperties, {
    itemCtaUrl: itemCtaUrl,
    itemCtaKey: itemCtaKey,
    pdpLink: checkValue(item.pdpLink)
  })))), item.image && item.image.attribution && item.image.attribution.attributionVersion !== 'CC0' && React.createElement(ImageAttribution, {
    attribution: (_b = item.image) === null || _b === void 0 ? void 0 : _b.attribution
  }))));
};
export default Displayitem;