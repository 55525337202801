import React from 'react';
import { checkValue } from 'components/functions';
import { RenderImage } from 'src/components/utils/responsive-image';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { useStores } from 'utils/useStore';
import ImageAttribution from '../generic/image-attribution';
var GoGuidesLongContentsComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchLongContents(goGuidesStore.pageUrlData);
  var contents = contentData.contents;
  var longformContent = checkValue(contents) ? contents : [];
  var ContentItem = function (_a) {
    var _b, _c;
    var item = _a.item;
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      params: {
        size: {
          small: '&width=800&height=533',
          medium: '&width=1600&height=1066',
          large: '&width=1600&height=1066'
        }
      }
    };
    return React.createElement(React.Fragment, null, item.html ? React.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: item.html.paragraph
      }
    }) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "img-wrap"
    }, React.createElement(UitkFigure, {
      className: "img",
      ratio: UitkFigureAspectRatioType.R3_2,
      roundcorner: true
    }, React.createElement(RenderImage, {
      imgProps: imageProps
    })), item.image.attribution && item.image.attribution.attributionVersion !== 'CC0' && React.createElement(ImageAttribution, {
      attribution: (_c = item.image) === null || _c === void 0 ? void 0 : _c.attribution
    }))));
  };
  return React.createElement(React.Fragment, null, checkValue(longformContent) && React.createElement("div", {
    className: "content-container"
  }, longformContent.map(function (item, index) {
    return React.createElement(ContentItem, {
      key: index,
      item: item
    });
  })));
};
export default GoGuidesLongContentsComponent;