import React from 'react';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkHeading } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkCard } from 'uitk-react-cards';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
var CardSizeXL = function (props) {
  var data = props.data;
  var name = data.name,
    targetUrl = data.targetUrl,
    snippetText = data.snippetText,
    image = data.image,
    index = data.index;
  var imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066'
      }
    }
  };
  var loc = new localization();
  var buttonKey = loc.getLocalizedText('read_more');
  return React.createElement("li", {
    key: index,
    className: "col-12 highlighted"
  }, React.createElement(UitkCard, {
    border: true,
    className: 'item-list-wrapper row'
  }, React.createElement(UitkFigure, {
    className: "img-wrapper col-12 col-m8",
    ratio: UitkFigureAspectRatioType.R16_9
  }, React.createElement(UitkLink, null, React.createElement("a", {
    href: targetUrl
  }, React.createElement(RenderImage, {
    imgProps: imageProps
  })))), React.createElement("div", {
    className: "detail-wrapper col-12 col-m4"
  }, React.createElement("div", {
    className: "header-wrap"
  }, React.createElement(UitkLink, null, React.createElement("a", {
    href: targetUrl
  }, React.createElement(UitkHeading, {
    size: 5,
    tag: "h3"
  }, name)))), React.createElement("div", {
    className: "description-wrapper"
  }, React.createElement("p", {
    dangerouslySetInnerHTML: {
      __html: snippetText
    }
  }), React.createElement(UitkLink, {
    className: "read-more"
  }, React.createElement("a", {
    href: targetUrl
  }, buttonKey))))));
};
export default CardSizeXL;