import React from 'react';
import CardSizeXL from './card-xl';
var BucketOneCard = function (_a) {
  var data = _a.data;
  return React.createElement("ul", {
    className: "row item-list-container"
  }, data.map(function (item, index) {
    return React.createElement(CardSizeXL, {
      key: index,
      data: item
    });
  }));
};
export default BucketOneCard;