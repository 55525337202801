export default {
  "current_deal": "Текущее предложение",
  "lbg.section_properties_theme.pet_friendly": "Обладатели награды в категории «Отели с возможностью проживания с домашними животными», {0}",
  "address": "Адрес",
  "hub.homepage.h2": "Так много мест, так мало времени...",
  "search_hotels_near_this_landmark": "Искать отели<span class=\"extra-label\"> рядом с этой достопримечательностью</span>",
  "hub.category.h1.things_to_do": "{0}: интересные занятия",
  "lbg.h2.vip_access": "Объекты размещения VIP Access выбраны благодаря высоким оценкам гостей и высочайшему уровню обслуживания.",
  "lbg.theme.cleanliness": "Чистота",
  "lbg.theme.lgbtq": "ЛГБТК",
  "load_more": "Загрузить больше",
  "link_long_form_to_listiicle_multiple": "{0} — одно из самых интересных мест, относящихся к {1} и {2} (открыть полную статью о городе {3})",
  "lbg.section_properties_theme.boutique": "Обладатели награды в категории «Бутик-отели», {0}",
  "lbg.h2.luxury": "Откройте для себя все великолепие роскоши. Вот почему гостям так нравится проживание в этих объектах размещения.",
  "hub.category.h1.information": "{0}: полезная информация",
  "families": "Семьи",
  "trending_now": "Популярно сегодня",
  "lbg.from": "С",
  "hub.category.h1.road_trips": "{0}: лучшие поездки на автомобиле",
  "lbg.h2.cleanliness": "Эти объекты размещения имеют высокий общий рейтинг по отзывам гостей и самые высокие оценки за чистоту по отзывам гостей.",
  "single": "Соло",
  "couples": "Пары",
  "trust_module.price_guarantee.find_out_more": "подробнее",
  "top_destinations_in": "{0}: популярные направления",
  "hub.category.h1.couples": "{0}: отдых вдвоем",
  "hub.category.h2.tours": "{0}: лучшие экскурсии",
  "hub.category.h1.photos": "{0}: фотопутешествия ",
  "category.photos": "Фотографии",
  "hub.category.h1.budget": "{0}: экономичный отдых",
  "hub.category.h2.ski": "{0}: лучшие горнолыжные курорты",
  "lbg.section_theme_world_winners.most_wanted": "Обладатели награды «Самый популярный отель»",
  "lbg.theme.home_and_apartments": "Дома и апартаменты",
  "hub.category.h1.luxury": "{0}: отдых класса «люкс»",
  "hub.category.h2.adventure": "{0}: лучший активный отдых",
  "hotels_in": "{0}: отели",
  "lbg.section_search_winners": "Найти других обладателей награды",
  "see_more_hotels_in": "Другие отели в {0}",
  "hub.category.h1.beach": "{0}: пляжи",
  "open": "Часы работы:",
  "enter_your_destination": "Введите пункт назначения",
  "best_of": "{0}: подборка",
  "top10_hotels_missing_properties": "Некоторые варианты сейчас могут не отображаться. Приносим свои извинения за возможные неудобства.",
  "adventure": "Экстремальный туризм",
  "lbg.h1.destination_theme.sustainable": "Обладатели награды в категории «Эко-отели», {0}",
  "luxury": "Класс «люкс»",
  "expand_map_view": "Развернуть карту",
  "book_now": "Забронировать",
  "lbg.h1.destination_theme.ski": "Обладатели награды в категории «Горнолыжные отели», {0}",
  "hub.category.h1.food": "{0}: рестораны и питание",
  "category.blogs": "Блог",
  "budget": "Бюджет",
  "popular_neighbourhoods_in": "{0}: популярные районы",
  "hub.category.h1.unusual": "{0}: тайные достопримечательности и необычные места",
  "see_all_accommodation_in": "{0}: посмотреть варианты жилья",
  "hub.category.h1.adventure": "{0}: активный отдых",
  "category_highlight.see_all.shopping": "Подробнее о шопинге",
  "lbg.section_most_popular_destination": "Самые популярные направления",
  "lbg.section_properties_theme.luxury": "Обладатели награды в категории «Отели класса \"люкс\"», {0}",
  "unusual": "Нестандартные поездки",
  "category.shopping": "Шопинг",
  "make_the_most_of_your_trip": "Сделайте свою поездку незабываемой",
  "x_review": "{0,choice,0#|1#{0} отзыв|2#{0} отзыва|2<{0} отзыва|4<{0} отзывов|10#{0} отзывов|10<{0} отзывов}",
  "hub.category.h1.single": "{0}: индивидуальные путешествия",
  "hub.category.h2.custom": "Популярно сегодня",
  "lbg.section_theme_world_winners.lgbt": "Обладатели награды в категории «ЛГБТК+»",
  "lbg.section_award_winners_theme_other": "Обладатели награды в других категориях, {0}",
  "lbg.supplier_collected_charges": "+ сборы к оплате в объекте размещения в размере {0}",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "{0}: обладатели награды в категории VIP Access",
  "link_long_form_to_listiicle_single": "{0} — одно из самых интересных мест, относящихся к {1} (открыть полную статью о городе {2})",
  "lbg.section_theme_world_winners.boutique": "Обладатели награды в категории «Бутик-отели»",
  "lbg.section_properties_theme.spa_hotel": "Обладатели награды в категории «Спа-отели», {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Обладатели награды в категории «Все включено»",
  "hub.category.h2": "{0}: путеводитель",
  "lbg.properties.price_tooltip": "Это самая низкая цена за сутки на нашем сайте на следующие 14 дней.",
  "hub.category.h2.road_trips": "{0}: лучшие поездки на автомобиле",
  "hub.category.h1.ski": "{0}: горнолыжные курорты",
  "food": "Еда",
  "lbg.theme.pet_friendly": "Проживание с домашними животными",
  "lbg.theme.business": "Бизнес-отели",
  "hub.category.h1.nature": "{0}: лучшие места отдыха на природе",
  "map": "Карта",
  "category_highlight.see_all.information": "Подробнее",
  "hotel_prices_in": "{0}: цены на отели",
  "hub.category.h1.nightlife": "{0}: ночная жизнь",
  "trust_module.free_cancellation.most_hotels": "в большинстве отелей*",
  "also_popular_in": "{0}: другие популярные места",
  "lbg.h1.destination_theme.business": "Обладатели награды в категории «Бизнес-отели», {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Обладатели награды в категории «Отели с возможностью проживания с домашними животными»",
  "hub.category.h1.maps": "{0}: карты ",
  "lbg.section_properties_theme.most_wanted": "Обладатели награды «Самый популярный отель», {0}",
  "category.nightlife": "Ночная жизнь",
  "search_hotels_in_this_area": "Искать отели<span class=\"extra-label\"> в этом районе</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 голос|2#2 голоса|2<{1} голоса|4<{1} голосов|10#10 голосов|10<{1} голосов})",
  "hub.category.h2.business": "{0}: лучшие бизнес-путешествия",
  "category.things_to_do": "Интересные занятия",
  "destination_travel_guide_by_category": "{0}: Путеводитель по категориям",
  "hub.category.h1.families": "{0}: семейный отдых",
  "lbg.section_theme_world_winners.ski": "Обладатели награды в категории «Горнолыжные отели»",
  "you_may_also_like": "Вам также может заинтересовать",
  "lbg.theme.sustainable": "Эко-отели",
  "hub.category.h2.unusual": "{0}: лучшие тайные достопримечательности и необычные места",
  "hub.category.h2.football": "Поддержите свою футбольную команду и получите максимум удовольствия от поездки!",
  "top_hotel": "Популярный отель",
  "lbg.h1.destination_theme.luxury": "Обладатели награды в категории «Отели класса \"люкс\"», {0}",
  "top10_hotels_empty_alert": "В настоящий момент мы испытываем технические проблемы. <span>Приносим свои извинения за возможные неудобства.</span>",
  "special_deal": "Спецпредложение",
  "lbg.h2.sustainable": "Поддержка экологии сейчас в приоритете! Обратите внимание на эти объекты размещения — здесь вас ждет прекрасный отдых.",
  "where_to_stay_in": "<span class=\"next-line\">{0}</span>: где остановиться",
  "start_planning_your_trip": "Начните планировать поездку",
  "good_for": "Рекомендуем в категориях:",
  "category.hotels": "Отели",
  "x_accommodation_guide": "{0}: гид по вариантам размещения",
  "lbg.theme.luxury": "Отели класса «люкс»",
  "hub.category.h2.sports": "{0}: лучшие варианты спорта и досуга",
  "lbg.section_theme_world_winners.lgbtq": "Обладатели награды в категории «ЛГБТК»",
  "see_more_neighbourhoods": "Другие районы",
  "category_highlight.title.nightlife": "{0}: куда сходить и что делать вечером?",
  "lbg.h1.destination_theme.lgbt": "Обладатели награды в категории «ЛГБТК+», {0}",
  "hub.category.h1.blogs": "{0}: туристический блог",
  "lbg.h2.business": "Эти объекты размещения получили высокую оценку бизнес-путешественников.",
  "hotels": "Отели",
  "lbg.h2.pet_friendly": "Гости рекомендуют эти объекты размещения, в которых будут рады вам и вашим четвероногим друзьям.",
  "popular_landmarks_nearby": "Популярные достопримечательности поблизости",
  "popular_extractions_in": "{0}: популярные достопримечательности и развлечения",
  "lbg.h2.all_inclusive": "В этих первоклассных объектах размещения вы избавитесь от стресса и напряжения.",
  "travel_guide_by_category_header": "{1}: Лучшее в категории «{0}»",
  "lbg.travel_between": "Путешествие с {0} по {1}",
  "keep_exploring": "Продолжайте исследовать",
  "what_you_need_to_know_before_you_go": "Полезные сведения перед поездкой",
  "read_it": "Читать",
  "view_on_map": "Посмотреть на карте",
  "lbg.h1.destination_theme.lgbtq": "{0}: обладатели награды в категории «ЛГБТК»",
  "lbg.h2.beach": "Гости обожают пляжный отдых, особенно в этих объектах размещения.",
  "lbg.h1.destination_theme.vip_access": "{0}: обладатели награды в категории VIP Access",
  "see_all_accommodation": "Посмотреть все варианты жилья",
  "shoppers": "Шопинг",
  "whats_around": "{0}: что посмотреть рядом?",
  "group": "Группы",
  "hub.category.h2.couples": "{0}: лучший отдых для двоих",
  "hub.category.h1.shopping": "{0}: шопинг",
  "travel_community": "{0} <span>&#124;</span> <span>Сообщество путешественников</span>",
  "hub.category.h2.group": "{0}: лучшие групповые поездки",
  "listicle_legal_msg": "В этой статье представлена точка зрения редакционной группы Go Guides. Hotels.com выплачивает вознаграждение авторам за публикации на этом сайте; вознаграждение может включать стоимость поездок и другие расходы.",
  "lbg.h1.destination_theme.cleanliness": "{0}: обладатели награды в категории «Чистота»",
  "menu.more": "Еще",
  "lbg.section_properties_theme.lgbtq": "{0}: обладатели награды в категории «ЛГБТК»",
  "lbg.section_award_winners_destination": "Обладатели награды по пунктам назначения",
  "lbg.terms_conditions": "Положения и условия",
  "hub.category.h2.nightlife": "{0}: лучшая ночная жизнь",
  "hub.neighbourhood.h2": "{0}: чем заняться?",
  "lbg.section_award_winners_theme_destination": "Обладатели награды по категориям, {0}",
  "lbg.section_award_world_winners": "Обладатели награды во всем мире",
  "things_to_see_and_do_in_other_cities": "Что посмотреть и чем заняться в других городах",
  "button.backToTop": "Наверх",
  "lbg.section_theme_world_winners.family": "Обладатели награды в категории «Семейные отели»",
  "hotels_near": "{0}: отели поблизости",
  "category.activity__sports_": "Досуг",
  "trust_module.get_reward_night": "Получите бонусные сутки",
  "hub.category.h1.business": "{0}: бизнес-путешествия ",
  "category.food": "Еда",
  "other_popular_destinations_in": "Другие популярные направления в {0}",
  "browse_by_category": "{0}: просматривайте информацию по категориям",
  "see_more_attactions": "Другие достопримечательности",
  "lbg.theme.lgbt": "ЛГБТК+",
  "more_information_about": "Больше информации о пункте назначения {0}",
  "lbg.h1.destination_theme.most_wanted": "Обладатели награды «Самый популярный отель», {0}",
  "see_all_properties_in": "{0}: посмотреть объекты размещения",
  "category.tours": "Экскурсии",
  "hub.category.h1.sports": "{0}: спорт и досуг",
  "where_will_you_go_next": "Куда поедете в следующий раз?",
  "cities": "Города",
  "lbg.theme.spa_hotel": "Спа-отели",
  "see_guide": "Открыть путеводитель",
  "lbg.section_properties_theme.ski": "Обладатели награды в категории «Горнолыжные отели», {0}",
  "related_stories": "Похожие статьи",
  "hub.destination.h2": "Полезные сведения перед поездкой",
  "show_less": "Скрыть",
  "hub.category.h1.football": "{0}: самый полный путеводитель для настоящих фанатов футбола",
  "hub.category.h2.food": "{0}: лучшая еда и рестораны",
  "fallback_language_alert": "К сожалению, эта страница еще не переведена на ваш язык...",
  "category_highlight.title.information": "Полезная информация",
  "neighbourhood_guides_for_other_cities": "Путеводители по районам других городов",
  "lbg.h2.hubpage": "Эти фантастические объекты могут похвастаться высокими рейтингами по оценкам гостей и множеством отзывов.",
  "travel_advisory.title": "Уведомление для путешественников",
  "lbg.section_properties_theme.all_inclusive": "Обладатели награды в категории «Все включено», {0}",
  "lbg.h2.boutique": "Если вы любите бутик-отели, наши гости рекомендуют остановиться здесь.",
  "lbg.section_properties_theme.family": "Обладатели награды в категории «Семейные отели», {0}",
  "hub.category.h2.families": "{0}: лучший семейный отдых",
  "from": "от",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Обладатели награды в категории «ЛГБТК+», {0}",
  "x_star": "{0,choice,0#|1#{0} звезда|1.5#{0} звезды|2#{0} звезды|2.5#{0} звезды|3#{0} звезды|3.5#{0} звезды|4#{0} звезды|4.5#{0} звезды|5#{0} звезд}",
  "trust_module.free_cancellation": "Бесплатная отмена бронирования",
  "lbg.section_properties_theme.home_and_apartments": "Обладатели награды в категории «Дома и апартаменты», {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Внештатный автор</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span>: что посмотреть и чем заняться",
  "lbg.check_price": "Проверить цену",
  "hub.category.h2.blogs": "от опытных путешественников",
  "lbg.h2.home_and_apartments": "Ищете дом вдали от дома? Обратите внимание на эти объекты размещения с высоким рейтингом.",
  "hub.category.h2.information": "{0}: полезная информация",
  "search_hotels_in": "<span class=\"extra-label\">{0}</span>: искать отели",
  "hub.category.h2.nature": "{0}: лучшие поездки на природу",
  "lbg.h1.destination_theme.home_and_apartments": "Обладатели награды в категории «Дома и апартаменты», {0}",
  "photo_by": "Фотография предоставлена {0}",
  "more_things_to_do": "Другие интересные занятия",
  "lbg.section_properties_theme.beach": "Обладатели награды в категории «Пляжные отели», {0}",
  "read_less": "Свернуть",
  "lbg.h2.spa_hotel": "Расслабьтесь и отдохните в этих высококлассных объектах размещения, которые знамениты своими спа-центрами.",
  "second_fold_related_stories.see_also": "Еще интересные статьи",
  "hub.category.h2.history": "{0}: лучшие исторические места",
  "lbg.section_properties_theme.cleanliness": "{0}: обладатели награды в категории «Чистота»",
  "lbg.h1.destination_theme.boutique": "Обладатели награды в категории «Бутик-отели», {0}",
  "nightlife_b595ae4": "Ночная жизнь",
  "lbg.h1.destination_theme.beach": "Обладатели награды в категории «Пляжные отели», {0}",
  "trust_module.free_cancellation.disclaimer": "В некоторых отелях необходимо отменять бронирование не позднее 24–48 часов до заезда. Подробности на сайте",
  "lbg.h2.family": "Эти объекты размещения получили высокие оценки в отзывах гостей и идеально подходят для семей.",
  "lbg.h2.lgbtq": "Эти объекты размещения, ориентированные на гостей ЛГБТК, получили высокие оценки в отзывах гостей.",
  "hub.category.h1.group": "{0}: групповые поездки",
  "popular_stories": "Популярные истории",
  "toc": "Содержание",
  "hub.category.h2.shopping": "{0}: лучшие места для шопинга",
  "lbg.section_theme_world_winners.beach": "Обладатели награды в категории «Пляжные отели»",
  "page_score": "Оценка этой страницы",
  "lbg.section_theme_world_winners.sustainable": "Обладатели награды в категории «Эко-отели»",
  "lbg.theme.boutique": "Бутик-отели",
  "trust_module.price_guarantee": "Наша гарантированная цена",
  "lbg.h1.destination_theme.all_inclusive": "Обладатели награды в категории «Все включено», {0}",
  "top_destinations": "Самые популярные направления",
  "hotel_prices_near": "{0}: цены на отели поблизости",
  "lbg.score.cleanliness": "Чистота:",
  "x_stars": "{0,choice,0#|1#{0} звезда|1<{0} звезды|4<{0} звезд|10<{0} звезд}",
  "lbg.theme.all_inclusive": "Отели «все включено»",
  "hub.category.h1.tours": "{0}: экскурсии и поездки",
  "lbg.section_award_winners_theme": "Обладатели награды по категориям",
  "guest_writer": "{0} <span>&#124;</span> <span>Приглашенный автор</span>",
  "start_planning_your_x_trip": "Начните планировать поездку в {0}",
  "lbg.section_theme_world_winners.business": "Обладатели награды в категории «Бизнес-отели»",
  "trending_now_in": "Сегодня популярно в {0}",
  "history": "История",
  "search_hotels": "Искать отели",
  "lbg.terms_conditions_details": "Критерии выбора победителя среди объектов размещения в {0} году основаны на высоком рейтинге по оценкам гостей за {1} год и наличию как минимум 50 отзывов гостей.",
  "see_nearby_properties": "Посмотреть объекты размещения поблизости",
  "hub.category.h1.history": "{0}: исторические места",
  "location": "Расположение:",
  "lbg.section_theme_world_winners.luxury": "Обладатели награды в категории «Отели класса люкс»",
  "category_highlight.see_all.nightlife": "Подробнее о ночной жизни",
  "also_popular": "Также популярны",
  "hub.category.h2.things_to_do": "{0}: чем заняться",
  "lbg.theme.family": "Семейные отели",
  "lbg.h1.destination_theme.pet_friendly": "Обладатели награды в категории «Отели с возможностью проживания с домашними животными», {0}",
  "category_highlight.title.shopping": "{0}: где сделать покупки и что купить?",
  "price": "Цена:",
  "hub.category.h2.beach": "{0}: лучшие пляжи",
  "neighbourhoods_in": "{0}: популярные районы",
  "business": "Бизнес",
  "photo": "Фото",
  "blog.see_all_stories": "Все истории",
  "category_highlight.see_all.food": "Подробнее о ресторанах и питании",
  "see_all_properties": "Показать все варианты",
  "lbg.theme.beach": "Пляжные отели",
  "what_to_see_and_do_in": "{0}: что посмотреть и чем заняться?",
  "hub.category.h2.single": "{0}: лучшие индивидуальные путешествия",
  "attractions_in": "{0}: достопримечательности",
  "read_more": "Подробнее",
  "show_more": "Еще",
  "hotels_near_this_landmark": "Отели рядом с этой достопримечательностью",
  "check_dates": "Проверить даты",
  "featured_stories_and_fun_stuff": "Избранные истории и забавные факты",
  "phone": "Телефон:",
  "lbg.section_search_header": "Найти обладателей награды",
  "category_highlight.title.food": "{0}: где и что поесть?",
  "see_all_things_to_do": "Все развлечения и досуг",
  "lbg.section_properties_theme.sustainable": "Обладатели награды в категории «Эко-отели», {0}",
  "lbg.h1.hubpage": "Обладатели награды «Выбор гостей» {0} г.",
  "lbg.section_theme_world_winners.spa_hotel": "Обладатели награды в категории «Спа-отели»",
  "category.maps": "Карты",
  "lbg.h1.destination_theme.family": "Обладатели награды в категории «Семейные отели», {0}",
  "lbg.section_theme_world_winners.cleanliness": "Обладатели награды в категории «Чистота»",
  "lbg.section_theme_world_winners.vip_access": "Обладатели награды в категории VIP Access",
  "trust_module.get_reward_night.night_you_stay": "за каждые 10 суток проживания",
  "modified": "изменено",
  "lbg.section_properties": "Обладатели награды, {0}",
  "trust_module.header": "Преимущества бронирования на Hotels.com",
  "hub.category.h2.luxury": "{0}: лучший отдых класса «люкс»",
  "lbg.theme.ski": "Горнолыжные отели",
  "lbg.h1.destination_theme.spa_hotel": "Обладатели награды в категории «Спа-отели», {0}",
  "lbg.h2.most_wanted": "Гости рекомендуют эти объекты размещения для идеального проживания.",
  "top10_hotels_incomplete_alert": "В настоящий момент на сайте много посетителей, поэтому некоторые варианты могут быть не видны. <span>Приносим свои извинения за возможные неудобства.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Опытный путешественник</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Обладатели награды в категории «Дома и апартаменты»",
  "loved_by_guests_collection_in": "{0}: коллекция «Выбор гостей»",
  "title.suffix": "{0} — путеводители Go Guides",
  "hub.category.h2.maps": "{0}: полезные карты",
  "opening_hours": "Часы работы",
  "hub.category.h2.photos": "{0}: лучшие фотопутешествия",
  "lbg.theme.most_wanted": "Награда «Самый популярный отель»",
  "blog.stories_container": "Истории от пользователей",
  "hub.category.h2.budget": "{0}: лучший экономичный отдых",
  "lbg.section_properties_theme.business": "Обладатели награды в категории «Бизнес-отели», {0}",
  "not_sure_where_to_stay": "Не знаете, где остановиться?",
  "category.information": "Информация"
};