var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React from 'react';
import { useState } from 'react';
import { useStores } from 'utils/useStore';
import { UitkLink } from 'uitk-react-link';
import { UitkBreadcrumbs } from 'uitk-react-breadcrumbs';
import OneCrumb from './onecrumb';
var GoGuideBreadcrumb = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var _a = __read(useState(false), 2),
    showMenu = _a[0],
    setShowMenu = _a[1];
  var crumbs;
  var buildBreadcrumb = function (data) {
    var breadcrumb = data.map(function (bread, index) {
      if (bread.targetUrl) {
        return React.createElement(UitkLink, {
          inline: true,
          key: index
        }, React.createElement("a", {
          href: bread.targetUrl
        }, bread.name));
      } else {
        return React.createElement("span", {
          key: index
        }, bread.name);
      }
    });
    crumbs = breadcrumb;
  };
  var handleClick = function () {
    setShowMenu(!showMenu);
  };
  buildBreadcrumb(goGuidesStore.fetchBreadcrumb(goGuidesStore.pageUrlData));
  return React.createElement(React.Fragment, null, crumbs && (crumbs.length > 1 ? React.createElement(UitkBreadcrumbs, {
    crumbs: crumbs,
    hasDynamicInlineCrumbs: true,
    isOpen: showMenu,
    onTriggerClick: handleClick
  }) : React.createElement(OneCrumb, {
    crumb: crumbs
  })));
};
export default GoGuideBreadcrumb;