var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from 'react';
import { useStores } from 'src/utils/useStore';
import { DiscoveryCardContentSize, DiscoveryContainerType, DiscoveryOfferingType, DiscoveryRecommendationInputContext, DiscoveryRecommendationStrategy, LineOfBusinessDomain, RecommendationsModule } from '@shared-ui/retail-recommendation-module';
import { PageLayout } from 'src/components/types/goguides-types.d';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";
import { checkValue } from 'src/components/functions';
var RecommendationModule = function (props) {
  var goGuidesStore = useStores().goGuidesStore;
  var pageLayout = props.pageLayout;
  var loc = new localization();
  var data = goGuidesStore.fetchGaiaIdAndDestinationName(goGuidesStore.pageUrlData);
  if (!checkValue(data)) {
    return null;
  }
  if (checkValue(data) && checkValue(data.hideWhereToStay) && data.hideWhereToStay === true) {
    return null;
  }
  var header = loc.getLocalizedText('where_to_stay_in', data === null || data === void 0 ? void 0 : data.destinationName);
  // strip out html from the header
  var headerText = checkValue(header) ? header.replace(/<\/?[^>]+(>|$)/g, "") : null;
  var recommendationInputs = {
    contentSize: DiscoveryCardContentSize.LANDING,
    offeringType: DiscoveryOfferingType.PRODUCT,
    strategy: DiscoveryRecommendationStrategy.POPULAR,
    input: [DiscoveryRecommendationInputContext.DESTINATION],
    recommendationContext: {
      outputLineOfBusiness: LineOfBusinessDomain.LODGING,
      destination: {
        placeId: {
          id: data.gaiaId
        }
      }
    },
    placementId: '185',
    configurationIdentifier: '0174e98a-57fc-4acb-965a-b3c283aa80fc'
  };
  return React.createElement(React.Fragment, null, data && React.createElement("section", {
    className: "recommendation-hotels sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(RecommendationsModule, {
    inputs: __assign(__assign({}, recommendationInputs), {
      containerType: DiscoveryContainerType.CAROUSEL
    }),
    customTitle: headerText,
    titleProps: {
      tag: 'h2'
    }
  })), React.createElement(ViewLarge, null, React.createElement(RecommendationsModule, {
    inputs: __assign(__assign({}, recommendationInputs), {
      containerType: pageLayout === PageLayout.TWO_COLUMN ? DiscoveryContainerType.LIST : DiscoveryContainerType.CAROUSEL,
      configurationIdentifier: pageLayout === PageLayout.TWO_COLUMN ? '8e9ec552-4809-402a-9410-dda3faf74df7' : '0174e98a-57fc-4acb-965a-b3c283aa80fc'
    }),
    customTitle: headerText,
    titleProps: {
      tag: 'h2'
    }
  })))))));
};
export { RecommendationModule };