import React from 'react';
import { checkValue } from 'components/functions';
import { useStores } from 'utils/useStore';
import Displayitem from './sub-component/listicle-item';
import GoGuidesRelatedStoriesComponent from '../related-stories/related-stories';
var GoGuidesListicleItemBucketComponent = function () {
  var _a;
  var goGuidesStore = useStores().goGuidesStore;
  var positionInject = 2;
  var contentData = goGuidesStore.fetchListicleItems(goGuidesStore.pageUrlData);
  var firstXItems = [];
  var remainingItems = [];
  if (checkValue(contentData) && contentData.items) {
    firstXItems = contentData.items.slice(0, positionInject);
    remainingItems = contentData.items.slice(positionInject);
  }
  var hideNumber = checkValue(contentData.features) && checkValue((_a = contentData.features) === null || _a === void 0 ? void 0 : _a.hideNumber);
  return React.createElement(React.Fragment, null, checkValue(firstXItems) && React.createElement("div", {
    className: "listicle-item-container ".concat(checkValue(hideNumber) ? 'hide-number' : '')
  }, React.createElement("ul", null, firstXItems.map(function (item, firstIndex) {
    return React.createElement(Displayitem, {
      key: firstIndex,
      item: item,
      index: firstIndex,
      hideNumber: hideNumber
    });
  }), React.createElement("li", {
    className: "see-also-stories"
  }, React.createElement(GoGuidesRelatedStoriesComponent, {
    seeAlso: true
  })), remainingItems.map(function (item, remainIndex) {
    return React.createElement(Displayitem, {
      key: remainIndex,
      item: item,
      index: firstXItems.length + remainIndex,
      hideNumber: hideNumber
    });
  }))));
};
export default GoGuidesListicleItemBucketComponent;