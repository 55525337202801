var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { UitkHeading, UitkSubheading } from 'uitk-react-text';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { useStores } from 'utils/useStore';
import { checkValue } from 'components/functions';
import GoGuideBreadcrumb from '../breadcrumb/breadcrumb';
import Navigation from '../navigation/navigation';
import { pageLayoutController } from '../../utils/helper';
import SegmentComponent from '../generic/segments';
import HeroSkim from './hero-skim';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { RenderImage } from 'src/components/utils/responsive-image';
import { useEffect, useState } from 'react';
var GoGuidesHeroComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var _a = __read(useState(false), 2),
    showSkim = _a[0],
    setShowSkim = _a[1];
  var trackingObject = {
    event: {
      event_name: 'go_guides_hero_image.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  var contentData = goGuidesStore.fetchHero(goGuidesStore.pageUrlData);
  var h1 = contentData.h1,
    h2 = contentData.h2,
    image = contentData.image,
    pageType = contentData.pageType,
    pageCategory = contentData.pageCategory;
  var imageUrl = checkValue(image) ? image.imageUrl : '';
  var eagerLoading = 'eager';
  var imageProps = {
    image: imageUrl,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066'
      }
    },
    lazyLoading: eagerLoading
  };
  var pageLayout = pageLayoutController(pageType);
  useEffect(function () {
    setShowSkim(true);
  }, []);
  return React.createElement(React.Fragment, null, imageUrl != '' && React.createElement(EgClickstreamImpressionTracker, {
    eventData: trackingObject
  }, React.createElement("section", {
    className: "page-header-section ".concat(pageLayout.header)
  }, React.createElement("div", {
    className: "hero-modules-container"
  }, React.createElement("div", {
    className: "hero-image-comtainer"
  }, React.createElement(UitkFigure, {
    className: "img",
    ratio: UitkFigureAspectRatioType.R16_9
  }, React.createElement(RenderImage, {
    imgProps: imageProps
  }), showSkim && React.createElement(HeroSkim, {
    header: pageLayout.header
  }))), React.createElement(Navigation, null), React.createElement("div", {
    className: "page-title-container"
  }, React.createElement("div", {
    className: "row content"
  }, React.createElement(GoGuideBreadcrumb, null), React.createElement("div", {
    className: "hero-text-component ".concat(h1.length > 22 ? 'long-header' : '')
  }, React.createElement(UitkHeading, {
    tag: "h1",
    size: 1,
    className: ""
  }, h1), React.createElement(UitkSubheading, {
    tag: "h2",
    className: ""
  }, h2), checkValue(pageCategory) && React.createElement(SegmentComponent, {
    data: pageCategory,
    pill: true
  }))))))));
};
export default GoGuidesHeroComponent;