export default {
  "current_deal": "Núverandi tilboð",
  "lbg.section_properties_theme.pet_friendly": "{0} - verðlaunahafar í flokknum „Gæludýravænt“",
  "address": "Heimilisfang",
  "hub.homepage.h2": "Svo margir staðir, svo lítill tími...",
  "search_hotels_near_this_landmark": "Leita að hótelum<span class=\"extra-label\"> nálægt þessu kennileiti</span>",
  "hub.category.h1.things_to_do": "{0} - áhugavert að gera",
  "lbg.h2.vip_access": "VIP Access-gististaðir hafa verið valdir út frá hárri einkunn og afburðagóðri upplifun gesta.",
  "lbg.theme.cleanliness": "Hreinlæti",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Sækja fleiri",
  "link_long_form_to_listiicle_multiple": "{0} er meðal þess helsta sem talað er um í greinunum {1} og {2} (Lestu allt um það sem {3} hefur upp á að bjóða hér)",
  "lbg.section_properties_theme.boutique": "{0} - verðlaunahafar í „Boutique“-flokki",
  "lbg.h2.luxury": "Viltu upplifa alvöru lúxus? Þá eru þessir gististaðir besti kosturinn fyrir þig, en þeir hafa fengið toppeinkunn hjá gestum.",
  "hub.category.h1.information": "{0} - nánari upplýsingar",
  "families": "Fjölskyldur",
  "trending_now": "Spennandi núna",
  "lbg.from": "Frá",
  "hub.category.h1.road_trips": "{0} - bestu vegaferðalögin",
  "lbg.h2.cleanliness": "Þessir gististaðir hafa fengið góða heildareinkunn frá gestum og toppeinkunn fyrir hreinlæti.",
  "single": "Einhleypa",
  "couples": "Pör",
  "trust_module.price_guarantee.find_out_more": "skoða nánar",
  "top_destinations_in": "{0} - vinsælustu áfangastaðirnir",
  "hub.category.h1.couples": "{0} - paraferðir",
  "hub.category.h2.tours": "{0} - bestu skoðunarferðirnar",
  "hub.category.h1.photos": "{0} - ljósmyndaferðir",
  "category.photos": "Myndir",
  "hub.category.h1.budget": "{0} - ódýrar ferðir",
  "hub.category.h2.ski": "{0} - bestu skíðaáfangastaðirnir",
  "lbg.section_theme_world_winners.most_wanted": "Verðlaunahafar í flokknum „Eftirsóttust“",
  "lbg.theme.home_and_apartments": "Heimili og íbúðir",
  "hub.category.h1.luxury": "{0} - lúxusferðir",
  "hub.category.h2.adventure": "{0} - bestu ævintýraferðirnar",
  "hotels_in": "{0} - hótel",
  "lbg.section_search_winners": "Leita að fleiri verðlaunahöfum",
  "see_more_hotels_in": "{0} - skoða fleiri hótel",
  "hub.category.h1.beach": "Strandir í {0}",
  "open": "Opnunartími:",
  "enter_your_destination": "Sláðu inn áfangastaðinn þinn",
  "best_of": "{0} - allt það besta",
  "top10_hotels_missing_properties": "Sumir gististaðir eru mögulega ekki sýnilegir sem stendur. Við biðjumst velvirðingar á óþægindunum.",
  "adventure": "Ævintýri",
  "lbg.h1.destination_theme.sustainable": "{0} - verðlaunahafar í flokknum „Sjálfbær“",
  "luxury": "Lúxus",
  "expand_map_view": "Stækka kortaglugga",
  "book_now": "Bóka núna",
  "lbg.h1.destination_theme.ski": "{0} - verðlaunahafar í skíðaflokki",
  "hub.category.h1.food": "{0} - matur og veitingar",
  "category.blogs": "Blogg",
  "budget": "Ódýrt",
  "popular_neighbourhoods_in": "{0} - vinsæl hverfi",
  "hub.category.h1.unusual": "{0} - faldir fjársjóðir og óvenjulegir staðir",
  "see_all_accommodation_in": "Sjá gististað í {0}",
  "hub.category.h1.adventure": "{0} - ævintýraferðir",
  "category_highlight.see_all.shopping": "Meira um verslun",
  "lbg.section_most_popular_destination": "Vinsælustu áfangastaðirnir",
  "lbg.section_properties_theme.luxury": "{0} - verðlaunahafar í lúxusflokki",
  "unusual": "Óvenjulegt",
  "category.shopping": "Verslanir",
  "make_the_most_of_your_trip": "Fáðu sem mest út úr ferðalaginu",
  "x_review": "{0,choice,0#|1#{0} umsögn|2#{0} umsagnir|2<{0} umsagnir|4<{0} umsagnir|10#{0} umsagnir|10<{0} umsagnir}",
  "hub.category.h1.single": "{0} - einstaklingsferðir",
  "hub.category.h2.custom": "Vinsælt núna",
  "lbg.section_theme_world_winners.lgbt": "Verðlaunahafar í flokknum LGBTQ+",
  "lbg.section_award_winners_theme_other": "{0} - Önnur verðlaunaþemu",
  "lbg.supplier_collected_charges": "+ {0} til greiðslu á gististaðnum",
  "in_destination": " {0} ",
  "lbg.section_properties_theme.vip_access": "{0} - verðlaunahafar í VIP Access-flokki",
  "link_long_form_to_listiicle_single": "{0} er meðal þess helsta sem talað er um í greininni {1} (Lestu allt um það sem {2} hefur upp á að bjóða hér)",
  "lbg.section_theme_world_winners.boutique": "Verðlaunahafar í „Boutique“-flokki",
  "lbg.section_properties_theme.spa_hotel": "{0} - verðlaunahafar í heilsulindarflokki",
  "lbg.section_theme_world_winners.all_inclusive": "Verðlaunahafar í flokknum „Allt innifalið“",
  "hub.category.h2": "{0} - ferðaleiðbeiningar",
  "lbg.properties.price_tooltip": "Þetta er lægsta verðið fyrir nóttina á síðunni okkar næstu 14 daga. ",
  "hub.category.h2.road_trips": "{0} - bestu vegaferðalögin",
  "hub.category.h1.ski": "{0} - skíðaáfangastaðir",
  "food": "Matgæðinga",
  "lbg.theme.pet_friendly": "Gæludýr velkomin",
  "lbg.theme.business": "Viðskiptaferðir",
  "hub.category.h1.nature": "{0} - bestu ferðirnar til að komast út í náttúruna",
  "map": "Kort",
  "category_highlight.see_all.information": "Frekari upplýsingar",
  "hotel_prices_in": "{0} - hótelverð",
  "hub.category.h1.nightlife": "{0} - næturlífið á svæðinu",
  "trust_module.free_cancellation.most_hotels": "á flestum hótelum*",
  "also_popular_in": "{0} - einnig vinsælt á svæðinu",
  "lbg.h1.destination_theme.business": "{0} - verðlaunahafar í viðskiptaferðalagaflokki",
  "lbg.section_theme_world_winners.pet_friendly": "Verðlaunahafar í flokknum „Gæludýravænt“",
  "hub.category.h1.maps": "{0} - kort",
  "lbg.section_properties_theme.most_wanted": "{0} - verðlaunahafar í flokknum „Eftirsóttust“",
  "category.nightlife": "Næturlíf",
  "search_hotels_in_this_area": "Leita að hótelum<span class=\"extra-label\"> á þessu svæði</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 atkvæði|2#2 atkvæði|2<{1} atkvæði|4<{1} atkvæði|10#10 atkvæði|10<{1} atkvæði})",
  "hub.category.h2.business": "{0} - bestu viðskiptaferðirnar",
  "category.things_to_do": "Afþreying",
  "destination_travel_guide_by_category": "{0} - ferðaleiðbeiningar eftir flokkum",
  "hub.category.h1.families": "{0} - ferðalög fyrir fjölskyldur",
  "lbg.section_theme_world_winners.ski": "Verðlaunahafar í skíðaflokki",
  "you_may_also_like": "Þú gætir einnig haft áhuga á",
  "lbg.theme.sustainable": "Sjálfbær",
  "hub.category.h2.unusual": "{0} - bestu földu fjársjóðirnir og óvenjulegu staðirnir",
  "hub.category.h2.football": "Hvettu þitt fótboltalið og nýttu ferðina til hins ítrasta!",
  "top_hotel": "Topp hótel",
  "lbg.h1.destination_theme.luxury": "{0} - verðlaunahafar í lúxusflokki",
  "top10_hotels_empty_alert": "Við eigum við tæknilega erfiðleika að stríða sem stendur. <span>Við biðjumst velvirðingar á óþægindunum.</span>",
  "special_deal": "Sértilboð",
  "lbg.h2.sustainable": "Þetta snýst allt um sjálfbærnina! Kannaðu þessa gististaði sem bjóða frábæra dvöl.",
  "where_to_stay_in": "<span class=\"next-line\">{0}</span> - hvar er gott að gista?",
  "start_planning_your_trip": "Byrjaðu að skipuleggja ferðina þína",
  "good_for": "Gott fyrir:",
  "category.hotels": "Hótel",
  "x_accommodation_guide": "{0} - gististaðaúrval",
  "lbg.theme.luxury": "Lúxus",
  "hub.category.h2.sports": "{0} - bestu íþróttirnar og afþreyingin",
  "lbg.section_theme_world_winners.lgbtq": " Verðlaunahafar í LGBTQ-flokki",
  "see_more_neighbourhoods": "Sjá fleiri hverfi",
  "category_highlight.title.nightlife": "Hvert skal fara og hvað skal gera á kvöldin í/á {0}",
  "lbg.h1.destination_theme.lgbt": "{0} - verðlaunahafar í flokknum LGBTQ+",
  "hub.category.h1.blogs": "{0} - ferðablogg",
  "lbg.h2.business": "Þessir gististaðir eru í sérstaklega miklum metum meðal viðskiptaferðalanga.",
  "hotels": "Hótel",
  "lbg.h2.pet_friendly": "Gestir eru hæstánægðir með þessa gististaði sem bjóða bæði gesti og gæludýr þeirra velkomin.",
  "popular_landmarks_nearby": "Vinsæl kennileiti í nágrenninu",
  "popular_extractions_in": "{0} - vinsælir staðir",
  "lbg.h2.all_inclusive": "Þú þarft ekki að hafa áhyggjur af neinu á þessum gististöðum sem hafa fengið toppeinkunn.",
  "travel_guide_by_category_header": "{1}: {0}",
  "lbg.travel_between": "Ferðastu á tímabilinu {0} til {1}",
  "keep_exploring": "Skoðaðu meira",
  "what_you_need_to_know_before_you_go": "Það sem þú þarft að vita fyrir ferðina",
  "read_it": "Lesa áfram",
  "view_on_map": "Skoða á korti",
  "lbg.h1.destination_theme.lgbtq": "{0} - verðlaunahafar í LGBTQ-flokki",
  "lbg.h2.beach": "Gestir elska ströndina, sérstaklega á þessum gististöðum.",
  "lbg.h1.destination_theme.vip_access": "{0} - verðlaunahafar í VIP Access-flokki",
  "see_all_accommodation": "Sjá alla gististaði",
  "shoppers": "Verslun",
  "whats_around": "{0} - hvað er í nágrenninu?",
  "group": "Hópa",
  "hub.category.h2.couples": "{0} - bestu paraferðirnar",
  "hub.category.h1.shopping": "{0} - verslun á svæðinu",
  "travel_community": "{0} <span>&#124;</span> <span>Samfélag ferðafólks</span>",
  "hub.category.h2.group": "{0} - bestu hópferðirnar",
  "listicle_legal_msg": "Þessi grein inniheldur skoðanir ritstjórnarteymis Go Guides. Hotels.com greiðir höfundum fyrir skrif sem birtast á þessari vefsíðu; slíkar greiðslur geta innihaldið ferðalög og annan kostnað.",
  "lbg.h1.destination_theme.cleanliness": "{0} - verðlaunahafar í flokki þrifalegustu gististaða",
  "menu.more": "Meira",
  "lbg.section_properties_theme.lgbtq": "{0} - verðlaunahafar í LGBTQ-flokki",
  "lbg.section_award_winners_destination": "Verðlaunahafar eftir áfangastað",
  "lbg.terms_conditions": "Skilmálar og skilyrði",
  "hub.category.h2.nightlife": "{0} - besta næturlífið",
  "hub.neighbourhood.h2": "{0} - hvað er áhugavert á svæðinu?",
  "lbg.section_award_winners_theme_destination": "{0} - Verðlaunahafar eftir þemum ",
  "lbg.section_award_world_winners": "Verðlaunahafar á heimsvísu",
  "things_to_see_and_do_in_other_cities": "Áhugavert að skoða og gera í öðrum borgum",
  "button.backToTop": "Fara aftur efst",
  "lbg.section_theme_world_winners.family": "Verðlaunahafar í fjölskylduflokki",
  "hotels_near": "{0} - hótel í nágrenninu",
  "category.activity__sports_": "Skoðunarferðir",
  "trust_module.get_reward_night": "Fáðu verðlaunanótt",
  "hub.category.h1.business": "{0} - viðskiptaferðir",
  "category.food": "Matur",
  "other_popular_destinations_in": "{0} - aðrir vinsælir áfangastaðir",
  "browse_by_category": "{0} - skoða eftir flokkum",
  "see_more_attactions": "Sjá fleiri vinsæla staði",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "{0} - frekari upplýsingar",
  "lbg.h1.destination_theme.most_wanted": "{0} - verðlaunahafar í flokknum „Eftirsóttust“",
  "see_all_properties_in": "{0} - Sjá alla gististaði",
  "category.tours": "Ferðir",
  "hub.category.h1.sports": "{0} - íþróttir og afþreying",
  "where_will_you_go_next": "Hvert ferðu næst?",
  "cities": "Borgir",
  "lbg.theme.spa_hotel": "Með heilsulind",
  "see_guide": "Skoða kynningu",
  "lbg.section_properties_theme.ski": "{0} - verðlaunahafar í skíðaflokki",
  "related_stories": "Tengdar greinar",
  "hub.destination.h2": "Það sem þú þarft að vita fyrir ferðina",
  "show_less": "Sýna minna",
  "hub.category.h1.football": "{0} - Hinn fullkomni leiðarvísir fyrir alla fótboltaaðdáendur",
  "hub.category.h2.food": "{0} - besti maturinn og veitingar",
  "fallback_language_alert": "Því miður hefur þessi síða ekki verið þýdd á íslensku ennþá...",
  "category_highlight.title.information": "Gagnlegar upplýsingar",
  "neighbourhood_guides_for_other_cities": "Hverfakynningar fyrir aðrar borgir",
  "lbg.h2.hubpage": "Þessir frábæru gististaðir hafa fengið háa einkunn frá gestum og fjölda umsagna.",
  "travel_advisory.title": "Ferðaviðvörun",
  "lbg.section_properties_theme.all_inclusive": "{0} - verðlaunahafar í flokknum „Allt innifalið“",
  "lbg.h2.boutique": "Ef þú vilt helst dvelja á „boutique“-gististöðum þá eru þetta þeir sem hafa fengið bestu meðmæli gesta.",
  "lbg.section_properties_theme.family": "{0} - verðlaunahafar í fjölskylduflokki",
  "hub.category.h2.families": "{0} - bestu ferðalögin fyrir fjölskyldur",
  "from": "frá",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "{0} - verðlaunahafar í flokknum LGBTQ+",
  "x_star": "{0,choice,0#|1#{0}-stjörnu|1.5#{0}-stjörnu|2#{0}-stjörnu|2.5#{0}-stjörnu|3#{0}-stjörnu|3.5#{0}-stjörnu|4#{0}-stjörnu|4.5#{0}-stjörnu|5#{0}-stjörnu}",
  "trust_module.free_cancellation": "Ókeypis afbókun",
  "lbg.section_properties_theme.home_and_apartments": "{0} - verðlaunahafar í flokki heimila og íbúða",
  "contributing_writer": "{0} <span>&#124;</span> <span>Dálkahöfundur</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span> - áhugavert að skoða og gera",
  "lbg.check_price": "Athuga verð",
  "hub.category.h2.blogs": "eftir víðförla ferðalanga",
  "lbg.h2.home_and_apartments": "Viltu hafa gistinguna heimilislega? Kannaðu þessa gististaði sem hafa fengið toppeinkunn.",
  "hub.category.h2.information": "{0} - gagnlegar upplýsingar",
  "search_hotels_in": "<span class=\"extra-label\">{0}</span> - leita að hótelum",
  "hub.category.h2.nature": "{0} - bestu ferðirnar út í náttúruna",
  "lbg.h1.destination_theme.home_and_apartments": "{0} - verðlaunahafar í flokki heimila og íbúða",
  "photo_by": "mynd frá {0}",
  "more_things_to_do": "Fleira sem gaman er að gera",
  "lbg.section_properties_theme.beach": "{0} - verðlaunahafar í strandflokki",
  "read_less": "Lesa minna",
  "lbg.h2.spa_hotel": "Náðu hinni fullkomnu ró á þessum heilsulindargististöðum sem hafa fengið hæstu einkunn hjá gestum.",
  "second_fold_related_stories.see_also": "Sjá einnig",
  "hub.category.h2.history": "{0} - bestu sögustaðirnir",
  "lbg.section_properties_theme.cleanliness": "{0} - verðlaunahafar í flokki þrifalegustu gististaða",
  "lbg.h1.destination_theme.boutique": "{0} - verðlaunahafar í „Boutique“-flokki",
  "nightlife_b595ae4": "Næturlíf",
  "lbg.h1.destination_theme.beach": "{0} - verðlaunahafar í strandflokki",
  "trust_module.free_cancellation.disclaimer": "Sum hótel gera kröfu um að þú afbókir a.m.k. 24-48 klst. fyrir innritun. Frekari upplýsingar á vefnum.",
  "lbg.h2.family": "Þessir gististaðir hafa fengið toppeinkunn hjá gestum og eru fullkomnir fyrir fjölskyldur.",
  "lbg.h2.lgbtq": "Þessir gististaðir sem bjóða LGBTQ-fólk velkomið hafa fengið háa einkunn hjá gestum.",
  "hub.category.h1.group": "{0} - hópferðir",
  "popular_stories": "Vinsælar sögur",
  "toc": "Efnisyfirlit",
  "hub.category.h2.shopping": "{0} - best að versla á svæðinu",
  "lbg.section_theme_world_winners.beach": "Verðlaunahafar í strandflokki",
  "page_score": "Einkunn þessarar síðu",
  "lbg.section_theme_world_winners.sustainable": "Verðlaunahafar í flokknum „Sjálfbær“",
  "lbg.theme.boutique": "„Boutique“",
  "trust_module.price_guarantee": "Verðverndin okkar",
  "lbg.h1.destination_theme.all_inclusive": "{0} - verðlaunahafar í flokknum „Allt innifalið“",
  "top_destinations": "Vinsælustu áfangastaðirnir",
  "hotel_prices_near": "{0} - hótelverð í nágrenninu",
  "lbg.score.cleanliness": "Hreinlæti:",
  "x_stars": "{0,choice,0#|1#{0} stjörnu|1<{0} stjörnu|4<{0} stjörnu|10<{0} stjörnu}",
  "lbg.theme.all_inclusive": "Allt innifalið",
  "hub.category.h1.tours": "{0} - skoðunar- og dagsferðir",
  "lbg.section_award_winners_theme": "Verðlaunahafar eftir þemum",
  "guest_writer": "{0} <span>&#124;</span> <span>Gestapenni</span>",
  "start_planning_your_x_trip": "{0} - Byrjaðu að skipuleggja ferðina þína",
  "lbg.section_theme_world_winners.business": "Verðlaunahafar í viðskiptaferðalagaflokki",
  "trending_now_in": "{0} - Vinsælt núna",
  "history": "Sögu",
  "search_hotels": "Leita að hótelum",
  "lbg.terms_conditions_details": "Skilyrðin fyrir tilnefningu gististaðar til verðlaunanna {0} voru há einkunn gesta á árinu {1} og að lágmarki 50 gestaumsagnir.",
  "see_nearby_properties": "Sjá gististaði í nágrenninu",
  "hub.category.h1.history": "{0} - sögustaðir",
  "location": "Staðsetning:",
  "lbg.section_theme_world_winners.luxury": "Verðlaunahafar í lúxusflokki",
  "category_highlight.see_all.nightlife": "Meira um næturlíf",
  "also_popular": "Einnig vinsælt",
  "hub.category.h2.things_to_do": "{0} - best að gera á svæðinu",
  "lbg.theme.family": "Fjölskylduferðir",
  "lbg.h1.destination_theme.pet_friendly": "{0} - verðlaunahafar í flokknum „Gæludýravænt“",
  "category_highlight.title.shopping": "Hvar á að versla og hvað á að kaupa í/á {0}",
  "price": "Verð:",
  "hub.category.h2.beach": "Bestu strandirnar í {0}",
  "neighbourhoods_in": "{0} - hverfi á svæðinu",
  "business": "Viðskiptaferðir",
  "photo": "Myndatökur",
  "blog.see_all_stories": "Sjá allar sögur",
  "category_highlight.see_all.food": "Meira um matarmenningu og veitingastaði",
  "see_all_properties": "Sjá alla gististaði",
  "lbg.theme.beach": "Strönd",
  "what_to_see_and_do_in": "Hvað á að skoða og gera í/á {0}",
  "hub.category.h2.single": "{0} - bestu einstaklingsferðirnar",
  "attractions_in": "{0} - áhugaverðir staðir",
  "read_more": "Lesa meira",
  "show_more": "Sýna meira",
  "hotels_near_this_landmark": "Hótel nálægt þessu kennileiti",
  "check_dates": "Kanna dagsetningar",
  "featured_stories_and_fun_stuff": "Helstu sögur og fleira skemmtilegt",
  "phone": "Símanúmer:",
  "lbg.section_search_header": "Leita að verðlaunahöfum",
  "category_highlight.title.food": "Hvar og hvað á að borða í/á {0}",
  "see_all_things_to_do": "Skoða allt sem er gaman að gera",
  "lbg.section_properties_theme.sustainable": "{0} - verðlaunahafar í flokknum „Sjálfbær“",
  "lbg.h1.hubpage": "Handhafar verðlaunanna „Uppáhald gesta“ {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Verðlaunahafar í heilsulindarflokki",
  "category.maps": "Kort",
  "lbg.h1.destination_theme.family": "{0} - verðlaunahafar í fjölskylduflokki",
  "lbg.section_theme_world_winners.cleanliness": "Verðlaunahafar í flokki þrifalegustu gististaða",
  "lbg.section_theme_world_winners.vip_access": " Verðlaunahafar í VIP Access-flokki",
  "trust_module.get_reward_night.night_you_stay": "fyrir hverjar 10 nætur sem þú dvelur",
  "modified": "breytt",
  "lbg.section_properties": "{0} - verðlaunahafar",
  "trust_module.header": "Hvers vegna að bóka hjá Hotels.com?",
  "hub.category.h2.luxury": "{0} - bestu lúxusferðirnar",
  "lbg.theme.ski": "Skíði",
  "lbg.h1.destination_theme.spa_hotel": "{0} - verðlaunahafar í heilsulindarflokki",
  "lbg.h2.most_wanted": "Gestir mæla sérstaklega með þessum gististöðum og segja þá bjóða hina fullkomnu dvöl.",
  "top10_hotels_incomplete_alert": "Það er mikið álag á kerfinu sem stendur þannig að sumir gististaðir gætu mögulega ekki verið birtir að svo stöddu. <span>Við biðjumst velvirðingar á óþægindunum.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Óforbetranlegur ferðalangur</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Verðlaunahafar í flokki heimila og íbúða",
  "loved_by_guests_collection_in": "{0} - safn gististaða sem hafa fengið verðlaunin „Uppáhald gesta“ á svæðinu",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "{0} - gagnleg kort",
  "opening_hours": "Opnunartímar",
  "hub.category.h2.photos": "{0} - bestu ljósmyndaferðirnar",
  "lbg.theme.most_wanted": "Eftirsóttust",
  "blog.stories_container": "Sögur frá ferðalöngum",
  "hub.category.h2.budget": "{0} - bestu ódýru ferðirnar",
  "lbg.section_properties_theme.business": "{0} - verðlaunahafar í viðskiptaferðalagaflokki",
  "not_sure_where_to_stay": "Ertu ekki viss um hvar sé best að gista?",
  "category.information": "Upplýsingar"
};