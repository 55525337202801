import { checkValue } from 'components/functions';
import React from 'react';
import { getLocalizedText } from 'src/utils/helper';
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from 'uitk-react-images';
import { UitkParagraph, UitkText } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
var GoGuidesWriterProfileComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchWriter(goGuidesStore.pageUrlData);
  var writerName;
  var writerImageUrl;
  var writerDescriptorKey;
  if (checkValue(contentData)) {
    var writer = contentData.writer;
    if (checkValue(writer)) {
      writerName = writer.name;
      writerImageUrl = writer.imageUrl;
      writerDescriptorKey = writer.descriptorKey;
    }
  }
  return React.createElement(React.Fragment, null, checkValue(writerName) && React.createElement("div", {
    className: "author-wrap"
  }, React.createElement("div", {
    className: "media-wrap"
  }, React.createElement("div", {
    className: "author-profile"
  }, React.createElement(UitkFigure, {
    className: "img",
    ratio: UitkFigureAspectRatioType.R1_1,
    roundcorner: ['max']
  }, React.createElement(UitkImage, {
    blankDefault: false,
    lazyLoading: "lazy",
    alt: "",
    src: writerImageUrl
  })), React.createElement("div", {
    className: "profile-wrap"
  }, writerDescriptorKey != 'none' ? React.createElement("div", {
    className: "author-name",
    dangerouslySetInnerHTML: {
      __html: getLocalizedText(writerDescriptorKey, writerName)
    }
  }) : React.createElement("div", {
    className: "author-name"
  }, React.createElement(UitkParagraph, null, React.createElement(UitkText, {
    inline: "strong"
  }, writerName))))))));
};
export default GoGuidesWriterProfileComponent;