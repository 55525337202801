export default {
  "current_deal": "Offre actuelle",
  "lbg.section_properties_theme.pet_friendly": "Gagnants dans la catégorie Animaux de compagnie acceptés – {0}",
  "address": "Adresse :",
  "hub.homepage.h2": "Tant d’endroits à visiter, si peu de temps...",
  "search_hotels_near_this_landmark": "Rechercher des hôtels<span class=\"extra-label\"> à proximité de ce site d’intérêt</span>",
  "hub.category.h1.things_to_do": "{0} : activités",
  "lbg.h2.vip_access": "Les établissements Accès VIP ont été sélectionnés pour leurs excellents avis et leurs expériences supérieures.",
  "lbg.theme.cleanliness": "Propreté",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Afficher plus",
  "link_long_form_to_listiicle_multiple": "{0} : l’un des points d’intérêt mis en évidence dans « {1} » et « {2} ». Découvrez tout ce qu’il y à savoir sur {3} ici.",
  "lbg.section_properties_theme.boutique": "Gagnants dans la catégorie Hôtel-boutique – {0}",
  "lbg.h2.luxury": "Découvrez le luxe à son meilleur : c’est pourquoi les voyageurs adorent séjourner dans ces établissements.",
  "hub.category.h1.information": "Renseignements sur {0}",
  "families": "Familles",
  "trending_now": "Tendances du moment",
  "lbg.from": "À partir de",
  "hub.category.h1.road_trips": "{0} : les meilleures escapades routières",
  "lbg.h2.cleanliness": "Ces établissements ont obtenu une très bonne note globale, notamment une excellente note de clients pour la propreté.",
  "single": "Célibataires",
  "couples": "Couples",
  "trust_module.price_guarantee.find_out_more": "En savoir plus",
  "top_destinations_in": "{0} : destinations populaires",
  "hub.category.h1.couples": "{0} : voyages en couple",
  "hub.category.h2.tours": "{0} : le meilleur des visites guidées",
  "hub.category.h1.photos": "{0} : voyages de photographie",
  "category.photos": "Photos",
  "hub.category.h1.budget": "{0} : voyages bon marché",
  "hub.category.h2.ski": "{0} – destinations populaires pour le ski",
  "lbg.section_theme_world_winners.most_wanted": "Gagnants dans la catégorie Plus populaire",
  "lbg.theme.home_and_apartments": "Maisons et appartements",
  "hub.category.h1.luxury": "{0} : voyages de luxe",
  "hub.category.h2.adventure": "{0} : le meilleur des voyages actifs",
  "hotels_in": "Hôtels à {0}",
  "lbg.section_search_winners": "Rechercher plus de gagnants",
  "see_more_hotels_in": "Afficher plus d’hôtels à {0}",
  "hub.category.h1.beach": "{0} : plages",
  "open": "Heures d’ouverture :",
  "enter_your_destination": "Saisir la destination",
  "best_of": "{0} : le meilleur de la destination",
  "top10_hotels_missing_properties": "Certains établissements peuvent ne pas s’afficher pour le moment. Nous sommes désolés des inconvénients occasionnés.",
  "adventure": "Aventure",
  "lbg.h1.destination_theme.sustainable": "Gagnants dans la catégorie Écoresponsable – {0}",
  "luxury": "Luxe",
  "expand_map_view": "Agrandir la carte",
  "book_now": "Réserver",
  "lbg.h1.destination_theme.ski": "Gagnants dans la catégorie Ski – {0}",
  "hub.category.h1.food": "Gastronomie à {0}",
  "category.blogs": "Article de blogue",
  "budget": "Budget",
  "popular_neighbourhoods_in": "Quartiers populaires à {0}",
  "hub.category.h1.unusual": "{0} : trésors cachés et endroits insolites",
  "see_all_accommodation_in": "Afficher tous les hébergements dans le quartier suivant : {0}",
  "hub.category.h1.adventure": "{0} : voyages actifs",
  "category_highlight.see_all.shopping": "En savoir plus sur le magasinage",
  "lbg.section_most_popular_destination": "Destinations populaires",
  "lbg.section_properties_theme.luxury": "Gagnants dans la catégorie Luxe – {0}",
  "unusual": "Voyages uniques",
  "category.shopping": "Boutiques",
  "make_the_most_of_your_trip": "Profitez au maximum de votre voyage",
  "x_review": "{0,choice,0#|1#{0} avis|2#{0} avis|2<{0} avis|4<{0} avis|10#{0} avis|10<{0} avis}",
  "hub.category.h1.single": "{0} : voyages en solo",
  "hub.category.h2.custom": "Tendances du moment",
  "lbg.section_theme_world_winners.lgbt": "Gagnants dans la catégorie LGBTQ+",
  "lbg.section_award_winners_theme_other": "Gagnants dans d’autres catégories – {0}",
  "lbg.supplier_collected_charges": "+ {0} à payer à l’établissement",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "Gagnants dans la catégorie Accès VIP – {0}",
  "link_long_form_to_listiicle_single": "{0} : l’un des points d’intérêt mis en évidence dans « {1} ». Découvrez tout ce qu’il y à savoir sur {2} ici.",
  "lbg.section_theme_world_winners.boutique": "Gagnants dans la catégorie Hôtel-boutique",
  "lbg.section_properties_theme.spa_hotel": "Gagnants dans la catégorie Spa – {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Gagnants dans la catégorie Tout inclus",
  "hub.category.h2": "{0} – Guide de voyage",
  "lbg.properties.price_tooltip": "Il s’agit du prix par nuit le plus bas sur notre site pour les 14 prochains jours.",
  "hub.category.h2.road_trips": "{0} : les meilleures escapades routières",
  "hub.category.h1.ski": "{0} – destinations pour le ski",
  "food": "Gastronomie",
  "lbg.theme.pet_friendly": "Animaux de compagnie acceptés",
  "lbg.theme.business": "Affaires",
  "hub.category.h1.nature": "{0} : les meilleures escapades en pleine nature",
  "map": "Carte",
  "category_highlight.see_all.information": "En savoir plus",
  "hotel_prices_in": "Prix des hôtels à {0}",
  "hub.category.h1.nightlife": "Vie nocturne à {0}",
  "trust_module.free_cancellation.most_hotels": "pour la plupart des réservations d’hôtel*",
  "also_popular_in": "Également populaires – {0}",
  "lbg.h1.destination_theme.business": "Gagnants dans la catégorie Affaires – {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Gagnants dans la catégorie Animaux de compagnie acceptés",
  "hub.category.h1.maps": "{0} : cartes",
  "lbg.section_properties_theme.most_wanted": "Gagnants dans la catégorie Plus populaire – {0}",
  "category.nightlife": "Vie nocturne",
  "search_hotels_in_this_area": "Rechercher des hôtels<span class=\"extra-label\"> dans cette région</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 vote|2#2 votes|2<{1} votes|4<{1} votes|10#10 votes|10<{1} votes})",
  "hub.category.h2.business": "{0} : le meilleur des voyages d’affaires",
  "category.things_to_do": "Activités",
  "destination_travel_guide_by_category": "{0} : guide de voyage par catégories",
  "hub.category.h1.families": "{0} : voyages en famille",
  "lbg.section_theme_world_winners.ski": "Gagnants dans la catégorie Ski",
  "you_may_also_like": "D’autres articles intéressants",
  "lbg.theme.sustainable": "Écoresponsable",
  "hub.category.h2.unusual": "{0} : le meilleur des trésors cachés et endroits insolites",
  "hub.category.h2.football": "Encouragez votre équipe de soccer préférée et profitez pleinement de votre voyage!",
  "top_hotel": "Hôtel prisé",
  "lbg.h1.destination_theme.luxury": "Gagnants dans la catégorie Luxe – {0}",
  "top10_hotels_empty_alert": "Nous éprouvons actuellement une erreur technique. <span>Nous sommes désolés des inconvénients occasionnés.</span>",
  "special_deal": "Offre spéciale",
  "lbg.h2.sustainable": "Tout tourne autour de la durabilité! Découvrez ces établissements qui proposent un séjour extraordinaire.",
  "where_to_stay_in": "Où séjourner à {0}",
  "start_planning_your_trip": "Commencez à planifier votre voyage",
  "good_for": "Idéal pour :",
  "category.hotels": "Hôtels",
  "x_accommodation_guide": "{0} – Guide d’hébergement",
  "lbg.theme.luxury": "Luxe",
  "hub.category.h2.sports": "{0} : le meilleur des sports et activités",
  "lbg.section_theme_world_winners.lgbtq": "Gagnants dans la catégorie LGBTQ",
  "see_more_neighbourhoods": "Afficher plus de quartiers",
  "category_highlight.title.nightlife": "{0} : où aller et quoi faire en soirée",
  "lbg.h1.destination_theme.lgbt": "Gagnants dans la catégorie LGBTQ+ – {0}",
  "hub.category.h1.blogs": "Article de blogue de voyage sur {0}",
  "lbg.h2.business": "Ces établissements sont bien notés par les voyageurs d’affaires.",
  "hotels": "Hôtels",
  "lbg.h2.pet_friendly": "Les voyageurs recommandent fortement ces établissements qui acceptent les animaux de compagnie.",
  "popular_landmarks_nearby": "Sites d’intérêt populaires à proximité",
  "popular_extractions_in": "Attractions populaires à {0}",
  "lbg.h2.all_inclusive": "Ces établissements populaires sont parfaits pour la détente.",
  "travel_guide_by_category_header": "{1} : {0}",
  "lbg.travel_between": "Voyagez du {0} au {1}.",
  "keep_exploring": "Continuez d’explorer",
  "what_you_need_to_know_before_you_go": "À savoir avant de partir",
  "read_it": "Lire la suite",
  "view_on_map": "Afficher sur une carte",
  "lbg.h1.destination_theme.lgbtq": "Gagnants dans la catégorie LGBTQ – {0}",
  "lbg.h2.beach": "Les voyageurs adorent séjourner à proximité de la plage, surtout dans ces établissements.",
  "lbg.h1.destination_theme.vip_access": "Gagnants dans la catégorie Accès VIP – {0}",
  "see_all_accommodation": "Afficher tous les hébergements",
  "shoppers": "Magasinage",
  "whats_around": "{0} : dans les environs",
  "group": "Groupes",
  "hub.category.h2.couples": "{0} : le meilleur des voyages en couple",
  "hub.category.h1.shopping": "Magasinage à {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Communauté de voyageurs</span>",
  "hub.category.h2.group": "{0} : le meilleur des voyages en groupe",
  "listicle_legal_msg": "Cet article contient les avis de l’équipe éditoriale des guides Go! Hotels.com offre une rémunération aux auteurs dont les textes sont affichés sur ce site, ce qui peut inclure des voyages et d’autres frais.",
  "lbg.h1.destination_theme.cleanliness": "Gagnants dans la catégorie Propreté – {0}",
  "menu.more": "Plus",
  "lbg.section_properties_theme.lgbtq": "Gagnants dans la catégorie LGBTQ – {0}",
  "lbg.section_award_winners_destination": "Gagnants par destination",
  "lbg.terms_conditions": "Conditions générales",
  "hub.category.h2.nightlife": "{0} : le meilleur de la vie nocturne",
  "hub.neighbourhood.h2": "{0} : choses à faire",
  "lbg.section_award_winners_theme_destination": "Gagnants par catégorie – {0}",
  "lbg.section_award_world_winners": "Gagnants du monde entier",
  "things_to_see_and_do_in_other_cities": "Activités et visites dans d’autres villes",
  "button.backToTop": "Haut de page",
  "lbg.section_theme_world_winners.family": "Gagnants dans la catégorie Famille",
  "hotels_near": "Hôtels à proximité de {0}",
  "category.activity__sports_": "Excursions",
  "trust_module.get_reward_night": "Obtenez une nuit de récompense",
  "hub.category.h1.business": "{0} : voyages d’affaires",
  "category.food": "Gastronomie",
  "other_popular_destinations_in": "{0} : autres destinations populaires",
  "browse_by_category": "{0} : parcourir par catégorie",
  "see_more_attactions": "Afficher plus d’attractions",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "{0} : en savoir plus",
  "lbg.h1.destination_theme.most_wanted": "Gagnants dans la catégorie Plus populaire – {0}",
  "see_all_properties_in": "Afficher tous les établissements dans le quartier {0}",
  "category.tours": "Excursions",
  "hub.category.h1.sports": "{0} : sports et activités",
  "where_will_you_go_next": "Quelle sera votre prochaine destination?",
  "cities": "Villes",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Consulter le guide",
  "lbg.section_properties_theme.ski": "Gagnants dans la catégorie Ski – {0}",
  "related_stories": "Articles similaires",
  "hub.destination.h2": "À savoir avant de partir",
  "show_less": "Afficher moins",
  "hub.category.h1.football": "{0} : le guide par excellence pour les vrais amateurs de soccer",
  "hub.category.h2.food": "{0} : le meilleur de la restauration",
  "fallback_language_alert": "Nous sommes désolés, cette page n’a pas encore été traduite dans votre langue...",
  "category_highlight.title.information": "Renseignements utiles",
  "neighbourhood_guides_for_other_cities": "Guides des quartiers pour d’autres villes",
  "lbg.h2.hubpage": "Ces établissements remarquables ont des notes de clients élevées et de nombreux avis.",
  "travel_advisory.title": "Alerte de voyage",
  "lbg.section_properties_theme.all_inclusive": "Gagnants dans la catégorie Tout inclus – {0}",
  "lbg.h2.boutique": "Si vous souhaitez séjourner dans un hôtel-boutique, les voyageurs recommandent fortement ces établissements.",
  "lbg.section_properties_theme.family": "Gagnants dans la catégorie Famille – {0}",
  "hub.category.h2.families": "{0} : le meilleur des voyages en famille",
  "from": "du",
  "lbg.theme.vip_access": "Accès VIP",
  "lbg.section_properties_theme.lgbt": "Gagnants dans la catégorie LGBTQ+ – {0}",
  "x_star": "{0,choice,0#|1#{0} étoile|1.5#{0} étoile|2#{0} étoiles|2.5#{0} étoiles|3#{0} étoiles|3.5#{0} étoiles|4#{0} étoiles|4.5#{0} étoiles|5#{0} étoiles}",
  "trust_module.free_cancellation": "Annulation sans frais",
  "lbg.section_properties_theme.home_and_apartments": "Gagnants dans la catégorie Maisons et appartements – {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Rédacteur(trice) en collaboration</span>",
  "things_to_see_and_do_in": "Activités et visites <span class=\"next-line\">à {0}</span>",
  "lbg.check_price": "Consulter le prix",
  "hub.category.h2.blogs": "par des voyageurs chevronnés",
  "lbg.h2.home_and_apartments": "À la recherche d’un chez-soi loin de la maison? Consultez ces établissements les mieux notés.",
  "hub.category.h2.information": "{0} : renseignements utiles",
  "search_hotels_in": "Rechercher des hôtels<span class=\"extra-label\"> à {0}</span>",
  "hub.category.h2.nature": "{0} : les meilleures escapades en pleine nature",
  "lbg.h1.destination_theme.home_and_apartments": "Gagnants dans la catégorie Maisons et appartements – {0}",
  "photo_by": "Photo prise par {0}",
  "more_things_to_do": "Plus d’activités",
  "lbg.section_properties_theme.beach": "Gagnants dans la catégorie Plage – {0}",
  "read_less": "Afficher moins",
  "lbg.h2.spa_hotel": "Oubliez le stress et détendez-vous dans ces établissements populaires réputés pour leurs spas.",
  "second_fold_related_stories.see_also": "Consultez aussi ceci",
  "hub.category.h2.history": "{0} : le meilleur des endroits historiques",
  "lbg.section_properties_theme.cleanliness": "Gagnants dans la catégorie Propreté – {0}",
  "lbg.h1.destination_theme.boutique": "Gagnants dans la catégorie Hôtel-boutique – {0}",
  "nightlife_b595ae4": "Vie nocturne",
  "lbg.h1.destination_theme.beach": "Gagnants dans la catégorie Plage – {0}",
  "trust_module.free_cancellation.disclaimer": "Certains hôtels vous demandent d’annuler au moins 24 à 48 heures avant l’arrivée. Consultez le site pour en savoir plus.",
  "lbg.h2.family": "Ces établissements ont reçu d’excellents avis de clients et sont idéals pour les familles.",
  "lbg.h2.lgbtq": "Ces établissements ouverts à la communauté LGBTQ ont reçu d’excellents avis de clients.",
  "hub.category.h1.group": "{0} : voyages en groupe",
  "popular_stories": "Histoires populaires",
  "toc": "Table des matières",
  "hub.category.h2.shopping": "{0} : le meilleur des boutiques",
  "lbg.section_theme_world_winners.beach": "Gagnants dans la catégorie Plage",
  "page_score": "Score de cette page",
  "lbg.section_theme_world_winners.sustainable": "Gagnants dans la catégorie Écoresponsable",
  "lbg.theme.boutique": "Hôtel-boutique",
  "trust_module.price_guarantee": "Notre garantie de prix",
  "lbg.h1.destination_theme.all_inclusive": "Gagnants dans la catégorie Tout inclus – {0}",
  "top_destinations": "Destinations populaires",
  "hotel_prices_near": "Prix des hôtels à proximité de {0}",
  "lbg.score.cleanliness": "Propreté :",
  "x_stars": "{0,choice,0#|1#{0} étoile|1<{0} étoiles|4<{0} étoiles|10<{0} étoiles}",
  "lbg.theme.all_inclusive": "Tout inclus",
  "hub.category.h1.tours": "Excursions et sorties d’une journée à {0}",
  "lbg.section_award_winners_theme": "Gagnants par catégorie",
  "guest_writer": "{0} <span>&#124;</span> <span>Rédacteur(trice) invitée(e)</span>",
  "start_planning_your_x_trip": "Commencez à planifier votre voyage à {0}",
  "lbg.section_theme_world_winners.business": "Gagnants dans la catégorie Affaires",
  "trending_now_in": "Tendances du moment à {0}",
  "history": "Histoire",
  "search_hotels": "Rechercher des hôtels",
  "lbg.terms_conditions_details": "Le critère pour tout établissement sélectionné comme lauréat d’un prix en {0} était fondé sur ses notes de clients élevées en {1} et un minimum de 50 avis de clients.",
  "see_nearby_properties": "Afficher les hébergements voisins",
  "hub.category.h1.history": "{0} : endroits historiques",
  "location": "Lieu :",
  "lbg.section_theme_world_winners.luxury": "Gagnants dans la catégorie Luxe",
  "category_highlight.see_all.nightlife": "En savoir plus sur la vie nocturne",
  "also_popular": "Également populaires",
  "hub.category.h2.things_to_do": "{0} : le meilleur des activités",
  "lbg.theme.family": "Famille",
  "lbg.h1.destination_theme.pet_friendly": "Gagnants dans la catégorie Animaux de compagnie acceptés – {0}",
  "category_highlight.title.shopping": "{0} : où magasiner et quoi acheter",
  "price": "Prix :",
  "hub.category.h2.beach": "{0} : plages populaires",
  "neighbourhoods_in": "quartiers à {0}",
  "business": "Affaires",
  "photo": "Photographie",
  "blog.see_all_stories": "Afficher tous les articles",
  "category_highlight.see_all.food": "En savoir plus sur la gastronomie et la restauration",
  "see_all_properties": "Afficher tous les établissements",
  "lbg.theme.beach": "Plage",
  "what_to_see_and_do_in": "{0} : à voir et à faire",
  "hub.category.h2.single": "{0} : le meilleur des voyages en solo",
  "attractions_in": "Attractions à {0}",
  "read_more": "Afficher plus",
  "show_more": "Afficher plus",
  "hotels_near_this_landmark": "Hôtels à proximité de ce site d’intérêt",
  "check_dates": "Vérifier les dates",
  "featured_stories_and_fun_stuff": "Articles en vedette et activités intéressantes",
  "phone": "Téléphone :",
  "lbg.section_search_header": "Rechercher des gagnants",
  "category_highlight.title.food": "{0} : où et quoi manger",
  "see_all_things_to_do": "Afficher toutes les activités",
  "lbg.section_properties_theme.sustainable": "Gagnants dans la catégorie Écoresponsable – {0}",
  "lbg.h1.hubpage": "Gagnants d’un prix Loved by Guests {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Gagnants dans la catégorie Spa",
  "category.maps": "Cartes",
  "lbg.h1.destination_theme.family": "Gagnants dans la catégorie Famille – {0}",
  "lbg.section_theme_world_winners.cleanliness": "Gagnants dans la catégorie Propreté",
  "lbg.section_theme_world_winners.vip_access": "Gagnants dans la catégorie Accès VIP",
  "trust_module.get_reward_night.night_you_stay": "chaque fois que vous séjournez 10 nuits",
  "modified": "modifiée",
  "lbg.section_properties": "Gagnants – {0}",
  "trust_module.header": "Pourquoi réserver avec Hotels.com?",
  "hub.category.h2.luxury": "{0} : le meilleur des voyages de luxe",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Gagnants dans la catégorie Spa – {0}",
  "lbg.h2.most_wanted": "Les voyageurs recommandent fortement ces établissements pour un séjour idéal.",
  "top10_hotels_incomplete_alert": "Nous recevons actuellement beaucoup de demandes. Certains établissements pourraient ne pas apparaître pour le moment. <span>Nous sommes désolés des inconvénients occasionnés.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Grand voyageur</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Gagnants dans la catégorie Maisons et appartements",
  "loved_by_guests_collection_in": "Collection Loved by Guests – {0}",
  "title.suffix": "{0} − Guides Go",
  "hub.category.h2.maps": "{0} : cartes utiles",
  "opening_hours": "Heures d’ouverture :",
  "hub.category.h2.photos": "{0} : le meilleur des voyages de photographie",
  "lbg.theme.most_wanted": "Plus populaire",
  "blog.stories_container": "Articles de contributeurs",
  "hub.category.h2.budget": "{0} : le meilleur des voyages bon marché",
  "lbg.section_properties_theme.business": "Gagnants dans la catégorie Affaires – {0}",
  "not_sure_where_to_stay": "Cherchez-vous où séjourner?",
  "category.information": "Renseignements"
};