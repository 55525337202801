import React from 'react';
import { localization } from 'utils/localization';
var ImageAttribution = function (props) {
  var attribution = props.attribution;
  var loc = new localization();
  var attributionLink = attribution.linkToOriginal ? "<a href=\"".concat(attribution.linkToOriginal, "\" target=\"_blank\" rel=\"noreferrer\">").concat(attribution.photographerName, "</a> ") : attribution.photographerName;
  var attributionPageLink = attribution.linkToAttributionPage ? "(<a href=\"".concat(attribution.linkToAttributionPage, "\" target=\"_blank\" rel=\"noreferrer\">").concat(attribution.attributionVersion, "</a>) ") : '';
  var attributionModified = loc.getLocalizedText('modified');
  var attributionPhotoBy = loc.getLocalizedText('photo_by', attributionLink);
  return React.createElement("p", {
    className: "photo-attribution",
    dangerouslySetInnerHTML: {
      __html: "".concat(attributionPhotoBy, " ").concat(attributionPageLink, " ").concat(attributionModified)
    }
  });
};
export default ImageAttribution;