import React from 'react';
import { checkValue } from 'components/functions';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import CarouselComponent from '../generic/render-carousel';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from 'src/utils/localization';
var GoGuidesWhatsAroundComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchWhatsAround(goGuidesStore.pageUrlData);
  var loc = new localization();
  var stories = [];
  var trackingModuleName = '';
  var headerText = '';
  if (checkValue(contentData)) {
    var storiesData = contentData.storiesData,
      travelBlog = contentData.travelBlog,
      destinationName = contentData.destinationName;
    stories = checkValue(storiesData) ? storiesData : [];
    trackingModuleName = travelBlog === true ? 'popular stories' : 'what\'s around';
    headerText = travelBlog === true ? loc.getLocalizedText('popular_stories') : loc.getLocalizedText('whats_around', destinationName);
  }
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: trackingModuleName
    }
  };
  var carouselConfigProps = {
    carouselName: trackingModuleName,
    carouselHeaderText: 'Whats Around Carousel',
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4
    },
    pageBy: 4,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience
  };
  var CarouselItem = function (_a) {
    var _b;
    var item = _a.item;
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      alt: item.name,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=500&height=333',
          large: '&width=600&height=400'
        }
      }
    };
    return React.createElement(UitkCard, {
      border: true
    }, React.createElement(UitkFigure, {
      ratio: UitkFigureAspectRatioType.R16_9
    }, React.createElement(RenderImage, {
      imgProps: imageProps
    })), React.createElement("div", {
      className: "detail-wrapper"
    }, React.createElement(UitkHeading, {
      tag: "h3",
      size: 7
    }, item.name)), React.createElement(UitkCardLink, null, React.createElement("a", {
      href: item.targetUrl
    })));
  };
  var carouselData = stories.map(function (item, index) {
    return React.createElement(CarouselItem, {
      key: index,
      item: item
    });
  });
  return React.createElement(React.Fragment, null, checkValue(stories) && checkValue(stories.length > 0) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement("section", {
    className: "teaser-carousel whats-around sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "teaser-wrapper"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement(UitkHeading, {
    tag: "h2"
  }, headerText)), React.createElement(CarouselComponent, {
    data: carouselData,
    config: carouselConfigProps
  })))))));
};
export default GoGuidesWhatsAroundComponent;