import React from 'react';
import { checkValue } from 'components/functions';
var KeepExploringSub = function (props) {
  var level = props.level,
    listing = props.listing,
    listingClassName = props.listingClassName;
  var levelMapping = {
    neighbourhood: {
      dataNodeName: 'neighbourhoods',
      wrapperClassName: 'neighbourhoods-wrap'
    },
    'main-city': {
      dataNodeName: 'cities',
      wrapperClassName: 'main-city-wrap'
    },
    city: {
      dataNodeName: 'allCities',
      wrapperClassName: 'city-wrap'
    },
    region: {
      dataNodeName: 'regions',
      wrapperClassName: 'country-wrap'
    },
    country: {
      dataNodeName: 'country',
      wrapperClassName: 'country-wrap'
    },
    continent: {
      dataNodeName: '',
      wrapperClassName: 'destination-wrap'
    }
  };
  var config = levelMapping[level];
  // no data for the requested node
  if (config.dataNodeName !== '' && !checkValue(listing[config.dataNodeName])) {
    return null;
  }
  var data = config.dataNodeName === '' ? listing : listing[config.dataNodeName];
  var wrapperClassName = config.wrapperClassName;
  var targetUrl = data.targetUrl,
    name = data.name,
    list = data.list;
  var destinationListing = level === "region" ? data : checkValue(list) ? list : checkValue(data.countries) ? data.countries : null;
  var listHeader = function () {
    if (!checkValue(name)) {
      return null;
    } else {
      return checkValue(targetUrl) ? React.createElement("a", {
        href: targetUrl
      }, React.createElement("h3", null, name)) : React.createElement("h3", null, name);
    }
  };
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: wrapperClassName
  }, listHeader(), checkValue(destinationListing) ? React.createElement("ul", {
    className: listingClassName
  }, destinationListing.map(function (destination, idx) {
    return React.createElement("li", {
      key: idx
    }, React.createElement("a", {
      href: destination.targetUrl
    }, destination.name));
  })) : React.createElement(React.Fragment, null)));
};
export default KeepExploringSub;