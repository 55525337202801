export default {
  "current_deal": "Ưu đãi hiện tại",
  "lbg.section_properties_theme.pet_friendly": "Đơn vị thắng giải Nơi lưu trú cho phép mang theo vật nuôi ở {0}",
  "address": "Địa chỉ",
  "hub.homepage.h2": "Tận hưởng trọn vẹn mỗi chuyến đi với quỹ thời gian hạn hẹp",
  "search_hotels_near_this_landmark": "Tìm khách sạn<span class=\"extra-label\"> gần địa danh này</span>",
  "hub.category.h1.things_to_do": "Hoạt động không nên bỏ lỡ tại {0}",
  "lbg.h2.vip_access": "Nơi lưu trú VIP Access được tuyển chọn đặc biệt với những kỳ nghỉ được đánh giá cao và chất lượng dịch vụ xuất sắc.",
  "lbg.theme.cleanliness": "Sạch sẽ",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Tải thêm",
  "link_long_form_to_listiicle_multiple": "{0} - một trong những điểm đến nổi bật nhất trong {1} và {2} (Đọc tất cả về {3} tại đây)",
  "lbg.section_properties_theme.boutique": "Đơn vị thắng giải Nơi lưu trú boutique ở {0}",
  "lbg.h2.luxury": "Khám phá một đẳng cấp sang trọng mới. Đây là lý do nhiều người yêu thích kỳ nghỉ tại những nơi lưu trú này.",
  "hub.category.h1.information": "Thông tin về {0}",
  "families": "Gia đình",
  "trending_now": "Xu hướng du lịch",
  "lbg.from": "Từ",
  "hub.category.h1.road_trips": "Những cung đường bộ hấp dẫn nhất tại {0}",
  "lbg.h2.cleanliness": "Những nơi lưu trú này có điểm đánh giá tổng quan tuyệt vời và điểm đánh giá hàng đầu về vấn đề vệ sinh từ khách của chúng tôi.",
  "single": "Du lịch một mình",
  "couples": "Cặp đôi",
  "trust_module.price_guarantee.find_out_more": "tìm hiểu thêm",
  "top_destinations_in": "Điểm đến hàng đầu ở {0}",
  "hub.category.h1.couples": "Du lịch cặp đôi ở {0}",
  "hub.category.h2.tours": "Tour tham quan nổi bật ở {0}",
  "hub.category.h1.photos": "Địa điểm chụp ảnh đẹp ở {0}",
  "category.photos": "Ảnh",
  "hub.category.h1.budget": "Du lịch tiết kiệm ở {0}",
  "hub.category.h2.ski": "Điểm đến trượt tuyết tuyệt nhất tại {0}",
  "lbg.section_theme_world_winners.most_wanted": "Đơn vị thắng giải Nơi lưu trú hàng đầu",
  "lbg.theme.home_and_apartments": "Nhà & Căn hộ cho thuê",
  "hub.category.h1.luxury": "Du lịch sang trọng ở {0}",
  "hub.category.h2.adventure": "Gợi ý nổi bật cho những người năng động ở {0}",
  "hotels_in": "Khách sạn tại {0}",
  "lbg.section_search_winners": "Tìm nơi lưu trú thắng giải khác",
  "see_more_hotels_in": "Xem thêm khách sạn tại {0}",
  "hub.category.h1.beach": "Bãi biển ở {0}",
  "open": "Giờ mở cửa:",
  "enter_your_destination": "Nhập điểm đến",
  "best_of": "Hoạt động thú vị ở {0}",
  "top10_hotels_missing_properties": "Không thể hiển thị một số nơi lưu trú trên trang của chúng tôi ở thời điểm hiện tại. Thành thật xin lỗi vì sự bất tiện này.",
  "adventure": "Mạo hiểm",
  "lbg.h1.destination_theme.sustainable": "Đơn vị thắng giải Nơi lưu trú bền vững ở {0}",
  "luxury": "Sang trọng",
  "expand_map_view": "Mở rộng chế độ xem bản đồ",
  "book_now": "Đặt phòng ngay",
  "lbg.h1.destination_theme.ski": "Đơn vị thắng giải Nơi lưu trú trượt tuyết ở {0}",
  "hub.category.h1.food": "Ẩm thực tại {0}",
  "category.blogs": "Blog",
  "budget": "Giá rẻ",
  "popular_neighbourhoods_in": "Khu vực lân cận nổi tiếng tại {0}",
  "hub.category.h1.unusual": "Địa điểm độc lạ ở {0}",
  "see_all_accommodation_in": "Xem nơi lưu trú ở {0}",
  "hub.category.h1.adventure": "Du lịch năng động ở {0}",
  "category_highlight.see_all.shopping": "Xem thêm về mua sắm",
  "lbg.section_most_popular_destination": "Điểm đến được yêu thích hàng đầu",
  "lbg.section_properties_theme.luxury": "Đơn vị thắng giải Nơi lưu trú sang trọng ở {0}",
  "unusual": "Độc đáo",
  "category.shopping": "Mua sắm",
  "make_the_most_of_your_trip": "Tận dụng tối đa chuyến đi",
  "x_review": "{0,choice,0#|1#{0} nhận xét|2#{0} nhận xét|2<{0} nhận xét|4<{0} nhận xét|10#{0} nhận xét|10<{0} nhận xét}",
  "hub.category.h1.single": "Du lịch một mình ở {0}",
  "hub.category.h2.custom": "Xu hướng nổi bật",
  "lbg.section_theme_world_winners.lgbt": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ+",
  "lbg.section_award_winners_theme_other": "Những nơi lưu trú khác thắng giải theo chủ đề ở {0}",
  "lbg.supplier_collected_charges": "+ {0} thu tại nơi lưu trú",
  "in_destination": "ở {0}",
  "lbg.section_properties_theme.vip_access": "Đơn vị thắng giải Nơi lưu trú VIP Access tại {0}",
  "link_long_form_to_listiicle_single": "{0} - một trong những điểm đến nổi bật nhất trong {1} (Đọc tất cả về {2} tại đây)",
  "lbg.section_theme_world_winners.boutique": "Đơn vị thắng giải Nơi lưu trú boutique",
  "lbg.section_properties_theme.spa_hotel": "Đơn vị thắng giải Nơi lưu trú spa ở {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Đơn vị thắng giải Nơi lưu trú trọn gói",
  "hub.category.h2": "Hướng dẫn du lịch {0}",
  "lbg.properties.price_tooltip": "Đây là giá thấp nhất mỗi đêm trên trang của chúng tôi cho 14 ngày tiếp theo.",
  "hub.category.h2.road_trips": "Những cung đường bộ hấp dẫn nhất tại {0}",
  "hub.category.h1.ski": "Điểm đến trượt tuyết tại {0}",
  "food": "Ẩm thực",
  "lbg.theme.pet_friendly": "Cho phép mang theo vật nuôi",
  "lbg.theme.business": "Dành cho khách đi công tác",
  "hub.category.h1.nature": "Kỳ nghỉ giữa thiên nhiên tuyệt vời nhất tại {0}",
  "map": "Bản đồ",
  "category_highlight.see_all.information": "Xem thêm thông tin",
  "hotel_prices_in": "Giá khách sạn tại {0}",
  "hub.category.h1.nightlife": "Cuộc sống về đêm ở {0}",
  "trust_module.free_cancellation.most_hotels": "tại hầu hết khách sạn*",
  "also_popular_in": "Những khu vực nổi tiếng khác ở {0}",
  "lbg.h1.destination_theme.business": "Đơn vị thắng giải Nơi lưu trú dành cho khách đi công tác ở {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Đơn vị thắng giải Nơi lưu trú cho phép mang theo vật nuôi",
  "hub.category.h1.maps": "Bản đồ {0}",
  "lbg.section_properties_theme.most_wanted": "Đơn vị thắng giải Nơi lưu trú hàng đầu ở {0}",
  "category.nightlife": "Giải trí về đêm",
  "search_hotels_in_this_area": "Tìm khách sạn<span class=\"extra-label\"> tại khu vực này</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 bình chọn|2#2 bình chọn|2<{1} bình chọn|4<{1} bình chọn|10#10 bình chọn|10<{1} bình chọn})",
  "hub.category.h2.business": "Gợi ý nổi bật cho người đi công tác ở {0}",
  "category.things_to_do": "Danh thắng",
  "destination_travel_guide_by_category": "Hướng dẫn du lịch theo hạng mục ở {0}",
  "hub.category.h1.families": "Chuyến đi gia đình ở {0}",
  "lbg.section_theme_world_winners.ski": "Đơn vị thắng giải Nơi lưu trú trượt tuyết",
  "you_may_also_like": "Bạn có thể sẽ thích",
  "lbg.theme.sustainable": "Bền vững",
  "hub.category.h2.unusual": "Địa điểm độc lạ nổi bật ở {0}",
  "hub.category.h2.football": "Cổ vũ cho đội bóng yêu thích và tận hưởng một chuyến đi tuyệt vời!",
  "top_hotel": "Khách sạn hàng đầu",
  "lbg.h1.destination_theme.luxury": "Đơn vị thắng giải Nơi lưu trú sang trọng ở {0}",
  "top10_hotels_empty_alert": "Hiện chúng tôi đang gặp phải lỗi kỹ thuật. <span>Thành thật xin lỗi vì sự bất tiện này.</span>",
  "special_deal": "Ưu đãi đặc biệt",
  "lbg.h2.sustainable": "Bạn quan tâm giá trị bền vững? Hãy tham khảo những nơi lưu trú này.",
  "where_to_stay_in": "Nơi lưu trú tại {0}",
  "start_planning_your_trip": "Bắt đầu lên kế hoạch cho chuyến đi",
  "good_for": "Phù hợp với:",
  "category.hotels": "Khách sạn",
  "x_accommodation_guide": "Hướng dẫn nơi lưu trú ở {0}",
  "lbg.theme.luxury": "Sang trọng",
  "hub.category.h2.sports": "Gợi ý nổi bật cho người yêu thể thao và thích hoạt động ở {0}",
  "lbg.section_theme_world_winners.lgbtq": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ",
  "see_more_neighbourhoods": "Xem thêm khu vực lân cận",
  "category_highlight.title.nightlife": "Đi đâu và chơi gì về đêm tại {0}",
  "lbg.h1.destination_theme.lgbt": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ+ ở {0}",
  "hub.category.h1.blogs": "Blog du lịch {0}",
  "lbg.h2.business": "Những nơi lưu trú được khách đi công tác đánh giá cao.",
  "hotels": "Khách sạn",
  "lbg.h2.pet_friendly": "Khách yêu thích những nơi lưu trú chào đón thú cưng đi cùng bạn này.",
  "popular_landmarks_nearby": "Địa danh nổi tiếng lân cận",
  "popular_extractions_in": "Điểm du lịch nổi tiếng tại {0}",
  "lbg.h2.all_inclusive": "Nghỉ tại nơi lưu trú hàng đầu này và quên hết mọi căng thẳng.",
  "travel_guide_by_category_header": "{0} hàng đầu ở {1}",
  "lbg.travel_between": "Đi trong thời gian {0} - {1}",
  "keep_exploring": "Tiếp tục khám phá",
  "what_you_need_to_know_before_you_go": "Những điều cần lưu ý cho chuyến đi trọn vẹn",
  "read_it": "Đọc thêm",
  "view_on_map": "Xem trên bản đồ",
  "lbg.h1.destination_theme.lgbtq": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ tại {0}",
  "lbg.h2.beach": "Khách đặc biệt yêu thích kỳ nghỉ trên bãi biển tại những nơi lưu trú này.",
  "lbg.h1.destination_theme.vip_access": "Đơn vị thắng giải Nơi lưu trú VIP Access tại {0}",
  "see_all_accommodation": "Xem mọi nơi lưu trú",
  "shoppers": "Tín đồ mua sắm",
  "whats_around": "Những điểm thú vị quanh {0}",
  "group": "Nhóm",
  "hub.category.h2.couples": "Gợi ý nổi bật cho cặp đôi ở {0}",
  "hub.category.h1.shopping": "Mua sắm ở {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Cộng đồng du lịch</span>",
  "hub.category.h2.group": "Gợi ý nổi bật cho người đi du lịch theo nhóm ở {0}",
  "listicle_legal_msg": "Bài viết này gồm ý kiến của nhóm biên tập Go Guides. Hotels.com chi trả cho các tác giả để sử dụng bài viết của họ trên trang này, khoản chi trả có thể bao gồm chi phí của chuyến đi hoặc các chi phi khác.",
  "lbg.h1.destination_theme.cleanliness": "Đơn vị thắng giải về vấn đề vệ sinh tại {0}",
  "menu.more": "Xem thêm",
  "lbg.section_properties_theme.lgbtq": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ tại {0}",
  "lbg.section_award_winners_destination": "Nơi lưu trú thắng giải theo điểm đến",
  "lbg.terms_conditions": "Điều khoản & Điều kiện",
  "hub.category.h2.nightlife": "Hoạt động giải trí về đêm nổi bật ở {0}",
  "hub.neighbourhood.h2": "Hoạt động không thể bỏ lỡ tại {0}",
  "lbg.section_award_winners_theme_destination": "Nơi lưu trú thắng giải theo chủ đề ở {0}",
  "lbg.section_award_world_winners": "Nơi lưu trú thắng giải trên toàn cầu",
  "things_to_see_and_do_in_other_cities": "Hoạt động tham quan và giải trí tại các thành phố khác",
  "button.backToTop": "Quay lại đầu trang",
  "lbg.section_theme_world_winners.family": "Đơn vị thắng giải Nơi lưu trú cho gia đình",
  "hotels_near": "Khách sạn gần {0}",
  "category.activity__sports_": "Hoạt động",
  "trust_module.get_reward_night": "Nhận 1 đêm thưởng",
  "hub.category.h1.business": "Đi công tác ở {0}",
  "category.food": "Ẩm thực",
  "other_popular_destinations_in": "Các điểm đến nổi bật khác ở {0}",
  "browse_by_category": "Xem thông tin về {0} theo danh mục",
  "see_more_attactions": "Xem thêm điểm du lịch",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Thông tin về {0}",
  "lbg.h1.destination_theme.most_wanted": "Đơn vị thắng giải Nơi lưu trú hàng đầu ở {0}",
  "see_all_properties_in": "Xem tất cả nơi lưu trú tại {0}",
  "category.tours": "Tour",
  "hub.category.h1.sports": "Thể thao và giải trí ở {0}",
  "where_will_you_go_next": "Đâu là điểm đến tiếp theo của bạn?",
  "cities": "Thành phố",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Xem hướng dẫn",
  "lbg.section_properties_theme.ski": "Đơn vị thắng giải Nơi lưu trú trượt tuyết ở {0}",
  "related_stories": "Nội dung liên quan",
  "hub.destination.h2": "Những điều cần lưu ý cho chuyến đi trọn vẹn",
  "show_less": "Hiển thị ít hơn",
  "hub.category.h1.football": "Cẩm nang về {0} cho fan bóng đá đích thực",
  "hub.category.h2.food": "Ẩm thực nổi bật ở {0}",
  "fallback_language_alert": "Rất tiếc, chưa hỗ trợ ngôn ngữ của bạn cho trang này...",
  "category_highlight.title.information": "Thông tin hữu ích",
  "neighbourhood_guides_for_other_cities": "Hướng dẫn về khu vực lân cận dành cho các thành phố khác",
  "lbg.h2.hubpage": "Những nơi lưu trú tuyệt vời này có điểm đánh giá của khách rất cao và nhiều nhận xét.",
  "travel_advisory.title": "Cảnh báo du lịch",
  "lbg.section_properties_theme.all_inclusive": "Đơn vị thắng giải Nơi lưu trú trọn gói ở {0}",
  "lbg.h2.boutique": "Được nhiệt liệt đề xuất cho những ai yêu thích nơi lưu trú boutique nhỏ xinh.",
  "lbg.section_properties_theme.family": "Đơn vị thắng giải Nơi lưu trú cho gia đình ở {0}",
  "hub.category.h2.families": "Gợi ý nổi bật cho chuyến đi gia đình ở {0}",
  "from": "từ",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Đơn vị thắng giải Nơi lưu trú thân thiện với LGBTQ+ ở {0}",
  "x_star": "{0,choice,0#|1#{0} sao|1.5#{0} sao|2#{0} sao|2.5#{0} sao|3#{0} sao|3.5#{0} sao|4#{0} sao|4.5#{0} sao|5#{0} sao}",
  "trust_module.free_cancellation": "Hủy miễn phí",
  "lbg.section_properties_theme.home_and_apartments": "Nhà và căn hộ cho thuê thắng giải ở {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Tác giả</span>",
  "things_to_see_and_do_in": "Hoạt động tham quan và giải trí <span class=\"next-line\">tại {0}</span>",
  "lbg.check_price": "Xem giá",
  "hub.category.h2.blogs": "Từ khách du lịch dày dặn kinh nghiệm",
  "lbg.h2.home_and_apartments": "Tìm một mái nhà phương xa? Bạn có thể tham khảo những nơi lưu trú được đánh giá cao này.",
  "hub.category.h2.information": "Thông tin hữu ích về {0}",
  "search_hotels_in": "Tìm khách sạn<span class=\"extra-label\"> tại {0}</span>",
  "hub.category.h2.nature": "Khám phá thiên nhiên tuyệt vời tại {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Nhà và căn hộ cho thuê thắng giải ở {0}",
  "photo_by": "Ảnh của {0}",
  "more_things_to_do": "Hoạt động vui chơi, giải trí khác",
  "lbg.section_properties_theme.beach": "Đơn vị thắng giải Nơi lưu trú bãi biển ở {0}",
  "read_less": "Đọc ít hơn",
  "lbg.h2.spa_hotel": "Thả lỏng và thư giãn tại những nơi lưu trú nổi tiếng với khu spa chất lượng cao này.",
  "second_fold_related_stories.see_also": "Xem thêm",
  "hub.category.h2.history": "Danh thắng lịch sử nổi bật ở {0}",
  "lbg.section_properties_theme.cleanliness": "Đơn vị thắng giải về vấn đề vệ sinh tại {0}",
  "lbg.h1.destination_theme.boutique": "Đơn vị thắng giải Nơi lưu trú boutique ở {0}",
  "nightlife_b595ae4": "Giải trí về đêm",
  "lbg.h1.destination_theme.beach": "Đơn vị thắng giải Nơi lưu trú bãi biển ở {0}",
  "trust_module.free_cancellation.disclaimer": "Một số nơi lưu trú yêu cầu hủy ít nhất 24-48 giờ trước ngày nhận phòng. Xem thêm chi tiết trên trang.",
  "lbg.h2.family": "Những nơi lưu trú được đánh giá cao, đặc biệt cho khách đi cùng gia đình.",
  "lbg.h2.lgbtq": "Những nơi lưu trú chào đón khách LGBTQ được khách đánh giá cao.",
  "hub.category.h1.group": "Du lịch theo nhóm ở {0}",
  "popular_stories": "Câu chuyện nổi bật",
  "toc": "Thông tin chi tiết",
  "hub.category.h2.shopping": "Khu mua sắm nổi bật ở {0}",
  "lbg.section_theme_world_winners.beach": "Đơn vị thắng giải Nơi lưu trú bãi biển",
  "page_score": "Điểm trang này",
  "lbg.section_theme_world_winners.sustainable": "Đơn vị thắng giải Nơi lưu trú bền vững",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Đảm bảo giá",
  "lbg.h1.destination_theme.all_inclusive": "Đơn vị thắng giải Nơi lưu trú trọn gói ở {0}",
  "top_destinations": "Điểm đến hàng đầu",
  "hotel_prices_near": "Giá khách sạn gần {0}",
  "lbg.score.cleanliness": "Sạch sẽ, vệ sinh:",
  "x_stars": "{0,choice,0#|1#{0} sao|1<{0} sao|4<{0} sao|10<{0} sao}",
  "lbg.theme.all_inclusive": "Trọn gói",
  "hub.category.h1.tours": "Tour tham quan và trong ngày tại {0}",
  "lbg.section_award_winners_theme": "Nơi lưu trú thắng giải theo chủ đề",
  "guest_writer": "{0} <span>&#124;</span> <span>Tác giả tự do</span>",
  "start_planning_your_x_trip": "Bắt đầu lên kế hoạch cho chuyến đi đến {0}",
  "lbg.section_theme_world_winners.business": "Đơn vị thắng giải Nơi lưu trú dành cho khách đi công tác",
  "trending_now_in": "Xu hướng nổi bật ở {0}",
  "history": "Lịch sử",
  "search_hotels": "Tìm khách sạn",
  "lbg.terms_conditions_details": "Tiêu chí lựa chọn nơi lưu trú thắng giải năm {0} được tính dựa trên điểm đánh giá của khách cao trong năm {1} và có tối thiểu 50 nhận xét của khách.",
  "see_nearby_properties": "Xem nơi lưu trú lân cận",
  "hub.category.h1.history": "Danh thắng lịch sử ở {0}",
  "location": "Địa điểm:",
  "lbg.section_theme_world_winners.luxury": "Đơn vị thắng giải Nơi lưu trú sang trọng",
  "category_highlight.see_all.nightlife": "Xem thêm về cuộc sống về đêm",
  "also_popular": "Những điểm đến nổi bật khác",
  "hub.category.h2.things_to_do": "Hoạt động không nên bỏ lỡ nổi bật ở {0}",
  "lbg.theme.family": "Gia đình",
  "lbg.h1.destination_theme.pet_friendly": "Đơn vị thắng giải Nơi lưu trú cho phép mang theo vật nuôi ở {0}",
  "category_highlight.title.shopping": "Mua gì và ở đâu tại {0}",
  "price": "Giá:",
  "hub.category.h2.beach": "Bãi biển nổi bật ở {0}",
  "neighbourhoods_in": "khu vực tại {0}",
  "business": "Đi công tác",
  "photo": "Ảnh",
  "blog.see_all_stories": "Xem tất cả bài viết",
  "category_highlight.see_all.food": "Xem thêm về đồ ăn & thức uống",
  "see_all_properties": "Xem tất cả nơi lưu trú",
  "lbg.theme.beach": "Bãi biển",
  "what_to_see_and_do_in": "Xem gì và làm gì tại {0}",
  "hub.category.h2.single": "Gợi ý nổi bật dành cho người đi du lịch một mình ở {0}",
  "attractions_in": "Danh thắng tại {0}",
  "read_more": "Đọc thêm",
  "show_more": "Hiển thị thêm",
  "hotels_near_this_landmark": "Khách sạn gần địa danh này",
  "check_dates": "Kiểm tra ngày",
  "featured_stories_and_fun_stuff": "Gợi ý du lịch & hoạt động không thể bỏ lỡ",
  "phone": "Điện thoại:",
  "lbg.section_search_header": "Tìm đơn vị thắng giải",
  "category_highlight.title.food": "Ăn gì và ở đâu tại {0}",
  "see_all_things_to_do": "Xem tất cả hoạt động giải trí",
  "lbg.section_properties_theme.sustainable": "Đơn vị thắng giải Nơi lưu trú bền vững ở {0}",
  "lbg.h1.hubpage": "Nơi lưu trú thắng giải Loved by Guests năm {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Đơn vị thắng giải Nơi lưu trú spa",
  "category.maps": "Bản đồ",
  "lbg.h1.destination_theme.family": "Đơn vị thắng giải Nơi lưu trú cho gia đình ở {0}",
  "lbg.section_theme_world_winners.cleanliness": "Đơn vị thắng giải về vấn đề vệ sinh",
  "lbg.section_theme_world_winners.vip_access": "Đơn vị thắng giải Nơi lưu trú VIP Access",
  "trust_module.get_reward_night.night_you_stay": "với mỗi 10 đêm bạn lưu trú",
  "modified": "đã điều chỉnh",
  "lbg.section_properties": "Nơi lưu trú thắng giải ở {0}",
  "trust_module.header": "Vì sao nên đặt phòng cùng Hotels.com?",
  "hub.category.h2.luxury": "Gợi ý nổi bật cho chuyến du lịch sang trọng ở {0}",
  "lbg.theme.ski": "Trượt tuyết",
  "lbg.h1.destination_theme.spa_hotel": "Đơn vị thắng giải Nơi lưu trú spa ở {0}",
  "lbg.h2.most_wanted": "Khách đề xuất những nơi lưu trú này cho một kỳ nghỉ hoàn hảo.",
  "top10_hotels_incomplete_alert": "Do có quá nhiều người truy cập nên một số nơi lưu trú không thể hiển thị được lúc này. <span>Thành thật xin lỗi vì sự bất tiện này.</span>",
  "compulsive_traveller": "{0} <span>|</span> <span>Du lịch thường xuyên</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Đơn vị thắng giải Nhà & Căn hộ cho thuê",
  "loved_by_guests_collection_in": "Nơi lưu trú Khách hàng Yêu thích ở {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Bản đồ hữu ích ở {0}",
  "opening_hours": "Giờ mở cửa",
  "hub.category.h2.photos": "Địa điểm chụp ảnh đẹp hàng đầu ở {0}",
  "lbg.theme.most_wanted": "Hàng đầu",
  "blog.stories_container": "Câu chuyện du lịch",
  "hub.category.h2.budget": "Gợi ý nổi bật cho khách du lịch tiết kiệm ở {0}",
  "lbg.section_properties_theme.business": "Đơn vị thắng giải Nơi lưu trú dành cho khách đi công tác ở {0}",
  "not_sure_where_to_stay": "Bạn không biết lưu trú tại đâu?",
  "category.information": "Thông tin"
};