export default {
  "current_deal": "目前的價格",
  "lbg.section_properties_theme.pet_friendly": "{0}寵物友善住宿獎項得主",
  "address": "地址",
  "hub.homepage.h2": "世界這麼大，時間卻太少...",
  "search_hotels_near_this_landmark": "搜尋<span class=\"extra-label\">此地標附近</span>的飯店",
  "hub.category.h1.things_to_do": "{0}當地玩樂",
  "lbg.h2.vip_access": "VIP Access 住宿提供頂級的住宿體驗，深受旅客推崇。",
  "lbg.theme.cleanliness": "清潔度",
  "lbg.theme.lgbtq": "性別友善",
  "load_more": "點我看更多",
  "link_long_form_to_listiicle_multiple": "{0} 名列{1}和{2} (閱讀{3}相關的所有文章)",
  "lbg.section_properties_theme.boutique": "{0}精品住宿獎項得主",
  "lbg.h2.luxury": "極致奢華，住過的人都讚不絕口的極品享受。",
  "hub.category.h1.information": "{0}相關資訊",
  "families": "家庭",
  "trending_now": "當紅景點",
  "lbg.from": "最低價格",
  "hub.category.h1.road_trips": "{0}公路之旅推薦",
  "lbg.h2.cleanliness": "這些住宿的整體旅客評分都很出色，而且上下乾淨溜溜，住過的人都讚不絕口！",
  "single": "單人旅客",
  "couples": "情侶夫妻",
  "trust_module.price_guarantee.find_out_more": "了解詳情",
  "top_destinations_in": "{0}最夯旅遊地點",
  "hub.category.h1.couples": "{0}情侶行程",
  "hub.category.h2.tours": "{0}最棒的行程推薦",
  "hub.category.h1.photos": "{0}拍照打卡行程",
  "category.photos": "相片",
  "hub.category.h1.budget": "{0}小資行程",
  "hub.category.h2.ski": "{0}最佳滑雪勝地",
  "lbg.section_theme_world_winners.most_wanted": "最受歡迎住宿獎項得主",
  "lbg.theme.home_and_apartments": "民宿及公寓",
  "hub.category.h1.luxury": "{0}奢華行程",
  "hub.category.h2.adventure": "{0}戶外行程推薦",
  "hotels_in": "{0}飯店",
  "lbg.section_search_winners": "搜尋更多住宿獎項得主",
  "see_more_hotels_in": "顯示更多{0}的飯店",
  "hub.category.h1.beach": "{0}的海灘",
  "open": "營業時間：",
  "enter_your_destination": "請輸入目的地",
  "best_of": "{0}好好玩",
  "top10_hotels_missing_properties": "目前部份住宿可能無法顯示，造成您的不便，我們深感抱歉。",
  "adventure": "探險",
  "lbg.h1.destination_theme.sustainable": "{0}環保住宿獎項得主",
  "luxury": "豪華飯店",
  "expand_map_view": "展開地圖檢視",
  "book_now": "立即預訂",
  "lbg.h1.destination_theme.ski": "{0}滑雪住宿獎項得主",
  "hub.category.h1.food": "{0}餐飲推薦",
  "category.blogs": "部落格",
  "budget": "經濟實惠",
  "popular_neighbourhoods_in": "{0}熱門區域",
  "hub.category.h1.unusual": "{0}秘境和隱藏景點",
  "see_all_accommodation_in": "查看所有{0}的住宿",
  "hub.category.h1.adventure": "{0}戶外行程",
  "category_highlight.see_all.shopping": "更多購物相關文章",
  "lbg.section_most_popular_destination": "熱門地點",
  "lbg.section_properties_theme.luxury": "{0}豪華住宿獎項得主",
  "unusual": "獨特難忘",
  "category.shopping": "購物",
  "make_the_most_of_your_trip": "盡享旅程",
  "x_review": "{0,choice,0#|1#{0} 則評語|2#{0} 則評語|2<{0} 則評語|4<{0} 則評語|10#{0} 則評語|10<{0} 則評語}",
  "hub.category.h1.single": "{0}單人輕旅行",
  "hub.category.h2.custom": "當紅景點",
  "lbg.section_theme_world_winners.lgbt": "性別友善+ 的住宿獎項得主",
  "lbg.section_award_winners_theme_other": "{0}的其他住宿獎項得主",
  "lbg.supplier_collected_charges": "+ {0} (在住宿支付)",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "{0} VIP Access 住宿獎項得主",
  "link_long_form_to_listiicle_single": "{0} 名列{1} (閱讀{2}相關的所有文章)",
  "lbg.section_theme_world_winners.boutique": "精品住宿獎項得主",
  "lbg.section_properties_theme.spa_hotel": "{0} Spa 住宿獎項得主",
  "lbg.section_theme_world_winners.all_inclusive": "全包式住宿獎項得主",
  "hub.category.h2": "{0}旅遊指南",
  "lbg.properties.price_tooltip": "這是未來 14 天我們網站最低的每晚房價。",
  "hub.category.h2.road_trips": "{0}公路之旅推薦",
  "hub.category.h1.ski": "{0}滑雪勝地",
  "food": "美食",
  "lbg.theme.pet_friendly": "寵物友善",
  "lbg.theme.business": "商務",
  "hub.category.h1.nature": "{0}戶外出走推薦",
  "map": "地圖",
  "category_highlight.see_all.information": "更多資訊",
  "hotel_prices_in": "{0}飯店價格",
  "hub.category.h1.nightlife": "{0}的夜生活",
  "trust_module.free_cancellation.most_hotels": "適用大多數飯店*",
  "also_popular_in": "{0}其他熱門地點",
  "lbg.h1.destination_theme.business": "{0}商務住宿獎項得主",
  "lbg.section_theme_world_winners.pet_friendly": "寵物友善住宿獎項得主",
  "hub.category.h1.maps": "{0}地圖",
  "lbg.section_properties_theme.most_wanted": "{0}最受歡迎住宿獎項得主",
  "category.nightlife": "夜間娛樂",
  "search_hotels_in_this_area": "搜尋<span class=\"extra-label\">此地區</span>的飯店",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 人評分|2#2 人評分|2<{1} 人評分|4<{1} 人評分|10#10 人評分|10<{1} 人評分})",
  "hub.category.h2.business": "{0}商務行程推薦",
  "category.things_to_do": "景點",
  "destination_travel_guide_by_category": "{0}旅遊指南",
  "hub.category.h1.families": "{0}家庭旅遊推薦",
  "lbg.section_theme_world_winners.ski": "滑雪住宿獎項得主",
  "you_may_also_like": "您可能也會喜歡",
  "lbg.theme.sustainable": "環保",
  "hub.category.h2.unusual": "{0}秘境和隱藏景點推薦",
  "hub.category.h2.football": "支持您的球隊，同時盡享旅程！",
  "top_hotel": "熱門飯店",
  "lbg.h1.destination_theme.luxury": "{0}豪華住宿獎項得主",
  "top10_hotels_empty_alert": "目前系統出現暫時性的技術問題！<span>造成您的不便，我們深感抱歉。</span>",
  "special_deal": "特別優惠",
  "lbg.h2.sustainable": "想要好好愛護北極熊？這裡有很多合適的住宿選擇。",
  "where_to_stay_in": "{0}飯店住宿推薦",
  "start_planning_your_trip": "開始計劃旅程",
  "good_for": "主打特色：",
  "category.hotels": "飯店",
  "x_accommodation_guide": "{0}住宿指南",
  "lbg.theme.luxury": "奢華",
  "hub.category.h2.sports": "{0}熱門運動和活動",
  "lbg.section_theme_world_winners.lgbtq": "性別友善住宿獎項得主",
  "see_more_neighbourhoods": "查看更多區域",
  "category_highlight.title.nightlife": "{0}夜生活好去處",
  "lbg.h1.destination_theme.lgbt": "{0}性別友善+ 的住宿獎項得主",
  "hub.category.h1.blogs": "{0}旅行部落格",
  "lbg.h2.business": "商務旅客首選。",
  "hotels": "飯店",
  "lbg.h2.pet_friendly": "想帶毛小孩出遊，這些住宿大家都推。",
  "popular_landmarks_nearby": "附近的熱門地標",
  "popular_extractions_in": "{0}熱門景點",
  "lbg.h2.all_inclusive": "入住熱門飯店，把煩惱通通忘掉。",
  "travel_guide_by_category_header": "{1}{0}",
  "lbg.travel_between": "入住期限：{0} - {1}",
  "keep_exploring": "繼續探索",
  "what_you_need_to_know_before_you_go": "行前準備二三事",
  "read_it": "閱讀全文",
  "view_on_map": "在地圖上檢視",
  "lbg.h1.destination_theme.lgbtq": "{0}性別友善住宿獎項得主",
  "lbg.h2.beach": "想天天看海？這些住宿讓你看好看滿。",
  "lbg.h1.destination_theme.vip_access": "{0} VIP Access 住宿獎項得主",
  "see_all_accommodation": "查看所有住宿",
  "shoppers": "購物狂",
  "whats_around": "{0}附近景點",
  "group": "團體",
  "hub.category.h2.couples": "{0}情侶行程推薦",
  "hub.category.h1.shopping": "{0}的購物活動",
  "travel_community": "{0} <span>&#124;</span> <span>旅遊社群</span>",
  "hub.category.h2.group": "{0}團體行程推薦",
  "listicle_legal_msg": "此文章包含 Go Guides 編輯小組的意見。Hotels.com 針對在本網站發表文章的作者提供報酬，報酬形式可能包含旅行和其他費用。",
  "lbg.h1.destination_theme.cleanliness": "{0}整潔住宿獎項得主",
  "menu.more": "更多",
  "lbg.section_properties_theme.lgbtq": "{0}性別友善住宿獎項得主",
  "lbg.section_award_winners_destination": "各旅遊目的地的住宿獎項得主",
  "lbg.terms_conditions": "條款和條件",
  "hub.category.h2.nightlife": "{0}最熱鬧的夜間娛樂推薦",
  "hub.neighbourhood.h2": "{0}推薦行程",
  "lbg.section_award_winners_theme_destination": "{0}的各類型住宿獎項得主",
  "lbg.section_award_world_winners": "全球住宿獎項得主",
  "things_to_see_and_do_in_other_cities": "其他城市的觀光行程和活動",
  "button.backToTop": "回到頂端",
  "lbg.section_theme_world_winners.family": "家庭式住宿獎項得主",
  "hotels_near": "{0}附近的飯店",
  "category.activity__sports_": "活動",
  "trust_module.get_reward_night": "獲得獎勵住宿",
  "hub.category.h1.business": "{0}商務行程",
  "category.food": "美食",
  "other_popular_destinations_in": "{0}其他熱門好去處",
  "browse_by_category": "{0}分類指南",
  "see_more_attactions": "查看更多景點",
  "lbg.theme.lgbt": "性別友善+",
  "more_information_about": "更多有關{0}的資訊",
  "lbg.h1.destination_theme.most_wanted": "{0}最受歡迎住宿獎項得主",
  "see_all_properties_in": "查看{0}的所有住宿",
  "category.tours": "導覽",
  "hub.category.h1.sports": "{0}運動和活動",
  "where_will_you_go_next": "接下來想去哪？",
  "cities": "城市",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "查看指南",
  "lbg.section_properties_theme.ski": "{0}滑雪住宿獎項得主",
  "related_stories": "相關推薦",
  "hub.destination.h2": "行前準備二三事",
  "show_less": "顯示較少",
  "hub.category.h1.football": "足球迷的{0}終極指南",
  "hub.category.h2.food": "{0}的美味餐飲推薦",
  "fallback_language_alert": "很抱歉，此頁面尚未翻譯成您的語言...",
  "category_highlight.title.information": "實用資訊",
  "neighbourhood_guides_for_other_cities": "其他城市的旅遊指南",
  "lbg.h2.hubpage": "這些超棒的住宿擁有很高的旅客評分和很多評論。",
  "travel_advisory.title": "旅遊警示",
  "lbg.section_properties_theme.all_inclusive": "{0}全包式住宿獎項得主",
  "lbg.h2.boutique": "人氣精品住宿，住過都說讚。",
  "lbg.section_properties_theme.family": "{0}家庭式住宿獎項得主",
  "hub.category.h2.families": "{0}家庭旅遊精選推薦",
  "from": "最低價",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "{0}性別友善+ 的住宿獎項得主",
  "x_star": "{0,choice,0#|1#{0} 星級|1.5#{0} 星級|2#{0} 星級|2.5#{0} 星級|3#{0} 星級|3.5#{0} 星級|4#{0} 星級|4.5#{0} 星級|5#{0} 星級}",
  "trust_module.free_cancellation": "免費取消",
  "lbg.section_properties_theme.home_and_apartments": "{0}民宿及公寓獎項得主",
  "contributing_writer": "{0} <span>&#124;</span> <span>特約作者</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span>觀光行程和活動",
  "lbg.check_price": "查看價格",
  "hub.category.h2.blogs": "由資深旅遊咖撰寫",
  "lbg.h2.home_and_apartments": "想找出門在外的家？這些人氣住宿就是首選。",
  "hub.category.h2.information": "{0}的實用資訊",
  "search_hotels_in": "搜尋<span class=\"extra-label\">{0}</span>的飯店",
  "hub.category.h2.nature": "{0}自然之旅推薦",
  "lbg.h1.destination_theme.home_and_apartments": "{0}民宿及公寓獎項得主",
  "photo_by": "照片由 {0} 拍攝",
  "more_things_to_do": "其他更多活動",
  "lbg.section_properties_theme.beach": "{0}海灘住宿獎項得主",
  "read_less": "較少資訊",
  "lbg.h2.spa_hotel": "入住人氣 Spa 住宿，好好放鬆身心靈。",
  "second_fold_related_stories.see_also": "延伸閱讀",
  "hub.category.h2.history": "{0}熱門歷史景點",
  "lbg.section_properties_theme.cleanliness": "{0}整潔住宿獎項得主",
  "lbg.h1.destination_theme.boutique": "{0}精品住宿獎項得主",
  "nightlife_b595ae4": "夜間娛樂",
  "lbg.h1.destination_theme.beach": "{0}海灘住宿獎項得主",
  "trust_module.free_cancellation.disclaimer": "部分飯店要求旅客如需取消預訂，最晚必須在入住當日的 24 - 48 小時前取消，個別詳情請見網站。",
  "lbg.h2.family": "家庭出遊首選，住過都說讚。",
  "lbg.h2.lgbtq": "旅客大推的性別友善住宿。",
  "hub.category.h1.group": "{0}團體行程",
  "popular_stories": "熱門文章",
  "toc": "內容",
  "hub.category.h2.shopping": "{0}最好買的商圈推薦",
  "lbg.section_theme_world_winners.beach": "海灘住宿獎項得主",
  "page_score": "此頁得分",
  "lbg.section_theme_world_winners.sustainable": "環保住宿獎項得主",
  "lbg.theme.boutique": "精品住宿",
  "trust_module.price_guarantee": "價格保證",
  "lbg.h1.destination_theme.all_inclusive": "{0}全包式住宿獎項得主",
  "top_destinations": "熱門目的地",
  "hotel_prices_near": "{0}附近飯店價格",
  "lbg.score.cleanliness": "清潔度：",
  "x_stars": "{0,choice,0#|1#{0} 星級|1<{0} 星級|4<{0} 星級|10<{0} 星級}",
  "lbg.theme.all_inclusive": "全包式",
  "hub.category.h1.tours": "{0}導覽和一日遊",
  "lbg.section_award_winners_theme": "各類型住宿獎項得主",
  "guest_writer": "{0} <span>&#124;</span> <span>自由作者</span>",
  "start_planning_your_x_trip": "開始計劃您的{0}旅程",
  "lbg.section_theme_world_winners.business": "商務住宿獎項得主",
  "trending_now_in": "{0}現在紅什麼？",
  "history": "歷史悠久",
  "search_hotels": "搜尋飯店",
  "lbg.terms_conditions_details": "住宿會被選為 {0} 年得獎者是因為他們在 {1} 年獲得很高的旅客評分和至少 50 則評論。",
  "see_nearby_properties": "查看附近的住宿",
  "hub.category.h1.history": "{0}歷史景點",
  "location": "位置資訊：",
  "lbg.section_theme_world_winners.luxury": "豪華住宿獎項得主",
  "category_highlight.see_all.nightlife": "更多夜生活相關文章",
  "also_popular": "其他熱門地點",
  "hub.category.h2.things_to_do": "{0}最推的在地玩樂",
  "lbg.theme.family": "家庭旅遊",
  "lbg.h1.destination_theme.pet_friendly": "{0}寵物友善住宿獎項得主",
  "category_highlight.title.shopping": "{0}購物推薦",
  "price": "價格：",
  "hub.category.h2.beach": "{0}最值得推薦的海灘",
  "neighbourhoods_in": "{0}的區域",
  "business": "商務出差",
  "photo": "拍照",
  "blog.see_all_stories": "查看所有文章",
  "category_highlight.see_all.food": "更多餐飲相關文章",
  "see_all_properties": "查看所有住宿",
  "lbg.theme.beach": "海灘",
  "what_to_see_and_do_in": "{0}的景點和活動推薦",
  "hub.category.h2.single": "{0}單人輕旅行推薦",
  "attractions_in": "{0}景點",
  "read_more": "更多資訊",
  "show_more": "顯示更多",
  "hotels_near_this_landmark": "此地標附近的飯店",
  "check_dates": "查看日期",
  "featured_stories_and_fun_stuff": "精選好文和趣聞",
  "phone": "電話：",
  "lbg.section_search_header": "搜尋得獎住宿",
  "category_highlight.title.food": "{0}美食推薦",
  "see_all_things_to_do": "查看所有當地玩樂",
  "lbg.section_properties_theme.sustainable": "{0}環保住宿獎項得主",
  "lbg.h1.hubpage": "{0}的深受旅客喜愛獎項得主",
  "lbg.section_theme_world_winners.spa_hotel": " Spa 住宿獎項得主",
  "category.maps": "地圖",
  "lbg.h1.destination_theme.family": "{0}家庭式住宿獎項得主",
  "lbg.section_theme_world_winners.cleanliness": "整潔住宿獎項得主",
  "lbg.section_theme_world_winners.vip_access": "VIP Access 住宿獎項得主",
  "trust_module.get_reward_night.night_you_stay": "每住滿 10 晚就送 1 晚",
  "modified": "經過修圖",
  "lbg.section_properties": "{0}的住宿獎項得主",
  "trust_module.header": "透過 Hotels.com 預訂好處多多",
  "hub.category.h2.luxury": "{0}奢華行程推薦",
  "lbg.theme.ski": "滑雪",
  "lbg.h1.destination_theme.spa_hotel": "{0} Spa 住宿獎項得主",
  "lbg.h2.most_wanted": "完美住宿體驗，住過都說讚。",
  "top10_hotels_incomplete_alert": "目前網路非常繁忙，部份住宿可能無法顯示。<span>造成您的不便，我們深感抱歉。</span>",
  "compulsive_traveller": "{0} <span>|</span> <span>旅遊行家</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "民宿及公寓獎項得主",
  "loved_by_guests_collection_in": "{0}深受旅客喜愛獎系列住宿",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "{0}的實用地圖",
  "opening_hours": "營業時間",
  "hub.category.h2.photos": "{0}熱門拍照打卡行程",
  "lbg.theme.most_wanted": "最受旅客歡迎",
  "blog.stories_container": "部落客的旅遊文章",
  "hub.category.h2.budget": "{0}小資行程推薦",
  "lbg.section_properties_theme.business": "{0}商務住宿獎項得主",
  "not_sure_where_to_stay": "還沒決定住哪嗎？",
  "category.information": "資訊"
};