import React from 'react';
var OneCrumb = function (props) {
  var crumb = props.crumb;
  return React.createElement(React.Fragment, null, crumb && React.createElement("nav", {
    "aria-label": "breadcrumb",
    className: "uitk-breadcrumbs uitk-type-200"
  }, React.createElement("ol", {
    className: "uitk-breadcrumbs-list"
  }, crumb.map(function (item, index) {
    return React.createElement("li", {
      className: "uitk-breadcrumbs-item",
      key: "".concat(item, "-").concat(index)
    }, item);
  }))));
};
export default OneCrumb;