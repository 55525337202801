var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState } from 'react';
import { UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { localization } from 'src/utils/localization';
import { checkValue } from 'src/components/functions';
var GoGuidesFreetextComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchFreeText(goGuidesStore.pageUrlData);
  var _a = __read(useState(false), 2),
    isVisible = _a[0],
    setIsVisible = _a[1];
  var loc = new localization();
  var content = '';
  var headerKey = '';
  var dName = '';
  if (checkValue(contentData)) {
    var freeText = contentData.freeText,
      destinationName = contentData.destinationName;
    content = checkValue(freeText === null || freeText === void 0 ? void 0 : freeText.content) ? freeText.content : '';
    headerKey = checkValue(freeText === null || freeText === void 0 ? void 0 : freeText.headerKey) ? freeText.headerKey : '';
    dName = checkValue(destinationName) ? destinationName : '';
  }
  var headerText = loc.getLocalizedText(headerKey, dName);
  return React.createElement(React.Fragment, null, checkValue(content) && React.createElement("section", {
    className: "free-text sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement(UitkSubheading, {
    tag: "h2"
  }, headerText)), React.createElement("div", {
    className: "free-text-wrapper"
  }, React.createElement("div", {
    className: "expandable-wrapper"
  }, React.createElement(UitkExpandoPeek, {
    isVisible: isVisible,
    handleClick: function () {
      return setIsVisible(function (prevIsVisible) {
        return !prevIsVisible;
      });
    },
    lines: 10,
    whitespace: false,
    expandLabel: loc.getLocalizedText('show_more'),
    collapseLabel: loc.getLocalizedText('show_less')
  }, React.createElement("div", {
    className: "free-text-details",
    dangerouslySetInnerHTML: {
      __html: content
    }
  }))))))));
};
export default GoGuidesFreetextComponent;