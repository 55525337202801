export default {
  "current_deal": "Current deal",
  "lbg.section_properties_theme.pet_friendly": "Pet-friendly award winners in {0}",
  "address": "Address",
  "hub.homepage.h2": "So many places, so little time...",
  "search_hotels_near_this_landmark": "Search hotels<span class=\"extra-label\"> near this landmark</span>",
  "hub.category.h1.things_to_do": "Things to Do in {0}",
  "lbg.h2.vip_access": "VIP Access properties have been selected for their highly rated stays and superior experiences.",
  "lbg.theme.cleanliness": "Cleanliness",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Load more",
  "link_long_form_to_listiicle_multiple": "{0} - one of the highlights of {1} and {2} (Read all about {3} here)",
  "lbg.section_properties_theme.boutique": "Boutique award winners in {0}",
  "lbg.h2.luxury": "Discover luxury at its best. That’s why guests love staying at these properties.",
  "hub.category.h1.information": "Information about {0}",
  "families": "Families",
  "trending_now": "Trending now",
  "lbg.from": "From",
  "hub.category.h1.road_trips": "Best Road Trips in {0}",
  "lbg.h2.cleanliness": "These properties have a great overall guest-rating score and a top guest score for cleanliness.",
  "single": "Single",
  "couples": "Couples",
  "trust_module.price_guarantee.find_out_more": "find out more",
  "top_destinations_in": "Top destinations in {0}",
  "hub.category.h1.couples": "Couples Travel in {0}",
  "hub.category.h2.tours": "Best tours in {0}",
  "hub.category.h1.photos": "Photography Travel in {0}",
  "category.photos": "Photos",
  "hub.category.h1.budget": "Budget Travel in {0}",
  "hub.category.h2.ski": "Best ski destinations in {0}",
  "lbg.section_theme_world_winners.most_wanted": "Most wanted award winners",
  "lbg.theme.home_and_apartments": "Homes & Apartments",
  "hub.category.h1.luxury": "Luxury Travel in {0}",
  "hub.category.h2.adventure": "Best active travel in {0}",
  "hotels_in": "Hotels in {0}",
  "lbg.section_search_winners": "Search for more award winners",
  "see_more_hotels_in": "See more hotels in {0}",
  "hub.category.h1.beach": "Beaches in {0}",
  "open": "Open:",
  "enter_your_destination": "Enter your destination",
  "best_of": "Best of {0}",
  "top10_hotels_missing_properties": "Some properties may not show at this time. We apologise for the inconvenience.",
  "adventure": "Adventure",
  "lbg.h1.destination_theme.sustainable": "Sustainable Award Winners in {0}",
  "luxury": "Luxury",
  "expand_map_view": "Expand map view",
  "book_now": "Book now",
  "lbg.h1.destination_theme.ski": "Ski Award Winners in {0}",
  "hub.category.h1.food": "Food and Dining in {0}",
  "category.blogs": "Blog",
  "budget": "Budget",
  "popular_neighbourhoods_in": "Popular neighbourhoods in {0}",
  "hub.category.h1.unusual": "Hidden Gems and Unusual Places in {0}",
  "see_all_accommodation_in": "See accommodation in {0}",
  "hub.category.h1.adventure": "Active Travel in {0}",
  "category_highlight.see_all.shopping": "More about shopping",
  "lbg.section_most_popular_destination": "Most popular destinations",
  "lbg.section_properties_theme.luxury": "Luxury award winners in {0}",
  "unusual": "Unusual",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Make the most of your trip",
  "x_review": "{0,choice,0#|1#{0} review|2#{0} reviews|2<{0} reviews|4<{0} reviews|10#{0} reviews|10<{0} reviews}",
  "hub.category.h1.single": "Solo Travel in {0}",
  "hub.category.h2.custom": "Trending now",
  "lbg.section_theme_world_winners.lgbt": "LGBTQ+ award winners ",
  "lbg.section_award_winners_theme_other": "Other award winners themes in {0}",
  "lbg.supplier_collected_charges": "+ {0} charges due at the property",
  "in_destination": "in {0}",
  "lbg.section_properties_theme.vip_access": "VIP Access award winners in {0}",
  "link_long_form_to_listiicle_single": "{0} - one of the highlights of {1} (Read all about {2} here)",
  "lbg.section_theme_world_winners.boutique": "Boutique award winners",
  "lbg.section_properties_theme.spa_hotel": "Spa award winners in {0}",
  "lbg.section_theme_world_winners.all_inclusive": "All inclusive award winners",
  "hub.category.h2": "{0} travel guide",
  "lbg.properties.price_tooltip": "This is the lowest price per night on our site for the next 14 days.",
  "hub.category.h2.road_trips": "Best Road Trips in {0}",
  "hub.category.h1.ski": "Ski destinations in {0}",
  "food": "Food",
  "lbg.theme.pet_friendly": "Pet Friendly",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "Best Trips to Escape to Nature in {0}",
  "map": "Map",
  "category_highlight.see_all.information": "More information",
  "hotel_prices_in": "Hotel prices in {0}",
  "hub.category.h1.nightlife": "Nightlife in {0}",
  "trust_module.free_cancellation.most_hotels": "on most hotels*",
  "also_popular_in": "Also popular in {0}",
  "lbg.h1.destination_theme.business": "Business Award Winners in {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Pet-friendly award winners",
  "hub.category.h1.maps": "Maps of {0}",
  "lbg.section_properties_theme.most_wanted": "Most wanted award winners in {0}",
  "category.nightlife": "Nightlife",
  "search_hotels_in_this_area": "Search hotels<span class=\"extra-label\"> in this area</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 vote|2#2 votes|2<{1} votes|4<{1} votes|10#10 votes|10<{1} votes})",
  "hub.category.h2.business": "Best business travel in {0}",
  "category.things_to_do": "Things to do",
  "destination_travel_guide_by_category": "{0} travel guide by category",
  "hub.category.h1.families": "Family Travel in {0}",
  "lbg.section_theme_world_winners.ski": "Ski award winners",
  "you_may_also_like": "You may also like",
  "lbg.theme.sustainable": "Sustainable",
  "hub.category.h2.unusual": "Best hidden gems and unusual places in {0}",
  "hub.category.h2.football": "Support your football team and make the most of your trip!",
  "top_hotel": "Top Hotel",
  "lbg.h1.destination_theme.luxury": "Luxury Award Winners in {0}",
  "top10_hotels_empty_alert": "We are currently experiencing a technical issue. <span>We apologise for the inconvenience.</span>",
  "special_deal": "Special deal",
  "lbg.h2.sustainable": "It’s all about sustainability! Check out these properties that make a great stay.",
  "where_to_stay_in": "Where to stay <span class=\"next-line\">in {0}</span>",
  "start_planning_your_trip": "Start planning your trip",
  "good_for": "Good for:",
  "category.hotels": "Hotels",
  "x_accommodation_guide": "{0} Accommodation Guide",
  "lbg.theme.luxury": "Luxury",
  "hub.category.h2.sports": "Best sports and activities in {0}",
  "lbg.section_theme_world_winners.lgbtq": "LGBTQ award winners",
  "see_more_neighbourhoods": "See more neighbourhoods",
  "category_highlight.title.nightlife": "Where to go & what to do at night in {0}",
  "lbg.h1.destination_theme.lgbt": "LGBTQ+ award winners in {0}",
  "hub.category.h1.blogs": "{0} Travel Blog",
  "lbg.h2.business": "These properties have been highly rated by business travellers.",
  "hotels": "Hotels",
  "lbg.h2.pet_friendly": "Guests highly recommend these properties that welcome you and your pet.",
  "popular_landmarks_nearby": "Popular landmarks nearby",
  "popular_extractions_in": "Popular attractions in {0}",
  "lbg.h2.all_inclusive": "These top-rated properties take all the stress away.",
  "travel_guide_by_category_header": "Best {0} in {1}",
  "lbg.travel_between": "Travel between {0} - {1}",
  "keep_exploring": "Keep exploring",
  "what_you_need_to_know_before_you_go": "What You Need to Know Before You Go",
  "read_it": "Read it",
  "view_on_map": "View on map",
  "lbg.h1.destination_theme.lgbtq": "LGBTQ award winners in {0}",
  "lbg.h2.beach": "Guests love a beach stay, especially at these properties.",
  "lbg.h1.destination_theme.vip_access": "VIP Access award winners in {0}",
  "see_all_accommodation": "See all accommodation",
  "shoppers": "Shoppers",
  "whats_around": "what’s around {0}",
  "group": "Group",
  "hub.category.h2.couples": "Best couples travel in {0}",
  "hub.category.h1.shopping": "Shopping in {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Travel Community</span>",
  "hub.category.h2.group": "Best group travel in {0}",
  "listicle_legal_msg": "This article includes opinions of the Go Guides editorial team. Hotels.com compensates authors for their writings appearing on this site; such compensation may include travel and other costs.",
  "lbg.h1.destination_theme.cleanliness": "Cleanliness award winners in {0}",
  "menu.more": "More",
  "lbg.section_properties_theme.lgbtq": "LGBTQ award winners in {0}",
  "lbg.section_award_winners_destination": "Award winners by destination",
  "lbg.terms_conditions": "Terms & Conditions",
  "hub.category.h2.nightlife": "Best nightlife in {0}",
  "hub.neighbourhood.h2": "What to do in {0}",
  "lbg.section_award_winners_theme_destination": "Award winners by theme in {0}",
  "lbg.section_award_world_winners": "Worldwide award winners",
  "things_to_see_and_do_in_other_cities": "Things to see and do in other cities",
  "button.backToTop": "Back to top",
  "lbg.section_theme_world_winners.family": "Family award winners",
  "hotels_near": "Hotels near {0}",
  "category.activity__sports_": "Activities",
  "trust_module.get_reward_night": "Get a reward night",
  "hub.category.h1.business": "Business Travel in {0}",
  "category.food": "Food",
  "other_popular_destinations_in": "Other popular destinations in {0}",
  "browse_by_category": "Browse {0} by category",
  "see_more_attactions": "See more attractions",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "More information about {0}",
  "lbg.h1.destination_theme.most_wanted": "Most Wanted Award Winners in {0}",
  "see_all_properties_in": "See properties in {0}",
  "category.tours": "Tours",
  "hub.category.h1.sports": "Sports and Activities in {0}",
  "where_will_you_go_next": "Where will you go next?",
  "cities": "Cities",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "See guide",
  "lbg.section_properties_theme.ski": "Ski award winners in {0}",
  "related_stories": "Related stories",
  "hub.destination.h2": "What you need to know before you go",
  "show_less": "Show less",
  "hub.category.h1.football": "The Ultimate {0} Guide for True Football Fans",
  "hub.category.h2.food": "Best food and dining in {0}",
  "fallback_language_alert": "Sorry, this page hasn’t been translated in your language yet…",
  "category_highlight.title.information": "Useful information",
  "neighbourhood_guides_for_other_cities": "Neighbourhood guides for other cities",
  "lbg.h2.hubpage": "These fantastic properties have high guest rating scores and lots of reviews.",
  "travel_advisory.title": "Travel alert",
  "lbg.section_properties_theme.all_inclusive": "All inclusive award winners in {0}",
  "lbg.h2.boutique": "If you love boutique properties, guests highly recommend staying here.",
  "lbg.section_properties_theme.family": "Family award winners in {0}",
  "hub.category.h2.families": "Best family travel in {0}",
  "from": "from",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "LGBTQ+ award winners in {0}",
  "x_star": "{0,choice,0#|1#{0}-star|1.5#{0}-star|2#{0}-star|2.5#{0}-star|3#{0}-star|3.5#{0}-star|4#{0}-star|4.5#{0}-star|5#{0}-star}",
  "trust_module.free_cancellation": "Free cancellation",
  "lbg.section_properties_theme.home_and_apartments": "Homes & apartments award winners in {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Contributing Writer</span>",
  "things_to_see_and_do_in": "Things to see and do <span class=\"next-line\">in {0}</span>",
  "lbg.check_price": "Check price",
  "hub.category.h2.blogs": "by Experienced travellers",
  "lbg.h2.home_and_apartments": "Looking for a home away from home? Check out these highly rated properties.",
  "hub.category.h2.information": "Useful information about {0}",
  "search_hotels_in": "Search hotels<span class=\"extra-label\"> in {0}</span>",
  "hub.category.h2.nature": "Best Trips to Nature in {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Homes & Apartments Award Winners in {0}",
  "photo_by": "photo by {0}",
  "more_things_to_do": "More things to do",
  "lbg.section_properties_theme.beach": "Beach award winners in {0}",
  "read_less": "Read less",
  "lbg.h2.spa_hotel": "Wind down and relax at these top-rated properties that are well known for their spas.",
  "second_fold_related_stories.see_also": "See also",
  "hub.category.h2.history": "Best historical places in {0}",
  "lbg.section_properties_theme.cleanliness": "Cleanliness award winners in {0}",
  "lbg.h1.destination_theme.boutique": "Boutique Award Winners in {0}",
  "nightlife_b595ae4": "Nightlife",
  "lbg.h1.destination_theme.beach": "Beach Award Winners in {0}",
  "trust_module.free_cancellation.disclaimer": "Some hotels require you to cancel at least 24-48 hours before check-in. Details on site.",
  "lbg.h2.family": "These properties are highly rated by guests and perfect for families.",
  "lbg.h2.lgbtq": "These LGBTQ-friendly properties have been highly rated by guests.",
  "hub.category.h1.group": "Group Travel in {0}",
  "popular_stories": "Popular stories",
  "toc": "Content",
  "hub.category.h2.shopping": "Best shopping in {0}",
  "lbg.section_theme_world_winners.beach": "Beach award winners",
  "page_score": "This page score",
  "lbg.section_theme_world_winners.sustainable": "Sustainable award winners",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Our price guarantee",
  "lbg.h1.destination_theme.all_inclusive": "All Inclusive Award Winners in {0}",
  "top_destinations": "Top destinations",
  "hotel_prices_near": "Hotel prices near {0}",
  "lbg.score.cleanliness": "Cleanliness:",
  "x_stars": "{0,choice,0#|1#{0} star|1<{0} stars|4<{0} stars|10<{0} stars}",
  "lbg.theme.all_inclusive": "All Inclusive",
  "hub.category.h1.tours": "Tours and Day Trips in {0}",
  "lbg.section_award_winners_theme": "Award winners by theme",
  "guest_writer": "{0} <span>&#124;</span> <span>Guest Writer</span>",
  "start_planning_your_x_trip": "Start planning your {0} trip",
  "lbg.section_theme_world_winners.business": "Business award winners",
  "trending_now_in": "Trending now in {0}",
  "history": "History",
  "search_hotels": "Search hotels",
  "lbg.terms_conditions_details": "The criteria for any property selected as an award-winner in {0} was based on its high guest rating scores in {1} and a minimum of 50 guest reviews.",
  "see_nearby_properties": "See nearby properties",
  "hub.category.h1.history": "Historical Places in {0}",
  "location": "Location:",
  "lbg.section_theme_world_winners.luxury": "Luxury award winners",
  "category_highlight.see_all.nightlife": "More about nightlife",
  "also_popular": "Also popular",
  "hub.category.h2.things_to_do": "Best things to do in {0}",
  "lbg.theme.family": "Family",
  "lbg.h1.destination_theme.pet_friendly": "Pet-friendly Award Winners in {0}",
  "category_highlight.title.shopping": "Where to shop & what to buy in {0}",
  "price": "Price:",
  "hub.category.h2.beach": "Best beaches in {0}",
  "neighbourhoods_in": "neighbourhoods in {0}",
  "business": "Business",
  "photo": "Photo",
  "blog.see_all_stories": "See all stories",
  "category_highlight.see_all.food": "More about food & dining",
  "see_all_properties": "See all properties",
  "lbg.theme.beach": "Beach",
  "what_to_see_and_do_in": "What to see & do in {0}",
  "hub.category.h2.single": "Best solo travel in {0}",
  "attractions_in": "Attractions in {0}",
  "read_more": "Read more",
  "show_more": "Show more",
  "hotels_near_this_landmark": "Hotels near this landmark",
  "check_dates": "Check dates",
  "featured_stories_and_fun_stuff": "Featured stories & fun stuff",
  "phone": "Phone:",
  "lbg.section_search_header": "Search for award winners",
  "category_highlight.title.food": "Where & what to eat in {0}",
  "see_all_things_to_do": "See all things to do",
  "lbg.section_properties_theme.sustainable": "Sustainable award winners in {0}",
  "lbg.h1.hubpage": "{0} Loved by Guests Award Winners!",
  "lbg.section_theme_world_winners.spa_hotel": "Spa award winners",
  "category.maps": "Maps",
  "lbg.h1.destination_theme.family": "Family Award Winners in {0}",
  "lbg.section_theme_world_winners.cleanliness": "Cleanliness award winners",
  "lbg.section_theme_world_winners.vip_access": "VIP Access award winners",
  "trust_module.get_reward_night.night_you_stay": "for every 10 nights you stay",
  "modified": "modified",
  "lbg.section_properties": "Award winners in {0}",
  "trust_module.header": "Why book with Hotels.com?",
  "hub.category.h2.luxury": "Best luxury travel in {0}",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Spa Award Winners in {0}",
  "lbg.h2.most_wanted": "Guests highly recommend these properties for the perfect stay.",
  "top10_hotels_incomplete_alert": "We are currently experiencing high demand so some properties may not show at this time. <span>We apologise for the inconvenience.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Compulsive Traveller</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Homes & apartments award winners",
  "loved_by_guests_collection_in": "Loved by Guests Collection in {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Useful maps of {0}",
  "opening_hours": "Opening hours",
  "hub.category.h2.photos": "Best photography travel in {0}",
  "lbg.theme.most_wanted": "Most Wanted",
  "blog.stories_container": "Stories from contributors",
  "hub.category.h2.budget": "Best budget travel in {0}",
  "lbg.section_properties_theme.business": "Business award winners in {0}",
  "not_sure_where_to_stay": "Not sure where to stay?",
  "category.information": "Information"
};