export default {
  "current_deal": "Offerta attuale",
  "lbg.section_properties_theme.pet_friendly": "Vincitori del premio per strutture che ammettono animali - {0}",
  "address": "Indirizzo",
  "hub.homepage.h2": "Così tanti posti da vedere, così poco tempo...",
  "search_hotels_near_this_landmark": "Cerca hotel<span class=\"extra-label\"> vicino a questo luogo d’interesse</span>",
  "hub.category.h1.things_to_do": "{0} - Cose da fare",
  "lbg.h2.vip_access": "Le strutture VIP Access sono state selezionate per il loro servizio eccellente e le ottime recensioni.",
  "lbg.theme.cleanliness": "Pulizia",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Mostra altro",
  "link_long_form_to_listiicle_multiple": "{0}: in evidenza nelle guide \"{1}\" e \"{2}\". Scopri tutto quello che c’è da sapere su {3}!",
  "lbg.section_properties_theme.boutique": "Vincitori del premio per strutture boutique - {0}",
  "lbg.h2.luxury": "Scopri il meglio del lusso. Ecco perché queste sono le strutture più amate dagli ospiti.",
  "hub.category.h1.information": "{0}: informazioni",
  "families": "Famiglie",
  "trending_now": "Ultime tendenze",
  "lbg.from": "A partire da",
  "hub.category.h1.road_trips": "{0}: i migliori viaggi su strada",
  "lbg.h2.cleanliness": "Queste strutture hanno ricevuto ottimi giudizi da parte degli ospiti, in particolare per la loro pulizia.",
  "single": "Single",
  "couples": "Coppie",
  "trust_module.price_guarantee.find_out_more": "scopri di più",
  "top_destinations_in": "{0}: le destinazioni più gettonate",
  "hub.category.h1.couples": "{0}: viaggi per coppie",
  "hub.category.h2.tours": "{0}: i tour più interessanti",
  "hub.category.h1.photos": "{0}: mete dove scattare foto eccezionali",
  "category.photos": "Foto",
  "hub.category.h1.budget": "{0}: viaggi economici",
  "hub.category.h2.ski": "{0}: le migliori località sciistiche",
  "lbg.section_theme_world_winners.most_wanted": "Strutture che hanno vinto un premio “Più richiesti”",
  "lbg.theme.home_and_apartments": "Case e appartamenti",
  "hub.category.h1.luxury": "{0}: viaggi di lusso",
  "hub.category.h2.adventure": "{0}: i migliori viaggi avventurosi",
  "hotels_in": "Hotel a {0}",
  "lbg.section_search_winners": "Cerca altre strutture che hanno vinto dei premi",
  "see_more_hotels_in": "Visualizza altri hotel a {0}",
  "hub.category.h1.beach": "{0}: spiagge",
  "open": "Orario di apertura:",
  "enter_your_destination": "Inserisci la tua destinazione",
  "best_of": "{0}: il meglio del meglio",
  "top10_hotels_missing_properties": "È possibile che alcune strutture non vengano mostrate in questo momento. Ci scusiamo per il disagio.",
  "adventure": "Avventure",
  "lbg.h1.destination_theme.sustainable": "Vincitori del premio per strutture ecosostenibili - {0}",
  "luxury": "Lusso",
  "expand_map_view": "Ingrandisci la mappa",
  "book_now": "Prenota subito",
  "lbg.h1.destination_theme.ski": "Vincitori del premio per strutture per sciatori - {0}",
  "hub.category.h1.food": "{0} - Dove mangiare",
  "category.blogs": "Blog",
  "budget": "Budget",
  "popular_neighbourhoods_in": "Le zone più famose di {0}",
  "hub.category.h1.unusual": "{0}: mete insolite e meno note",
  "see_all_accommodation_in": "Tutte le sistemazioni in zona {0}",
  "hub.category.h1.adventure": "{0}: viaggi avventurosi",
  "category_highlight.see_all.shopping": "Altre informazioni sullo shopping",
  "lbg.section_most_popular_destination": "Le destinazioni più richieste",
  "lbg.section_properties_theme.luxury": "Vincitori del premio per strutture di lusso - {0}",
  "unusual": "Viaggi alternativi",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Approfitta al massimo del tuo viaggio",
  "x_review": "{0,choice,0#|1#{0} recensione|2#{0} recensioni|2<{0} recensioni|4<{0} recensioni|10#{0} recensioni|10<{0} recensioni}",
  "hub.category.h1.single": "{0}: per chi viaggia da solo",
  "hub.category.h2.custom": "Di tendenza",
  "lbg.section_theme_world_winners.lgbt": "Vincitori del premio per strutture LGBTQ+",
  "lbg.section_award_winners_theme_other": "Altre strutture che hanno vinto premi - {0}",
  "lbg.supplier_collected_charges": "+ {0} da pagare in struttura",
  "in_destination": " a {0}",
  "lbg.section_properties_theme.vip_access": "Vincitori del premio VIP Access - {0}",
  "link_long_form_to_listiicle_single": "{0}: in evidenza nella guida \"{1}\". Scopri tutto quello che c’è da sapere su {2}!",
  "lbg.section_theme_world_winners.boutique": "Strutture boutique premiate",
  "lbg.section_properties_theme.spa_hotel": "Vincitori del premio per strutture con spa - {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Strutture all inclusive premiate",
  "hub.category.h2": "{0}: guida turistica",
  "lbg.properties.price_tooltip": "Questo è il prezzo (a notte) più basso disponibile sul nostro sito per i prossimi 14 giorni.",
  "hub.category.h2.road_trips": "{0}: i migliori viaggi su strada",
  "hub.category.h1.ski": "{0}: vacanze sulla neve",
  "food": "Cibo",
  "lbg.theme.pet_friendly": "Animali ammessi",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "{0}: i migliori viaggi in mezzo alla natura",
  "map": "Mappa",
  "category_highlight.see_all.information": "Altre informazioni",
  "hotel_prices_in": "Prezzi degli hotel a {0}",
  "hub.category.h1.nightlife": "{0}: vita notturna",
  "trust_module.free_cancellation.most_hotels": "per gran parte degli hotel*",
  "also_popular_in": "{0}: altre destinazioni molto richieste",
  "lbg.h1.destination_theme.business": "Vincitori del premio per strutture con servizi business - {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Strutture che ammettono animali premiate",
  "hub.category.h1.maps": "{0}: mappe",
  "lbg.section_properties_theme.most_wanted": "Vincitori del premio “Più richiesti” - {0}",
  "category.nightlife": "Vita notturna",
  "search_hotels_in_this_area": "Cerca hotel<span class=\"extra-label\"> in questa zona</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 voti|2<{1} voti|4<{1} voti|10#10 voti|10<{1} voti})",
  "hub.category.h2.business": "{0}: i migliori viaggi di lavoro",
  "category.things_to_do": "Attività",
  "destination_travel_guide_by_category": "{0}: guide di viaggio per categoria",
  "hub.category.h1.families": "{0}: viaggi in famiglia",
  "lbg.section_theme_world_winners.ski": "Strutture per sciatori premiate",
  "you_may_also_like": "Possono interessarti anche",
  "lbg.theme.sustainable": "Ecosostenibile",
  "hub.category.h2.unusual": "{0}: le migliore mete insolite e meno note",
  "hub.category.h2.football": "Fai il tifo per la tua squadra del cuore e goditi il tuo viaggio!",
  "top_hotel": "Hotel più richiesto",
  "lbg.h1.destination_theme.luxury": "Vincitori del premio per strutture di lusso - {0}",
  "top10_hotels_empty_alert": "Si sta verificando un problema tecnico. <span>Ci scusiamo per il disagio.</span>",
  "special_deal": "Offerta speciale",
  "lbg.h2.sustainable": "La sostenibilità è fondamentale! Dai un’occhiata a queste strutture che offrono un servizio eccellente.",
  "where_to_stay_in": "Dove soggiornare a {0}",
  "start_planning_your_trip": "Inizia a programmare il tuo viaggio",
  "good_for": "Ideale per:",
  "category.hotels": "Hotel",
  "x_accommodation_guide": "Guida alle sistemazioni a {0}",
  "lbg.theme.luxury": "Lusso",
  "hub.category.h2.sports": "{0}: le migliori mete per chi ama attività e sport",
  "lbg.section_theme_world_winners.lgbtq": "Vincitori del premio LGBTQ",
  "see_more_neighbourhoods": "Mostra altre zone",
  "category_highlight.title.nightlife": "{0}: cosa fare e dove andare di sera",
  "lbg.h1.destination_theme.lgbt": "Vincitori del premio per strutture LGBTQ+ - {0}",
  "hub.category.h1.blogs": "Blog di viaggio su {0}",
  "lbg.h2.business": "Queste strutture hanno ricevuto ottime recensioni da chi viaggia per lavoro.",
  "hotels": "Hotel",
  "lbg.h2.pet_friendly": "Gli ospiti consigliano queste strutture per chi viaggia con un amico a quattro zampe.",
  "popular_landmarks_nearby": "I più famosi luoghi d’interesse nelle vicinanze",
  "popular_extractions_in": "Le attrazioni più gettonate di {0}",
  "lbg.h2.all_inclusive": "Viaggia senza stress prenotando le strutture più amate dagli ospiti.",
  "travel_guide_by_category_header": "{0} a {1}",
  "lbg.travel_between": "Viaggi nel periodo {0} - {1}",
  "keep_exploring": "Altre destinazioni",
  "what_you_need_to_know_before_you_go": "Cose da sapere prima di partire",
  "read_it": "Leggi",
  "view_on_map": "Visualizza sulla mappa",
  "lbg.h1.destination_theme.lgbtq": "Vincitori del premio LGBTQ - {0}",
  "lbg.h2.beach": "Tutti amano le vacanze al mare, soprattutto in queste strutture.",
  "lbg.h1.destination_theme.vip_access": "Vincitori del premio VIP Access - {0}",
  "see_all_accommodation": "Tutte le sistemazioni",
  "shoppers": "Acquisti",
  "whats_around": "Nei dintorni di {0}",
  "group": "Gruppi",
  "hub.category.h2.couples": "{0}: i migliori viaggi per coppie",
  "hub.category.h1.shopping": "{0}: shopping",
  "travel_community": "{0} <span>&#124;</span> <span>Comunità di viaggio</span>",
  "hub.category.h2.group": "{0}: i migliori viaggi per gruppi",
  "listicle_legal_msg": "Questo articolo include opinioni del team editoriale delle Go Guides. Hotels.com retribuisce gli autori per i loro scritti pubblicati su questo sito; i compensi possono includere spese di viaggio o di altro tipo.",
  "lbg.h1.destination_theme.cleanliness": "Vincitori del premio per la pulizia - {0}",
  "menu.more": "Altro",
  "lbg.section_properties_theme.lgbtq": "Vincitori del premio LGBTQ - {0}",
  "lbg.section_award_winners_destination": "Strutture che hanno ricevuto premi (per destinazione)",
  "lbg.terms_conditions": "Termini e condizioni",
  "hub.category.h2.nightlife": "{0}: i ritrovi della vita notturna",
  "hub.neighbourhood.h2": "Cose da fare a {0}",
  "lbg.section_award_winners_theme_destination": "Strutture che hanno ricevuto premi (per tipologia) - {0}",
  "lbg.section_award_world_winners": "Strutture che hanno ricevuto premi, in tutto il mondo",
  "things_to_see_and_do_in_other_cities": "Cose da fare e da vedere in altre città",
  "button.backToTop": "Torna all’inizio",
  "lbg.section_theme_world_winners.family": "Strutture per famiglie premiate",
  "hotels_near": "Hotel vicino a questa attrazione: {0}",
  "category.activity__sports_": "Attività",
  "trust_module.get_reward_night": "Ricevi una notte bonus",
  "hub.category.h1.business": "{0}: viaggi di lavoro",
  "category.food": "Cibo",
  "other_popular_destinations_in": "Altre destinazioni di tendenza a {0}",
  "browse_by_category": "{0}: cerca nelle categorie",
  "see_more_attactions": "Mostra altre attrazioni",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "{0}: maggiori informazioni",
  "lbg.h1.destination_theme.most_wanted": "Vincitori del premio “Più richiesti” - {0}",
  "see_all_properties_in": "Mostra le strutture in zona {0}",
  "category.tours": "Tour",
  "hub.category.h1.sports": "{0}: attività e sport",
  "where_will_you_go_next": "Quale sarà la tua prossima meta?",
  "cities": "Città",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Mostra guida",
  "lbg.section_properties_theme.ski": "Vincitori del premio per strutture per sciatori - {0}",
  "related_stories": "Articoli correlati",
  "hub.destination.h2": "Cose da sapere prima di partire",
  "show_less": "Mostra meno",
  "hub.category.h1.football": "{0}: la guida completa per veri appassionati di calcio",
  "hub.category.h2.food": "{0}: i posti migliori per mangiare",
  "fallback_language_alert": "Spiacenti, questa pagina non è ancora stata tradotta nella tua lingua...",
  "category_highlight.title.information": "Informazioni utili",
  "neighbourhood_guides_for_other_cities": "Guide dei quartieri di altre città",
  "lbg.h2.hubpage": "Queste strutture fantastiche hanno ottime valutazioni degli ospiti e tantissime recensioni.",
  "travel_advisory.title": "Avviso di viaggio",
  "lbg.section_properties_theme.all_inclusive": "Vincitori del premio per strutture all inclusive - {0}",
  "lbg.h2.boutique": "Se ti piacciono le strutture boutique, i nostri ospiti consigliano queste.",
  "lbg.section_properties_theme.family": "Vincitori del premio per strutture per famiglie - {0}",
  "hub.category.h2.families": "{0}: i migliori viaggi in famiglia",
  "from": "a partire da",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Vincitori del premio per strutture LGBTQ+ - {0}",
  "x_star": "{0,choice,0#|1#{0} stella|1.5#{0} stelle|2#{0} stelle|2.5#{0} stelle|3#{0} stelle|3.5#{0} stelle|4#{0} stelle|4.5#{0} stelle|5#{0} stelle}",
  "trust_module.free_cancellation": "Cancellazione gratuita",
  "lbg.section_properties_theme.home_and_apartments": "Vincitori del premio per case e appartamenti - {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Collaboratore alla scrittura</span>",
  "things_to_see_and_do_in": "Cose da fare e da vedere <span class=\"next-line\">a {0}</span>",
  "lbg.check_price": "Verifica prezzo",
  "hub.category.h2.blogs": "scritto da veri esperti",
  "lbg.h2.home_and_apartments": "Vuoi sentirti come a casa anche mentre viaggi? Dai un’occhiata a queste strutture con ottime valutazioni.",
  "hub.category.h2.information": "{0}: informazioni utili",
  "search_hotels_in": "Cerca hotel<span class=\"extra-label\"> a {0}</span>",
  "hub.category.h2.nature": "{0}: i migliori viaggi in mezzo alla natura",
  "lbg.h1.destination_theme.home_and_apartments": "Vincitori del premio per case e appartamenti - {0}",
  "photo_by": "Foto di {0}",
  "more_things_to_do": "Altre cose da fare",
  "lbg.section_properties_theme.beach": "Vincitori del premio per strutture sulla spiaggia - {0}",
  "read_less": "Meno informazioni",
  "lbg.h2.spa_hotel": "Riposati e rilassati in queste strutture con ottime valutazioni, che sono famose per le loro spa.",
  "second_fold_related_stories.see_also": "Potrebbero interessarti anche queste attività",
  "hub.category.h2.history": "{0}: le migliori destinazioni storiche",
  "lbg.section_properties_theme.cleanliness": "Vincitori del premio per la pulizia - {0}",
  "lbg.h1.destination_theme.boutique": "Vincitori del premio per strutture boutique - {0}",
  "nightlife_b595ae4": "Vita notturna",
  "lbg.h1.destination_theme.beach": "Vincitori del premio per strutture sulla spiaggia - {0}",
  "trust_module.free_cancellation.disclaimer": "Alcuni hotel richiedono la cancellazione almeno 24-48 ore prima del check-in. Visita il sito per maggiori informazioni.",
  "lbg.h2.family": "Queste strutture hanno ricevuto ottime recensioni e sono ideali per le famiglie.",
  "lbg.h2.lgbtq": "Queste strutture LGBTQ-friendly hanno ricevuto ottime valutazioni degli ospiti.",
  "hub.category.h1.group": "{0}: viaggi per gruppi",
  "popular_stories": "Storie popolari",
  "toc": "Indice",
  "hub.category.h2.shopping": "{0}: le mete per lo shopping",
  "lbg.section_theme_world_winners.beach": "Strutture sulla spiaggia premiate",
  "page_score": "Punteggio di questa pagina",
  "lbg.section_theme_world_winners.sustainable": "Strutture ecosostenibili premiate",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Garanzia del prezzo",
  "lbg.h1.destination_theme.all_inclusive": "Vincitori del premio per strutture all inclusive - {0}",
  "top_destinations": "Destinazioni più visitate",
  "hotel_prices_near": "Prezzi degli hotel vicino a {0}",
  "lbg.score.cleanliness": "Pulizia:",
  "x_stars": "{0,choice,0#|1#{0} stella|1<{0} stelle|4<{0} stelle|10<{0} stelle}",
  "lbg.theme.all_inclusive": "All Inclusive",
  "hub.category.h1.tours": "{0} - Tour e gite in giornata",
  "lbg.section_award_winners_theme": "Strutture premiate per categoria",
  "guest_writer": "{0} <span>&#124;</span> <span>Autore ospite</span>",
  "start_planning_your_x_trip": "Inizia a programmare il tuo viaggio a {0}",
  "lbg.section_theme_world_winners.business": "Strutture con servizi business premiate",
  "trending_now_in": "Il meglio di {0}",
  "history": "Storia",
  "search_hotels": "Cerca hotel",
  "lbg.terms_conditions_details": "I criteri per selezionare le strutture premiate nel {0} si sono basati sui giudizi migliori da parte degli ospiti nel {1} e un minimo di 50 recensioni ricevute.",
  "see_nearby_properties": "Visualizza strutture nelle vicinanze",
  "hub.category.h1.history": "{0}: destinazioni storiche",
  "location": "Posizione:",
  "lbg.section_theme_world_winners.luxury": "Strutture di lusso premiate",
  "category_highlight.see_all.nightlife": "Altre informazioni sulla vita notturna",
  "also_popular": "Altre destinazioni gettonate",
  "hub.category.h2.things_to_do": "{0}: luoghi d’interesse popolari",
  "lbg.theme.family": "In famiglia",
  "lbg.h1.destination_theme.pet_friendly": "Vincitori del premio per strutture che ammettono animali - {0}",
  "category_highlight.title.shopping": "{0}: dove fare acquisti e cosa comprare",
  "price": "Prezzo:",
  "hub.category.h2.beach": "{0}: le migliori spiagge",
  "neighbourhoods_in": "quartieri di {0}",
  "business": "Business",
  "photo": "Foto",
  "blog.see_all_stories": "Scopri tutte le storie",
  "category_highlight.see_all.food": "Altre informazioni sulla cucina",
  "see_all_properties": "Mostra tutte le strutture",
  "lbg.theme.beach": "Spiaggia",
  "what_to_see_and_do_in": "{0}: cosa fare e cosa vedere",
  "hub.category.h2.single": "{0}: le migliori mete per chi viaggia da solo",
  "attractions_in": "Attrazioni a {0}",
  "read_more": "Continua a leggere",
  "show_more": "Mostra altro",
  "hotels_near_this_landmark": "Hotel vicino a questo luogo d’interesse",
  "check_dates": "Verifica date",
  "featured_stories_and_fun_stuff": "Consigli e storie divertenti",
  "phone": "Telefono:",
  "lbg.section_search_header": "Cerca strutture che hanno vinto dei premi",
  "category_highlight.title.food": "{0}: cosa mangiare e dove",
  "see_all_things_to_do": "Scopri tutte le cose da fare",
  "lbg.section_properties_theme.sustainable": "Vincitori del premio per strutture ecosostenibili - {0}",
  "lbg.h1.hubpage": "Vincitori del premio “I preferiti degli ospiti {0}“",
  "lbg.section_theme_world_winners.spa_hotel": "Strutture con spa premiate",
  "category.maps": "Mappa",
  "lbg.h1.destination_theme.family": "Vincitori del premio per strutture per famiglie - {0}",
  "lbg.section_theme_world_winners.cleanliness": "Vincitori del premio per la pulizia",
  "lbg.section_theme_world_winners.vip_access": "Vincitori del premio VIP Access",
  "trust_module.get_reward_night.night_you_stay": "ogni 10 notti di soggiorno",
  "modified": "modificata",
  "lbg.section_properties": "Strutture che hanno ricevuto premi - {0}",
  "trust_module.header": "Perché prenotare con Hotels.com?",
  "hub.category.h2.luxury": "{0}: i migliori viaggi di lusso",
  "lbg.theme.ski": "Sci",
  "lbg.h1.destination_theme.spa_hotel": "Vincitori del premio per strutture con spa - {0}",
  "lbg.h2.most_wanted": "Gli ospiti consigliano queste strutture per trascorrere un soggiorno perfetto.",
  "top10_hotels_incomplete_alert": "A causa di una domanda elevata, è possibile che alcune strutture non vengano mostrate in questo momento. <span>Ci scusiamo per il disagio.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Viaggiatore seriale</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Case e appartamenti premiati",
  "loved_by_guests_collection_in": "Tra i preferiti degli ospiti a {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "{0}: mappe utili",
  "opening_hours": "Orari di apertura",
  "hub.category.h2.photos": "{0}: le migliori mete per scattare foto eccezionali",
  "lbg.theme.most_wanted": "“Più richiesti”",
  "blog.stories_container": "Storie dal blog",
  "hub.category.h2.budget": "{0}: i migliori viaggi economici",
  "lbg.section_properties_theme.business": "Vincitori del premio per strutture con servizi business - {0}",
  "not_sure_where_to_stay": "Non sai dove alloggiare?",
  "category.information": "Informazioni"
};