var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { getLocalizedText } from 'src/utils/helper';
var GoGuidesTableOfContent = function (props) {
  var _a = __read(useState(null), 2),
    data = _a[0],
    setData = _a[1];
  var goGuidesStore = useStores().goGuidesStore;
  var track = useEgClickstream();
  var complete = false;
  var checkBoxRef = useRef(null);
  var tocArea = useRef(null);
  var tagElement = props.tagElement,
    parentClassName = props.parentClassName,
    needWrapper = props.needWrapper;
  var handleClickOutSide = function (event) {
    var isClickInside = tocArea.current.contains(event.target);
    if (!isClickInside) {
      // The click was OUTSIDE the specifiedElement, do something
      checkBoxRef.current.checked = false;
    }
  };
  var toggleClickOutSideEvent = function (e) {
    if (e.target.checked) {
      document.addEventListener('click', handleClickOutSide);
    } else {
      document.removeEventListener('click', handleClickOutSide);
    }
  };
  var generateUniqueId = function (text) {
    // Generate a valid ID. Spaces are replaced with underscores.
    // Check if the ID already exists in the document. If so, append "_1", "_2", etc.
    // until find an unused ID.
    if (text.length === 0) {
      text = '?';
    }
    var baseId = text.replace(/\s+/g, '_');
    var suffix = '',
      count = 1;
    while (document.getElementById(baseId + suffix) !== null) {
      suffix = '_' + count++;
    }
    return baseId + suffix;
  };
  var processMenu = function () {
    var root = document.getElementById('toc');
    var numberOfLink = 10;
    var numberOfSkip = 0;
    var menu = [];
    // Set up some automatic IDs if do not already have them
    var cont1 = document.getElementsByClassName(parentClassName);
    if (cont1.length > 0) {
      var cont = cont1[0];
      var elementsList = cont.getElementsByTagName(tagElement);
      var count = 0;
      for (var i = 0; i < elementsList.length; i++) {
        var elm = elementsList[i];
        if (elm.getAttribute('id') === null) {
          elm.setAttribute('id', generateUniqueId(elm.textContent.trim()));
        }
        if (count >= numberOfLink + numberOfSkip) {
          break;
        }
        if (elm.textContent.trim() === '') {
          numberOfSkip++;
          continue;
        }
        menu.push({
          id: elm.getAttribute('id'),
          text: elm.textContent
        });
        count++;
      }
      setData(menu);
      // register click event for the hidden checkbox
      // const checkbox = document.getElementById('menu-toc') as HTMLInputElement | null;
      // checkbox.checked = false;
    }
    complete = true;
  };
  var handleClick = function (e, id) {
    e.preventDefault();
    e.stopPropagation();
    var my_element = document.getElementById(id);
    my_element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
    checkBoxRef.current.checked = false;
  };
  var readyStateChange = function () {
    if (document.readyState == 'complete') {
      processMenu();
    }
  };
  useEffect(function () {
    if (document.readyState == 'complete') {
      processMenu();
    }
    document.addEventListener('readystatechange', readyStateChange);
    return function () {
      document.removeEventListener('readystatechange', readyStateChange);
    };
  }, [complete]);
  var trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'content_menu.selected',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  var lebelClick = function () {
    track(trackObject);
  };
  var buildTOC = function () {
    if (data) {
      return React.createElement("div", {
        className: "toc-wrapper"
      }, React.createElement("div", {
        className: "toc-inner",
        ref: tocArea
      }, React.createElement("label", {
        htmlFor: "menu-toc",
        onClick: lebelClick
      }, getLocalizedText('toc'), " ", React.createElement(UitkIcon, {
        name: "arrow_downward_iOS",
        size: UitkIconSize.EXTRA_SMALL
      })), React.createElement("span", {
        className: "arrow-toc"
      }), React.createElement("input", {
        type: "checkbox",
        id: "menu-toc",
        ref: checkBoxRef,
        onChange: toggleClickOutSideEvent
      }), React.createElement("ul", {
        id: "toc",
        className: "toc"
      }, data.map(function (item) {
        return React.createElement("li", {
          key: item.id
        }, React.createElement("a", {
          onClick: function (e) {
            return handleClick(e, item.id);
          },
          href: "#".concat(item.id),
          key: item.id
        }, item.text));
      }))));
    } else {
      return null;
    }
  };
  var RenderTOC = function () {
    var toc = buildTOC();
    if (needWrapper) {
      return React.createElement("div", null, React.createElement("div", {
        className: "container-wrap row"
      }, React.createElement("div", {
        className: "row-inner col-12"
      }, toc)));
    } else {
      return toc;
    }
  };
  return React.createElement(React.Fragment, null, data && React.createElement(RenderTOC, null));
};
export default GoGuidesTableOfContent;