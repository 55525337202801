var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { useWindowSize } from '../components/common/helper';
import { useEffect, useState } from 'react';
import { AVAILABLE_LANGUAGES } from './localization-data';
import { useStores } from './useStore';
export var viewportCheck = function () {
  var _a = __read(useWindowSize(), 1),
    width = _a[0];
  var screenSize = {
    //s: 599,
    m: 749,
    l: 959,
    xl: 1259
  };
  var _b = __read(useState(''), 2),
    windowSize = _b[0],
    setWindowSize = _b[1];
  useEffect(function () {
    if (width > screenSize.xl) {
      setWindowSize('large-desktop');
    } else if (width > screenSize.l) {
      setWindowSize('desktop');
    } else if (width > screenSize.m) {
      setWindowSize('tablet');
    } else if (width <= screenSize.m) {
      setWindowSize('mobile');
    }
  }, [width]);
  return windowSize;
};
export var checkPageType = function (page) {
  if (page === 'destinationhub' || page === 'categoryhub' || page === 'home') {
    return 'hubpage';
  } else if (page === 'listicle' || page === 'longform') {
    return 'articlepage';
  }
};
export var pageLayoutController = function (page) {
  var pageLayout = {
    header: '',
    body: '' // one-col, two-col
  };
  switch (page) {
    case 'home':
    case 'destinationhub':
      pageLayout = {
        header: 'align-center',
        body: 'one-col'
      };
      break;
    case 'categoryhub':
      pageLayout = {
        header: 'align-left',
        body: 'one-col'
      };
      break;
    case 'listicle':
    case 'longform':
      pageLayout = {
        header: 'align-left',
        body: 'two-col'
      };
      break;
    default:
      break;
  }
  return pageLayout;
};
export var getLocalizedText = function (msgKey) {
  var _a;
  var args = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }
  var context = useStores().context;
  var langLocalization = AVAILABLE_LANGUAGES[(_a = context === null || context === void 0 ? void 0 : context.locale) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()] || AVAILABLE_LANGUAGES['en_us'];
  var msg = langLocalization[msgKey];
  if (msg && args && args.length) {
    args.map(function (arg, idx) {
      msg = msg.replace("{".concat(idx, "}"), arg);
    });
  }
  return msg;
};