export default {
  "current_deal": "Aktuelles Angebot",
  "lbg.section_properties_theme.pet_friendly": "Gewinner in der Kategorie „Haustierfreundlich“ – {0}",
  "address": "Adresse",
  "hub.homepage.h2": "So viele Reiseziele und so wenig Zeit...",
  "search_hotels_near_this_landmark": "Hotels<span class=\"extra-label\"> nahe dieser Sehenswürdigkeit suchen</span>",
  "hub.category.h1.things_to_do": "{0} – Sehenswürdigkeiten und Aktivitäten",
  "lbg.h2.vip_access": "VIP Access-Unterkünfte wurden aufgrund ihrer exzellenten Bewertungen und ihres außerordentlichen Service ausgewählt.",
  "lbg.theme.cleanliness": "Sauberkeit",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Mehr laden",
  "link_long_form_to_listiicle_multiple": "{0}: eines der Highlights der Kategorien „{1}“ und „{2}“. Hier erfahren Sie alles, was Sie über {3} wissen müssen.",
  "lbg.section_properties_theme.boutique": "Gewinner in der Kategorie „Boutique“ – {0}",
  "lbg.h2.luxury": "Luxus vom Feinsten. Deshalb lieben Gäste diese Unterkünfte.",
  "hub.category.h1.information": "{0} – allgemeine Informationen",
  "families": "Familien",
  "trending_now": "Derzeit beliebt",
  "lbg.from": "Ab",
  "hub.category.h1.road_trips": "{0} – die besten Roadtrips",
  "lbg.h2.cleanliness": "Diese Unterkünfte haben von Gästen eine tolle Gesamtbewertung und eine Spitzenbewertungen für Sauberkeit erhalten.",
  "single": "Singles",
  "couples": "Paare",
  "trust_module.price_guarantee.find_out_more": "Mehr erfahren",
  "top_destinations_in": "{0} – Top-Reiseziele",
  "hub.category.h1.couples": "{0}: Urlaub für Paare",
  "hub.category.h2.tours": "{0} – die besten Touren",
  "hub.category.h1.photos": "{0} für Fotos mit Wow-Effekt",
  "category.photos": "Fotos",
  "hub.category.h1.budget": "{0}: günstig reisen",
  "hub.category.h2.ski": "{0}: die besten Skigebiete",
  "lbg.section_theme_world_winners.most_wanted": "Gewinner in der Kategorie „Gästefavorit“",
  "lbg.theme.home_and_apartments": "Häuser & Apartments",
  "hub.category.h1.luxury": "{0} für Luxusreisen",
  "hub.category.h2.adventure": "{0} – Wenn Sie auf Reisen aktiv sein möchten",
  "hotels_in": "Hotels in {0}",
  "lbg.section_search_winners": "Suchen Sie nach weiteren Gewinnern",
  "see_more_hotels_in": "Mehr Hotels in {0} anzeigen",
  "hub.category.h1.beach": "{0} – Strände",
  "open": "Öffnungszeiten:",
  "enter_your_destination": "Reiseziel eingeben",
  "best_of": "Höhepunkte von {0}",
  "top10_hotels_missing_properties": "Einige Unterkünfte werden derzeit möglicherweise nicht angezeigt. Wir bitten um Ihr Verständnis.",
  "adventure": "Abenteuer",
  "lbg.h1.destination_theme.sustainable": "Gewinner in der Kategorie „Nachhaltig“ – {0}",
  "luxury": "Luxus",
  "expand_map_view": "Kartenansicht erweitern",
  "book_now": "Jetzt buchen",
  "lbg.h1.destination_theme.ski": "Gewinner in der Kategorie „Ski“ – {0}",
  "hub.category.h1.food": "{0} – Kulinarisches",
  "category.blogs": "Blog",
  "budget": "Budget",
  "popular_neighbourhoods_in": "Beliebte Viertel in {0}",
  "hub.category.h1.unusual": "{0}: Geheimtipps und außergewöhnliche Orte",
  "see_all_accommodation_in": "{0} – Unterkünfte anzeigen",
  "hub.category.h1.adventure": "{0}: reisen und aktiv sein",
  "category_highlight.see_all.shopping": "Mehr Infos zum Shopping",
  "lbg.section_most_popular_destination": "Die beliebtesten Reiseziele",
  "lbg.section_properties_theme.luxury": "Gewinner in der Kategorie „Luxus“ – {0}",
  "unusual": "Außergewöhnliche Erfahrungen",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Kosten Sie Ihre Reise voll aus",
  "x_review": "{0,choice,0#|1#{0} Bewertung|2#{0} Bewertungen|2<{0} Bewertungen|4<{0} Bewertungen|10#{0} Bewertungen|10<{0} Bewertungen}",
  "hub.category.h1.single": "{0} für Alleinreisende",
  "hub.category.h2.custom": "Derzeit beliebt",
  "lbg.section_theme_world_winners.lgbt": "Gewinner in der Kategorie „LGBTQ+“",
  "lbg.section_award_winners_theme_other": "Andere Gewinnerthemen – {0}",
  "lbg.supplier_collected_charges": "+ {0} (in der Unterkunft fällig)",
  "in_destination": " in {0}",
  "lbg.section_properties_theme.vip_access": "Gewinner in der Kategorie „VIP Access“ – {0}",
  "link_long_form_to_listiicle_single": "{0}: eines der Highlights der Kategorie „{1}“. Hier erfahren Sie alles, was Sie über {2} wissen müssen.",
  "lbg.section_theme_world_winners.boutique": "Gewinner in der Kategorie „Boutique“",
  "lbg.section_properties_theme.spa_hotel": "Gewinner in der Kategorie „Wellness“ – {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Gewinner in der Kategorie „All-inclusive“ ",
  "hub.category.h2": "Reiseführer für {0}",
  "lbg.properties.price_tooltip": "Dies ist der niedrigste Preis pro Nacht auf unserer Website für die nächsten 14 Tage.",
  "hub.category.h2.road_trips": "{0} – die besten Roadtrips",
  "hub.category.h1.ski": "Skigebiete – {0}",
  "food": "Essen",
  "lbg.theme.pet_friendly": "Haustiere erlaubt",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "{0} – die besten Reisen für Naturliebhaber",
  "map": "Karte",
  "category_highlight.see_all.information": "Mehr Informationen",
  "hotel_prices_in": "Hotelpreise in {0}",
  "hub.category.h1.nightlife": "{0} – Nachtleben",
  "trust_module.free_cancellation.most_hotels": "bei den meisten Hotels*",
  "also_popular_in": "{0} – andere beliebte Ziele",
  "lbg.h1.destination_theme.business": "Gewinner in der Kategorie „Business“ – {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Gewinner in der Kategorie „Haustierfreundlich“",
  "hub.category.h1.maps": "Karten von {0}",
  "lbg.section_properties_theme.most_wanted": "Gewinner in der Kategorie „Gästefavorit“ – {0}",
  "category.nightlife": "Nachtleben",
  "search_hotels_in_this_area": "Hotels<span class=\"extra-label\"> in dieser Gegend suchen</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 Stimme|2#2 Stimmen|2<{1} Stimmen|4<{1} Stimmen|10#10 Stimmen|10<{1} Stimmen})",
  "hub.category.h2.business": "{0} – Besonders beliebte Optionen für Geschäftsreisen",
  "category.things_to_do": "Aktivitäten",
  "destination_travel_guide_by_category": "Reiseführer für {0} nach Kategorie",
  "hub.category.h1.families": "{0} – Urlaub mit der Familie",
  "lbg.section_theme_world_winners.ski": "Gewinner in der Kategorie „Ski“",
  "you_may_also_like": "Was Ihnen auch gefallen könnte",
  "lbg.theme.sustainable": "Nachhaltig",
  "hub.category.h2.unusual": "{0} – Besondere Geheimtipps und beliebte außergewöhnliche Orte",
  "hub.category.h2.football": "Feuern Sie Ihre Lieblingsmannschaft an und erleben Sie eine unvergessliche Reise! ",
  "top_hotel": "Bestseller",
  "lbg.h1.destination_theme.luxury": "Gewinner in der Kategorie „Luxus“ – {0}",
  "top10_hotels_empty_alert": "Leider liegt derzeit ein technischer Fehler vor. <span>Wir bitten um Ihr Verständnis.</span>",
  "special_deal": "Spezialpreis",
  "lbg.h2.sustainable": "In diesen Unterkünften dreht sich alles um Nachhaltigkeit! Schauen Sie sie sich genauer an und freuen Sie sich auf einen fantastischen Aufenthalt.",
  "where_to_stay_in": "Unterkünfte in {0}",
  "start_planning_your_trip": "Plant eure Reise",
  "good_for": "Toll für:",
  "category.hotels": "Hotels",
  "x_accommodation_guide": "Unterkunftsratgeber für {0}",
  "lbg.theme.luxury": "Luxus",
  "hub.category.h2.sports": "{0} – Besonders beliebte Optionen für Reisen mit Aktivitäten und Sport",
  "lbg.section_theme_world_winners.lgbtq": "Gewinner in der Kategorie „LGBTQ“",
  "see_more_neighbourhoods": "Mehr zur Umgebung anzeigen",
  "category_highlight.title.nightlife": "{0}: Abends ausgehen – hier die Tipps",
  "lbg.h1.destination_theme.lgbt": "Gewinner in der Kategorie „LGBTQ+“ – {0}",
  "hub.category.h1.blogs": "Reiseblog – {0}",
  "lbg.h2.business": "Diese Unterkünfte haben von Geschäftsreisenden Top-Bewertungen erhalten.",
  "hotels": "Hotels",
  "lbg.h2.pet_friendly": "Gäste empfehlen diese Unterkünfte für einen Urlaub mit Haustier.",
  "popular_landmarks_nearby": "Beliebte Sehenswürdigkeiten in der Nähe",
  "popular_extractions_in": "Beliebte Attraktionen in {0}",
  "lbg.h2.all_inclusive": "Diese Unterkünfte mit Top-Bewertung laden zum Entspannen ein.",
  "travel_guide_by_category_header": "{1}: {0}",
  "lbg.travel_between": "Reisezeitraum: {0} – {1}",
  "keep_exploring": "Weiter erkunden",
  "what_you_need_to_know_before_you_go": "Wissenswertes vor der Anreise",
  "read_it": "Weiterlesen",
  "view_on_map": "Auf der Karte ansehen",
  "lbg.h1.destination_theme.lgbtq": "Gewinner in der Kategorie „LGBTQ“ – {0}",
  "lbg.h2.beach": "Wer mag keinen Strandurlaub? In diesen Unterkünften ist er besonders schön!",
  "lbg.h1.destination_theme.vip_access": "Gewinner in der Kategorie „VIP Access“ – {0}",
  "see_all_accommodation": "Alle Unterkünfte anzeigen",
  "shoppers": "Shopping",
  "whats_around": "Umgebung von {0}",
  "group": "Gruppen",
  "hub.category.h2.couples": "{0} – Für einen ganz besonderen Pärchenurlaub",
  "hub.category.h1.shopping": "{0} – Shopping",
  "travel_community": "{0} <span>&#124;</span> <span>Reisecommunity</span>",
  "hub.category.h2.group": "{0} – Für besonders gelungene Gruppenreisen",
  "listicle_legal_msg": "Dieser Artikel enthält Meinungen und Ansichten des Redaktionsteams von Go Guides. Die Autoren der Artikel, die auf dieser Website veröffentlicht werden, erhalten von Hotels.com eine Vergütung, die Reisekosten und andere Kosten enthalten kann.",
  "lbg.h1.destination_theme.cleanliness": "Gewinner in der Kategorie „Sauberkeit“ – {0}",
  "menu.more": "Mehr",
  "lbg.section_properties_theme.lgbtq": "Gewinner in der Kategorie „LGBTQ“ – {0}",
  "lbg.section_award_winners_destination": "Gewinner nach Reiseziel",
  "lbg.terms_conditions": "Allgemeine Geschäftsbedingungen",
  "hub.category.h2.nightlife": "{0} – das beste Nachtleben",
  "hub.neighbourhood.h2": "{0} entdecken",
  "lbg.section_award_winners_theme_destination": "Gewinner nach Thema – {0}",
  "lbg.section_award_world_winners": "Gewinner auf der ganzen Welt",
  "things_to_see_and_do_in_other_cities": "Aktivitäten in anderen Städten anzeigen",
  "button.backToTop": "Zum Seitenanfang",
  "lbg.section_theme_world_winners.family": "Gewinner in der Kategorie „Familie“",
  "hotels_near": "Hotels nahe {0}",
  "category.activity__sports_": "Aktivitäten",
  "trust_module.get_reward_night": "Eine Prämiennacht",
  "hub.category.h1.business": "{0} für Geschäftsreisen",
  "category.food": "Essen",
  "other_popular_destinations_in": "Andere beliebte Reiseziele in {0}",
  "browse_by_category": "{0} nach Kategorie durchstöbern",
  "see_more_attactions": "Mehr Attraktionen anzeigen",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Mehr Informationen über {0}",
  "lbg.h1.destination_theme.most_wanted": "Gewinner in der Kategorie „Gästefavorit“ – {0}",
  "see_all_properties_in": "Alle Unterkünfte in {0} anzeigen",
  "category.tours": "Touren",
  "hub.category.h1.sports": "{0} für Reisen mit Aktivitäten und Sport",
  "where_will_you_go_next": "Wohin geht es als Nächstes?",
  "cities": "Städte",
  "lbg.theme.spa_hotel": "Wellness",
  "see_guide": "Reiseführer anzeigen",
  "lbg.section_properties_theme.ski": "Gewinner in der Kategorie „Ski“ – {0}",
  "related_stories": "Verwandte Themen",
  "hub.destination.h2": "Wissenswertes vor der Anreise",
  "show_less": "Weniger anzeigen",
  "hub.category.h1.football": "{0}: der ultimative Reiseführer für echte Fußballfans",
  "hub.category.h2.food": "{0} – das Beste der Gastronomie",
  "fallback_language_alert": "Leider wurde diese Seite noch nicht in Ihre Sprache übersetzt.",
  "category_highlight.title.information": "Wissenswertes",
  "neighbourhood_guides_for_other_cities": "Reiseführer für Viertel anderer Städte",
  "lbg.h2.hubpage": "Diese fantastischen Unterkünfte haben viele Bewertungen und sind bei Gästen besonders beliebt.",
  "travel_advisory.title": "Reisehinweis",
  "lbg.section_properties_theme.all_inclusive": "Gewinner in der Kategorie „All-inclusive“ – {0}",
  "lbg.h2.boutique": "Sie mögen Boutique-Hotels? Diese Unterkünfte werden von Gästen empfohlen.",
  "lbg.section_properties_theme.family": "Gewinner in der Kategorie „Familie“ – {0}",
  "hub.category.h2.families": "{0} – wo Urlaub mit der Familie besonders schön ist",
  "from": "ab",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Gewinner in der Kategorie „LGBTQ+“ – {0}",
  "x_star": "{0,choice,0#|1#{0} Stern|1.5#{0} Sterne|2#{0} Sterne|2.5#{0} Sterne|3#{0} Sterne|3.5#{0} Sterne|4#{0} Sterne|4.5#{0} Sterne|5#{0} Sterne}",
  "trust_module.free_cancellation": "Kostenlose Stornierung",
  "lbg.section_properties_theme.home_and_apartments": "Gewinner in der Kategorie „Häuser & Apartments“ – {0}",
  "contributing_writer": "{0} <span>|</span> <span>Freie(r) Autor(in)</span>",
  "things_to_see_and_do_in": "Aktivitäten <span class=\"next-line\">in {0}</span> anzeigen",
  "lbg.check_price": "Preis überprüfen",
  "hub.category.h2.blogs": "von erfahrenen Reisenden",
  "lbg.h2.home_and_apartments": "Sie möchten sich auch unterwegs wie zu Hause fühlen? Dann stöbern Sie durch diese Unterkünfte mit Top-Bewertungen.",
  "hub.category.h2.information": "{0} – Wissenswertes",
  "search_hotels_in": "Hotels<span class=\"extra-label\"> in {0}</span> suchen",
  "hub.category.h2.nature": "{0} – die schönsten Reisen inmitten der Natur",
  "lbg.h1.destination_theme.home_and_apartments": "Gewinner in der Kategorie „Häuser & Apartments“ – {0}",
  "photo_by": "Foto von {0}",
  "more_things_to_do": "Mehr Aktivitäten und Sehenswürdigkeiten",
  "lbg.section_properties_theme.beach": "Gewinner in der Kategorie „Strand“ – {0}",
  "read_less": "Weniger anzeigen",
  "lbg.h2.spa_hotel": "Für Entspannung pur sorgen diese Unterkünfte mit Top-Bewertung, die für ihre Wellnessbereiche bekannt sind.",
  "second_fold_related_stories.see_also": "Auch interessant",
  "hub.category.h2.history": "{0} – Besonders beliebte historische Stätten",
  "lbg.section_properties_theme.cleanliness": "Gewinner in der Kategorie „Sauberkeit“ – {0}",
  "lbg.h1.destination_theme.boutique": "Gewinner in der Kategorie „Boutique“ – {0}",
  "nightlife_b595ae4": "Nachtleben",
  "lbg.h1.destination_theme.beach": "Gewinner in der Kategorie „Strand“ – {0}",
  "trust_module.free_cancellation.disclaimer": "Bei einigen Hotels muss die Stornierung mindestens 24–48 Stunden vor Check-in erfolgen. Weitere Details auf der Website.",
  "lbg.h2.family": "Diese Unterkünfte haben hohe Gästebewertungen erhalten und werden besonders von Familien geschätzt.",
  "lbg.h2.lgbtq": "Diese LGBTQ-freundlichen Unterkünfte wurden von Gästen bestens bewertet.",
  "hub.category.h1.group": "{0}: Gruppenreisen",
  "popular_stories": "Beliebte Artikel",
  "toc": "Inhalt",
  "hub.category.h2.shopping": "{0} – die besten Einkaufsmöglichkeiten",
  "lbg.section_theme_world_winners.beach": "Gewinner in der Kategorie „Strand“",
  "page_score": "Bewertung dieser Seite",
  "lbg.section_theme_world_winners.sustainable": "Gewinner in der Kategorie „Nachhaltig“",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Unsere Preisgarantie",
  "lbg.h1.destination_theme.all_inclusive": "Gewinner in der Kategorie „All-inclusive“ – {0}",
  "top_destinations": "Top-Reiseziele",
  "hotel_prices_near": "Hotelpreise nahe {0}",
  "lbg.score.cleanliness": "Sauberkeit:",
  "x_stars": "{0,choice,0#|1#{0} Stern|1<{0} Sterne|4<{0} Sterne|10<{0} Sterne}",
  "lbg.theme.all_inclusive": "All-inclusive",
  "hub.category.h1.tours": "{0} – Touren und Tagesausflüge",
  "lbg.section_award_winners_theme": "Gewinner nach Thema",
  "guest_writer": "{0} <span>&#124;</span> <span>Gastautor</span>",
  "start_planning_your_x_trip": "Plant eure Reise nach {0}",
  "lbg.section_theme_world_winners.business": "Gewinner in der Kategorie „Business“",
  "trending_now_in": "Derzeit beliebt in {0}",
  "history": "Geschichte",
  "search_hotels": " Hotels suchen",
  "lbg.terms_conditions_details": "Die Kriterien für die Auswahl der Gewinnerunterkünfte {0} basieren auf einer hohen durchschnittlichen Gästebewertung im Jahr {1} und mindestens 50 Bewertungen.",
  "see_nearby_properties": "Unterkünfte in der Nähe anzeigen",
  "hub.category.h1.history": "{0}: historische Stätten",
  "location": "Lage:",
  "lbg.section_theme_world_winners.luxury": "Gewinner in der Kategorie „Luxus“",
  "category_highlight.see_all.nightlife": "Mehr Infos zum Nachtleben",
  "also_popular": "Auch beliebt",
  "hub.category.h2.things_to_do": "{0} – die besten Sehenswürdigkeiten",
  "lbg.theme.family": "Familie",
  "lbg.h1.destination_theme.pet_friendly": "Gewinner in der Kategorie „Haustierfreundlich“ – {0}",
  "category_highlight.title.shopping": "{0}: Wohin zum Shopping",
  "price": "Preis:",
  "hub.category.h2.beach": "{0} – die schönsten Strände",
  "neighbourhoods_in": "Stadtteile in {0}",
  "business": "Geschäftsreisen",
  "photo": "Foto",
  "blog.see_all_stories": "Alle Geschichten anzeigen",
  "category_highlight.see_all.food": "Mehr Infos zur Gastronomie",
  "see_all_properties": "Alle Unterkünfte anzeigen",
  "lbg.theme.beach": "Strand",
  "what_to_see_and_do_in": "{0}: Was gibt es zu besichtigen und zu unternehmen?",
  "hub.category.h2.single": "{0} – Besonders beliebte Optionen für Alleinreisende",
  "attractions_in": "Attraktionen in {0}",
  "read_more": "Mehr anzeigen",
  "show_more": "Mehr anzeigen",
  "hotels_near_this_landmark": "Hotels in der Nähe dieser Sehenswürdigkeit",
  "check_dates": "Daten überprüfen",
  "featured_stories_and_fun_stuff": "Ausgewählte Erlebnisse und Unterhaltsames",
  "phone": "Telefon:",
  "lbg.section_search_header": "Suchen Sie nach Gewinnern",
  "category_highlight.title.food": "{0}: Kulinarische Tipps",
  "see_all_things_to_do": "Alle Aktivitäten anzeigen",
  "lbg.section_properties_theme.sustainable": "Gewinner in der Kategorie „Nachhaltig“ – {0}",
  "lbg.h1.hubpage": "Die Gewinner des Beliebtheitspreises {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Gewinner in der Kategorie „Wellness“",
  "category.maps": "Karten",
  "lbg.h1.destination_theme.family": "Gewinner in der Kategorie „Familie“ – {0}",
  "lbg.section_theme_world_winners.cleanliness": "Gewinner in der Kategorie „Sauberkeit“",
  "lbg.section_theme_world_winners.vip_access": "Gewinner in der Kategorie „VIP Access“",
  "trust_module.get_reward_night.night_you_stay": "nach je 10 wahrgenommenen Übernachtungen",
  "modified": "bearbeitet",
  "lbg.section_properties": "Gewinner – {0}",
  "trust_module.header": "Warum auf Hotels.com buchen?",
  "hub.category.h2.luxury": "{0} – Wenn Luxus im Urlaub auf Ihrer Wunschliste steht",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Gewinner in der Kategorie „Wellness“ – {0}",
  "lbg.h2.most_wanted": "Diese Unterkünfte bieten einen perfekten Aufenthalt und werden von Gästen wärmstens empfohlen.",
  "top10_hotels_incomplete_alert": "Aufgrund der hohen Nachfrage werden einige Unterkünfte momentan möglicherweise nicht angezeigt. <span>Wir bitten um Ihr Verständnis.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Reiseenthusiast(in)</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Gewinner in der Kategorie „Häuser & Apartments“",
  "loved_by_guests_collection_in": "„Bei Gästen sehr beliebt“ – die Auswahl für {0}",
  "title.suffix": "{0} – Go!",
  "hub.category.h2.maps": "{0} – nützliche Karten",
  "opening_hours": "Öffnungszeiten",
  "hub.category.h2.photos": "{0} – Besonders beliebte Optionen für Fotos mit Wow-Effekt",
  "lbg.theme.most_wanted": "Gästefavorit",
  "blog.stories_container": "Gesammelte Reisegeschichten",
  "hub.category.h2.budget": "{0} – Wenn Sie besonders günstig reisen möchten",
  "lbg.section_properties_theme.business": "Gewinner in der Kategorie „Business“ – {0}",
  "not_sure_where_to_stay": "Unsicher, wo Sie übernachten wollen?",
  "category.information": "Informationen"
};