import React, { useRef } from 'react';
import { UitkIcon } from 'uitk-react-icons';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import { localization } from 'src/utils/localization';
var MobileNavigation = function (props) {
  var menu = props.menu,
    handleClick = props.handleClick,
    iconMapping = props.iconMapping;
  var goGuidesStore = useStores().goGuidesStore;
  var track = useEgClickstream();
  var menuButton = useRef(null);
  if (!menu.category || menu.category.length == 0) {
    return null;
  }
  var handleClickOutSide = function (event) {
    var isClickInside = menuButton.current.contains(event.target);
    if (!isClickInside) {
      handleClick(false);
      document.removeEventListener('click', handleClickOutSide);
    }
  };
  var trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'more_button.selected',
      event_category: 'go_guides',
      action_location: 'toolbar'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  var onClick = function (e) {
    e.preventDefault();
    e.stopPropagation();
    document.addEventListener('click', handleClickOutSide);
    handleClick(true);
    // send Clickstream tracking
    track(trackObject);
  };
  var loc = new localization();
  // if we have more than 3 category menu first 3 menu has empty class name
  // if less than that use 'menu{x}'
  var menuClass = menu.category.length < 3 ? "menu".concat(menu.category.length) : '';
  var buttonClass = menu.category.length == 1 ? menuClass : menu.category.length == 2 ? "fixed ".concat(menuClass) : 'fixed';
  var subMenu = menu.category.map(function (item, index) {
    var itemClass = index >= 3 ? 'hide' : menuClass;
    var target = item.categoryKey == 'hotels' ? '_blank' : '_self';
    var categoryName = loc.getLocalizedText("category.".concat(item.categoryKey));
    return React.createElement("li", {
      className: itemClass,
      key: index
    }, React.createElement("a", {
      className: "nav-menu ".concat(item.categoryKey),
      href: item.targetUrl,
      target: target
    }, React.createElement(UitkIcon, {
      name: iconMapping[item.categoryKey]
    }), categoryName));
  });
  var menuLabel = loc.getLocalizedText('menu.more');
  return React.createElement("ul", {
    className: "mobile-tab"
  }, subMenu, React.createElement("li", {
    className: buttonClass
  }, React.createElement("a", {
    className: "open-button",
    onClick: onClick,
    ref: menuButton
  }, React.createElement("span", null, React.createElement(UitkIcon, {
    name: "more_horiz"
  }), " ", menuLabel))));
};
export default MobileNavigation;