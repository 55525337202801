var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useRef } from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkEGMaps, UitkEGMapsType, UitkEGMapsQualifier } from 'uitk-react-map';
import { UitkSheet } from 'uitk-react-sheet';
import { UitkToolbar } from 'uitk-react-toolbar';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { checkValue } from 'src/components/functions';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
var MapComponent = function (_a) {
  var _b, _c;
  var name = _a.name,
    i18nKey = _a.i18nKey,
    geo = _a.geo,
    _d = _a.openMap,
    openMap = _d === void 0 ? false : _d;
  var _e = __read(React.useState(false), 2),
    isGoogleMapOpen = _e[0],
    setIsGoogleMapOpen = _e[1];
  var _f = useStores(),
    goGuidesStore = _f.goGuidesStore,
    context = _f.context;
  var locale = context.locale;
  var track = useEgClickstream();
  var trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Outcome',
      event_name: 'go_guides_map.opened',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  var openGoogleMap = function () {
    setIsGoogleMapOpen(true);
    track(trackObject);
  };
  var closeGoogleMap = function () {
    setIsGoogleMapOpen(false);
  };
  var formatText = useLocalization().formatText;
  var toolBarAttributesGoogle = {
    navigationContent: {
      onClick: closeGoogleMap,
      navType: 'close',
      navIconLabel: formatText('module.map.close.a11yLabel')
    },
    toolbarTitle: name
  };
  var cfg = React.useMemo(function () {
    return {
      locale: locale,
      markers: {
        property: {
          markerType: {
            portOfCall: 'text'
          }
        },
        place: {
          textLabel: {
            show: {
              default: 'always'
            }
          },
          markerType: {
            customQualifier: 'text'
          }
        }
      },
      "googleMaps": {
        "gmapsAddConfig": {
          "fullscreenControl": true,
          "streetViewControl": true,
          "streetViewControlOptions": {
            "position": 9
          },
          "zoomControlOptions": {
            "position": 9
          },
          "MapTypeId": "terrain",
          "mapTypeControl": true,
          "mapTypeControlOptions": {
            "MapTypeId": ["terrain"]
          }
        }
      }
    };
  }, []);
  var features = [{
    id: 'POI',
    type: 'place',
    lat: Number((_b = geo.coord) === null || _b === void 0 ? void 0 : _b.lat),
    lng: Number((_c = geo.coord) === null || _c === void 0 ? void 0 : _c.lon),
    name: name,
    status: 'unknown',
    qualifiers: [UitkEGMapsQualifier.PINNED]
  }];
  var initialViewport = React.useMemo(function () {
    var _a, _b;
    return {
      center: {
        lat: Number((_a = geo.coord) === null || _a === void 0 ? void 0 : _a.lat),
        lng: Number((_b = geo.coord) === null || _b === void 0 ? void 0 : _b.lon)
      },
      zoom: 16
    };
  }, [geo.coord]);
  var googleToken = 'AIzaSyDT-2VaHp2uNY0QeL6U2JbQ0xk6yj-3MeU';
  var egMapsGoogleComponent = React.createElement(UitkEGMaps, {
    accessToken: googleToken,
    mapType: UitkEGMapsType.GOOGLE,
    mapConfig: cfg,
    features: features,
    initialViewport: initialViewport
  });
  var googleMapTriggerRef = useRef(null);
  var sheetAttributes = {
    triggerRef: googleMapTriggerRef
  };
  var withFullscreen = function (component, toolBarAttributes, sheetAttributes) {
    return React.createElement("div", {
      className: 'egds-eg-maps',
      style: {
        zIndex: 600,
        position: 'fixed',
        left: 0
      }
    }, React.createElement(UitkSheet, __assign({
      isVisible: true,
      type: "full"
    }, sheetAttributes), React.createElement(UitkToolbar, __assign({}, toolBarAttributes)), component));
  };
  return React.createElement(React.Fragment, null, checkValue(openMap) ? React.createElement("div", {
    className: "map-container"
  }, React.createElement(UitkEGMaps, {
    accessToken: googleToken,
    mapType: UitkEGMapsType.GOOGLE,
    mapConfig: cfg,
    features: features,
    initialViewport: initialViewport
  })) : React.createElement(React.Fragment, null, React.createElement(UitkLink, {
    inline: true,
    className: "map-button"
  }, React.createElement("a", {
    onClick: openGoogleMap,
    ref: googleMapTriggerRef
  }, React.createElement("div", {
    className: "map-wrapper"
  }, React.createElement("span", null, React.createElement(UitkIcon, {
    className: "icon",
    name: "place",
    size: UitkIconSize.EXTRA_SMALL
  }), i18nKey)))), isGoogleMapOpen && googleToken && withFullscreen(egMapsGoogleComponent, __assign({}, toolBarAttributesGoogle), sheetAttributes)));
};
export default MapComponent;