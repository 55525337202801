export default {
  "current_deal": "Aktuelle tilbud",
  "lbg.section_properties_theme.pet_friendly": "Vindere i kategorien ”Kæledyrsvenligt” i {0}",
  "address": "Adresse",
  "hub.homepage.h2": "Så mange steder, så lidt tid...",
  "search_hotels_near_this_landmark": "Søg efter hoteller<span class=\"extra-label\"> tæt på denne seværdighed</span>",
  "hub.category.h1.things_to_do": "Oplevelser i {0}",
  "lbg.h2.vip_access": "VIP Access-overnatningssteder er udvalgt for deres høje bedømmelser og fantastiske gæsteoplevelser.",
  "lbg.theme.cleanliness": "Regøring",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Vis mere",
  "link_long_form_to_listiicle_multiple": "{0} - et af højdepunkterne ved \"{1}\" og \"{2}\". Læs alt om {3} her.",
  "lbg.section_properties_theme.boutique": "Vindere i kategorien ”Boutique-stil” i {0}",
  "lbg.h2.luxury": "Oplev luksus, når det er bedst. Det er derfor, gæster nyder deres ophold på disse overnatningssteder.",
  "hub.category.h1.information": "Oplysninger om {0}",
  "families": "Familier",
  "trending_now": "Populært lige nu",
  "lbg.from": "Fra",
  "hub.category.h1.road_trips": "De bedste roadtrips i {0}",
  "lbg.h2.cleanliness": "Disse overnatningssteder har fået fantastiske bedømmelser fra gæster og topscore for rengøring.",
  "single": "Singler",
  "couples": "Par",
  "trust_module.price_guarantee.find_out_more": "få mere at vide",
  "top_destinations_in": "Populære destinationer i {0}",
  "hub.category.h1.couples": "Parferie i {0}",
  "hub.category.h2.tours": "De bedste ture i {0}",
  "hub.category.h1.photos": "Fotogene feriedestinationer i {0}",
  "category.photos": "Billeder",
  "hub.category.h1.budget": "Ferie på budget i {0}",
  "hub.category.h2.ski": "De bedste skiferiedestinationer i {0}",
  "lbg.section_theme_world_winners.most_wanted": "Vindere i kategorien ”Mest eftertragtede”",
  "lbg.theme.home_and_apartments": "Ferieboliger og lejligheder",
  "hub.category.h1.luxury": "Luksusferie i {0}",
  "hub.category.h2.adventure": "Populære aktive ferier i {0}",
  "hotels_in": "Hoteller i {0}",
  "lbg.section_search_winners": "Søg efter flere vindere",
  "see_more_hotels_in": "Se flere hoteller i {0}",
  "hub.category.h1.beach": "Strande i {0}",
  "open": "Åbningstider:",
  "enter_your_destination": "Angiv din destination",
  "best_of": "Det bedste fra {0}",
  "top10_hotels_missing_properties": "Nogle overnatningssteder kan muligvis ikke vises i øjeblikket. Vi beklager ulejligheden.",
  "adventure": "Oplevelser",
  "lbg.h1.destination_theme.sustainable": "Vindere i kategorien ”Bæredygtigt” i {0}",
  "luxury": "Luksus",
  "expand_map_view": "Udvid kortvisning",
  "book_now": "Book nu",
  "lbg.h1.destination_theme.ski": "Vindere i kategorien ”Ski” i {0}",
  "hub.category.h1.food": "Spisemuligheder i {0}",
  "category.blogs": "Blog",
  "budget": "Lavt budget",
  "popular_neighbourhoods_in": "Populære områder i {0}",
  "hub.category.h1.unusual": "Skjulte perler og usædvanlige steder i {0}",
  "see_all_accommodation_in": "Se alle overnatningssteder i {0}",
  "hub.category.h1.adventure": "Aktiv ferie i {0}",
  "category_highlight.see_all.shopping": "Mere om shopping",
  "lbg.section_most_popular_destination": "Mest populære destinationer",
  "lbg.section_properties_theme.luxury": "Vindere i kategorien ”Luksus” i {0}",
  "unusual": "Usædvanlige oplevelser",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Få mest muligt ud af din rejse",
  "x_review": "{0,choice,0#|1#{0} anmeldelse|2#{0} anmeldelser|2<{0} anmeldelser|4<{0} anmeldelser|10#{0} anmeldelser|10<{0} anmeldelser}",
  "hub.category.h1.single": "Soloferie i {0}",
  "hub.category.h2.custom": "Populært lige nu",
  "lbg.section_theme_world_winners.lgbt": "Vindere i kategorien ”LGBTQ+-venligt”",
  "lbg.section_award_winners_theme_other": "Andre vindere efter kategori i {0}",
  "lbg.supplier_collected_charges": "+ {0} til betaling på overnatningsstedet",
  "in_destination": " {0}",
  "lbg.section_properties_theme.vip_access": "Vindere i kategorien ”VIP Access” i {0}",
  "link_long_form_to_listiicle_single": "{0} - et af højdepunkterne ved \"{1}\". Læs alt om {2} her.",
  "lbg.section_theme_world_winners.boutique": "Vindere i kategorien ”Boutique-stil”",
  "lbg.section_properties_theme.spa_hotel": "Vindere i kategorien ”Spa” i {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Vindere i kategorien ”Alt inklusive”",
  "hub.category.h2": "Rejseguide til {0}",
  "lbg.properties.price_tooltip": "Dette er den laveste pris pr. nat på vores hjemmeside i løbet af de næste 14 dage.",
  "hub.category.h2.road_trips": "De bedste roadtrips i {0}",
  "hub.category.h1.ski": "Skiferiedestinationer i {0}",
  "food": "Mad",
  "lbg.theme.pet_friendly": "Kæledyrsvenligt",
  "lbg.theme.business": "Egnet til forretningsrejsende",
  "hub.category.h1.nature": "De bedste naturoplevelser i {0}",
  "map": "Kort",
  "category_highlight.see_all.information": "Flere oplysninger",
  "hotel_prices_in": "Hotelpriser i {0}",
  "hub.category.h1.nightlife": "Natteliv i {0}",
  "trust_module.free_cancellation.most_hotels": "på de fleste hoteller*",
  "also_popular_in": "Også populært i {0}",
  "lbg.h1.destination_theme.business": "Vindere i kategorien ”Egnet til forretningsrejsende” i {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Vindere i kategorien ”Kæledyrsvenligt”",
  "hub.category.h1.maps": "Kort over {0}",
  "lbg.section_properties_theme.most_wanted": "Vindere i kategorien ”Mest eftertragtede” i {0}",
  "category.nightlife": "Natteliv",
  "search_hotels_in_this_area": "Søg efter hoteller<span class=\"extra-label\"> i dette område</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 stemme|2#2 stemmer|2<{1} stemmer|4<{1} stemmer|10#10 stemmer|10<{1} stemmer})",
  "hub.category.h2.business": "Populære forretningsrejser i {0}",
  "category.things_to_do": "Oplevelser",
  "destination_travel_guide_by_category": "{0} – Rejseguide efter kategori",
  "hub.category.h1.families": "Familieferie i {0}",
  "lbg.section_theme_world_winners.ski": "Vindere i kategorien ”Ski”",
  "you_may_also_like": "Måske er dette noget for dig",
  "lbg.theme.sustainable": "Bæredygtigt",
  "hub.category.h2.unusual": "Populære skjulte perler og usædvanlige steder i {0}",
  "hub.category.h2.football": "Støt dit fodboldhold, og få mest muligt ud af rejsen!",
  "top_hotel": "Populært hotel",
  "lbg.h1.destination_theme.luxury": "Vindere i kategorien ”Luksus” i {0}",
  "top10_hotels_empty_alert": "Vi oplever i øjeblikket tekniske problemer. <span>Vi beklager ulejligheden.</span>",
  "special_deal": "Særtilbud",
  "lbg.h2.sustainable": "Det handler om bæredygtighed! Tag et kig på disse skønne overnatningssteder.",
  "where_to_stay_in": "Her kan du overnatte i {0}",
  "start_planning_your_trip": "Planlæg din næste rejse",
  "good_for": "Velegnet til:",
  "category.hotels": "Hoteller",
  "x_accommodation_guide": "Opholdsguide for {0}",
  "lbg.theme.luxury": "Luksus",
  "hub.category.h2.sports": "Populære sportsaktiviteter og andre aktiviteter i {0}",
  "lbg.section_theme_world_winners.lgbtq": "Vindere i kategorien ”LGBTQ-venligt”",
  "see_more_neighbourhoods": "Se flere områder",
  "category_highlight.title.nightlife": "Hvor du kan gå ud, og hvad du kan foretage dig om aftenen i {0}",
  "lbg.h1.destination_theme.lgbt": "Vindere i kategorien ”LGBTQ+-venligt” i {0}",
  "hub.category.h1.blogs": "Rejseblog om {0}",
  "lbg.h2.business": "Disse overnatningssteder har fået høje bedømmelser af erhvervsrejsende.",
  "hotels": "Hoteller",
  "lbg.h2.pet_friendly": "Gæster anbefaler disse overnatningssteder, som også tager mod kæledyr.",
  "popular_landmarks_nearby": "Populære vartegn i nærheden",
  "popular_extractions_in": "Populære seværdigheder i {0}",
  "lbg.h2.all_inclusive": "Vink farvel til stress og jag med disse højt bedømte overnatningssteder.",
  "travel_guide_by_category_header": "{1} – {0}",
  "lbg.travel_between": "Rejs mellem {0} og {1}",
  "keep_exploring": "Find inspiration",
  "what_you_need_to_know_before_you_go": "Godt at vide, inden du rejser",
  "read_it": "Læs mere",
  "view_on_map": "Vis på kort",
  "lbg.h1.destination_theme.lgbtq": "Vindere i kategorien ”LGBTQ-venligt” i {0}",
  "lbg.h2.beach": "Vores gæster elsker badeferier − særligt på disse overnatningssteder.",
  "lbg.h1.destination_theme.vip_access": "Vindere i kategorien ”VIP Access” i {0}",
  "see_all_accommodation": "Se alle overnatningssteder",
  "shoppers": "Shopping",
  "whats_around": "Det kan du opleve i {0}",
  "group": "Grupper",
  "hub.category.h2.couples": "Populære parferier i {0}",
  "hub.category.h1.shopping": "Shopping i {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Rejsefællesskab</span>",
  "hub.category.h2.group": "Populære grupperejser i {0}",
  "listicle_legal_msg": "Denne artikel indeholder meninger fra Go Guides’ redaktion. Hotels.com kompenserer skribenter for tekster, som fremgår på denne side. Kompensationen kan inkludere rejser og andre omkostninger.",
  "lbg.h1.destination_theme.cleanliness": "Vindere i kategorien \"Rengøring\" i {0}",
  "menu.more": "Mere",
  "lbg.section_properties_theme.lgbtq": "Vindere i kategorien ”LGBTQ-venligt” i {0}",
  "lbg.section_award_winners_destination": "Vindere efter destination",
  "lbg.terms_conditions": "Vilkår og betingelser",
  "hub.category.h2.nightlife": "Det bedste natteliv i {0}",
  "hub.neighbourhood.h2": "Det kan du opleve i {0}",
  "lbg.section_award_winners_theme_destination": "Vindere efter kategori i {0}",
  "lbg.section_award_world_winners": "Vindere fra hele verden",
  "things_to_see_and_do_in_other_cities": "Aktiviteter i andre byer",
  "button.backToTop": "Gå til toppen af siden",
  "lbg.section_theme_world_winners.family": "Vindere i kategorien ”Familievenligt”",
  "hotels_near": "Hoteller i nærheden af {0}",
  "category.activity__sports_": "Aktiviteter",
  "trust_module.get_reward_night": "Få en bonusnat",
  "hub.category.h1.business": "Forretningsrejse i {0}",
  "category.food": "Mad",
  "other_popular_destinations_in": "Andre populære destinationer i {0}",
  "browse_by_category": "Bliv klogere på {0} efter kategori",
  "see_more_attactions": "Se flere seværdigheder",
  "lbg.theme.lgbt": "LGBTQ+-venligt",
  "more_information_about": "Flere oplysninger om {0}",
  "lbg.h1.destination_theme.most_wanted": "Vindere i kategorien ”Mest eftertragtede” i {0}",
  "see_all_properties_in": "{0} – se overnatningssteder",
  "category.tours": "Udflugter og rundvisninger",
  "hub.category.h1.sports": "Sportsaktiviteter og andre aktiviteter i {0}",
  "where_will_you_go_next": "Hvor går din næste rejse hen?",
  "cities": "Byer",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Se guide",
  "lbg.section_properties_theme.ski": "Vindere i kategorien ”Ski” i {0}",
  "related_stories": "Relaterede artikler",
  "hub.destination.h2": "Godt at vide, inden du rejser",
  "show_less": "Vis færre",
  "hub.category.h1.football": "Den ultimative guide til {0} for ægte fodboldfans",
  "hub.category.h2.food": "Populære spisemuligheder i {0}",
  "fallback_language_alert": "Denne side er desværre ikke oversat til dit sprog endnu…",
  "category_highlight.title.information": "Nyttige oplysninger",
  "neighbourhood_guides_for_other_cities": "Områdeguides til andre byer",
  "lbg.h2.hubpage": "Disse fantastiske overnatningssteder har gode gæstebedømmelser og masser af anmeldelser.",
  "travel_advisory.title": "Rejsevarsel",
  "lbg.section_properties_theme.all_inclusive": "Vindere i kategorien ”Alt inklusive” i {0}",
  "lbg.h2.boutique": "Hvis du er vild med boutique-overnatningssteder, kan du tage et kig på disse højt bedømte favoritter.",
  "lbg.section_properties_theme.family": "Vindere i kategorien ”Familievenligt” i {0}",
  "hub.category.h2.families": "De bedste familieferier i {0}",
  "from": "fra",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Vindere i kategorien ”LGBTQ+-venligt” i {0}",
  "x_star": "{0,choice,0#|1#{0}-stjernet|1.5#{0}-stjernet|2#{0}-stjernet|2.5#{0}-stjernet|3#{0}-stjernet|3.5#{0}-stjernet|4#{0}-stjernet|4.5#{0}-stjernet|5#{0}-stjernet}",
  "trust_module.free_cancellation": "Gratis afbestilling",
  "lbg.section_properties_theme.home_and_apartments": "Vindere i kategorien ”Ferieboliger og lejligheder” i {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Freelanceforfatter</span>",
  "things_to_see_and_do_in": "Aktiviteter <span class=\"next-line\">i {0}</span>",
  "lbg.check_price": "Tjek pris",
  "hub.category.h2.blogs": "af erfarne rejsende",
  "lbg.h2.home_and_apartments": "Leder du efter et hjem udenfor hjemmet? Tag et kig på disse højt bedømte overnatningssteder.",
  "hub.category.h2.information": "Nyttige oplysninger om {0}",
  "search_hotels_in": "Søg efter hoteller<span class=\"extra-label\"> i {0}</span>",
  "hub.category.h2.nature": "De bedste naturoplevelser i {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Vindere i kategorien ”Ferieboliger og lejligheder” i {0}",
  "photo_by": "Billede taget af {0}",
  "more_things_to_do": "Flere oplevelser",
  "lbg.section_properties_theme.beach": "Vindere i kategorien ”Strand” i {0}",
  "read_less": "Færre oplysninger",
  "lbg.h2.spa_hotel": "Læn dig tilbage og slap af på disse højt bedømte overnatningssteder, som er kendte for deres spaer.",
  "second_fold_related_stories.see_also": "Se også",
  "hub.category.h2.history": "Populære historiske steder i {0}",
  "lbg.section_properties_theme.cleanliness": "Vindere i kategorien \"Rengøring\" i {0}",
  "lbg.h1.destination_theme.boutique": "Vindere i kategorien ”Boutique-stil” i {0}",
  "nightlife_b595ae4": "Natteliv",
  "lbg.h1.destination_theme.beach": "Vindere i kategorien ”Strand” i {0}",
  "trust_module.free_cancellation.disclaimer": "Nogle hoteller kræver afbestilling mindst 24 til 48 timer inden indtjekning. Se flere oplysninger på hjemmesiden.",
  "lbg.h2.family": "Disse overnatningssteder har høje bedømmelser fra gæster og er perfekte til familier.",
  "lbg.h2.lgbtq": "Disse LGBTQ-venlige overnatningssteder har fået høje bedømmelser af gæster.",
  "hub.category.h1.group": "Grupperejser i {0}",
  "popular_stories": "Populære historier",
  "toc": "Indhold",
  "hub.category.h2.shopping": "De bedste shoppingmuligheder i {0}",
  "lbg.section_theme_world_winners.beach": "Vindere i kategorien ”Strand”",
  "page_score": "Denne sides bedømmelse",
  "lbg.section_theme_world_winners.sustainable": "Vindere i kategorien ”Bæredygtigt”",
  "lbg.theme.boutique": "Boutique-stil",
  "trust_module.price_guarantee": "Vores prisgaranti",
  "lbg.h1.destination_theme.all_inclusive": "Vindere i kategorien ”Alt inklusive” i {0}",
  "top_destinations": "Topdestinationer",
  "hotel_prices_near": "Hotelpriser omkring {0}",
  "lbg.score.cleanliness": "Rengøring:",
  "x_stars": "{0,choice,0#|1#{0} stjerne|1<{0} stjerner|4<{0} stjerner|10<{0} stjerner}",
  "lbg.theme.all_inclusive": "Alt inklusive",
  "hub.category.h1.tours": "Udflugter og dagture i {0}",
  "lbg.section_award_winners_theme": "Vindere efter kategori",
  "guest_writer": "{0} <span>&#124;</span> <span>Gæsteforfatter</span>",
  "start_planning_your_x_trip": "Start planlægningen af din rejse til {0}",
  "lbg.section_theme_world_winners.business": "Vindere i kategorien ”Egnet til forretningsrejsende”",
  "trending_now_in": "Populært i {0}",
  "history": "Historie",
  "search_hotels": "Søg efter hoteller",
  "lbg.terms_conditions_details": "Kriterierne for alle prisbelønnede overnatningssteder i {0} er baseret på gode gæstebedømmelser i {1} og mindst 50 anmeldelser.",
  "see_nearby_properties": "Se overnatningssteder i nærheden",
  "hub.category.h1.history": "Historiske steder i {0}",
  "location": "Adresse:",
  "lbg.section_theme_world_winners.luxury": "Vindere i kategorien ”Luksus”",
  "category_highlight.see_all.nightlife": "Mere om nattelivet",
  "also_popular": "Andre populære destinationer",
  "hub.category.h2.things_to_do": "De bedste oplevelser i {0}",
  "lbg.theme.family": "Familievenligt",
  "lbg.h1.destination_theme.pet_friendly": "Vindere i kategorien ”Kæledyrsvenligt” i {0}",
  "category_highlight.title.shopping": "Hvor du kan shoppe, og hvad du bør købe i {0}",
  "price": "Pris:",
  "hub.category.h2.beach": "De bedste strande i {0}",
  "neighbourhoods_in": "kvarterer i {0}",
  "business": "Forretningsrejser",
  "photo": "Billeder",
  "blog.see_all_stories": "Se alle artikler",
  "category_highlight.see_all.food": "Mere om mad og spisemuligheder",
  "see_all_properties": "Vis alle overnatningssteder",
  "lbg.theme.beach": "Strand",
  "what_to_see_and_do_in": "Hvad du kan se og gøre i {0}",
  "hub.category.h2.single": "Populære soloferier i {0}",
  "attractions_in": "Seværdigheder i {0}",
  "read_more": "Læs mere",
  "show_more": "Vis flere",
  "hotels_near_this_landmark": "Hoteller i nærheden af denne seværdighed",
  "check_dates": "Tjek datoer",
  "featured_stories_and_fun_stuff": "Udvalgte oplevelser og sjove idéer",
  "phone": "Telefon:",
  "lbg.section_search_header": "Søg efter vindere",
  "category_highlight.title.food": "Hvad og hvor du bør spise i {0}",
  "see_all_things_to_do": "Se alle aktiviteter",
  "lbg.section_properties_theme.sustainable": "Vindere i kategorien ”Bæredygtigt” i {0}",
  "lbg.h1.hubpage": "Vindere af prisen ”Loved by Guests” i {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Vindere i kategorien ”Spa”",
  "category.maps": "Kort",
  "lbg.h1.destination_theme.family": "Vindere i kategorien ”Familievenligt” i {0}",
  "lbg.section_theme_world_winners.cleanliness": "Vindere i kategorien \"Rengøring\"",
  "lbg.section_theme_world_winners.vip_access": "Vindere i kategorien ”VIP Access”",
  "trust_module.get_reward_night.night_you_stay": "for hver 10 overnatninger, du gennemfører",
  "modified": "Redigeret",
  "lbg.section_properties": "Vindere i {0}",
  "trust_module.header": "Derfor bør du reservere på Hotels.com",
  "hub.category.h2.luxury": "Populære luksusferier i {0}",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Vindere i kategorien ”Spa” i {0}",
  "lbg.h2.most_wanted": "Gæster anbefaler disse overnatningssteder til det perfekte ophold.",
  "top10_hotels_incomplete_alert": "På grund af høj efterspørgsel bliver nogle overnatningssteder muligvis ikke vist på nuværende tidspunkt. <span>Vi beklager ulejligheden.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Rejseentusiast</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Vindere i kategorien ”Ferieboliger og lejligheder”",
  "loved_by_guests_collection_in": "Samling af overnatningssteder i {0}, som har fået topkarakter af vores gæster",
  "title.suffix": "{0} – Go Guides",
  "hub.category.h2.maps": "Nyttige kort over {0}",
  "opening_hours": "Åbningstider",
  "hub.category.h2.photos": "Populære fotogene feriedestinationer i {0}",
  "lbg.theme.most_wanted": "Mest eftertragtede",
  "blog.stories_container": "Blogartikler",
  "hub.category.h2.budget": "Populære ferier på budget i {0}",
  "lbg.section_properties_theme.business": "Vindere i kategorien ”Egnet til forretningsrejsende” i {0}",
  "not_sure_where_to_stay": "Ikke sikker på, hvor du vil overnatte?",
  "category.information": "Praktiske oplysninger"
};