import GoGuidesContributorsStoriesComponent from "components/modules/contributors-stories/contributors-stories";
import GoGuidesTableOfContent from "components/modules/table-of-content/toc";
import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesAlsoPopularComponent from 'components/modules/also-popular/also-popular';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesOtherCategoriesComponent from 'components/modules/other-categories/other-categories';
import GoGuidesWhatsAroundComponent from 'src/components/modules/whats-around/whats-around';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';
var CategoryHubPage = function () {
  return React.createElement(MasterPage, null, React.createElement("div", {
    className: "page-container"
  }, React.createElement(GoGuidesHeroComponent, null), React.createElement("div", {
    className: "hub-body"
  }, React.createElement(GoGuidesTableOfContent, {
    tagElement: 'h2',
    parentClassName: 'hub-body',
    needWrapper: true
  }), React.createElement(GoGuidesIntroComponent, null), React.createElement(GoGuidesFeaturedStoriesComponent, null), React.createElement(GoGuidesWhatsAroundComponent, null), React.createElement(GoGuidesContributorsStoriesComponent, null), React.createElement(GoGuidesOtherCategoriesComponent, null), React.createElement(RecommendationModule, {
    pageLayout: PageLayout.ONE_COLUMN
  }), React.createElement(GoGuidesSearchWizard, null), React.createElement(GoGuidesAlsoPopularComponent, null), React.createElement(GoGuidesKeepExploringComponent, null)), React.createElement(GoGuidesBackToTop, null)));
};
export default CategoryHubPage;