import { MasterPage } from 'components/common/template/master-page';
import GoGuidesAlsoPopularComponent from 'components/modules/also-popular/also-popular';
import GoGuidesContributorsStoriesComponent from 'components/modules/contributors-stories/contributors-stories';
import React from 'react';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesFreetextComponent from 'components/modules/free-text/free-text';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesOtherCategoriesComponent from 'components/modules/other-categories/other-categories';
import GoGuidesOtherNeighbourhoodsComponent from 'components/modules/other-neighbourhoods/other-neighbourhoods';
import GoGuidesTopDestinationComponent from 'components/modules/top-destination/top-destination';
import GoGuidesWhatsAroundComponent from 'src/components/modules/whats-around/whats-around';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
import GoGuidesTableOfContent from '../modules/table-of-content/toc';
import GoGuidesCategoryHighLightStoriesComponent from '../modules/highlight-stories/category-highlight-stories';
import { RecommendationModule } from '../modules/recommendation-module/recommendation';
import { PageLayout } from '../types/goguides-types.d';
var DestinationHubPage = function () {
  return React.createElement(MasterPage, null, React.createElement("div", {
    className: "page-container"
  }, React.createElement(GoGuidesHeroComponent, null), React.createElement("div", {
    className: "hub-body"
  }, React.createElement(GoGuidesTableOfContent, {
    tagElement: 'h2',
    parentClassName: 'hub-body',
    needWrapper: true
  }), React.createElement(GoGuidesIntroComponent, null), React.createElement(GoGuidesTopDestinationComponent, null), React.createElement(GoGuidesFeaturedStoriesComponent, null), React.createElement(GoGuidesCategoryHighLightStoriesComponent, {
    "category": "THINGS_TO_DO",
    "maxContent": 9
  }), React.createElement(GoGuidesWhatsAroundComponent, null), React.createElement(GoGuidesCategoryHighLightStoriesComponent, {
    "category": "RESTAURANT"
  }), React.createElement(GoGuidesCategoryHighLightStoriesComponent, {
    "category": "SHOPPING"
  }), React.createElement(GoGuidesCategoryHighLightStoriesComponent, {
    "category": "NIGHTLIFE"
  }), React.createElement(GoGuidesCategoryHighLightStoriesComponent, {
    "category": "INFORMATION"
  }), React.createElement(GoGuidesContributorsStoriesComponent, null), React.createElement(GoGuidesOtherCategoriesComponent, null), React.createElement(RecommendationModule, {
    pageLayout: PageLayout.ONE_COLUMN
  }), React.createElement(GoGuidesOtherNeighbourhoodsComponent, null), React.createElement(GoGuidesFreetextComponent, null), React.createElement(GoGuidesSearchWizard, null), React.createElement(GoGuidesAlsoPopularComponent, null), React.createElement(GoGuidesKeepExploringComponent, null)), React.createElement(GoGuidesBackToTop, null)));
};
export default DestinationHubPage;