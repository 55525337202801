var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect, useState } from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
var GoGuidesBackToTop = function () {
  var thresholdConfig = 1000;
  var scrollButton;
  var _a = __read(useState('fadeOut'), 2),
    fadeInOut = _a[0],
    setFadeInOut = _a[1];
  var scrollFunction = function () {
    if (window.scrollY > thresholdConfig) {
      setFadeInOut('fadeIn');
    } else {
      setFadeInOut('fadeOut');
    }
  };
  useEffect(function () {
    scrollButton = document.getElementById('scroll-to-top');
    window.addEventListener('scroll', scrollFunction, {
      passive: true
    });
    return function () {
      window.removeEventListener('scroll', scrollFunction);
    };
  });
  var handleClick = function (e) {
    e.preventDefault();
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  };
  return React.createElement(React.Fragment, null, React.createElement("a", {
    id: "scroll-to-top",
    href: "#",
    onClick: handleClick,
    className: fadeInOut
  }, React.createElement(UitkIcon, {
    name: "arrow_upward_iOS",
    size: UitkIconSize.MEDIUM
  }), " Back to top"));
};
export default GoGuidesBackToTop;