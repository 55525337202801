export default {
  "current_deal": "Oferta actual",
  "lbg.section_properties_theme.pet_friendly": "Ganadores de la categoría Aceptan mascotas en {0}",
  "address": "Dirección",
  "hub.homepage.h2": "El mundo está lleno de lugares por descubrir",
  "search_hotels_near_this_landmark": "Buscar hoteles<span class=\"extra-label\"> cerca de esta atracción</span>",
  "hub.category.h1.things_to_do": "Qué hacer en {0}",
  "lbg.h2.vip_access": "Los establecimientos VIP Access se eligen por sus buenas evaluaciones y por ofrecer una experiencia que supera las expectativas.",
  "lbg.theme.cleanliness": "Limpieza",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Ver más",
  "link_long_form_to_listiicle_multiple": "{0}: parte de la lista de {1} y {2} (lee más sobre {3} aquí)",
  "lbg.section_properties_theme.boutique": "Ganadores de la categoría Boutique en {0}",
  "lbg.h2.luxury": "¡El lujo en todo su esplendor! Es por ello que los huéspedes aman estos establecimientos.",
  "hub.category.h1.information": "Información sobre {0}",
  "families": "Familias",
  "trending_now": "Tendencias actuales",
  "lbg.from": "Desde",
  "hub.category.h1.road_trips": "Mejores viajes por carretera en {0}",
  "lbg.h2.cleanliness": "Estos establecimientos tienen, en general, buena puntuación y buenas opiniones de los huéspedes por su limpieza.",
  "single": "Viajes en solitario",
  "couples": "Parejas",
  "trust_module.price_guarantee.find_out_more": "más información",
  "top_destinations_in": "Principales destinos en {0}",
  "hub.category.h1.couples": "Viajes para parejas en {0}",
  "hub.category.h2.tours": "Los mejores tours en {0}",
  "hub.category.h1.photos": "Viajes ideales para fotografía en {0}",
  "category.photos": "Fotos",
  "hub.category.h1.budget": "Viajes económicos en {0}",
  "hub.category.h2.ski": "Los mejores destinos para esquiar en {0}",
  "lbg.section_theme_world_winners.most_wanted": "Ganadores de la categoría Más solicitados",
  "lbg.theme.home_and_apartments": "Casas y departamentos",
  "hub.category.h1.luxury": "Viajes de lujo en {0}",
  "hub.category.h2.adventure": "Viajes con actividades físicas más populares en {0}",
  "hotels_in": "Hoteles en {0}",
  "lbg.section_search_winners": "Encuentra más ganadores",
  "see_more_hotels_in": "Ver más hoteles en {0}",
  "hub.category.h1.beach": "Playas en {0}",
  "open": "Horario:",
  "enter_your_destination": "Ingresa tu destino",
  "best_of": "Lo mejor de {0}",
  "top10_hotels_missing_properties": "Es posible que algunos establecimientos no se muestren en este momento. Te ofrecemos una disculpa.",
  "adventure": "Aventura",
  "lbg.h1.destination_theme.sustainable": "Ganadores de la categoría Sustentables en {0}",
  "luxury": "Lujo",
  "expand_map_view": "Ampliar vista del mapa",
  "book_now": "Reservar",
  "lbg.h1.destination_theme.ski": "Ganadores de la categoría Ski en {0}",
  "hub.category.h1.food": "Gastronomía en {0}",
  "category.blogs": "Blog",
  "budget": "Opciones económicas",
  "popular_neighbourhoods_in": "Zonas destacadas en {0}",
  "hub.category.h1.unusual": "Tesoros ocultos y lugares poco comunes en {0}",
  "see_all_accommodation_in": "Ver hospedaje en {0}",
  "hub.category.h1.adventure": "Viajes con actividades físicas en {0}",
  "category_highlight.see_all.shopping": "Ver más sobre compras",
  "lbg.section_most_popular_destination": "Destinos más populares",
  "lbg.section_properties_theme.luxury": "Ganadores de la categoría Lujo en {0}",
  "unusual": "Viajes diferentes",
  "category.shopping": "Compras",
  "make_the_most_of_your_trip": "Disfruta tu viaje al máximo",
  "x_review": "{0,choice,0#|1#{0} opinión|2#{0} opiniones|2<{0} opiniones|4<{0} opiniones|10#{0} opiniones|10<{0} opiniones}",
  "hub.category.h1.single": "Viajes en solitario en {0}",
  "hub.category.h2.custom": "Populares ahora",
  "lbg.section_theme_world_winners.lgbt": "Ganadores de la categoría LGBTQ+",
  "lbg.section_award_winners_theme_other": "Ganadores de otras categorías en {0}",
  "lbg.supplier_collected_charges": "+ cargo de {0} a pagar en el establecimiento",
  "in_destination": " en {0}",
  "lbg.section_properties_theme.vip_access": "Ganadores de la categoría VIP Access en {0}",
  "link_long_form_to_listiicle_single": "{0}: parte de la lista de {1} (lee más sobre {2} aquí)",
  "lbg.section_theme_world_winners.boutique": "Ganadores de la categoría Boutique",
  "lbg.section_properties_theme.spa_hotel": "Ganadores de la categoría Spa en {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Ganadores de la categoría Todo incluido",
  "hub.category.h2": "Guía de viaje de {0}",
  "lbg.properties.price_tooltip": "Este es el precio por noche más bajo disponible en nuestro sitio para los próximos 14 días.",
  "hub.category.h2.road_trips": "Mejores viajes por carretera en {0}",
  "hub.category.h1.ski": "Destinos para esquiar en {0}",
  "food": "Gastronomía",
  "lbg.theme.pet_friendly": "Aceptan mascotas",
  "lbg.theme.business": "Negocios",
  "hub.category.h1.nature": "Mejores viajes para una escapada a la naturaleza en {0}",
  "map": "Mapa",
  "category_highlight.see_all.information": "Más información",
  "hotel_prices_in": "Precios de hoteles en {0}",
  "hub.category.h1.nightlife": "Vida nocturna en {0}",
  "trust_module.free_cancellation.most_hotels": "en la mayoría de los hoteles*",
  "also_popular_in": "Otras zonas en {0}",
  "lbg.h1.destination_theme.business": "Ganadores de la categoría Negocios en {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Ganadores de la categoría Aceptan mascotas",
  "hub.category.h1.maps": "Mapas de {0}",
  "lbg.section_properties_theme.most_wanted": "Ganadores de la categoría Más solicitados en {0}",
  "category.nightlife": "Vida nocturna",
  "search_hotels_in_this_area": "Buscar hoteles<span class=\"extra-label\"> en esta zona</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 votos|2<{1} votos|4<{1} votos|10#10 votos|10<{1} votos})",
  "hub.category.h2.business": "Viajes de negocios más populares en {0}",
  "category.things_to_do": "Atracciones",
  "destination_travel_guide_by_category": "Guía de viaje de {0} por categoría",
  "hub.category.h1.families": "Viajes familiares en {0}",
  "lbg.section_theme_world_winners.ski": "Ganadores de la categoría Ski",
  "you_may_also_like": "También podría interesarte",
  "lbg.theme.sustainable": "Sustentables",
  "hub.category.h2.unusual": "Tesoros ocultos y lugares poco comunes más populares en {0}",
  "hub.category.h2.football": "Apoya a tu equipo de fútbol favorito y disfruta al máximo tu viaje",
  "top_hotel": "Hotel destacado",
  "lbg.h1.destination_theme.luxury": "Ganadores de la categoría Lujo en {0}",
  "top10_hotels_empty_alert": "En este momento, tenemos dificultades técnicas. <span>Te ofrecemos una disculpa.</span>",
  "special_deal": "Oferta especial",
  "lbg.h2.sustainable": "¡La sustentabilidad es importante! Echa un vistazo a estos establecimientos que ofrecen una estancia agradable.",
  "where_to_stay_in": "Dónde hospedarse en {0}",
  "start_planning_your_trip": "Organiza tu viaje",
  "good_for": "Categoría:",
  "category.hotels": "Hoteles",
  "x_accommodation_guide": "Guía de hospedaje en {0}",
  "lbg.theme.luxury": "Lujo",
  "hub.category.h2.sports": "Deportes y actividades más populares en {0}",
  "lbg.section_theme_world_winners.lgbtq": "Ganadores de la categoría LGBTQ",
  "see_more_neighbourhoods": "Ver más zonas",
  "category_highlight.title.nightlife": "A dónde ir y qué hacer de noche en {0}",
  "lbg.h1.destination_theme.lgbt": "Ganadores de la categoría LGBTQ+ en {0}",
  "hub.category.h1.blogs": "Blog de viajes a {0}",
  "lbg.h2.business": "Estos establecimientos están bien evaluados por las personas que viajan por negocios.",
  "hotels": "Hoteles",
  "lbg.h2.pet_friendly": "Los huéspedes recomiendan ampliamente estos establecimientos que aceptan mascotas.",
  "popular_landmarks_nearby": "Atracciones destacadas en la zona",
  "popular_extractions_in": "Atracciones destacadas en {0}",
  "lbg.h2.all_inclusive": "Estos establecimientos están bien evaluados y son ideales para relajarse.",
  "travel_guide_by_category_header": "{0}: lo mejor en {1}",
  "lbg.travel_between": "Viaja entre el {0} y el {1}",
  "keep_exploring": "Descubre más destinos",
  "what_you_need_to_know_before_you_go": "Lo que debes saber antes de tu viaje",
  "read_it": "Leer",
  "view_on_map": "Ver en el mapa",
  "lbg.h1.destination_theme.lgbtq": "Ganadores de la categoría LGBTQ en {0}",
  "lbg.h2.beach": "A los huéspedes les encanta hospedarse en la playa, en especial en estos establecimientos.",
  "lbg.h1.destination_theme.vip_access": "Ganadores de la categoría VIP Access en {0}",
  "see_all_accommodation": "Ver todas las opciones de hospedaje",
  "shoppers": "Compras",
  "whats_around": "Atracciones cerca de {0}",
  "group": "Grupos",
  "hub.category.h2.couples": "Viajes para parejas más populares en {0}",
  "hub.category.h1.shopping": "Compras en {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Comunidad de viajes</span>",
  "hub.category.h2.group": "Viajes para grupos más populares en {0}",
  "listicle_legal_msg": "Este artículo incluye opiniones del equipo editorial de Go Guides. Hoteles.com compensa a los autores de los textos que aparecen en este sitio. Esta compensación podría incluir viajes y otros costos.",
  "lbg.h1.destination_theme.cleanliness": "Ganadores de la categoría Limpieza en {0}",
  "menu.more": "Más",
  "lbg.section_properties_theme.lgbtq": "Ganadores de la categoría LGBTQ en {0}",
  "lbg.section_award_winners_destination": "Ganadores por destino",
  "lbg.terms_conditions": "Términos y condiciones",
  "hub.category.h2.nightlife": "Lo mejor de la vida nocturna en {0}",
  "hub.neighbourhood.h2": "Qué hacer en {0}",
  "lbg.section_award_winners_theme_destination": "Ganadores por categoría en {0}",
  "lbg.section_award_world_winners": "Ganadores a nivel mundial",
  "things_to_see_and_do_in_other_cities": "Actividades y atracciones en otras ciudades",
  "button.backToTop": "Volver arriba",
  "lbg.section_theme_world_winners.family": "Ganadores de la categoría Familiar",
  "hotels_near": "Hoteles cerca de {0}",
  "category.activity__sports_": "Actividades",
  "trust_module.get_reward_night": "Obtén una noche de regalo",
  "hub.category.h1.business": "Viajes de negocios en {0}",
  "category.food": "Gastronomía",
  "other_popular_destinations_in": "Otros destinos populares en {0}",
  "browse_by_category": "Información sobre {0} por categoría",
  "see_more_attactions": "Ver más atracciones",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Más información sobre {0}",
  "lbg.h1.destination_theme.most_wanted": "Ganadores de la categoría Más solicitados en {0}",
  "see_all_properties_in": "Ver establecimientos en {0}",
  "category.tours": "Tours",
  "hub.category.h1.sports": "Deportes y actividades en {0}",
  "where_will_you_go_next": "¿Cuál será tu próximo destino?",
  "cities": "Ciudades",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Ver guía",
  "lbg.section_properties_theme.ski": "Ganadores de la categoría Ski en {0}",
  "related_stories": "Artículos relacionados",
  "hub.destination.h2": "Lo que debes saber antes de tu viaje",
  "show_less": "Ver menos",
  "hub.category.h1.football": "La mejor guía de {0} si te apasiona el fútbol",
  "hub.category.h2.food": "Lo mejor de la gastronomía de {0}",
  "fallback_language_alert": "Esta página aún no se ha traducido a tu idioma.",
  "category_highlight.title.information": "Información de interés",
  "neighbourhood_guides_for_other_cities": "Guías de las mejores zonas en otras ciudades",
  "lbg.h2.hubpage": "Estos establecimientos están bien evaluados por los huéspedes y tienen muchas opiniones",
  "travel_advisory.title": "Alerta de viaje",
  "lbg.section_properties_theme.all_inclusive": "Ganadores de la categoría Todo incluido en {0}",
  "lbg.h2.boutique": "Si te gustan los establecimientos boutique, los huéspedes recomiendan ampliamente alojarse aquí.",
  "lbg.section_properties_theme.family": "Ganadores de la categoría Familiar en {0}",
  "hub.category.h2.families": "Los mejores viajes familiares en {0}",
  "from": "desde",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Ganadores de la categoría LGBTQ+ en {0}",
  "x_star": "{0,choice,0#|1#{0} estrella|1.5#{0} estrellas|2#{0} estrellas|2.5#{0} estrellas|3#{0} estrellas|3.5#{0} estrellas|4#{0} estrellas|4.5#{0} estrellas|5#{0} estrellas}",
  "trust_module.free_cancellation": "Cancelación gratis",
  "lbg.section_properties_theme.home_and_apartments": "Ganadores de la categoría Casas y departamentos en {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Autor colaborador</span>",
  "things_to_see_and_do_in": "Actividades y atracciones <span class=\"next-line\">en {0}</span>",
  "lbg.check_price": "Verificar precio",
  "hub.category.h2.blogs": "por expertos en viajes",
  "lbg.h2.home_and_apartments": "¿Quieres sentirte como en casa durante tu viaje? Echa un vistazo a estos establecimientos bien evaluados.",
  "hub.category.h2.information": "Información útil sobre {0}",
  "search_hotels_in": "Buscar hoteles<span class=\"extra-label\"> en {0}</span>",
  "hub.category.h2.nature": "Mejores viajes a la naturaleza en {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Ganadores de la categoría Casas y departamentos en {0}",
  "photo_by": "foto por {0}",
  "more_things_to_do": "Más cosas qué hacer",
  "lbg.section_properties_theme.beach": "Ganadores de la categoría Playa en {0}",
  "read_less": "Mostrar menos",
  "lbg.h2.spa_hotel": "Descansa y relájate en estos establecimientos bien evaluados por su spa.",
  "second_fold_related_stories.see_also": "También te pueden interesar",
  "hub.category.h2.history": "Lugares históricos más populares en {0}",
  "lbg.section_properties_theme.cleanliness": "Ganadores de la categoría Limpieza en {0}",
  "lbg.h1.destination_theme.boutique": "Ganadores de la categoría Boutique en {0}",
  "nightlife_b595ae4": "Vida nocturna",
  "lbg.h1.destination_theme.beach": "Ganadores de la categoría Playa en {0}",
  "trust_module.free_cancellation.disclaimer": "Algunos hoteles piden que canceles al menos de 24 a 48 horas antes del check-in. Obtén más información en el sitio web.",
  "lbg.h2.family": "Estos establecimientos están bien evaluados por los huéspedes y son ideales para familias.",
  "lbg.h2.lgbtq": "Estos establecimientos LGBTQ friendly están bien evaluados por los huéspedes.",
  "hub.category.h1.group": "Viajes para grupos en {0}",
  "popular_stories": "Artículos más populares",
  "toc": "Contenido",
  "hub.category.h2.shopping": "Los mejores lugares para comprar en {0}",
  "lbg.section_theme_world_winners.beach": "Ganadores de la categoría Playa",
  "page_score": "Calificación de la página",
  "lbg.section_theme_world_winners.sustainable": "Ganadores de la categoría Sustentables",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Nuestra Garantía de precio",
  "lbg.h1.destination_theme.all_inclusive": "Ganadores de la categoría Todo incluido en {0}",
  "top_destinations": "Destinos principales",
  "hotel_prices_near": "Precios de hoteles cerca de {0}",
  "lbg.score.cleanliness": "Limpieza:",
  "x_stars": "{0,choice,0#|1#{0} estrella|1<{0} estrellas|4<{0} estrellas|10<{0} estrellas}",
  "lbg.theme.all_inclusive": "Todo incluido",
  "hub.category.h1.tours": "Tours y excursiones en {0}",
  "lbg.section_award_winners_theme": "Ganadores por categoría",
  "guest_writer": "{0} <span>&#124;</span> <span>Autor invitado</span>",
  "start_planning_your_x_trip": "Organiza tu viaje a {0}",
  "lbg.section_theme_world_winners.business": "Ganadores de la categoría Negocios",
  "trending_now_in": "Lo más popular en {0}",
  "history": "Historia",
  "search_hotels": "Buscar  hoteles",
  "lbg.terms_conditions_details": "El criterio para elegir a los establecimientos ganadores en {0}, se basó en su alta puntuación en las evaluaciones de los huéspedes en {1} y que tuvieran un mínimo de 50 opiniones.",
  "see_nearby_properties": "Ver propiedades cercanas",
  "hub.category.h1.history": "Lugares históricos en {0}",
  "location": "Ubicación:",
  "lbg.section_theme_world_winners.luxury": "Ganadores de la categoría Lujo",
  "category_highlight.see_all.nightlife": "Ver más sobre vida nocturna",
  "also_popular": "Otras opciones populares",
  "hub.category.h2.things_to_do": "Las mejores actividades en {0}",
  "lbg.theme.family": "Familiar",
  "lbg.h1.destination_theme.pet_friendly": "Ganadores de la categoría Aceptan mascotas en {0}",
  "category_highlight.title.shopping": "Qué comprar y dónde en {0}",
  "price": "Precio:",
  "hub.category.h2.beach": "Las mejores playas de {0}",
  "neighbourhoods_in": "zonas en {0}",
  "business": "Negocios",
  "photo": "Fotos",
  "blog.see_all_stories": "Ver todas las historias",
  "category_highlight.see_all.food": "Ver más sobre gastronomía",
  "see_all_properties": "Ver todos los establecimientos",
  "lbg.theme.beach": "Playa",
  "what_to_see_and_do_in": "Qué ver y hacer en {0}",
  "hub.category.h2.single": "Viajes en solitario más populares en {0}",
  "attractions_in": "Lugares de interés en {0}",
  "read_more": "Mostrar más",
  "show_more": "Ver más",
  "hotels_near_this_landmark": "Hoteles cerca de esta atracción",
  "check_dates": "Revisar fechas",
  "featured_stories_and_fun_stuff": "Artículos destacados y actividades interesantes",
  "phone": "Teléfono:",
  "lbg.section_search_header": "Encuentra establecimientos ganadores",
  "category_highlight.title.food": "Qué comer y dónde en {0}",
  "see_all_things_to_do": "Ver más actividades",
  "lbg.section_properties_theme.sustainable": "Ganadores de la categoría Sustentables en {0}",
  "lbg.h1.hubpage": "Ganadores de la categoría Favoritos de los huéspedes en {0}",
  "lbg.section_theme_world_winners.spa_hotel": "Ganadores de la categoría Spa",
  "category.maps": "Mapas",
  "lbg.h1.destination_theme.family": "Ganadores de la categoría Familiar en {0}",
  "lbg.section_theme_world_winners.cleanliness": "Ganadores de la categoría Limpieza",
  "lbg.section_theme_world_winners.vip_access": "Ganadores de la categoría VIP Access",
  "trust_module.get_reward_night.night_you_stay": "por cada 10 noches de estancia",
  "modified": "modificada",
  "lbg.section_properties": "Ganadores en {0}",
  "trust_module.header": "¿Por qué reservar con Hoteles.com?",
  "hub.category.h2.luxury": "Viajes de lujo más populares en {0}",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Ganadores de la categoría Spa en {0}",
  "lbg.h2.most_wanted": "Los huéspedes recomiendan mucho estos establecimientos para disfrutar una estancia perfecta.",
  "top10_hotels_incomplete_alert": "En este momento, hay una alta demanda por lo que es posible que algunos establecimientos no se muestren. <span>Te ofrecemos una disculpa.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Viaja con frecuencia</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Ganadores de la categoría Casas y departamentos",
  "loved_by_guests_collection_in": "Lista de Favoritos de los huéspedes en {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Mapas útiles de {0}",
  "opening_hours": "Horario",
  "hub.category.h2.photos": "Viajes ideales para fotografía más populares en {0}",
  "lbg.theme.most_wanted": "Más solicitados",
  "blog.stories_container": "Historias de colaboradores",
  "hub.category.h2.budget": "Viajes económicos más populares en {0}",
  "lbg.section_properties_theme.business": "Ganadores de la categoría Negocios en {0}",
  "not_sure_where_to_stay": "¿Aún no sabes dónde hospedarte?",
  "category.information": "Información"
};