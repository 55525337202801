import React from 'react';
import { UitkLink } from "uitk-react-link";
import { UitkHeading } from "uitk-react-text";
var ListicleItemHeader = function (_a) {
  var hideNumber = _a.hideNumber,
    number = _a.number,
    item = _a.item,
    itemTargetUrl = _a.itemTargetUrl,
    linkTargetPdp = _a.linkTargetPdp,
    linkRel = _a.linkRel;
  return React.createElement("div", {
    className: "header-wrap"
  }, !hideNumber && React.createElement("div", {
    className: "index-num"
  }, number), React.createElement("div", {
    className: "header-inner-wrap"
  }, itemTargetUrl ? React.createElement(UitkLink, {
    inline: true
  }, React.createElement("a", {
    href: itemTargetUrl,
    target: linkTargetPdp,
    rel: linkRel
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 5
  }, item.name))) : React.createElement(UitkHeading, {
    tag: "h2",
    size: 5
  }, item.name), item.tagLine && React.createElement(UitkHeading, {
    tag: "h3",
    size: 6
  }, item.tagLine)));
};
export default ListicleItemHeader;