var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState } from 'react';
import { checkValue } from 'components/functions';
import KeepExploringSub from './keep-exploring-sub';
import { UitkExpandoPeek } from 'uitk-react-expando';
import { localization } from 'src/utils/localization';
import { Viewport, ViewLarge, ViewSmall } from "@shared-ui/viewport-context";
var KeepExploringCountry = function (props) {
  var listing = props.listing;
  var _a = __read(useState(false), 2),
    isVisible = _a[0],
    setIsVisible = _a[1];
  var loc = new localization();
  if (!checkValue(listing.country)) {
    return null;
  } else {
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: "list-container main-destinations"
    }, React.createElement(KeepExploringSub, {
      level: "neighbourhood",
      listing: listing.country
    }), React.createElement(KeepExploringSub, {
      level: "main-city",
      listing: listing.country
    }), React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(UitkExpandoPeek, {
      isVisible: isVisible,
      handleClick: function () {
        return setIsVisible(function (prevIsVisible) {
          return !prevIsVisible;
        });
      },
      lines: 6,
      whitespace: false,
      expandLabel: loc.getLocalizedText('show_more'),
      collapseLabel: loc.getLocalizedText('show_less')
    }, React.createElement(KeepExploringSub, {
      level: "city",
      listing: listing.country
    }))), React.createElement(ViewLarge, null, React.createElement(KeepExploringSub, {
      level: "city",
      listing: listing.country
    }))), React.createElement(KeepExploringSub, {
      level: "region",
      listing: listing.country,
      listingClassName: "list-region"
    }), React.createElement(KeepExploringSub, {
      level: "country",
      listing: listing.country
    })));
  }
};
export default KeepExploringCountry;