import React from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { checkValue } from '../../../functions';
var ButtonNearbyProperties = function (_a) {
  var itemCtaUrl = _a.itemCtaUrl,
    itemCtaKey = _a.itemCtaKey,
    pdpLink = _a.pdpLink;
  return React.createElement(React.Fragment, null, (checkValue(itemCtaUrl) || checkValue(pdpLink)) && React.createElement("div", {
    className: "button-wrap"
  }, React.createElement(UitkPrimaryButton, {
    className: "nearby-properties ".concat(pdpLink ? 'book-now' : ''),
    tag: "a",
    href: itemCtaUrl,
    type: "button",
    target: "_blank",
    rel: "noreferrer"
  }, itemCtaKey)));
};
export default ButtonNearbyProperties;