import React from 'react';
import { checkValue } from 'components/functions';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import CarouselComponent from '../generic/render-carousel';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
var GoGuidesOtherCategoriesComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchOtherCategories(goGuidesStore.pageUrlData);
  var moduleName = 'browse by category';
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName
    }
  };
  var loc = new localization();
  var categories = [];
  var headerText = '';
  if (checkValue(contentData)) {
    var categoriesData = contentData.categoriesData,
      destinationName = contentData.destinationName;
    categories = checkValue(categoriesData === null || categoriesData === void 0 ? void 0 : categoriesData.category) ? categoriesData.category : [];
    headerText = loc.getLocalizedText('browse_by_category', destinationName);
  }
  var carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Blog Stories Carousel',
    itemsVisible: {
      sm: 1,
      md: 4,
      lg: 6,
      xl: 6
    },
    pageBy: 6,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience
  };
  var CatetoryItem = function (_a) {
    var _b;
    var item = _a.item;
    var categoryName = loc.getLocalizedText("category.".concat(item.categoryCode));
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=400&height=267',
          large: '&width=400&height=267'
        }
      }
    };
    return React.createElement(UitkCard, {
      className: 'item-wrap'
    }, React.createElement(UitkFigure, {
      className: "img-wrap",
      ratio: UitkFigureAspectRatioType.R1_1
    }, React.createElement("span", null, categoryName), React.createElement(RenderImage, {
      imgProps: imageProps
    })), React.createElement(UitkCardLink, null, React.createElement("a", {
      href: item.targetUrl
    })));
  };
  var carouselData = categories.map(function (item, index) {
    return React.createElement(CatetoryItem, {
      key: index,
      item: item
    });
  });
  return React.createElement(React.Fragment, null, checkValue(categories) && checkValue(categories.length > 0) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement("section", {
    className: "theme-hub teaser-carousel sec-control"
  }, React.createElement("div", {
    className: "container-wrap  row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement(UitkHeading, {
    size: 5,
    tag: "h2"
  }, headerText)), React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement("div", {
    className: "category-container"
  }, React.createElement("ul", {
    className: "row item-list-container"
  }, categories.map(function (item, index) {
    return React.createElement("li", {
      key: index,
      className: "item-wrap col-6"
    }, React.createElement(CatetoryItem, {
      item: item
    }));
  })))), React.createElement(ViewMedium, null, React.createElement(CarouselComponent, {
    data: carouselData,
    config: carouselConfigProps
  }))))))));
};
export default GoGuidesOtherCategoriesComponent;