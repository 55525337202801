import { checkValue } from 'components/functions';
import React from 'react';
import { UitkHeading, UitkParagraph, UitkText } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import MapComponent from '../generic/maps';
import { getLocalizedText } from 'src/utils/helper';
var GoGuidesLocationInfoComponent = function (props) {
  var goGuidesStore = useStores().goGuidesStore;
  var isListicle = props.isListicle;
  var info;
  if (!checkValue(props.info)) {
    info = {
      location: null,
      phoneNumber: null,
      openingHours: null,
      price: null,
      title: null
    };
  }
  var contentData;
  if (isListicle) {
    contentData = props;
  } else {
    contentData = goGuidesStore.fetchLocationInfo(goGuidesStore.pageUrlData);
  }
  if (checkValue(contentData)) {
    info = contentData.info;
  }
  var checkInfo = function () {
    var location = info.location,
      openingHours = info.openingHours,
      phoneNumber = info.phoneNumber,
      price = info.price;
    return checkValue(location) || checkValue(openingHours) || checkValue(phoneNumber) || checkValue(price);
  };
  var renderInfoItem = function (property, label) {
    if (info && info[property]) {
      return React.createElement(UitkParagraph, {
        size: 1
      }, React.createElement(UitkText, {
        inline: "strong"
      }, label, " "), info[property]);
    }
    return null;
  };
  return React.createElement(React.Fragment, null, checkValue(info) && React.createElement("div", {
    className: "info-bullet-wrap"
  }, React.createElement(UitkHeading, {
    tag: "h3"
  }, info.title), renderInfoItem('location', getLocalizedText('location')), renderInfoItem('openingHours', getLocalizedText('open')), renderInfoItem('phoneNumber', getLocalizedText('phone')), renderInfoItem('price', getLocalizedText('price'))), checkValue(info.geo) && checkValue(!isListicle) && React.createElement(MapComponent, {
    name: info.title,
    geo: info.geo,
    openMap: true
  }));
};
export default GoGuidesLocationInfoComponent;