import React from 'react';
import { useStores } from 'src/utils/useStore';
import { checkValue } from 'src/components/functions';
import HomePage from 'src/components/pages/home';
import DestinationHubPage from 'src/components/pages/destination-hub';
import CategoryHubPage from 'src/components/pages/category-hub';
import ListiclePage from 'src/components/pages/listicle';
import LongFormPage from 'src/components/pages/long-form';
export var GoGuidesView = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var renderView = function () {
    var contentTypeId = 0;
    if (checkValue(goGuidesStore.pageUrlData) && checkValue(goGuidesStore.pageUrlData["contentTypeId"])) {
      contentTypeId = goGuidesStore.pageUrlData["contentTypeId"];
    }
    switch (contentTypeId) {
      case 1:
        {
          return React.createElement(HomePage, null);
        }
      case 2:
        {
          return React.createElement(React.Fragment, null, React.createElement(DestinationHubPage, null));
        }
      case 3:
        {
          return React.createElement(CategoryHubPage, null);
        }
      case 4:
        {
          return React.createElement(ListiclePage, null);
        }
      case 5:
        {
          return React.createElement(LongFormPage, null);
        }
      case 98:
        {
          return React.createElement("pre", null, JSON.stringify(goGuidesStore.jsonData, null, 2));
        }
      default:
        {
          window.location.href = "/404/?rfrr=goguides";
        }
    }
  };
  return renderView();
};
GoGuidesView.displayName = 'GoGuidesView';
export default GoGuidesView;