import React from 'react';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { checkValue } from 'src/components/functions';
import { localization } from 'utils/localization';
var SegmentComponent = function (_a) {
  var data = _a.data,
    i18nKey = _a.i18nKey,
    _b = _a.pill,
    pill = _b === void 0 ? false : _b;
  var pillClass = checkValue(pill) ? 'view-pill' : '';
  var loc = new localization();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "tag-container ".concat(pillClass)
  }, checkValue(i18nKey) ? React.createElement("label", null, React.createElement(UitkIcon, {
    className: "icon",
    name: "local_offer",
    size: UitkIconSize.MEDIUM
  }), i18nKey) : React.createElement(UitkIcon, {
    className: "icon",
    name: "local_offer",
    size: UitkIconSize.MEDIUM
  }), React.createElement("ul", null, data.map(function (item, index) {
    var segmentCode = item.segmentCode;
    var segmentLabel = checkValue(pill) ? loc.getSpecificLocText('en_gb', "category.".concat(segmentCode)) : loc.getLocalizedText(segmentCode);
    return checkValue(segmentLabel) && React.createElement("li", {
      key: index
    }, item.targetUrl ? React.createElement(UitkLink, {
      inline: true
    }, React.createElement("a", {
      href: item.targetUrl
    }, React.createElement("span", null, segmentLabel))) : React.createElement("span", null, segmentLabel));
  }))));
};
export default SegmentComponent;