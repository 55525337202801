import React from 'react';
import { MasterPage } from 'components/common/template/master-page';
import GoGuidesFeaturedStoriesComponent from 'components/modules/featured-stories/featured-stories';
import GoGuidesHeroComponent from 'components/modules/hero/hero';
import GoGuidesIntroComponent from 'components/modules/intro/intro';
import GoGuidesKeepExploringComponent from 'components/modules/keep-exploring/keep-exploring';
import GoGuidesTopDestinationComponent from 'components/modules/top-destination/top-destination';
import GoGuidesSearchWizard from 'components/modules/search-wizard/search-wizard';
import GoGuidesBackToTop from '../modules/back-to-top/back-to-top';
var HomePage = function () {
  return React.createElement(MasterPage, null, React.createElement("div", {
    className: "page-container"
  }, React.createElement(GoGuidesHeroComponent, null), React.createElement("div", {
    className: "hub-body"
  }, React.createElement(GoGuidesIntroComponent, null), React.createElement(GoGuidesTopDestinationComponent, null), React.createElement(GoGuidesFeaturedStoriesComponent, null), React.createElement(GoGuidesSearchWizard, null), React.createElement(GoGuidesKeepExploringComponent, {
    isHomePage: true
  })), React.createElement(GoGuidesBackToTop, null)));
};
export default HomePage;