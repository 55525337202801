import { useStores } from './useStore';
import { AVAILABLE_LANGUAGES } from './localization-data';
var localization = /** @class */function () {
  function localization() {
    this.locale = 'en_gb';
    this.availableLanguages = [];
    var context = useStores().context;
    this.locale = context === null || context === void 0 ? void 0 : context.locale;
    this.availableLanguages = AVAILABLE_LANGUAGES[this.locale.toLocaleLowerCase()];
  }
  localization.prototype.getLocalizedText = function (msgKey) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    var msg = this.availableLanguages[msgKey];
    if (msg && args && args.length) {
      args.map(function (arg, idx) {
        msg = msg.replace("{".concat(idx, "}"), arg);
      });
    }
    return msg;
  };
  localization.prototype.getSpecificLocText = function (locale, msgKey) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      args[_i - 2] = arguments[_i];
    }
    var msg = AVAILABLE_LANGUAGES[locale.toLocaleLowerCase()][msgKey];
    if (msg && args && args.length) {
      args.map(function (arg, idx) {
        msg = msg.replace("{".concat(idx, "}"), arg);
      });
    }
    return msg;
  };
  return localization;
}();
export { localization };