export default {
  "current_deal": "Huidige aanbieding",
  "lbg.section_properties_theme.pet_friendly": "Winnaars diervriendelijkheidsprijs in {0}",
  "address": "Adres",
  "hub.homepage.h2": "Zo veel plaatsen om te zien, maar zo weinig tijd ...",
  "search_hotels_near_this_landmark": "Hotels<span class=\"extra-label\"> nabij deze bezienswaardigheid</span> vinden",
  "hub.category.h1.things_to_do": "Activiteiten − {0}",
  "lbg.h2.vip_access": "VIP Access-accommodaties zijn geselecteerd voor hun hoog beoordeelde verblijven en uitzonderlijke ervaringen.",
  "lbg.theme.cleanliness": "Netheid",
  "lbg.theme.lgbtq": "Lhbtq",
  "load_more": "Meer laden",
  "link_long_form_to_listiicle_multiple": "{0} - één van de hoogtepunten van ‘{1}’ en ‘{2}’. Lees hier alles over {3}.",
  "lbg.section_properties_theme.boutique": "Winnaars boetiekprijs in {0}",
  "lbg.h2.luxury": "Verblijf in totale luxe. Deze accommodaties zijn dan ook populair bij gasten.",
  "hub.category.h1.information": "Informatie over {0}",
  "families": "Gezinnen",
  "trending_now": "Nu populair",
  "lbg.from": "Vanaf",
  "hub.category.h1.road_trips": "De beste rondreizen in {0}",
  "lbg.h2.cleanliness": "Deze accommodaties hebben een geweldige algemene gastenscore en de hoogste gastenscore voor hygiëne.",
  "single": "Single",
  "couples": "Stellen",
  "trust_module.price_guarantee.find_out_more": "Meer informatie",
  "top_destinations_in": "Topbestemmingen in {0}",
  "hub.category.h1.couples": "Romantische reizen − {0}",
  "hub.category.h2.tours": "Beste tours − {0}",
  "hub.category.h1.photos": "Fotogenieke reizen − {0}",
  "category.photos": "Foto’s",
  "hub.category.h1.budget": "Voordelige reizen − {0}",
  "hub.category.h2.ski": "Beste skibestemmingen − {0}",
  "lbg.section_theme_world_winners.most_wanted": "Winnaars ‘favoriete keuze’-prijs",
  "lbg.theme.home_and_apartments": "Huizen & appartementen",
  "hub.category.h1.luxury": "Luxueuze reizen − {0}",
  "hub.category.h2.adventure": "Populairste actieve reizen − {0}",
  "hotels_in": "Hotels in {0}",
  "lbg.section_search_winners": "Zoek meer prijswinnaars",
  "see_more_hotels_in": "Meer hotels in {0} bekijken",
  "hub.category.h1.beach": "Stranden − {0}",
  "open": "Openingstijden:",
  "enter_your_destination": "Voer uw bestemming in",
  "best_of": "Het beste van {0}",
  "top10_hotels_missing_properties": "Sommige accommodaties worden mogelijk momenteel niet getoond. Onze excuses voor het ongemak.",
  "adventure": "Avontuur",
  "lbg.h1.destination_theme.sustainable": "Winnaars Duurzaamheidsprijs in {0}",
  "luxury": "Luxueus",
  "expand_map_view": "Kaartweergave uitvouwen",
  "book_now": "Nu boeken",
  "lbg.h1.destination_theme.ski": "Winnaars Wintersportprijs in {0}",
  "hub.category.h1.food": "Eten en dineren − {0}",
  "category.blogs": "Blog",
  "budget": "Goedkoop",
  "popular_neighbourhoods_in": "Populaire buurten in {0}",
  "hub.category.h1.unusual": "Verborgen parels en ongewone plekken − {0}",
  "see_all_accommodation_in": "Accommodatie in {0} bekijken",
  "hub.category.h1.adventure": "Actieve reizen − {0}",
  "category_highlight.see_all.shopping": "Meer over winkelen",
  "lbg.section_most_popular_destination": "Populairste bestemmingen",
  "lbg.section_properties_theme.luxury": "Winnaars luxeprijs in {0}",
  "unusual": "Bijzonder",
  "category.shopping": "Winkelen",
  "make_the_most_of_your_trip": "Haal het meeste uit je reis",
  "x_review": "{0,choice,0#|1#{0} beoordeling|2#{0} beoordelingen|2<{0} beoordelingen|4<{0} beoordelingen|10#{0} beoordelingen|10<{0} beoordelingen}",
  "hub.category.h1.single": "Solo reizen − {0}",
  "hub.category.h2.custom": "Nu populair",
  "lbg.section_theme_world_winners.lgbt": "Winnaars in de categorie lhbtq+",
  "lbg.section_award_winners_theme_other": "Andere prijswinnaarsthema’s in {0}",
  "lbg.supplier_collected_charges": "+ {0} aan toeslagen te betalen bij de accommodatie",
  "in_destination": " in {0}",
  "lbg.section_properties_theme.vip_access": "Winnaars in de categorie VIP Access in {0}",
  "link_long_form_to_listiicle_single": "{0} - één van de hoogtepunten van ‘{1}’. Lees hier alles over {2}.",
  "lbg.section_theme_world_winners.boutique": "Winnaars boetiekprijs",
  "lbg.section_properties_theme.spa_hotel": "Winnaars spaprijs in {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Winnaars all-inclusive-prijs",
  "hub.category.h2": "Reisgids voor {0}",
  "lbg.properties.price_tooltip": "Dit is de laagste prijs per nacht op onze site voor de volgende 14 dagen.",
  "hub.category.h2.road_trips": "De beste rondreizen in {0}",
  "hub.category.h1.ski": "Skibestemmingen − {0}",
  "food": "Eten",
  "lbg.theme.pet_friendly": "Huisdiervriendelijkheid",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "De beste reizen in {0} voor natuurliefhebbers",
  "map": "Kaart",
  "category_highlight.see_all.information": "Meer informatie",
  "hotel_prices_in": "Hotelprijzen in {0}",
  "hub.category.h1.nightlife": "Uitgaansactiviteiten − {0}",
  "trust_module.free_cancellation.most_hotels": "bij het merendeel van de hotels*",
  "also_popular_in": "Ook populair in {0}",
  "lbg.h1.destination_theme.business": "Winnaars Businessprijs in {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Winnaars diervriendelijkheidsprijs",
  "hub.category.h1.maps": "Kaart van {0}",
  "lbg.section_properties_theme.most_wanted": "Winnaars ‘favoriete keuze’-prijs in {0}",
  "category.nightlife": "Nachtleven",
  "search_hotels_in_this_area": "Hotels<span class=\"extra-label\"> in deze buurt</span> vinden",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 stem|2#2 stemmen|2<{1} stemmen|4<{1} stemmen|10#10 stemmen|10<{1} stemmen})",
  "hub.category.h2.business": "Populairste zakenreizen − {0}",
  "category.things_to_do": "Attracties & bezienswaardigheden",
  "destination_travel_guide_by_category": "Reisgids voor {0} per categorie",
  "hub.category.h1.families": "Familiereis − {0}",
  "lbg.section_theme_world_winners.ski": "Winnaars wintersportprijs",
  "you_may_also_like": "Misschien vind je dit ook leuk:",
  "lbg.theme.sustainable": "Duurzaamheid",
  "hub.category.h2.unusual": "Populairste verborgen parels en ongewone plekken − {0}",
  "hub.category.h2.football": "Supporter voor je voetbalteam en beleef een onvergetelijke reis!",
  "top_hotel": "Tophotel",
  "lbg.h1.destination_theme.luxury": "Winnaars Luxeprijs in {0}",
  "top10_hotels_empty_alert": "We ondervinden op dit moment een technisch probleem. <span>Onze excuses voor het ongemak.</span>",
  "special_deal": "Speciale aanbieding",
  "lbg.h2.sustainable": "Het draait allemaal om duurzaamheid! Geniet van een geweldig verblijf bij deze accommodaties.",
  "where_to_stay_in": "Accommodaties in {0}",
  "start_planning_your_trip": "Begin met het plannen van je reis",
  "good_for": "Aanbevolen voor:",
  "category.hotels": "Hotels",
  "x_accommodation_guide": "Accommodatiegids van {0}",
  "lbg.theme.luxury": "Luxe",
  "hub.category.h2.sports": "Populairste sporten en activiteiten − {0}",
  "lbg.section_theme_world_winners.lgbtq": "Winnaars in de categorie LGBTQ",
  "see_more_neighbourhoods": "Meer buurten bekijken",
  "category_highlight.title.nightlife": "Het nachtleven in {0}",
  "lbg.h1.destination_theme.lgbt": "Winnaars in de categorie lhbtq+ in {0}",
  "hub.category.h1.blogs": "Reisblog over {0}",
  "lbg.h2.business": "Deze accommodaties ontvingen hoge gastenscores van zakenreizigers.",
  "hotels": "Hotels",
  "lbg.h2.pet_friendly": "Deze accommodaties werden hoog beoordeeld door gasten met huisdieren.",
  "popular_landmarks_nearby": "Populaire bezienswaardigheden in de buurt",
  "popular_extractions_in": "Populaire attracties in {0}",
  "lbg.h2.all_inclusive": "Bij deze hoog beoordeelde accommodaties kun je heerlijk ontspannen.",
  "travel_guide_by_category_header": "{1}: {0}",
  "lbg.travel_between": "Reis tussen {0} en {1}",
  "keep_exploring": "Meer verkennen",
  "what_you_need_to_know_before_you_go": "Belangrijke info voor vertrek",
  "read_it": "Lezen",
  "view_on_map": "Op een kaart bekijken",
  "lbg.h1.destination_theme.lgbtq": "Winnaars in de categorie LGBTQ in {0}",
  "lbg.h2.beach": "Gasten zijn dol op een strandvakantie en verblijven dan graag bij deze accommodaties.",
  "lbg.h1.destination_theme.vip_access": "Winnaars in de categorie VIP Access in {0}",
  "see_all_accommodation": "Alle accommodaties bekijken",
  "shoppers": "Shoppers",
  "whats_around": "In de buurt van {0}",
  "group": "Groep",
  "hub.category.h2.couples": "Populairste romantische reizen − {0}",
  "hub.category.h1.shopping": "Shopping − {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Reizigersgemeenschap</span>",
  "hub.category.h2.group": "Populairste groepsreizen − {0}",
  "listicle_legal_msg": "Dit artikel bevat persoonlijke meningen van de Go Guides-redactie. Hotels.com compenseert auteurs voor hun artikels die op deze site verschijnen. Een dergelijke vergoeding kan reis- en andere kosten omvatten.",
  "lbg.h1.destination_theme.cleanliness": "Winnaars in de categorie hygiëne in {0}",
  "menu.more": "Meer",
  "lbg.section_properties_theme.lgbtq": "Winnaars in de categorie LGBTQ in {0}",
  "lbg.section_award_winners_destination": "Prijswinnaars per bestemming",
  "lbg.terms_conditions": "Voorwaarden & Bepalingen",
  "hub.category.h2.nightlife": "Beste uitgaansactiviteiten − {0}",
  "hub.neighbourhood.h2": "{0} verkennen",
  "lbg.section_award_winners_theme_destination": "Prijswinnaars per thema in {0}",
  "lbg.section_award_world_winners": "Wereldwijde prijswinnaars",
  "things_to_see_and_do_in_other_cities": "Activiteiten en bezienswaardigheden in andere steden",
  "button.backToTop": "Omhoog",
  "lbg.section_theme_world_winners.family": "Winnaars familieprijs",
  "hotels_near": "Hotels nabij {0}",
  "category.activity__sports_": "Tours",
  "trust_module.get_reward_night": "Ontvang een bonusnacht",
  "hub.category.h1.business": "Zakenreizen − {0}",
  "category.food": "Eten",
  "other_popular_destinations_in": "Andere populaire bestemmingen in {0}",
  "browse_by_category": "Doorzoek {0} per categorie",
  "see_more_attactions": "Meer attracties bekijken",
  "lbg.theme.lgbt": "Lhbtq+",
  "more_information_about": "Meer informatie over {0}",
  "lbg.h1.destination_theme.most_wanted": "Winnaars ‘Favoriete keuze’-prijs in {0}",
  "see_all_properties_in": "Alle accommodaties in {0} bekijken",
  "category.tours": "Tours",
  "hub.category.h1.sports": "Sporten en activiteiten − {0}",
  "where_will_you_go_next": "Waar ga je deze keer naartoe?",
  "cities": "Steden",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Gids bekijken",
  "lbg.section_properties_theme.ski": "Winnaars wintersportprijs in {0}",
  "related_stories": "Gerelateerde verhalen",
  "hub.destination.h2": "Belangrijke info voor vertrek",
  "show_less": "Minder weergeven",
  "hub.category.h1.football": "De ultieme gids voor {0} voor echte voetbalfans",
  "hub.category.h2.food": "Beste eetgelegenheden − {0}",
  "fallback_language_alert": "Sorry, deze pagina is nog niet in je taal vertaald...",
  "category_highlight.title.information": "Nuttige informatie",
  "neighbourhood_guides_for_other_cities": "Buurtgidsen voor andere steden",
  "lbg.h2.hubpage": "Deze fantastische accommodaties ontvingen hoge gastenscores en veel beoordelingen.",
  "travel_advisory.title": "Reisnotificatie",
  "lbg.section_properties_theme.all_inclusive": "Winnaars all-inclusive-prijs in {0}",
  "lbg.h2.boutique": "Als je van boetiekaccommodaties houdt, raden gasten je hier een verblijf aan.",
  "lbg.section_properties_theme.family": "Winnaars familieprijs in {0}",
  "hub.category.h2.families": "Beste familiereis − {0}",
  "from": "van",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Winnaars in de categorie lhbtq+ in {0}",
  "x_star": "{0,choice,0#|1#{0} ster|1.5#{0} sterren|2#{0} sterren|2.5#{0} sterren|3#{0} sterren|3.5#{0} sterren|4#{0} sterren|4.5#{0} sterren|5#{0} sterren}",
  "trust_module.free_cancellation": "Gratis annulering",
  "lbg.section_properties_theme.home_and_apartments": "Winnaars huizen- & appartementenprijs in {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Schrijver</span>",
  "things_to_see_and_do_in": "Activiteiten en bezienswaardigheden <span class=\"next-line\">in {0}</span>",
  "lbg.check_price": "Prijs controleren",
  "hub.category.h2.blogs": "door ervaren reizigers",
  "lbg.h2.home_and_apartments": "Op zoek naar een verblijf waar je je helemaal thuis voelt? Bekijk deze hoog beoordeelde accommodaties.",
  "hub.category.h2.information": "Handige informatie over {0}",
  "search_hotels_in": "Hotels <span class=\"extra-label\"> in {0}</span> vinden",
  "hub.category.h2.nature": "De beste natuurreizen in {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Winnaars Huizen- & Appartementenprijs in {0}",
  "photo_by": "foto door {0}",
  "more_things_to_do": "Meer activiteiten",
  "lbg.section_properties_theme.beach": "Winnaars strandprijs in {0}",
  "read_less": "Minder lezen",
  "lbg.h2.spa_hotel": "Kom helemaal tot rust bij deze hoog beoordeelde accommodaties die bekend staan om hun spa’s.",
  "second_fold_related_stories.see_also": "Bekijk ook dit",
  "hub.category.h2.history": "Populairste historische plekken − {0}",
  "lbg.section_properties_theme.cleanliness": "Winnaars in de categorie hygiëne in {0}",
  "lbg.h1.destination_theme.boutique": "Winnaars Boetiekprijs in {0}",
  "nightlife_b595ae4": "Nachtleven",
  "lbg.h1.destination_theme.beach": "Winnaars Strandprijs in {0}",
  "trust_module.free_cancellation.disclaimer": "Bij sommige hotels dien je ten minste 24-48 uur vóór het inchecken te annuleren. Details staan op onze site.",
  "lbg.h2.family": "Deze accommodaties ontvingen hoge gastenscores en zijn erg gezinsvriendelijk.",
  "lbg.h2.lgbtq": "Deze lhbtq-vriendelijke accommodaties ontvingen hoge gastenscores van gasten.",
  "hub.category.h1.group": "Groepsreizen − {0}",
  "popular_stories": "Populaire verhalen",
  "toc": "Inhoud",
  "hub.category.h2.shopping": "Beste winkelplekken − {0}",
  "lbg.section_theme_world_winners.beach": "Winnaars strandprijs",
  "page_score": "Score van deze pagina",
  "lbg.section_theme_world_winners.sustainable": "Winnaars duurzaamheidsprijs",
  "lbg.theme.boutique": "Boetiek",
  "trust_module.price_guarantee": "Onze prijsgarantie",
  "lbg.h1.destination_theme.all_inclusive": "Winnaars All-inclusive-prijs in {0}",
  "top_destinations": "Topbestemmingen",
  "hotel_prices_near": "Hotelprijzen nabij {0}",
  "lbg.score.cleanliness": "Netheid:",
  "x_stars": "{0,choice,0#|1#{0} ster |1<{0} sterren|4<{0} sterren|10<{0} sterren}",
  "lbg.theme.all_inclusive": "All-inclusive",
  "hub.category.h1.tours": "Tours en dagexcursies − {0}",
  "lbg.section_award_winners_theme": "Prijswinnaars per thema",
  "guest_writer": "{0} <span>&#124;</span> <span>Gastschrijver</span>",
  "start_planning_your_x_trip": "Begin met het plannen van je reis naar {0}",
  "lbg.section_theme_world_winners.business": "Winnaars businessprijs",
  "trending_now_in": "Nu populair in {0}",
  "history": "Geschiedenis",
  "search_hotels": "Hotels vinden",
  "lbg.terms_conditions_details": "De criteria voor de prijswinnende accommodaties in {0} zijn gebaseerd op hoge gastenscores in {1} en minimaal 50 gastenbeoordelingen.",
  "see_nearby_properties": "Nabijgelegen accommodaties bekijken",
  "hub.category.h1.history": "Historische plekken − {0}",
  "location": "Locatie:",
  "lbg.section_theme_world_winners.luxury": "Winnaars luxeprijs",
  "category_highlight.see_all.nightlife": "Meer over het nachtleven",
  "also_popular": "Ook populair",
  "hub.category.h2.things_to_do": "Beste activiteiten − {0}",
  "lbg.theme.family": "Familie",
  "lbg.h1.destination_theme.pet_friendly": "Winnaars Diervriendelijkheidsprijs in {0}",
  "category_highlight.title.shopping": "Winkelen in {0}",
  "price": "Prijs:",
  "hub.category.h2.beach": "{0} - de beste stranden",
  "neighbourhoods_in": "buurten in {0}",
  "business": "Zakelijk",
  "photo": "Foto",
  "blog.see_all_stories": "Alle artikels bekijken",
  "category_highlight.see_all.food": "Meer over eten & drinken",
  "see_all_properties": "Alle accommodaties bekijken",
  "lbg.theme.beach": "Strand",
  "what_to_see_and_do_in": "Activiteiten in {0}",
  "hub.category.h2.single": "Populairste soloreizen − {0}",
  "attractions_in": "Attracties in {0}",
  "read_more": "Lees meer",
  "show_more": "Meer weergeven",
  "hotels_near_this_landmark": "Hotels nabij deze bezienswaardigheid",
  "check_dates": "Datums controleren",
  "featured_stories_and_fun_stuff": "Uitgelichte verhalen & leuke dingen",
  "phone": "Telefoonnummer:",
  "lbg.section_search_header": "Zoek naar prijswinnaars",
  "category_highlight.title.food": "Uit eten in {0}",
  "see_all_things_to_do": "Alle activiteiten bekijken",
  "lbg.section_properties_theme.sustainable": "Winnaars duurzaamheidsprijs in {0}",
  "lbg.h1.hubpage": "Winnaars ‘Loved by Guests’-prijs {0}",
  "lbg.section_theme_world_winners.spa_hotel": "Winnaars spaprijs",
  "category.maps": "Kaarten",
  "lbg.h1.destination_theme.family": "Winnaars Familieprijs in {0}",
  "lbg.section_theme_world_winners.cleanliness": "Winnaars in de categorie hygiëne",
  "lbg.section_theme_world_winners.vip_access": "Winnaars in de categorie VIP Access",
  "trust_module.get_reward_night.night_you_stay": "voor elke 10 nachten die je verblijft",
  "modified": "bewerkt",
  "lbg.section_properties": "Prijswinnaars in {0}",
  "trust_module.header": "Waarom met Hotels.com boeken?",
  "hub.category.h2.luxury": "Populairste luxueuze reizen − {0}",
  "lbg.theme.ski": "Wintersport",
  "lbg.h1.destination_theme.spa_hotel": "Winnaars Spaprijs in {0}",
  "lbg.h2.most_wanted": "Gasten raden deze accommodaties aan als je op zoek bent naar het perfecte verblijf.",
  "top10_hotels_incomplete_alert": "Er zijn op het moment veel gebruikers op onze site, dus het is mogelijk dat je sommige accommodaties niet ziet. <span>Onze excuses voor het ongemak.</span>",
  "compulsive_traveller": "{0} <span>| </span> <span>Reisfanaat</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Winnaars huizen- & appartementenprijs",
  "loved_by_guests_collection_in": "’Loved by Guests’-collectie in {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Handige kaarten voor {0}",
  "opening_hours": "Openingstijden",
  "hub.category.h2.photos": "Populairste fotogenieke reizen − {0}",
  "lbg.theme.most_wanted": "Favoriete keuze",
  "blog.stories_container": "Artikels van bijdragers",
  "hub.category.h2.budget": "Populairste voordelige reizen − {0}",
  "lbg.section_properties_theme.business": "Winnaars businessprijs in {0}",
  "not_sure_where_to_stay": "Twijfels over waar je wilt verblijven?",
  "category.information": "Informatie"
};