export default {
  "current_deal": "Hind praegu",
  "lbg.section_properties_theme.pet_friendly": "Kategooria „lemmikloomasõbralik majutus“ auhinnavõitjad, {0}",
  "address": "Aadress",
  "hub.homepage.h2": "Nii põnevad kohad, nii vähe aega...",
  "search_hotels_near_this_landmark": "Hotelliotsing<span class=\"extra-label\"> selle orientiiri lähiümbruses</span>",
  "hub.category.h1.things_to_do": "{0} – kuhu minna, mida teha?",
  "lbg.h2.vip_access": "VIP Access majutusasutused on valitud kõrgelt hinnatud ööbimiste ja esmaklassiliste kogemuste järgi.",
  "lbg.theme.cleanliness": "Puhtus",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Rohkem",
  "link_long_form_to_listiicle_multiple": "{0} – üks loendite {1} ja {2} esiletõstudest (loe asukoha {3} kohta siit)",
  "lbg.section_properties_theme.boutique": "Kategooria „butiikhotellid“ auhinnavõitjad, {0}",
  "lbg.h2.luxury": "Avasta suurim luksus. Just sellepärast meeldib külastajatele nendes majutusasutustes peatuda.",
  "hub.category.h1.information": "{0} – kasulik teave",
  "families": "Kõigile vanuseastmetele",
  "trending_now": "Hetkel kuum",
  "lbg.from": "Alates",
  "hub.category.h1.road_trips": "Parimad autoretked asukohas {0}",
  "lbg.h2.cleanliness": "Järgmistel majutusasutustel on suurepärased üldised hinded ja parimad puhtusehinded.",
  "single": "Üksi reisimine",
  "couples": "Kahekesi",
  "trust_module.price_guarantee.find_out_more": "rohkem teavet",
  "top_destinations_in": "{0} – populaarsemad sihtkohad ",
  "hub.category.h1.couples": "Paarireis sihtkohas {0}",
  "hub.category.h2.tours": "{0} – parimad ekskursioonid",
  "hub.category.h1.photos": "Fotoreis sihtkohas {0}",
  "category.photos": "Fotod",
  "hub.category.h1.budget": "Säästureis sihtkohas {0}",
  "hub.category.h2.ski": "{0} – parimad kohad suusapuhkuseks",
  "lbg.section_theme_world_winners.most_wanted": "Auhinna „Kõige kuumem“ võitjad ",
  "lbg.theme.home_and_apartments": "Kodumajutus ja korterid",
  "hub.category.h1.luxury": "Luksusreis sihtkohas {0}",
  "hub.category.h2.adventure": "Parim aktiivne reis sihtkohas {0}",
  "hotels_in": "{0} – hotellid",
  "lbg.section_search_winners": "Otsi auhinnavõitjaid",
  "see_more_hotels_in": "Veel hotelle asukohas {0}",
  "hub.category.h1.beach": "Rannad, {0}",
  "open": "Avatud:",
  "enter_your_destination": "Sisesta sihtkoht",
  "best_of": "Parimad palad sihtkohas {0}",
  "top10_hotels_missing_properties": "Kõiki majutusasutusi ei saanud hetkel kuvada. Vabandame võimalike probleemide pärast.",
  "adventure": "Seiklusturism",
  "lbg.h1.destination_theme.sustainable": "Kategooria „keskkonnasäästlik“ auhinnavõitjad, {0}",
  "luxury": "Luksus",
  "expand_map_view": "Kaardivaate laiendamine",
  "book_now": "Broneeri kohe",
  "lbg.h1.destination_theme.ski": "Kategooria „suusapuhkus“ auhinnavõitjad, {0}",
  "hub.category.h1.food": "{0} – toit ja söögikohad",
  "category.blogs": "Blogi",
  "budget": "Soodne hind",
  "popular_neighbourhoods_in": "Populaarsemad piirkonnad ",
  "hub.category.h1.unusual": "Peidetud aarded ja ebatavalised kohad sihtkohas {0}",
  "see_all_accommodation_in": "Vt majutusi asukohas {0}",
  "hub.category.h1.adventure": "Aktiivne reis sihtkohas {0}",
  "category_highlight.see_all.shopping": "Veel ostuvõimaluste kohta",
  "lbg.section_most_popular_destination": "Kõige populaarsemad sihtkohad",
  "lbg.section_properties_theme.luxury": "Kategooria „luksusmajutus“ auhinnavõitjad, {0}",
  "unusual": "Teistmoodi",
  "category.shopping": "Ostuvõimalused",
  "make_the_most_of_your_trip": "Kasulikke vihjeid",
  "x_review": "{0,choice,0#|1#{0} arvustus|2#{0} arvustust|2<{0} arvustust|4<{0} arvustust|10#{0} arvustust|10<{0} arvustust}",
  "hub.category.h1.single": "Sooloreis sihtkohas {0}",
  "hub.category.h2.custom": "Hetkel kuum",
  "lbg.section_theme_world_winners.lgbt": "Kategooria „LGBTQ+“ auhinnavõitjad",
  "lbg.section_award_winners_theme_other": "Veel auhinnakategooriaid, {0}",
  "lbg.supplier_collected_charges": "+ {0} kohapeal",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "Kategooria \"VIP Access\" võitjad, {0}",
  "link_long_form_to_listiicle_single": "{0} – üks loendi {1} esiletõstudest (loe asukoha {2} kohta siit)",
  "lbg.section_theme_world_winners.boutique": "Kategooria „butiikhotellid“ auhinnavõitjad ",
  "lbg.section_properties_theme.spa_hotel": "Kategooria „spaa“ auhinnavõitjad, {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Kategooria „kõik hinnas“ auhinnavõitjad ",
  "hub.category.h2": "{0} – reisiinfo",
  "lbg.properties.price_tooltip": "Meie kodulehel kõige madalam ööpäeva hind lähema 14 päeva jooksul. ",
  "hub.category.h2.road_trips": "Parimad autoretked asukohas {0}",
  "hub.category.h1.ski": "{0} suusasõpradele",
  "food": "Toit",
  "lbg.theme.pet_friendly": "Lemmikloomasõbralik majutus",
  "lbg.theme.business": "Äriklass",
  "hub.category.h1.nature": "Parimad loodusesse põgenemise retked asukohas {0}",
  "map": "Kaart",
  "category_highlight.see_all.information": "Rohkem teavet",
  "hotel_prices_in": "{0} – hotellide hinnad",
  "hub.category.h1.nightlife": "{0} – ööelu",
  "trust_module.free_cancellation.most_hotels": "enamiku hotellide puhul*",
  "also_popular_in": "Veel populaarseid piirkondi sihtkohas {0}",
  "lbg.h1.destination_theme.business": "Kategooria „äriklass“ auhinnavõitjad, {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Kategooria „lemmikloomasõbralik majutus“ auhinnavõitjad ",
  "hub.category.h1.maps": "Sihtkoha {0} kaardid",
  "lbg.section_properties_theme.most_wanted": "Auhinna „Kõige kuumem“ võitjad, {0}",
  "category.nightlife": "Ööelu",
  "search_hotels_in_this_area": "Hotelliotsing<span class=\"extra-label\"> selles piirkonnas</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 hääl|2#2 häält|2<{1} häält|4<{1} häält|10#10 häält|10<{1} häält})",
  "hub.category.h2.business": "Parim ärireis sihtkohas {0}",
  "category.things_to_do": "Kuhu minna, mida teha",
  "destination_travel_guide_by_category": "{0}. Kasulikku teavet eri valdkondadest",
  "hub.category.h1.families": "{0} perega reisijatele",
  "lbg.section_theme_world_winners.ski": "Kategooria „suusapuhkus“ auhinnavõitjad ",
  "you_may_also_like": "Soovitame ka järgnevaid",
  "lbg.theme.sustainable": "Keskkonnasäästlik",
  "hub.category.h2.unusual": "Parimad peidetud aarded ja ebatavalised kohad sihtkohas {0}",
  "hub.category.h2.football": "Toeta oma jalgpallimeeskonda ja võta reisist maksimum!",
  "top_hotel": "Ekstra hea hotell",
  "lbg.h1.destination_theme.luxury": "Kategooria „luksusmajutus“ auhinnavõitjad, {0}",
  "top10_hotels_empty_alert": "Tegeleme hetkel tehnilise tõrke kõrvaldamisega. <span>Vabandame võimalike probleemide pärast.</span>",
  "special_deal": "Eripakkumine",
  "lbg.h2.sustainable": "Kõige olulisem on keskkonnasäästlikkus! Vaata neid majutusasutusi, kus on suurepärane ööbida.",
  "where_to_stay_in": "{0} – milline piirkond <span class=\"next-line\">valida ööbimiseks?</span>",
  "start_planning_your_trip": "Leia oma majutus",
  "good_for": "Teemad:",
  "category.hotels": "Hotellid",
  "x_accommodation_guide": "{0}. Ülevaade majutusvõimalustest",
  "lbg.theme.luxury": "Luksusmajutus",
  "hub.category.h2.sports": "Parimad spordialad ja tegevused sihtkohas {0}",
  "lbg.section_theme_world_winners.lgbtq": "Kategooria \"LGBTQ\" võitjad",
  "see_more_neighbourhoods": "Veel piirkondi",
  "category_highlight.title.nightlife": "Kuhu õhtul minna ja mida teha asukohas {0}",
  "lbg.h1.destination_theme.lgbt": "Kategooria „LGBTQ+“ auhinnavõitjad, {0}",
  "hub.category.h1.blogs": "{0} – reisisõprade ajaveeb",
  "lbg.h2.business": "Neid majutusasutusi hindavad kõrgelt tööasjus reisijad.",
  "hotels": "Hotellid",
  "lbg.h2.pet_friendly": "Külastajad soovitavad neid majutusasutusi, kus on lisaks sinule teretulnud ka su lemmikloom.",
  "popular_landmarks_nearby": "Populaarseid vaatamisväärsusi lähiümbruses",
  "popular_extractions_in": "Populaarseid vaatamisväärsusi ",
  "lbg.h2.all_inclusive": "Need parima hinnaga majutusasutused kõrvaldavad kõik pinged.",
  "travel_guide_by_category_header": "{1}, {0}",
  "lbg.travel_between": "Kehtib majutusele ajavahemikus {0} – {1}",
  "keep_exploring": "Jätka tutvumist",
  "what_you_need_to_know_before_you_go": "Asjad, mida tasub teada igal reisisõbral",
  "read_it": "Loe edasi",
  "view_on_map": "Vaata kaardilt",
  "lbg.h1.destination_theme.lgbtq": "Kategooria \"LGBTQ\" võitjad, {0}",
  "lbg.h2.beach": "Külastajatele meeldib rannapuhkus, eriti nendes majutusasutustes.",
  "lbg.h1.destination_theme.vip_access": "Kategooria \"VIP Access\" võitjad, {0}",
  "see_all_accommodation": "Vt kõiki majutusasutusi",
  "shoppers": "Ostuvõimalused",
  "whats_around": "Mis asub sihtkoha {0} ümbruses",
  "group": "Rühmareisid",
  "hub.category.h2.couples": "Parim paarireis sihtkohas {0}",
  "hub.category.h1.shopping": "{0} – ostuvõimalused",
  "travel_community": "{0} <span>&#124;</span> <span>Reisirahva meediaplatvorm</span>",
  "hub.category.h2.group": "Parim grupireis sihtkohas {0}",
  "listicle_legal_msg": "See artikkel sisaldab Go Guidesi toimetuse arvamusi. Autorid saavad sellel saidil ilumvate kirjutiste eest Hotels.com-ilt hüvitist; hüvitis võib hõlmata reisimist ja muud kulusid.",
  "lbg.h1.destination_theme.cleanliness": "Kategooria \"Puhtus\" võitjad, {0}",
  "menu.more": "Veel",
  "lbg.section_properties_theme.lgbtq": "Kategooria \"LGBTQ\" võitjad, {0}",
  "lbg.section_award_winners_destination": "Auhinnavõitjad sihtkoha järgi",
  "lbg.terms_conditions": "Siduvad tingimused",
  "hub.category.h2.nightlife": "{0} – parimad peopaigad",
  "hub.neighbourhood.h2": "{0} väärib avastamist",
  "lbg.section_award_winners_theme_destination": "Auhinnavõitjad kategooriate järgi, {0}",
  "lbg.section_award_world_winners": "Ülemaailmsed võitjad",
  "things_to_see_and_do_in_other_cities": "Mida põnevat pakuvad teised linnad?",
  "button.backToTop": "Tagasi üles",
  "lbg.section_theme_world_winners.family": "Kategooria „kogu perele“ auhinnavõitjad ",
  "hotels_near": "{0} – lähiümbruse hotellid",
  "category.activity__sports_": "Aktiivne puhkus",
  "trust_module.get_reward_night": "Preemiaöö",
  "hub.category.h1.business": "Ärireis sihtkohas {0}",
  "category.food": "Toit",
  "other_popular_destinations_in": "{0} tõmbab? Veel populaarseid sihtkohti",
  "browse_by_category": "{0} alajaotuste kaupa",
  "see_more_attactions": "Veel vaatamisväärsusi",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Lisateavet neile, keda huvitab {0}",
  "lbg.h1.destination_theme.most_wanted": "Auhinna „Kõige kuumem“ võitjad, {0}",
  "see_all_properties_in": "{0}, kõik majutusasutused",
  "category.tours": "Ekskursioonid",
  "hub.category.h1.sports": "Sport ja tegevused sihtkohas {0}",
  "where_will_you_go_next": "Kuhu reisite järgmiseks?",
  "cities": "Linnad",
  "lbg.theme.spa_hotel": "Spaa",
  "see_guide": "Loe tutvustust",
  "lbg.section_properties_theme.ski": "Kategooria „suusapuhkus“ auhinnavõitjad, {0}",
  "related_stories": "Samal teemal",
  "hub.destination.h2": "Asjad, mida tasub teada igal reisisõbral",
  "show_less": "Kuva vähem",
  "hub.category.h1.football": "Parim {0} teejuht tõelistele jalgpallifännidele",
  "hub.category.h2.food": "{0} – parimad toidud ja toidukohad",
  "fallback_language_alert": "Kahjuks ei ole see lehekülg veel sinu keeles saadaval...",
  "category_highlight.title.information": "Kasulik teave",
  "neighbourhood_guides_for_other_cities": "Teiste linnade linnaosade tutvustused",
  "lbg.h2.hubpage": "Fantastilised majutuskohad, mis paistavad silma kõrgete hinnete ja paljude arvustustega.",
  "travel_advisory.title": "Tähelepanu",
  "lbg.section_properties_theme.all_inclusive": "Kategooria „kõik hinnas“ auhinnavõitjad, {0}",
  "lbg.h2.boutique": "Kui sulle meeldivad luksuslikud majutusasutused, soovitavad külastajad siin peatuda.",
  "lbg.section_properties_theme.family": "Kategooria „kogu perele“ auhinnavõitjad, {0}",
  "hub.category.h2.families": "{0}: parimad võimalused kogu perele",
  "from": "alates",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Kategooria „LGBTQ+“ auhinnavõitjad, {0}",
  "x_star": "{0,choice,0#|1#{0} tärni|1.5#{0} tärni|2#{0} tärni|2.5#{0} tärni|3#{0} tärni|3.5#{0} tärni|4#{0} tärni|4.5#{0} tärni|5#{0} tärni}",
  "trust_module.free_cancellation": "Tasuta tühistamine",
  "lbg.section_properties_theme.home_and_apartments": "Kategooria „kodumajutus ja korterid“ auhinnavõitjad, {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Meie autor</span>",
  "things_to_see_and_do_in": "Mida põnevat<span class=\"next-line\"> {0}</span> pakub?",
  "lbg.check_price": "Kontrolli hinda",
  "hub.category.h2.blogs": "Nõuanded kogenud reisisõpradelt",
  "lbg.h2.home_and_apartments": "Otsid omale teist kodu kodust eemal? Vaata neid kõrgelt hinnatud majutusasutusi.",
  "hub.category.h2.information": "{0} – kasulik teave",
  "search_hotels_in": "Hotelliotsing<span class=\"extra-label\"> {0}</span>",
  "hub.category.h2.nature": "Parimad loodusretked asukohas {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Kategooria „kodumajutus ja korterid“ auhinnavõitjad, {0}",
  "photo_by": "foto: {0}",
  "more_things_to_do": "Kuhu veel minna, mida veel teha",
  "lbg.section_properties_theme.beach": "Kategooria „rannamajutus“ auhinnavõitjad, {0}",
  "read_less": "Kuva vähem",
  "lbg.h2.spa_hotel": "Lõõgastu nendes kõrgelt hinnatud majutusasutustes, mis on tuntud spaade poolest.",
  "second_fold_related_stories.see_also": "Vaata ka",
  "hub.category.h2.history": "Parimad ajaloolised kohad sihtkohas {0}",
  "lbg.section_properties_theme.cleanliness": "Kategooria \"Puhtus\" võitjad, {0}",
  "lbg.h1.destination_theme.boutique": "Kategooria „butiikhotellid“ auhinnavõitjad, {0}",
  "nightlife_b595ae4": "Ööelu",
  "lbg.h1.destination_theme.beach": "Kategooria „rannamajutus“ auhinnavõitjad, {0}",
  "trust_module.free_cancellation.disclaimer": "Mõnedes hotellides kehtib nõue teatada tühistamisest hiljemalt 24-48 tundi enne majutuse algust. Teave on meie kodulehel.",
  "lbg.h2.family": "Külastajad hindavad neid majutusasutusi kõrgelt ja need sobivad ideaalselt peredele.",
  "lbg.h2.lgbtq": "Neid LGBTQ-sõbralikke majutusasutusi on külastajad kõrgelt hinnanud.",
  "hub.category.h1.group": "Grupireis sihtkohas {0}",
  "popular_stories": "Populaarsed artiklid",
  "toc": "Sisukord",
  "hub.category.h2.shopping": "{0} – parimad ostuvõimalused",
  "lbg.section_theme_world_winners.beach": "Kategooria „rannamajutus“ auhinnavõitjad ",
  "page_score": "Lehekülje hinne",
  "lbg.section_theme_world_winners.sustainable": "Kategooria „keskkonnasäästlik“ auhinnavõitjad ",
  "lbg.theme.boutique": "Butiikhotellid",
  "trust_module.price_guarantee": "Meie hinnagarantii",
  "lbg.h1.destination_theme.all_inclusive": "Kategooria „kõik hinnas“ auhinnavõitjad, {0}",
  "top_destinations": "Populaarsemad sihtkohad",
  "hotel_prices_near": "{0} – ümbruskonna hotellide hinnad",
  "lbg.score.cleanliness": "Puhtus:",
  "x_stars": "{0,choice,0#|1#{0} tärn|1<{0} tärni|4<{0} tärni|10<{0} tärni}",
  "lbg.theme.all_inclusive": "Kõik hinnas",
  "hub.category.h1.tours": "{0} – ekskursioonid ja ühepäevareisid",
  "lbg.section_award_winners_theme": "Auhinnavõitjad kategooriate järgi",
  "guest_writer": "{0} <span>&#124;</span> <span>Külalisautor</span>",
  "start_planning_your_x_trip": "Leia oma majutus asukohas {0} ",
  "lbg.section_theme_world_winners.business": "Kategooria „äriklass“ auhinnavõitjad ",
  "trending_now_in": "{0} – hetkel kuum",
  "history": "Ajalugu",
  "search_hotels": "Hotelliotsing",
  "lbg.terms_conditions_details": "{0}. aasta finalistide valiku eeltingimus oli külastajate kõrge hinne aastal {1} ja vähemalt 50 arvustust.",
  "see_nearby_properties": "Vaata majutusasutusi lähiümbruses",
  "hub.category.h1.history": "Ajaloolised kohad sihtkohas {0}",
  "location": "Asukoht:",
  "lbg.section_theme_world_winners.luxury": "Kategooria „luksusmajutus“ auhinnavõitjad ",
  "category_highlight.see_all.nightlife": "Veel ööelu kohta",
  "also_popular": "Samuti populaarne",
  "hub.category.h2.things_to_do": "{0} – parimad ajaviitetegevused",
  "lbg.theme.family": "Kogu perele",
  "lbg.h1.destination_theme.pet_friendly": "Kategooria „lemmikloomasõbralik majutus“ auhinnavõitjad, {0}",
  "category_highlight.title.shopping": "Kus ja mida osta asukohas {0}",
  "price": "Hind:",
  "hub.category.h2.beach": "Parimad rannad, {0}",
  "neighbourhoods_in": "{0} piirkondade kaupa",
  "business": "Tööreisid",
  "photo": "Fotomälestused",
  "blog.see_all_stories": "Vaata kõiki artikleid",
  "category_highlight.see_all.food": "Veel toidu ja söögikohtade kohta",
  "see_all_properties": "Kõik majutusasutused",
  "lbg.theme.beach": "Rannamajutus",
  "what_to_see_and_do_in": "Mida vaadata ja teha asukohas {0}",
  "hub.category.h2.single": "Parim sooloreis sihtkohas {0}",
  "attractions_in": "{0} – vaatamisväärsused ",
  "read_more": "Rohkem",
  "show_more": "Kuva rohkem",
  "hotels_near_this_landmark": "Hotellid selle orientiiri lähedal",
  "check_dates": "Kontrolli kuupäevi",
  "featured_stories_and_fun_stuff": "Uudised ja muu lahe värk",
  "phone": "Telefon:",
  "lbg.section_search_header": "Otsi auhinnavõitjaid",
  "category_highlight.title.food": "Kus ja mida süüa asukohas {0}",
  "see_all_things_to_do": "Vaata kõiki tegevusi",
  "lbg.section_properties_theme.sustainable": "Kategooria „keskkonnasäästlik“ auhinnavõitjad, {0}",
  "lbg.h1.hubpage": "Külastajate lemmiku auhinna võitjad {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Kategooria „spaa“ auhinnavõitjad ",
  "category.maps": "Kaardid",
  "lbg.h1.destination_theme.family": "Kategooria „kogu perele“ auhinnavõitjad, {0}",
  "lbg.section_theme_world_winners.cleanliness": "Kategooria \"Puhtus\" võitjad",
  "lbg.section_theme_world_winners.vip_access": "Kategooria \"VIP Access\" võitjad",
  "trust_module.get_reward_night.night_you_stay": "iga 10 majutusöö eest",
  "modified": "muudetud",
  "lbg.section_properties": "Auhinnavõitjad, {0}",
  "trust_module.header": "Miks broneerida just Hotels.com-i kaudu?",
  "hub.category.h2.luxury": "Parim luksusreis sihtkohas {0}",
  "lbg.theme.ski": "Suusapuhkus",
  "lbg.h1.destination_theme.spa_hotel": "Kategooria „spaa“ auhinnavõitjad, {0}",
  "lbg.h2.most_wanted": "Külastajad soovitavad neid majutusasutusi ideaalseks ööbimiseks.",
  "top10_hotels_incomplete_alert": "Kodulehe tavalisest suurema koormuse tõttu ei pruugi osa majutusasutusi hetkel näha olla. <span>Vabandame võimalike probleemide pärast.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Reisifanaatik</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Kategooria „kodumajutus ja korterid“ auhinnavõitjad ",
  "loved_by_guests_collection_in": "Külastajate lemmikud, {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "{0} – kasulikud kaardid",
  "opening_hours": "Avatud",
  "hub.category.h2.photos": "Parim fotoreis sihtkohas {0}",
  "lbg.theme.most_wanted": "Kõige kuumem",
  "blog.stories_container": "Artiklid kaasautoritelt",
  "hub.category.h2.budget": "Parim säästureis sihtkohas {0}",
  "lbg.section_properties_theme.business": "Kategooria „äriklass“ auhinnavõitjad, {0}",
  "not_sure_where_to_stay": "Milline piirkond valida ööbimiseks?",
  "category.information": "Info"
};