import React from 'react';
import { checkValue } from 'src/components/functions';
import CardSizeS from './card-s';
import CardSizeM from './card-m';
import CardSizeL from './card-l';
import CardSizeXL from './card-xl';
var BucketCardDefault = function (_a) {
  var data = _a.data,
    highlight = _a.highlight,
    stories = _a.stories;
  return React.createElement("ul", {
    className: "row item-list-container ".concat(highlight || stories ? '' : 'list5')
  }, data.map(function (item, index) {
    return checkValue(highlight) ? index === 0 ? React.createElement(CardSizeXL, {
      key: index,
      data: item
    }) : React.createElement(CardSizeS, {
      key: index,
      data: item
    }) : checkValue(stories) ? index === 0 ? React.createElement(CardSizeL, {
      key: index,
      data: item
    }) : React.createElement(CardSizeS, {
      key: index,
      data: item
    }) : index < 2 ? React.createElement(CardSizeM, {
      key: index,
      data: item
    }) : React.createElement(CardSizeS, {
      key: index,
      data: item
    });
  }));
};
export default BucketCardDefault;