var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { checkValue } from 'components/functions';
import React, { useState } from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import BucketOneCard from '../top-destination/sub-component/bucket-one-card';
import BucketThreeCard from '../top-destination/sub-component/bucket-three-card';
import BucketTwoCard from '../top-destination/sub-component/bucket-two-card';
import BucketCardDefault from '../top-destination/sub-component/bucket-card-default';
import { localization } from 'src/utils/localization';
var FeaturedStoriesInner = function (props) {
  var _a = useStores(),
    goGuidesStore = _a.goGuidesStore,
    context = _a.context;
  var pageType = props.pageType,
    destinationName = props.destinationName,
    categoryCode = props.categoryCode,
    _b = props.items,
    items = _b === void 0 ? [] : _b;
  var _c = __read(useState([]), 2),
    displayedItems = _c[0],
    setDisplayedItems = _c[1];
  var _d = __read(useState(1), 2),
    currentPage = _d[0],
    setCurrentPage = _d[1];
  var track = useEgClickstream();
  var trackObject = {
    event: {
      event_version: '1.0.0',
      event_type: 'Interaction',
      event_name: 'show_more_button.selected',
      event_category: 'go_guides',
      action_location: 'trending now'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  var loc = new localization();
  var itemsLoadMorePerClick = 6;
  var initialItems = pageType === 'categoryhub' ? 12 : 9;
  if (displayedItems.length === 0) {
    setDisplayedItems(items.slice(0, initialItems));
  }
  var loadMoreItems = function () {
    var newItems = items.slice(displayedItems.length, displayedItems.length + itemsLoadMorePerClick);
    setDisplayedItems(function (prevItems) {
      return __spreadArray(__spreadArray([], __read(prevItems), false), __read(newItems), false);
    });
    setCurrentPage(function (prevPage) {
      return prevPage + 1;
    });
    track(trackObject);
  };
  var showLoadMore = displayedItems.length < items.length;
  var renderBucket = function () {
    var bucketComponents = {
      1: BucketOneCard,
      2: BucketTwoCard,
      3: BucketThreeCard,
      4: BucketTwoCard
    };
    var BucketComponent = bucketComponents[displayedItems.length] || BucketCardDefault;
    return React.createElement(BucketComponent, {
      data: displayedItems,
      stories: true
    });
  };
  var bucketHeader = pageType == 'home' ? loc.getLocalizedText('trending_now') : checkValue(categoryCode) ? loc.getLocalizedText("hub.category.h2.".concat(categoryCode), destinationName) : loc.getLocalizedText('trending_now_in', destinationName);
  return React.createElement(React.Fragment, null, checkValue(displayedItems) && displayedItems.length > 0 && React.createElement("section", {
    className: "feature-stories"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement(UitkHeading, {
    size: 5,
    tag: "h2"
  }, bucketHeader), React.createElement("span", {
    className: "sub-header"
  }, loc.getLocalizedText('featured_stories_and_fun_stuff'))), React.createElement("div", {
    className: "item-list-page-wrap"
  }, renderBucket()), showLoadMore && React.createElement(UitkPrimaryButton, {
    className: "load-more-btn",
    tag: "button",
    type: "button",
    onClick: loadMoreItems
  }, loc.getLocalizedText('load_more'))))));
};
export default FeaturedStoriesInner;