import React from 'react';
import { viewportCheck } from '../../utils/helper';
import { UitkScrim, UitkScrimType } from 'uitk-react-scrim';
var HeroSkim = function (_a) {
  var header = _a.header;
  var currentViewPort = viewportCheck();
  return React.createElement(React.Fragment, null, (currentViewPort === 'desktop' || currentViewPort === 'large-desktop') && (header === 'align-center' ? React.createElement(UitkScrim, {
    type: UitkScrimType.OVERLAY
  }) : header === 'align-left' && React.createElement(UitkScrim, {
    type: UitkScrimType.BOTTOM
  })));
};
export default HeroSkim;