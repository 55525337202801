var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect } from 'react';
import { getLocalizedText } from 'src/utils/helper';
import { UitkIcon } from 'uitk-react-icons';
var FallbackLanguageAlert = function () {
  var _a = __read(React.useState(true), 2),
    show = _a[0],
    setShow = _a[1];
  var _b = __read(React.useState(false), 2),
    tick = _b[0],
    setTick = _b[1];
  var closeAlert = function () {
    setShow(false);
    window.removeEventListener('scroll', function () {
      timedCloseAlert();
    });
  };
  var timedCloseAlert = function () {
    if (show && !tick) {
      setTimeout(function () {
        setShow(false);
      }, 8000);
      setTick(true);
    }
  };
  useEffect(function () {
    // register event listener for window scroll, and the alert after scroll for 8 seconds
    window.addEventListener('scroll', function () {
      timedCloseAlert();
    });
    return function () {
      window.removeEventListener('scroll', function () {
        timedCloseAlert();
      });
    };
  });
  return React.createElement(React.Fragment, null, show && React.createElement("div", {
    className: "fallback-lang-alert"
  }, React.createElement("div", {
    className: "inner"
  }, getLocalizedText('fallback_language_alert'), React.createElement("button", {
    className: "close-btn",
    onClick: closeAlert
  }, React.createElement(UitkIcon, {
    name: "close"
  })))));
};
export default FallbackLanguageAlert;