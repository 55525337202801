export default {
  "current_deal": "Oferta atual",
  "lbg.section_properties_theme.pet_friendly": "Vencedores do prêmio na categoria “Aceita animais de estimação” em {0}",
  "address": "Endereço",
  "hub.homepage.h2": "O mundo está cheio de lugares para explorar",
  "search_hotels_near_this_landmark": "Buscar hotéis<span class=\"extra-label\"> próximos a este ponto de referência</span>",
  "hub.category.h1.things_to_do": "Atividades em {0}",
  "lbg.h2.vip_access": "As acomodações VIP Access se destacam pelas ótimas avaliações recebidas e pelo seu serviço de excelência.",
  "lbg.theme.cleanliness": "Limpeza",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Carregar mais",
  "link_long_form_to_listiicle_multiple": "{0}: este ponto de interesse é um dos destaques nos artigos {1} e {2}. Confira todas as informações sobre {3} aqui.",
  "lbg.section_properties_theme.boutique": "Vencedores do prêmio na categoria “Butique” em {0}",
  "lbg.h2.luxury": "Procura uma estadia de luxo? Estas acomodações que nossos hóspedes adoram são perfeitas para você.",
  "hub.category.h1.information": "Informações sobre {0}",
  "families": "Famílias",
  "trending_now": "Mais populares",
  "lbg.from": "A partir de",
  "hub.category.h1.road_trips": "Melhores opções de viagem de carro em {0}",
  "lbg.h2.cleanliness": "Estas acomodações receberam ótimas avaliações dos hóspedes em geral, principalmente em relação à limpeza.",
  "single": "Solteiros",
  "couples": "Casais",
  "trust_module.price_guarantee.find_out_more": "Saiba mais",
  "top_destinations_in": "Principais destinos em {0}",
  "hub.category.h1.couples": "Viagens românticas em {0}",
  "hub.category.h2.tours": "Passeios populares em {0}",
  "hub.category.h1.photos": "Viagens fotográficas em {0}",
  "category.photos": "Fotos",
  "hub.category.h1.budget": "Viagens econômicas em {0}",
  "hub.category.h2.ski": "Os melhores destinos de esqui em {0}",
  "lbg.section_theme_world_winners.most_wanted": "Vencedores do prêmio na categoria “Mais populares”",
  "lbg.theme.home_and_apartments": "Casas e apartamentos",
  "hub.category.h1.luxury": "Viagens de luxo em {0}",
  "hub.category.h2.adventure": "Melhor viagem de aventura em {0}",
  "hotels_in": "Hotéis em {0}",
  "lbg.section_search_winners": "Buscar mais vencedores do prêmio",
  "see_more_hotels_in": "Ver mais hotéis em {0}",
  "hub.category.h1.beach": "Praias em {0}",
  "open": "Abre:",
  "enter_your_destination": "Insira o seu destino",
  "best_of": "O melhor de {0}",
  "top10_hotels_missing_properties": "Algumas acomodações podem não aparecer no momento. Pedimos desculpas pelo inconveniente.",
  "adventure": "Aventura",
  "lbg.h1.destination_theme.sustainable": "Vencedores do prêmio na categoria “Sustentável” em {0}",
  "luxury": "Luxo",
  "expand_map_view": "Expandir exibição de mapa",
  "book_now": "Reservar",
  "lbg.h1.destination_theme.ski": "Vencedores do prêmio na categoria “Esqui” em {0}",
  "hub.category.h1.food": "Opções de refeição em {0}",
  "category.blogs": "Blog",
  "budget": "Econômico",
  "popular_neighbourhoods_in": "Bairros populares em {0}",
  "hub.category.h1.unusual": "Tesouros escondidos e lugares incomuns em {0}",
  "see_all_accommodation_in": "Ver acomodações em {0}",
  "hub.category.h1.adventure": "Viagens de aventura em {0}",
  "category_highlight.see_all.shopping": "Mais informações sobre as opções de compras",
  "lbg.section_most_popular_destination": "Destinos mais populares",
  "lbg.section_properties_theme.luxury": "Vencedores do prêmio na categoria “Luxo” em {0}",
  "unusual": "Viagens alternativas",
  "category.shopping": "Compras",
  "make_the_most_of_your_trip": "Aproveite sua viagem ao máximo",
  "x_review": "{0,choice,0#|1#{0} avaliação|2#{0} avaliações|2<{0} avaliações|4<{0} avaliações|10#{0} avaliações|10<{0} avaliações}",
  "hub.category.h1.single": "Viagens solo em {0}",
  "hub.category.h2.custom": "Mais populares",
  "lbg.section_theme_world_winners.lgbt": "Vencedores do prêmio na categoria \"LGBTQ+\"",
  "lbg.section_award_winners_theme_other": "Outros vencedores do prêmio por tema em {0}",
  "lbg.supplier_collected_charges": "+ {0} a pagar na acomodação",
  "in_destination": " em {0}",
  "lbg.section_properties_theme.vip_access": "Vencedores do prêmio na categoria VIP Access em {0}",
  "link_long_form_to_listiicle_single": "{0}: este ponto de interesse é um dos destaques no artigo {1}. Confira todas as informações sobre {2} aqui.",
  "lbg.section_theme_world_winners.boutique": "Vencedores do prêmio na categoria “Butique”",
  "lbg.section_properties_theme.spa_hotel": "Vencedores do prêmio na categoria “Spa” em {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Vencedores do prêmio na categoria “Tudo incluído”",
  "hub.category.h2": "Guia de viagem de {0}",
  "lbg.properties.price_tooltip": "Este é o preço mais baixo por noite no nosso site para os próximos 14 dias.",
  "hub.category.h2.road_trips": "Melhores opções de viagem de carro em {0}",
  "hub.category.h1.ski": "Destinos de esqui em {0}",
  "food": "Alimentação",
  "lbg.theme.pet_friendly": "Aceita animais de estimação",
  "lbg.theme.business": "Negócios",
  "hub.category.h1.nature": "Melhores opções de viagem para ficar em contato com a natureza em {0}",
  "map": "Mapa",
  "category_highlight.see_all.information": "Mais informações",
  "hotel_prices_in": "Preços de hotel em {0}",
  "hub.category.h1.nightlife": "Vida noturna em {0}",
  "trust_module.free_cancellation.most_hotels": "na maioria dos hotéis*",
  "also_popular_in": "Também populares em {0}",
  "lbg.h1.destination_theme.business": "Vencedores do prêmio na categoria “Negócios” em {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Vencedores do prêmio na categoria “Aceita animais de estimação”",
  "hub.category.h1.maps": "Mapas de {0}",
  "lbg.section_properties_theme.most_wanted": "Vencedores do prêmio na categoria “Mais populares” em {0}",
  "category.nightlife": "Vida noturna",
  "search_hotels_in_this_area": "Buscar hotéis<span class=\"extra-label\"> nessa área</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 voto|2#2 votos|2<{1} votos|4<{1} votos|10#10 votos|10<{1} votos})",
  "hub.category.h2.business": "Melhor viagem a negócios em {0}",
  "category.things_to_do": "Atividades",
  "destination_travel_guide_by_category": "{0}: guia de viagem por categoria",
  "hub.category.h1.families": "Viagem em família em {0}",
  "lbg.section_theme_world_winners.ski": "Vencedores do prêmio na categoria “Esqui”",
  "you_may_also_like": "Você também pode gostar de",
  "lbg.theme.sustainable": "Sustentável",
  "hub.category.h2.unusual": "Melhores tesouros escondidos e lugares incomuns em {0}",
  "hub.category.h2.football": "Torça para o seu time e aproveite o melhor da sua viagem!",
  "top_hotel": "Hotel em destaque",
  "lbg.h1.destination_theme.luxury": "Vencedores do prêmio na categoria “Luxo” em {0}",
  "top10_hotels_empty_alert": "Houve um erro. <span>Pedimos desculpas pelo inconveniente.</span>",
  "special_deal": "Oferta especial",
  "lbg.h2.sustainable": "Procura uma opção sustentável? Confira estas acomodações e aproveite uma estadia inesquecível.",
  "where_to_stay_in": "Onde ficar em {0}",
  "start_planning_your_trip": "Planeje agora sua viagem",
  "good_for": "Ideal para:",
  "category.hotels": "Hotéis",
  "x_accommodation_guide": "Guia de acomodações em {0}",
  "lbg.theme.luxury": "Luxo",
  "hub.category.h2.sports": "Melhores esportes e atividades em {0}",
  "lbg.section_theme_world_winners.lgbtq": "Vencedores do prêmio na categoria LGBTQ",
  "see_more_neighbourhoods": "Ver mais áreas do bairro",
  "category_highlight.title.nightlife": "{0}: vida noturna",
  "lbg.h1.destination_theme.lgbt": "Vencedores do prêmio na categoria \"LGBTQ+\" em {0}",
  "hub.category.h1.blogs": "Blog de viagem sobre {0}",
  "lbg.h2.business": "Estas acomodações receberam boas avaliações de quem viaja a negócios.",
  "hotels": "Hotéis",
  "lbg.h2.pet_friendly": "Os hóspedes recomendam estas acomodações para quem viaja com animais de estimação.",
  "popular_landmarks_nearby": "Pontos de referência populares nas proximidades",
  "popular_extractions_in": "Atrações populares em {0}",
  "lbg.h2.all_inclusive": "Estas acomodações com ótimas avaliações são perfeitas para relaxar.",
  "travel_guide_by_category_header": "{0}: {1}",
  "lbg.travel_between": "Viaje entre {0} - {1}",
  "keep_exploring": "Explore outros destinos",
  "what_you_need_to_know_before_you_go": "Informações importantes para a sua viagem",
  "read_it": "Ler mais",
  "view_on_map": "Ver no mapa",
  "lbg.h1.destination_theme.lgbtq": "Vencedores do prêmio na categoria LGBTQ em {0}",
  "lbg.h2.beach": "Os hóspedes adoram férias na praia, principalmente nestas acomodações.",
  "lbg.h1.destination_theme.vip_access": "Vencedores do prêmio na categoria VIP Access em {0}",
  "see_all_accommodation": "Ver todas acomodações",
  "shoppers": "Compras",
  "whats_around": "Atrações nos arredores de {0}",
  "group": "Grupos",
  "hub.category.h2.couples": "Melhor viagem romântica em {0}",
  "hub.category.h1.shopping": "Compras em {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Comunidade de viajantes</span>",
  "hub.category.h2.group": "Melhor viagem em grupos em {0}",
  "listicle_legal_msg": "Este artigo inclui opiniões da equipe editorial dos Go Guides. A Hoteis.com remunera os autores dos textos publicados neste site, o que pode incluir custos de viagens e outras despesas.",
  "lbg.h1.destination_theme.cleanliness": "Vencedores do prêmio na categoria Limpeza em {0}",
  "menu.more": "Mais",
  "lbg.section_properties_theme.lgbtq": "Vencedores do prêmio na categoria LGBTQ em {0}",
  "lbg.section_award_winners_destination": "Vencedores do prêmio por destino",
  "lbg.terms_conditions": "Termos e condições",
  "hub.category.h2.nightlife": "O melhor da vida noturna em {0}",
  "hub.neighbourhood.h2": "O que fazer em {0}",
  "lbg.section_award_winners_theme_destination": "Vencedores do prêmio por tema em {0}",
  "lbg.section_award_world_winners": "Vencedores do prêmio no mundo todo",
  "things_to_see_and_do_in_other_cities": "Atividades em outras cidades",
  "button.backToTop": "Voltar ao início",
  "lbg.section_theme_world_winners.family": "Vencedores do prêmio na categoria “Família”",
  "hotels_near": "Hotéis próximos a {0}",
  "category.activity__sports_": "Excursões",
  "trust_module.get_reward_night": "Ganhe uma noite de recompensa",
  "hub.category.h1.business": "Viagens a negócios em {0}",
  "category.food": "Alimentação",
  "other_popular_destinations_in": "Outros destinos populares em {0}",
  "browse_by_category": "Pesquisar {0} por categoria",
  "see_more_attactions": "Ver mais atrações",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Mais informações sobre {0}",
  "lbg.h1.destination_theme.most_wanted": "Vencedores do prêmio na categoria “Mais populares” em {0}",
  "see_all_properties_in": "Ver todas as acomodações em {0}",
  "category.tours": "Passeios",
  "hub.category.h1.sports": "Esportes e atividades em {0}",
  "where_will_you_go_next": "Qual o seu próximo destino?",
  "cities": "Cidades",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Ver guia",
  "lbg.section_properties_theme.ski": "Vencedores do prêmio na categoria “Esqui” em {0}",
  "related_stories": "Artigos relacionados",
  "hub.destination.h2": "Informações importantes para a sua viagem",
  "show_less": "Mostrar menos",
  "hub.category.h1.football": "O guia completo de {0} para quem realmente gosta de futebol",
  "hub.category.h2.food": "Melhores opções para refeição em {0}",
  "fallback_language_alert": "Esta página ainda não foi traduzida para o seu idioma.",
  "category_highlight.title.information": "Informações úteis",
  "neighbourhood_guides_for_other_cities": "Guia de bairros de outras cidades",
  "lbg.h2.hubpage": "Estas acomodações incríveis receberam uma alta pontuação dos hóspedes e muitas avaliações.",
  "travel_advisory.title": "Alerta de viagem",
  "lbg.section_properties_theme.all_inclusive": "Vencedores do prêmio na categoria “Tudo incluído” em {0}",
  "lbg.h2.boutique": "Se você gosta de acomodações butique, confira estas recomendações dos hóspedes.",
  "lbg.section_properties_theme.family": "Vencedores do prêmio na categoria “Família” em {0}",
  "hub.category.h2.families": "Melhores viagens em família em {0}",
  "from": "a partir de",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Vencedores do prêmio na categoria \"LGBTQ+\" em {0}",
  "x_star": "{0,choice,0#|1#{0} estrela|1.5#{0} estrela|2#{0} estrelas|2.5#{0} estrelas|3#{0} estrelas|3.5#{0} estrelas|4#{0} estrelas|4.5#{0} estrelas|5#{0} estrelas}",
  "trust_module.free_cancellation": "Cancelamento grátis",
  "lbg.section_properties_theme.home_and_apartments": "Vencedores do prêmio na categoria “Casas e apartamentos” em {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Autor colaborador</span>",
  "things_to_see_and_do_in": "Atividades <span class=\"next-line\">em {0}</span>",
  "lbg.check_price": "Ver preço",
  "hub.category.h2.blogs": "por viajantes experientes",
  "lbg.h2.home_and_apartments": "Quer se sentir em casa fora de casa? Confira estas acomodações com excelentes avaliações.",
  "hub.category.h2.information": "Informações úteis sobre {0}",
  "search_hotels_in": "Buscar hotéis<span class=\"extra-label\"> em {0}</span>",
  "hub.category.h2.nature": "Melhores opções de viagem em meio à natureza em {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Vencedores do prêmio na categoria “Casas e apartamentos” em {0}",
  "photo_by": "foto de {0}",
  "more_things_to_do": "Mais atividades",
  "lbg.section_properties_theme.beach": "Vencedores do prêmio na categoria “Praia” em {0}",
  "read_less": "Recolher",
  "lbg.h2.spa_hotel": "Relaxe em uma destas acomodações populares e famosas pelos seus spas.",
  "second_fold_related_stories.see_also": "Confira também",
  "hub.category.h2.history": "Melhor viagem a locais históricos em {0}",
  "lbg.section_properties_theme.cleanliness": "Vencedores do prêmio na categoria Limpeza em {0}",
  "lbg.h1.destination_theme.boutique": "Vencedores do prêmio na categoria “Butique” em {0}",
  "nightlife_b595ae4": "Vida noturna",
  "lbg.h1.destination_theme.beach": "Vencedores do prêmio na categoria “Praia” em {0}",
  "trust_module.free_cancellation.disclaimer": "Alguns hotéis exigem que o cancelamento seja feito, pelo menos, 24 a 48 horas antes do check-in. Veja mais detalhes no site.",
  "lbg.h2.family": "Estas acomodações receberam boas avaliações dos hóspedes e são ideais para quem viaja com a família.",
  "lbg.h2.lgbtq": "Estas acomodações voltadas ao público LGBTQ receberam boas avaliações dos hóspedes.",
  "hub.category.h1.group": "Viagens em grupos em {0}",
  "popular_stories": "Artigos populares",
  "toc": "Conteúdo",
  "hub.category.h2.shopping": "Melhores opções de compras em {0}",
  "lbg.section_theme_world_winners.beach": "Vencedores do prêmio na categoria “Praia”",
  "page_score": "Pontuação desta página",
  "lbg.section_theme_world_winners.sustainable": "Vencedores do prêmio na categoria “Sustentável”",
  "lbg.theme.boutique": "Butique",
  "trust_module.price_guarantee": "Nossa Garantia de Preços",
  "lbg.h1.destination_theme.all_inclusive": "Vencedores do prêmio na categoria “Tudo incluído” em {0}",
  "top_destinations": "Principais destinos",
  "hotel_prices_near": "Preços de hotel perto de {0}",
  "lbg.score.cleanliness": "Limpeza:",
  "x_stars": "{0,choice,0#|1#{0} estrela|1<{0} estrelas|4<{0} estrelas|10<{0} estrelas}",
  "lbg.theme.all_inclusive": "Tudo incluído",
  "hub.category.h1.tours": "Passeios e excursões em {0}",
  "lbg.section_award_winners_theme": "Vencedores do prêmio por tema",
  "guest_writer": "{0} <span>&#124;</span> <span>Autor convidado</span>",
  "start_planning_your_x_trip": "Planeje agora sua viagem para {0}",
  "lbg.section_theme_world_winners.business": "Vencedores do prêmio na categoria “Negócios”",
  "trending_now_in": "Mais populares em {0}",
  "history": "História",
  "search_hotels": "Buscar hotéis",
  "lbg.terms_conditions_details": "Os critérios para escolher as acomodações vencedoras em {0} tiveram como base as altas pontuações dos hóspedes em {1} e, pelo menos, 50 avaliações.",
  "see_nearby_properties": "Ver acomodações nas proximidades",
  "hub.category.h1.history": "Viagens a locais históricos em {0}",
  "location": "Localização:",
  "lbg.section_theme_world_winners.luxury": "Vencedores do prêmio na categoria “Luxo”",
  "category_highlight.see_all.nightlife": "Mais informações sobre a vida noturna",
  "also_popular": "Outras opções populares",
  "hub.category.h2.things_to_do": "Atividades populares em {0}",
  "lbg.theme.family": "Família",
  "lbg.h1.destination_theme.pet_friendly": "Vencedores do prêmio na categoria “Aceita animais de estimação” em {0}",
  "category_highlight.title.shopping": "{0}: opções de compras",
  "price": "Preço:",
  "hub.category.h2.beach": "Melhores praias em {0}",
  "neighbourhoods_in": "Bairros em {0}",
  "business": "Negócios",
  "photo": "Foto",
  "blog.see_all_stories": "Ver todos os stories",
  "category_highlight.see_all.food": "Mais informações sobre a gastronomia e as opções para refeição",
  "see_all_properties": "Ver todas as acomodações",
  "lbg.theme.beach": "Praia",
  "what_to_see_and_do_in": "{0}: atrações e atividades",
  "hub.category.h2.single": "Melhor viagem solo em {0}",
  "attractions_in": "Atrações em {0}",
  "read_more": "Leia mais",
  "show_more": "Mostrar mais",
  "hotels_near_this_landmark": "Hotéis perto deste ponto de referência",
  "check_dates": "Verificar datas",
  "featured_stories_and_fun_stuff": "Artigos principais e curiosidades",
  "phone": "Telefone:",
  "lbg.section_search_header": "Busque por vencedores do prêmio",
  "category_highlight.title.food": "{0}: gastronomia e opções para refeição",
  "see_all_things_to_do": "Mostrar todas as atividades",
  "lbg.section_properties_theme.sustainable": "Vencedores do prêmio na categoria “Sustentável” em {0}",
  "lbg.h1.hubpage": "Vencedores do prêmio Preferidos entre os hóspedes {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Vencedores do prêmio na categoria “Spa”",
  "category.maps": "Mapas",
  "lbg.h1.destination_theme.family": "Vencedores do prêmio na categoria “Família” em {0}",
  "lbg.section_theme_world_winners.cleanliness": "Vencedores do prêmio na categoria Limpeza",
  "lbg.section_theme_world_winners.vip_access": "Vencedores do prêmio na categoria VIP Access",
  "trust_module.get_reward_night.night_you_stay": "a cada 10 diárias",
  "modified": "modificada",
  "lbg.section_properties": "Vencedores do prêmio em {0}",
  "trust_module.header": "Por que reservar na Hoteis.com?",
  "hub.category.h2.luxury": "Melhor viagem de luxo em {0}",
  "lbg.theme.ski": "Esqui",
  "lbg.h1.destination_theme.spa_hotel": "Vencedores do prêmio na categoria “Spa” em {0}",
  "lbg.h2.most_wanted": "Os hóspedes recomendam estas acomodações para quem quer curtir uma estadia perfeita.",
  "top10_hotels_incomplete_alert": "Devido a uma elevada demanda, algumas acomodações podem não exibidas no momento. <span>Pedimos desculpas pelo inconveniente.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Viajante compulsivo</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Vencedores do prêmio na categoria “Casas e apartamentos”",
  "loved_by_guests_collection_in": "Preferidos entre os hóspedes em {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Mapas úteis de {0}",
  "opening_hours": "Horário de funcionamento",
  "hub.category.h2.photos": "Melhor viagem fotográfica em {0}",
  "lbg.theme.most_wanted": "Mais populares",
  "blog.stories_container": "Stories de nossos colaboradores",
  "hub.category.h2.budget": "Melhor viagem econômica em {0}",
  "lbg.section_properties_theme.business": "Vencedores do prêmio na categoria “Negócios” em {0}",
  "not_sure_where_to_stay": "Não sabe onde ficar?",
  "category.information": "Informações"
};