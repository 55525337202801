export default {
  "current_deal": "Tämänhetkinen tarjous",
  "lbg.section_properties_theme.pet_friendly": "Voittajat: lemmikkiystävällinen – {0}",
  "address": "Osoite",
  "hub.homepage.h2": "Niin paljon nähtävää, niin vähän aikaa...",
  "search_hotels_near_this_landmark": "Hae hotelleja<span class=\"extra-label\"> läheltä tätä maamerkkiä</span>",
  "hub.category.h1.things_to_do": "{0}: Tekemistä",
  "lbg.h2.vip_access": "VIP Access -majoitusliikkeet on valittu niiden saamien korkeiden arvosanojen ja huipputason asiakaspalvelun perusteella.",
  "lbg.theme.cleanliness": "Siisteys",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Näytä lisää",
  "link_long_form_to_listiicle_multiple": "{0} – yksi oppaiden {1} ja {2} kohokohdista (Lue kaikki kohteesta {3} täällä)",
  "lbg.section_properties_theme.boutique": "Voittajat: boutique – {0}",
  "lbg.h2.luxury": "Luksusta parhaimmillaan. Siksi asiakkaat yöpyvät näissä majoitusliikkeissä mielellään.",
  "hub.category.h1.information": "{0}: Tietoa",
  "families": "Perheet",
  "trending_now": "Pinnalla nyt",
  "lbg.from": "Alkaen",
  "hub.category.h1.road_trips": "{0}: Suosituimmat road tripit",
  "lbg.h2.cleanliness": "Nämä majoitusliikkeet ovat saaneet asiakkailta hyvät yleisarvosanat ja korkean arvosanan puhtaudesta.",
  "single": "Sinkut",
  "couples": "Pariskunnat",
  "trust_module.price_guarantee.find_out_more": "Lue lisää",
  "top_destinations_in": "{0}: Suosituimmat kohteet",
  "hub.category.h1.couples": "{0}: Romanttiset lomat",
  "hub.category.h2.tours": "{0}: Suosituimmat kierrokset ja retket",
  "hub.category.h1.photos": "{0}: Valokuvausmatkat",
  "category.photos": "Kuvat",
  "hub.category.h1.budget": "{0}: Budjettilomat",
  "hub.category.h2.ski": "{0}: Suosituimmat laskettelukohteet",
  "lbg.section_theme_world_winners.most_wanted": "Voittajat: suosituimmat",
  "lbg.theme.home_and_apartments": "Kodit ja asunnot",
  "hub.category.h1.luxury": "{0}: Luksuslomat",
  "hub.category.h2.adventure": "{0}: Suosituimmat aktiivilomat",
  "hotels_in": "{0}: Hotellit",
  "lbg.section_search_winners": "Hae lisää palkittuja voittajia",
  "see_more_hotels_in": "{0}: Lisää hotelleja",
  "hub.category.h1.beach": "{0}: Rannat",
  "open": "Avoinna:",
  "enter_your_destination": "Anna kohteesi",
  "best_of": "{0}: Parasta täällä",
  "top10_hotels_missing_properties": "Et välttämättä näe kaikkia majoitusliikkeitä tällä hetkellä. Pahoittelemme siitä mahdollisesti aiheutuvaa haittaa.",
  "adventure": "Seikkailu",
  "lbg.h1.destination_theme.sustainable": "Ympäristöystävällinen – voittajat kohteessa {0}",
  "luxury": "Luksus",
  "expand_map_view": "Laajenna karttanäkymää",
  "book_now": "Varaa nyt",
  "lbg.h1.destination_theme.ski": "Hiihto – voittajat kohteessa {0}",
  "hub.category.h1.food": "{0}: Ruoka",
  "category.blogs": "Blogi",
  "budget": "Halpa",
  "popular_neighbourhoods_in": "{0}: Suosittuja alueita",
  "hub.category.h1.unusual": "{0}: Vähemmän tunnetut ja epätavalliset paikat",
  "see_all_accommodation_in": "{0}: Näytä majoitukset",
  "hub.category.h1.adventure": "{0}: Aktiivilomat",
  "category_highlight.see_all.shopping": "Lisää ostoksista",
  "lbg.section_most_popular_destination": "Suosituimmat kohteet",
  "lbg.section_properties_theme.luxury": "Voittajat: luksus – {0}",
  "unusual": "Uniikki",
  "category.shopping": "Shoppailu",
  "make_the_most_of_your_trip": "Parhaat vinkit matkallesi",
  "x_review": "{0,choice,0#|1#{0} arvostelu|2#{0} arvostelua|2<{0} arvostelua|4<{0} arvostelua|10#{0} arvostelua|10<{0} arvostelua}",
  "hub.category.h1.single": "{0}: Soolomatkat",
  "hub.category.h2.custom": "Pinnalla nyt",
  "lbg.section_theme_world_winners.lgbt": "LGBTQ+ -palkinnon voittajat",
  "lbg.section_award_winners_theme_other": "Muut voittajat teemoittain – {0}",
  "lbg.supplier_collected_charges": "+ {0} maksettava majoitusliikkeessä",
  "in_destination": "kohteessa {0}",
  "lbg.section_properties_theme.vip_access": "VIP Access -kategorian voittajat – {0}",
  "link_long_form_to_listiicle_single": "{0} – yksi oppaan {1} kohokohdista (Lue kaikki kohteesta {2} täällä)",
  "lbg.section_theme_world_winners.boutique": "Voittajat: boutique",
  "lbg.section_properties_theme.spa_hotel": "Voittajat: kylpylä – {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Voittajat: all inclusive",
  "hub.category.h2": "{0}: Matkaopas",
  "lbg.properties.price_tooltip": "Tämä on alhaisin hinta yöltä sivustollamme seuraavien 14 päivän aikana.",
  "hub.category.h2.road_trips": "{0}: Suosituimmat road tripit",
  "hub.category.h1.ski": "{0}: Laskettelukohteet",
  "food": "Ruoka",
  "lbg.theme.pet_friendly": "Lemmikkiystävällinen",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "{0}: Suosituimmat luontoretket",
  "map": "Kartta",
  "category_highlight.see_all.information": "Lisätietoja",
  "hotel_prices_in": "{0}: Hotellien hinnat",
  "hub.category.h1.nightlife": "{0}: Yöelämä",
  "trust_module.free_cancellation.most_hotels": "useimmissa hotelleissa*",
  "also_popular_in": "{0}: Muita suosittuja kohteita",
  "lbg.h1.destination_theme.business": "Business – voittajat kohteessa {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Voittajat: lemmikkiystävällinen",
  "hub.category.h1.maps": "{0}: Kartat",
  "lbg.section_properties_theme.most_wanted": "Voittajat: suosituimmat – {0}",
  "category.nightlife": "Yöelämä",
  "search_hotels_in_this_area": "Hae hotelleja<span class=\"extra-label\"> tältä alueelta</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 ääni|2#2 ääntä|2<{1} ääntä|4<{1} ääntä|10#10 ääntä|10<{1} ääntä})",
  "hub.category.h2.business": "{0}: Suosituimmat liikematkat",
  "category.things_to_do": "Nähtävää ja tehtävää",
  "destination_travel_guide_by_category": "{0}: Matkaopas kategorioittain",
  "hub.category.h1.families": "{0}: Perhelomat",
  "lbg.section_theme_world_winners.ski": "Voittajat: hiihto",
  "you_may_also_like": "Sinua saattaisi kiinnostaa",
  "lbg.theme.sustainable": "Ympäristöystävällinen",
  "hub.category.h2.unusual": "{0}: Suositellut vähemmän tunnetut ja epätavalliset paikat",
  "hub.category.h2.football": "Tue jalkapallojoukkuettasi ja ota matkastasi kaikki irti! ",
  "top_hotel": "Huippuhotelli",
  "lbg.h1.destination_theme.luxury": "Luksus – voittajat kohteessa {0}",
  "top10_hotels_empty_alert": "Sivustollamme on parhaillaan tekninen ongelma. <span>Pahoittelemme siitä mahdollisesti aiheutuvaa haittaa.</span>",
  "special_deal": "Erikoistarjous",
  "lbg.h2.sustainable": "Tärkeintä on kestävyys! Tutustu näihin ympäristöystävällisiin majoitusliikkeisiin.",
  "where_to_stay_in": "{0}: Missä yöpyä?",
  "start_planning_your_trip": "Suunnittele matkasi",
  "good_for": "Kategoriat:",
  "category.hotels": "Hotellit",
  "x_accommodation_guide": "Kohteen {0} majoitusopas",
  "lbg.theme.luxury": "Luksus",
  "hub.category.h2.sports": "{0}: Suosituimmat urheilulajit ja aktiviteetit",
  "lbg.section_theme_world_winners.lgbtq": "LGBTQ-kategorian voittajat",
  "see_more_neighbourhoods": "Katso lisää alueita",
  "category_highlight.title.nightlife": "{0}: Minne mennä ja yöelämä",
  "lbg.h1.destination_theme.lgbt": "LGBTQ+ -palkinnon voittajat – {0}",
  "hub.category.h1.blogs": "{0}: Matkablogi",
  "lbg.h2.business": "Nämä majoitusliikkeet ovat saaneet korkeita arvosanoja liikematkustajilta.",
  "hotels": "Hotellit",
  "lbg.h2.pet_friendly": "Asiakkaat suosittelevat näitä majoitusliikkeitä, kun matkustat lemmikkisi kanssa.",
  "popular_landmarks_nearby": "Lähellä olevia suosittuja maamerkkejä",
  "popular_extractions_in": "{0}: Suosittuja nähtävyyksiä",
  "lbg.h2.all_inclusive": "Nämä huippuarvosteluja saaneet majoitusliikkeet poistavat takuulla stressin.",
  "travel_guide_by_category_header": "{0} – {1}",
  "lbg.travel_between": "Matkusta välillä {0}–{1}",
  "keep_exploring": "Tutustu myös",
  "what_you_need_to_know_before_you_go": "Hyvä tietää ennen lähtöä",
  "read_it": "Lue lisää",
  "view_on_map": "Katso kartalla",
  "lbg.h1.destination_theme.lgbtq": "LGBTQ-kategorian voittajat – {0}",
  "lbg.h2.beach": "Asiakkaat suosivat majoitusta rannalla, erityisesti näissä majoitusliikkeissä.",
  "lbg.h1.destination_theme.vip_access": "VIP Access -kategorian voittajat – {0}",
  "see_all_accommodation": "Näytä kaikki majoitukset",
  "shoppers": "Shoppailu",
  "whats_around": "{0}: Nähtävyydet ja aktiviteetit",
  "group": "Ryhmät",
  "hub.category.h2.couples": "{0}: Suosituimmat romanttiset lomat",
  "hub.category.h1.shopping": "{0}: Shoppailu",
  "travel_community": "{0} <span>&#124;</span> <span>Matkayhteisö</span>",
  "hub.category.h2.group": "{0}: Suosituimmat ryhmämatkat",
  "listicle_legal_msg": "Tämä artikkeli sisältää mielipiteitä Go Guides -toimitustiimiltä. Hotels.com maksaa kirjoittajille palkkion tällä sivustolla julkaisuista kirjoituksista esimerkiksi matka- ja muiden kulujen muodossa.",
  "lbg.h1.destination_theme.cleanliness": "Siisteys-kategorian voittajat – {0}",
  "menu.more": "Lisää",
  "lbg.section_properties_theme.lgbtq": "LGBTQ-kategorian voittajat – {0}",
  "lbg.section_award_winners_destination": "Voittajat kohteittain",
  "lbg.terms_conditions": "Ehdot ja rajoitukset",
  "hub.category.h2.nightlife": "{0}: Suosituin yöelämä",
  "hub.neighbourhood.h2": "{0}: Tekemistä",
  "lbg.section_award_winners_theme_destination": "Voittajat teemoittain – {0}",
  "lbg.section_award_world_winners": "Voittajat ympäri maailmaa",
  "things_to_see_and_do_in_other_cities": "Näkemistä ja tekemistä muissa kaupungeissa",
  "button.backToTop": "Takaisin ylös",
  "lbg.section_theme_world_winners.family": "Voittajat: perhe",
  "hotels_near": "{0}: Hotellit lähistöllä",
  "category.activity__sports_": "Aktiviteetit",
  "trust_module.get_reward_night": "Saat palkintoyön",
  "hub.category.h1.business": "{0}: Liikematkat",
  "category.food": "Ruoka",
  "other_popular_destinations_in": "{0}: Muita suosittuja kohteita",
  "browse_by_category": "{0}: Kategoriat",
  "see_more_attactions": "Katso lisää nähtävyyksiä",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "{0}: Lisätietoa",
  "lbg.h1.destination_theme.most_wanted": "Suosituimmat – voittajat kohteessa {0}",
  "see_all_properties_in": "{0}: Näytä majoituspaikat",
  "category.tours": "Kierrokset",
  "hub.category.h1.sports": "{0}: Urheilu ja aktiviteetit",
  "where_will_you_go_next": "Minne menet seuraavaksi?",
  "cities": "Kaupungit",
  "lbg.theme.spa_hotel": "Kylpylä",
  "see_guide": "Katso opas",
  "lbg.section_properties_theme.ski": "Voittajat: hiihto – {0}",
  "related_stories": "Aiheeseen liittyviä juttuja",
  "hub.destination.h2": "Hyvä tietää ennen lähtöä",
  "show_less": "Näytä vähemmän",
  "hub.category.h1.football": "{0}: Opas todellisille jalkapallofaneille",
  "hub.category.h2.food": "{0}: Suosituin ruoka ja ravintolat",
  "fallback_language_alert": "Valitettavasti tätä sivua ei ole vielä käännetty kielellesi...",
  "category_highlight.title.information": "Hyödyllistä tietoa",
  "neighbourhood_guides_for_other_cities": "Muiden kaupunkien alueoppaat",
  "lbg.h2.hubpage": "Nämä fantastiset majoitusliikkeet ovat saaneet korkeat asiakasarvosanat ja paljon arvosteluja.",
  "travel_advisory.title": "Matkustustiedote",
  "lbg.section_properties_theme.all_inclusive": "Voittajat: all inclusive – {0}",
  "lbg.h2.boutique": "Jos rakastat boutique-majoitusliikkeitä, asiakkaat suosittelevat majoittumaan täällä.",
  "lbg.section_properties_theme.family": "Voittajat: perhe – {0}",
  "hub.category.h2.families": "{0}: Suosituimmat perhelomat",
  "from": "alkaen",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "LGBTQ+ -palkinnon voittajat kohteessa {0}",
  "x_star": "{0,choice,0#|1#{0} tähti|1.5#{0} tähteä|2#{0} tähteä|2.5#{0} tähteä|3#{0} tähteä|3.5#{0} tähteä|4#{0} tähteä|4.5#{0} tähteä|5#{0} tähteä}",
  "trust_module.free_cancellation": "Ilmainen peruutus",
  "lbg.section_properties_theme.home_and_apartments": "Voittajat: kodit ja asunnot – {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Kirjoittaja</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span>: Nähtävää ja tehtävää",
  "lbg.check_price": "Tarkista hinta",
  "hub.category.h2.blogs": "Kirjoituksia kokeneilta matkailijoilta",
  "lbg.h2.home_and_apartments": "Etsitkö lomakotia? Tutustu näihin suosittuihin majoitustiloihin.",
  "hub.category.h2.information": "{0}: Hyödyllistä tietoa",
  "search_hotels_in": "<span class=\"extra-label\">{0}</span>: Hae hotelleja",
  "hub.category.h2.nature": "{0}: Suosituimmat luontoretket",
  "lbg.h1.destination_theme.home_and_apartments": "Kodit ja asunnot – voittajat kohteessa {0}",
  "photo_by": "Valokuva: {0}",
  "more_things_to_do": "Lisää aktiviteetteja",
  "lbg.section_properties_theme.beach": "Voittajat: ranta – {0}",
  "read_less": "Näytä vähemmän",
  "lbg.h2.spa_hotel": "Rentoudu näissä huippuarvostelut saaneissa majoitusliikkeissä, jotka tunnetaan kylpylöistään.",
  "second_fold_related_stories.see_also": "Katso myös",
  "hub.category.h2.history": "{0}: Suosituimmat historialliset paikat",
  "lbg.section_properties_theme.cleanliness": "Siisteys-kategorian voittajat – {0}",
  "lbg.h1.destination_theme.boutique": "Boutique – voittajat kohteessa {0}",
  "nightlife_b595ae4": "Yöelämä",
  "lbg.h1.destination_theme.beach": "Ranta – voittajat kohteessa {0}",
  "trust_module.free_cancellation.disclaimer": "Jotkut hotellit vaativat peruutuksen tekemistä viimeistään 24–48 tuntia ennen sisäänkirjautumista. Lue lisätiedot sivustolta.",
  "lbg.h2.family": "Nämä perheille sopivat majoitusliikkeet ovat saaneet asiakkailta korkeat arvosanat.",
  "lbg.h2.lgbtq": "Nämä LGBTQ-ystävälliset majoitustilat ovat saaneet asiakkailta korkeat arvosanat.",
  "hub.category.h1.group": "{0}: Ryhmämatkat",
  "popular_stories": "Suositut tarinat",
  "toc": "Sisältö",
  "hub.category.h2.shopping": "{0}: Suosituimmat ostospaikat",
  "lbg.section_theme_world_winners.beach": "Voittajat: ranta",
  "page_score": "Sivun arvosana",
  "lbg.section_theme_world_winners.sustainable": "Voittajat: ympäristöystävällinen",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Hintatakuu",
  "lbg.h1.destination_theme.all_inclusive": "All inclusive – voittajat kohteessa {0}",
  "top_destinations": "Huippukohteet",
  "hotel_prices_near": "{0}: Hotellien hinnat lähistöllä",
  "lbg.score.cleanliness": "Siisteys:",
  "x_stars": "{0,choice,0#|1#{0} tähti|1<{0} tähteä|4<{0} tähteä|10<{0} tähteä}",
  "lbg.theme.all_inclusive": "All inclusive",
  "hub.category.h1.tours": "{0}: Kierrokset ja päiväretket",
  "lbg.section_award_winners_theme": "Voittajat teemoittain",
  "guest_writer": "{0} <span>&#124;</span> <span>Vieraskirjoittaja</span>",
  "start_planning_your_x_trip": "Suunnittele matkasi kohteeseen {0}",
  "lbg.section_theme_world_winners.business": "Voittajat: business",
  "trending_now_in": "{0}: Nousussa olevat trendit",
  "history": "Historia",
  "search_hotels": "Hae hotelleja",
  "lbg.terms_conditions_details": "Vuoden {0} palkinnonsaajiksi valittiin sellaisia majoitusliikkeitä, joilla on vähintään 50 asiakasarvostelua ja jotka saivat korkeat asiakasarvosanat vuonna {1}.",
  "see_nearby_properties": "Näytä lähellä sijaitsevat majoitusliikkeet",
  "hub.category.h1.history": "{0}: Historialliset paikat",
  "location": "Osoite:",
  "lbg.section_theme_world_winners.luxury": "Voittajat: luksus",
  "category_highlight.see_all.nightlife": "Lisää yöelämästä",
  "also_popular": "Muita suosittuja",
  "hub.category.h2.things_to_do": "{0}: Suosituimmat aktiviteetit",
  "lbg.theme.family": "Perhe",
  "lbg.h1.destination_theme.pet_friendly": "Lemmikkiystävällinen – voittajat kohteessa {0}",
  "category_highlight.title.shopping": "{0}: Shoppailu ja parhaat ostokset",
  "price": "Hinta:",
  "hub.category.h2.beach": "{0}: Suosituimmat rannat",
  "neighbourhoods_in": "{0}: Alueet",
  "business": "Business",
  "photo": "Valokuvaus",
  "blog.see_all_stories": "Näytä kaikki blogit",
  "category_highlight.see_all.food": "Lisää ruoasta ja ravintoloista",
  "see_all_properties": "Näytä kaikki majoitusliikkeet",
  "lbg.theme.beach": "Ranta",
  "what_to_see_and_do_in": "{0}: Nähtävää ja tehtävää",
  "hub.category.h2.single": "{0}: Suosituimmat soolomatkat",
  "attractions_in": "{0}: Nähtävyydet",
  "read_more": "Lue lisää",
  "show_more": "Näytä lisää",
  "hotels_near_this_landmark": "Hotellit lähellä tätä maamerkkiä",
  "check_dates": "Tarkista päivämäärät",
  "featured_stories_and_fun_stuff": "Blogeja ja hauskaa tekemistä",
  "phone": "Puhelin:",
  "lbg.section_search_header": "Hae palkinnon voittajia",
  "category_highlight.title.food": "{0}: Mitä ja missä syödä",
  "see_all_things_to_do": "Näytä kaikki aktiviteetit",
  "lbg.section_properties_theme.sustainable": "Voittajat: ympäristöystävällinen – {0}",
  "lbg.h1.hubpage": "Asiakkaiden suosikki -palkinnon voittajat {0}!",
  "lbg.section_theme_world_winners.spa_hotel": "Voittajat: kylpylä",
  "category.maps": "Kartat",
  "lbg.h1.destination_theme.family": "Perhe – voittajat kohteessa {0}",
  "lbg.section_theme_world_winners.cleanliness": "Siisteys-kategorian voittajat",
  "lbg.section_theme_world_winners.vip_access": "VIP Access -kategorian voittajat",
  "trust_module.get_reward_night.night_you_stay": "jokaista yövyttyä 10 yötä kohti",
  "modified": "muokattu",
  "lbg.section_properties": "Voittajat – {0}",
  "trust_module.header": "Miksi kannattaa varata Hotels.comilta?",
  "hub.category.h2.luxury": "{0}: Suosituimmat luksuslomat",
  "lbg.theme.ski": "Hiihto",
  "lbg.h1.destination_theme.spa_hotel": "Kylpylä – voittajat kohteessa {0}",
  "lbg.h2.most_wanted": "Asiakkaat suosittelevat näitä majoitusliikkeitä, kun haet täydellistä majoitusta.",
  "top10_hotels_incomplete_alert": "Sivustollamme on parhaillaan paljon käyttäjiä, joten et välttämättä näe kaikkia majoitusliikkeitä. <span>Pahoittelemme siitä mahdollisesti aiheutuvaa haittaa.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Intohimoinen matkaaja</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Voittajat: kodit ja asunnot",
  "loved_by_guests_collection_in": "Asiakkaiden suosikit – {0}",
  "title.suffix": "{0} – Go Guides",
  "hub.category.h2.maps": "{0}: Hyödyllisiä karttoja",
  "opening_hours": "Aukioloajat",
  "hub.category.h2.photos": "{0}: Suosituimmat valokuvausmatkat",
  "lbg.theme.most_wanted": "Suosituimmat",
  "blog.stories_container": "Matkablogit",
  "hub.category.h2.budget": "{0}: Suosituimmat budjettilomat",
  "lbg.section_properties_theme.business": "Voittajat: business – {0}",
  "not_sure_where_to_stay": "Missä kannattaa yöpyä?",
  "category.information": "Tiedot"
};