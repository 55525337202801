import React from 'react';
import { checkValue } from 'src/components/functions';
import CardSizeM from './card-m';
import CardSizeXL from './card-xl';
var BucketTwoCard = function (_a) {
  var data = _a.data,
    highlight = _a.highlight;
  return React.createElement("ul", {
    className: "row item-list-container ".concat(highlight ? '' : 'list2')
  }, data.map(function (item, index) {
    return checkValue(highlight) ? index < 2 && React.createElement(CardSizeXL, {
      key: index,
      data: item
    }) : React.createElement(CardSizeM, {
      key: index,
      data: item
    });
  }));
};
export default BucketTwoCard;