import React from 'react';
import { checkValue } from 'src/components/functions';
import CardSizeS from './card-s';
import CardSizeM from './card-m';
import CardSizeXL from './card-xl';
var BucketFourCard = function (_a) {
  var data = _a.data,
    highlight = _a.highlight;
  return React.createElement("ul", {
    className: "row item-list-container ".concat(highlight ? '' : 'list4')
  }, data.map(function (item, index) {
    return checkValue(highlight) ? index === 0 ? React.createElement(CardSizeXL, {
      key: index,
      data: item
    }) : React.createElement(CardSizeS, {
      key: index,
      data: item
    }) : React.createElement(CardSizeM, {
      key: index,
      data: item
    });
  }));
};
export default BucketFourCard;