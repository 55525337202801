export default {
  "current_deal": "Pašreizējais piedāvājums",
  "lbg.section_properties_theme.pet_friendly": "Mājdzīvniekiem piemērotu naktsmītņu balvas ieguvējas — {0}",
  "address": "Adrese",
  "hub.homepage.h2": "Tik daudz vietu, bet tik maz laika...",
  "search_hotels_near_this_landmark": "Meklēt viesnīcas<span class=\"extra-label\"> šā orientiera tuvumā</span>",
  "hub.category.h1.things_to_do": "{0} — atpūtas un apskates iespējas",
  "lbg.h2.vip_access": "„VIP Access” naktsmītnes ir izvēlētas, jo tām ir piešķirts augsts vērtējums par uzturēšanos un lielisko pieredzi.",
  "lbg.theme.cleanliness": "Tīrība",
  "lbg.theme.lgbtq": "Vīrniekiem un sievniecēm (LGBTQ)",
  "load_more": "Ielādēt vairāk",
  "link_long_form_to_listiicle_multiple": "{0} — ietilpst šādās kategorijās: {1} un {2} (šeit pieejama informācija par to, ko piedāvā {3})",
  "lbg.section_properties_theme.boutique": "Dizaina naktsmītņu balvas ieguvējas — {0}",
  "lbg.h2.luxury": "Izbaudiet luksusu visā tā krāšņumā. Tāpēc viesiem patīk uzturēties šajās naktsmītnēs.",
  "hub.category.h1.information": "{0} — informācija",
  "families": "Ģimenes",
  "trending_now": "Šobrīd iecienīti galamērķi",
  "lbg.from": "Sākot ar",
  "hub.category.h1.road_trips": "Labākie sauszemes ceļojumi vietā: {0}",
  "lbg.h2.cleanliness": "Šīm naktsmītnēm ir lielisks kopējais viesu vērtējums un augstākais viesu vērtējums par tīrību.",
  "single": "Neprecēts",
  "couples": "Pāri",
  "trust_module.price_guarantee.find_out_more": "uzzināt vairāk",
  "top_destinations_in": "{0} — pieprasītākie galamērķi",
  "hub.category.h1.couples": "Pāru ceļojumi valstī: {0}",
  "hub.category.h2.tours": "{0} — labākās tūres",
  "hub.category.h1.photos": "Fotografēšanas ceļojumi valstī: {0}",
  "category.photos": "Attēli",
  "hub.category.h1.budget": "Budžeta ceļojumi valstī: {0}",
  "hub.category.h2.ski": "Labākie slēpošanas galamērķi — {0}",
  "lbg.section_theme_world_winners.most_wanted": "Iecienītāko naktsmītņu balvas ieguvējas",
  "lbg.theme.home_and_apartments": "Māju un dzīvokļu naktsmītnes",
  "hub.category.h1.luxury": "Luksusa ceļojumi valstī: {0}",
  "hub.category.h2.adventure": "Vislabākie aktīvie ceļojumi valstī: {0}",
  "hotels_in": "{0} — viesnīcas",
  "lbg.section_search_winners": "Meklēt citas balvas ieguvējas",
  "see_more_hotels_in": "{0} — skatīt citas viesnīcas",
  "hub.category.h1.beach": "Pludmales — {0}",
  "open": "Darba laiks:",
  "enter_your_destination": "Ievadiet galamērķi",
  "best_of": "{0} — iecienītas vietas",
  "top10_hotels_missing_properties": "Dažas naktsmītnes šobrīd var nebūt redzamas. Atvainojamies par sagādātajām neērtībām!",
  "adventure": "Piedzīvojumi",
  "lbg.h1.destination_theme.sustainable": "Ilgtspējīgu naktsmītņu balvas ieguvējas — {0}",
  "luxury": "Greznības",
  "expand_map_view": "Izvērst kartes skatu",
  "book_now": "Rezervēt tūlīt",
  "lbg.h1.destination_theme.ski": "Slēpošanas naktsmītņu balvas ieguvējas — {0}",
  "hub.category.h1.food": "{0} — ēdieni un maltīšu ieturēšanas iespējas",
  "category.blogs": "Emuārs",
  "budget": "Budžeta ceļojums",
  "popular_neighbourhoods_in": "{0} — iecienītas apkaimes",
  "hub.category.h1.unusual": "Īpaši atklājumi un neparastas vietas valstī: {0}",
  "see_all_accommodation_in": "Skatīt naktsmītnes vietā {0}",
  "hub.category.h1.adventure": "Aktīvās atpūtas ceļojumi valstī: {0}",
  "category_highlight.see_all.shopping": "Izvērstas ziņas par iepirkšanos",
  "lbg.section_most_popular_destination": "Iecienītākie galamērķi",
  "lbg.section_properties_theme.luxury": "Luksusa naktsmītņu balvas ieguvējas — {0}",
  "unusual": "Neparasts",
  "category.shopping": "Iepirkšanās",
  "make_the_most_of_your_trip": "Izmantojiet visas ceļojuma iespējas",
  "x_review": "{0,choice,0#|1#{0} atsauksme|2#{0} atsauksmes|2<{0} atsauksmes|4<{0} atsauksmes|10#{0} atsauksmju|10<{0} atsauksmes}",
  "hub.category.h1.single": "Ceļojumi vienatnē valstī: {0}",
  "hub.category.h2.custom": "Šobrīd iecienīti",
  "lbg.section_theme_world_winners.lgbt": "Vīrniekiem un sievniecēm (LGBTQ+) piemērotu naktsmītņu balvu ieguvējas",
  "lbg.section_award_winners_theme_other": "Citu tematiku naktsmītņu balvas ieguvējas — {0}",
  "lbg.supplier_collected_charges": "+ {0} jāmaksā naktsmītnē",
  "in_destination": " {0}",
  "lbg.section_properties_theme.vip_access": "„VIP Access” naktsmītņu balvas ieguvējas — {0}",
  "link_long_form_to_listiicle_single": "{0} — ietilpst šādā kategorijā: {1} (šeit pieejama informācija par to, ko piedāvā {2})",
  "lbg.section_theme_world_winners.boutique": "Dizaina naktsmītņu balvas ieguvējas",
  "lbg.section_properties_theme.spa_hotel": "Spa naktsmītņu balvas ieguvējas — {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Pilnas pansijas naktsmītņu balvas ieguvējas",
  "hub.category.h2": "{0} — tūrisma ceļvedis",
  "lbg.properties.price_tooltip": "Šī ir zemākā diennakts cena šajā tīmekļvietnē, un tā būs spēkā turpmākās 14 dienas.",
  "hub.category.h2.road_trips": "Labākie sauszemes ceļojumi vietā: {0}",
  "hub.category.h1.ski": "Slēpošanas galamērķi — {0}",
  "food": "Ēdiens",
  "lbg.theme.pet_friendly": "Mājdzīvniekiem piemērotas naktsmītnes",
  "lbg.theme.business": "Darījumiem piemērotas naktsmītnes",
  "hub.category.h1.nature": "Labākie ceļojumi dabas baudīšanai vietā: {0}",
  "map": "Karte",
  "category_highlight.see_all.information": "Izvērstas ziņas",
  "hotel_prices_in": "{0} — viesnīcu cenas",
  "hub.category.h1.nightlife": "{0} — naktsdzīve",
  "trust_module.free_cancellation.most_hotels": "lielākajā daļā viesnīcu*",
  "also_popular_in": "{0} — citas iecienītas vietas",
  "lbg.h1.destination_theme.business": "Darījumiem piemērotu naktsmītņu balvas ieguvējas — {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Mājdzīvniekiem piemērotu balvas ieguvējas",
  "hub.category.h1.maps": "Kartes par valsti: {0}",
  "lbg.section_properties_theme.most_wanted": "Iecienītāko naktsmītņu balvas ieguvējas — {0}",
  "category.nightlife": "Naktsdzīve",
  "search_hotels_in_this_area": "Meklēt viesnīcas<span class=\"extra-label\"> šajā apkārtnē</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 balss|2#2 balsis|2<{1} balsis|4<{1} balsis|10#10 balsu|10<{1} balsis})",
  "hub.category.h2.business": "Vislabākie darījumu ceļojumi valstī: {0}",
  "category.things_to_do": "Atpūtas un apskates vietas",
  "destination_travel_guide_by_category": "{0} — tematiskie ceļveži",
  "hub.category.h1.families": "Ģimenes ceļojums: {0}",
  "lbg.section_theme_world_winners.ski": "Slēpošanas naktsmītņu balvas ieguvējas",
  "you_may_also_like": "Jūs varētu interesēt arī šie raksti",
  "lbg.theme.sustainable": "Ilgtspējīgas naktsmītnes",
  "hub.category.h2.unusual": "Vislabākie īpašie atklājumi un neparastās vietas valstī: {0}",
  "hub.category.h2.football": "Atbalstiet savu futbola komandu un padariet ceļojumu neaizmirstamu.",
  "top_hotel": "Iecienīta viesnīca",
  "lbg.h1.destination_theme.luxury": "Luksusa naktsmītņu balvas ieguvējas — {0}",
  "top10_hotels_empty_alert": "Pašlaik ir radušies tehniski sarežģījumi. <span>Atvainojamies par sagādātajām neērtībām!</span>",
  "special_deal": "Īpašais piedāvājums",
  "lbg.h2.sustainable": "Ilgtspējai ir nozīme! Izskatiet šīs naktsmītnes ar lieliskām uzturēšanās iespējām.",
  "where_to_stay_in": "{0} — uzturēšanās vietas",
  "start_planning_your_trip": "Ceļojuma plānošana",
  "good_for": "Piemērotība:",
  "category.hotels": "Viesnīcas",
  "x_accommodation_guide": "{0} — naktsmītņu ceļvedis",
  "lbg.theme.luxury": "Luksusa naktsmītnes",
  "hub.category.h2.sports": "Vislabākais sports un aktivitātes valstī: {0}",
  "lbg.section_theme_world_winners.lgbtq": "Vīrniekiem un sievniecēm (LGBTQ) piemērotu naktsmītņu balvas ieguvējas",
  "see_more_neighbourhoods": "Skatīt citas apkaimes",
  "category_highlight.title.nightlife": "Kur doties un ko darīt nakts laikā vietā: {0}",
  "lbg.h1.destination_theme.lgbt": "{0} — vīrniekiem un sievniecēm (LGBTQ+) piemērotu naktsmītņu balvu ieguvējas",
  "hub.category.h1.blogs": "{0} — ceļotāju emuāri",
  "lbg.h2.business": "Darījumu ceļotāji ir augstu novērtējuši šīs naktsmītnes.",
  "hotels": "Viesnīcas",
  "lbg.h2.pet_friendly": "Viesi īpaši iesaka šīs naktsmītnes, kas ir piemērotas jums un jūsu lolojumdzīvniekam.",
  "popular_landmarks_nearby": "Iecienīti tuvumā esoši orientieri",
  "popular_extractions_in": "{0} — iecienītas apskates vietas",
  "lbg.h2.all_inclusive": "Šajās novērtētajās naktsmītnēs stress pazudīs kā nebijis.",
  "travel_guide_by_category_header": "{1} — {0}",
  "lbg.travel_between": "Ceļojiet laika posmā no {0} līdz {1}",
  "keep_exploring": "Iepazīstiet citas vietas",
  "what_you_need_to_know_before_you_go": "Kas jums jāzina pirms došanās ceļā",
  "read_it": "Izvērst",
  "view_on_map": "Skatīt kartē",
  "lbg.h1.destination_theme.lgbtq": "Vīrniekiem un sievniecēm (LGBTQ) piemērotu naktsmītņu balvas ieguvējas — {0}",
  "lbg.h2.beach": "Viesiem patīk uzturēties pludmalē, jo īpaši šajās naktsmītnēs.",
  "lbg.h1.destination_theme.vip_access": "„VIP Access” naktsmītņu balvas ieguvējas — {0}",
  "see_all_accommodation": "Skatīt visas naktsmītnes",
  "shoppers": "Iepirkšanās",
  "whats_around": "{0} — apkārtnes apskates vietas",
  "group": "Grupas",
  "hub.category.h2.couples": "Vislabākie pāru ceļojumi valstī: {0}",
  "hub.category.h1.shopping": "{0} — iepirkšanās",
  "travel_community": "{0} <span>&#124;</span><span>Rakstu sagatavojusi ceļotāju kopa</span>",
  "hub.category.h2.group": "Vislabākie grupu ceļojumi valstī: {0}",
  "listicle_legal_msg": "Šajā rakstā ir ietverts ceļvežu „Go Guides” redaktoru komandas viedoklis. Kad autoru rakstītais tiek parādīts šajā vietnē, uzņēmums „Hotels.com” izmaksā autoriem kompensācijas; šādās kompensācijās var ietilpt ceļojumu izmaksas un citas izmaksas.",
  "lbg.h1.destination_theme.cleanliness": "Tīrāko naktsmītņu balvas ieguvējas — {0}",
  "menu.more": "Vairāk",
  "lbg.section_properties_theme.lgbtq": "Vīrniekiem un sievniecēm (LGBTQ) piemērotu naktsmītņu balvas ieguvējas — {0}",
  "lbg.section_award_winners_destination": "Balvas ieguvējas pēc galamērķa",
  "lbg.terms_conditions": "Noteikumi un nosacījumi",
  "hub.category.h2.nightlife": "{0} — labākās naktsdzīves baudīšanas iespējas",
  "hub.neighbourhood.h2": "{0} — ko darīt šajā galamērķī",
  "lbg.section_award_winners_theme_destination": "Balvas ieguvējas pēc tematikas — {0}",
  "lbg.section_award_world_winners": "Pasaules līmeņa balvas ieguvējas",
  "things_to_see_and_do_in_other_cities": "Atpūtas un apskates iespējas citās pilsētās",
  "button.backToTop": "Atgriezties lapas augšā",
  "lbg.section_theme_world_winners.family": "Ģimenēm piemērotu naktsmītņu balvas ieguvējas",
  "hotels_near": "{0} — tuvējās viesnīcas",
  "category.activity__sports_": "Pasākumi",
  "trust_module.get_reward_night": "Saņemiet pateicības nakti",
  "hub.category.h1.business": "Darījumu ceļojumi valstī: {0}",
  "category.food": "Ēdieni un dzērieni",
  "other_popular_destinations_in": "Citi iecienīti galamērķi, ko piedāvā {0}",
  "browse_by_category": "{0} — pārlūkot pēc kategorijas",
  "see_more_attactions": "Skatīt citas apskates vietas",
  "lbg.theme.lgbt": "Vīrniekiem un sievniecēm piemērotas (LGBTQ+) naktsmītnes",
  "more_information_about": "{0} — papildinformācija",
  "lbg.h1.destination_theme.most_wanted": "Iecienītāko naktsmītņu balvas ieguvējas — {0}",
  "see_all_properties_in": "{0} — skatīt naktsmītnes",
  "category.tours": "Tūres",
  "hub.category.h1.sports": "Sports un aktivitātes valstī: {0}",
  "where_will_you_go_next": "Kurp dosities nākamreiz?",
  "cities": "Pilsētas",
  "lbg.theme.spa_hotel": "Spa naktsmītnes",
  "see_guide": "Skatīt ceļvedi",
  "lbg.section_properties_theme.ski": "Slēpošanas naktsmītņu balvas ieguvējas — {0}",
  "related_stories": "Saistītie raksti",
  "hub.destination.h2": "Kas jāzina pirms došanās ceļā.",
  "show_less": "Sakļaut",
  "hub.category.h1.football": "Kaismīgiem futbola līdzjutējiem domāts visaptverošs ceļvedis pilsētai {0}",
  "hub.category.h2.food": "{0} — labākās maltītes un ēdināšanas iespējas",
  "fallback_language_alert": "Atvainojiet! Šī sadaļa vēl nav pārtulkota latviešu valodā...",
  "category_highlight.title.information": "Noderīga informācija",
  "neighbourhood_guides_for_other_cities": "Citu pilsētu apkaimju ceļveži",
  "lbg.h2.hubpage": "Šīs brīnišķīgās naktsmītnes ir saņēmušas augstus viesu vērtējumus un daudz atsauksmju.",
  "travel_advisory.title": "Ceļojuma brīdinājums",
  "lbg.section_properties_theme.all_inclusive": "Pilnas pansijas naktsmītņu balvas ieguvējas — {0}",
  "lbg.h2.boutique": "Ja jums patīk dizaina naktsmītnes, viesi īpaši iesaka šeit uzturēties.",
  "lbg.section_properties_theme.family": "Ģimenēm piemērotu naktsmītņu balvas ieguvējas — {0}",
  "hub.category.h2.families": "Labākais ģimenes ceļojums: {0}",
  "from": "no",
  "lbg.theme.vip_access": "„VIP Access”",
  "lbg.section_properties_theme.lgbt": "{0} — vīrniekiem un sievniecēm (LGBTQ+) piemērotu naktsmītņu balvu ieguvējas",
  "x_star": "{0,choice,0#|1#{0} zvaigznes|1.5#{0} zvaigžņu|2#{0} zvaigžņu|2.5#{0} zvaigžņu|3#{0} zvaigžņu|3.5#{0} zvaigžņu|4#{0} zvaigžņu|4.5#{0} zvaigžņu|5#{0} zvaigžņu} naktsmītne",
  "trust_module.free_cancellation": "Bezmaksas atcelšana",
  "lbg.section_properties_theme.home_and_apartments": "Māju un dzīvokļu naktsmītņu balvas ieguvējas — {0}",
  "contributing_writer": "{0} <span>&#124;</span><span>Raksta autors ir uzņēmuma „Hotels.com” darbinieks</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}</span> — atpūtas un apskates iespējas",
  "lbg.check_price": "Skatīt cenas",
  "hub.category.h2.blogs": "Pieredzējušu ceļotāju stāsti",
  "lbg.h2.home_and_apartments": "Vai meklējat uzturēšanās vietu, kur varētu justies kā mājās? Izskatiet šīs augstu novērtētās naktsmītnes.",
  "hub.category.h2.information": "{0} — noderīgas ziņas",
  "search_hotels_in": "Meklēt viesnīcas<span class=\"extra-label\"> vietā {0}</span>",
  "hub.category.h2.nature": "Labākie ceļojumi pie dabas vietā: {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Māju un dzīvokļu naktsmītņu balvas ieguvējas — {0}",
  "photo_by": "Fotoattēla avots: {0}",
  "more_things_to_do": "Vēl citas atpūtas un apskates vietas",
  "lbg.section_properties_theme.beach": "Pludmales naktsmītņu balvas ieguvējas — {0}",
  "read_less": "Sakļaut",
  "lbg.h2.spa_hotel": "Apturiet ikdienas straujo ritmu un atpūtieties šajās lieliskajās naktsmītnēs, kas ir iecienītas spa dēļ.",
  "second_fold_related_stories.see_also": "Skatiet arī",
  "hub.category.h2.history": "Vislabākās vēsturiskās vietas valstī: {0}",
  "lbg.section_properties_theme.cleanliness": "Tīrāko naktsmītņu balvas ieguvējas — {0}",
  "lbg.h1.destination_theme.boutique": "Dizaina naktsmītņu balvas ieguvējas — {0}",
  "nightlife_b595ae4": "Naktsdzīve",
  "lbg.h1.destination_theme.beach": "Pludmales naktsmītņu balvas ieguvējas — {0}",
  "trust_module.free_cancellation.disclaimer": "Dažas viesnīcas pieprasa rezervējumu atcelt ne vēlāk kā 24-48 stundas pirms reģistrēšanās datuma. Izvērsta informācija vietnē.",
  "lbg.h2.family": "Šīm naktsmītnēm ir augsts viesu vērtējums un tās ir lieliski piemērotas ģimenēm.",
  "lbg.h2.lgbtq": "Viesi ir piešķīruši augstus vērtējumus šīm sievniecēm un vīrniekiem (LGBTQ) piemērotajām naktsmītnēm.",
  "hub.category.h1.group": "Grupu ceļojumi valstī: {0}",
  "popular_stories": "Iecienīti raksti",
  "toc": "Satura rādītājs",
  "hub.category.h2.shopping": "{0} — labākās iepirkšanās iespējas",
  "lbg.section_theme_world_winners.beach": "Pludmales naktsmītņu balvas ieguvējas",
  "page_score": "Lapas vērtējums",
  "lbg.section_theme_world_winners.sustainable": "Ilgtspējīgu naktsmītņu balvas ieguvējas",
  "lbg.theme.boutique": "Dizaina naktsmītnes",
  "trust_module.price_guarantee": "Cenas garantija",
  "lbg.h1.destination_theme.all_inclusive": "Pilnas pansijas naktsmītņu balvas ieguvējas — {0}",
  "top_destinations": "Pieprasītākie galamērķi",
  "hotel_prices_near": "{0} — tuvumā esošo viesnīcu cenas",
  "lbg.score.cleanliness": "Tīrība:",
  "x_stars": "{0,choice,0#|1#{0} zvaigzne|1<{0} zvaigznes|4<{0} zvaigznes|10<{0} zvaigznes}",
  "lbg.theme.all_inclusive": "Pilnas pansijas naktsmītnes",
  "hub.category.h1.tours": "{0} — tūres un vienas dienas ekskursijas",
  "lbg.section_award_winners_theme": "Balvas ieguvējas pēc tematikas",
  "guest_writer": "{0} <span>&#124;</span><span>Raksta autors nav uzņēmuma „Hotels.com” darbinieks</span>",
  "start_planning_your_x_trip": "Ceļojuma plānošana — {0}",
  "lbg.section_theme_world_winners.business": "Darījumiem piemērotu naktsmītņu balvas ieguvējas",
  "trending_now_in": "{0} — pašreizējās tendences",
  "history": "Vēsture",
  "search_hotels": "Meklēt viesnīcas",
  "lbg.terms_conditions_details": "{0}. gadā balvas saņēmušās naktsmītnes izvēlētas, ņemot vērā augstākos viesu vērtējumus un vismaz 50 viesu atsauksmes, kas iesniegtas {1}. gadā.",
  "see_nearby_properties": "Skatīt tuvumā esošās naktsmītnes",
  "hub.category.h1.history": "Vēsturiskas vietas valstī: {0}",
  "location": "Atrašanās vieta:",
  "lbg.section_theme_world_winners.luxury": "Luksusa naktsmītņu balvas ieguvējas",
  "category_highlight.see_all.nightlife": "Izvērstas ziņas par naktsdzīvi",
  "also_popular": "Citi iecienīti galamērķi",
  "hub.category.h2.things_to_do": "{0} — labākās apskates vietas",
  "lbg.theme.family": "Ģimenēm piemērotas naktsmītnes",
  "lbg.h1.destination_theme.pet_friendly": "Mājdzīvniekiem piemērotu naktsmītņu balvas ieguvējas — {0}",
  "category_highlight.title.shopping": "Kur iepirkties un ko pirkt vietā: {0}",
  "price": "Cena:",
  "hub.category.h2.beach": "Labākās pludmales — {0}",
  "neighbourhoods_in": "{0} un apkaimes",
  "business": "Darījumu braucieni",
  "photo": "Fotografēšana",
  "blog.see_all_stories": "Skatīt visus rakstus",
  "category_highlight.see_all.food": "Izvērstas ziņas par maltītēm un ēdināšanas iespējām",
  "see_all_properties": "Skatīt visas naktsmītnes",
  "lbg.theme.beach": "Pludmales naktsmītnes",
  "what_to_see_and_do_in": "Ko apskatīt un darīt vietā: {0}",
  "hub.category.h2.single": "Vislabākie ceļojumi vienatnē valstī: {0}",
  "attractions_in": "{1} — apskates vietas",
  "read_more": "Izvērst",
  "show_more": "Izvērst",
  "hotels_near_this_landmark": "Viesnīcas šī orientiera tuvumā",
  "check_dates": "Pārbaudīt datumus",
  "featured_stories_and_fun_stuff": "Ievērību cienīgi un izklaidējoši raksti",
  "phone": "Tālrunis:",
  "lbg.section_search_header": "Balvu ieguvēju meklēšana",
  "category_highlight.title.food": "Kur un ar ko mieloties vietā: {0}",
  "see_all_things_to_do": "Skatīt visas iespējas",
  "lbg.section_properties_theme.sustainable": "Ilgtspējīgu naktsmītņu balvas ieguvējas — {0}",
  "lbg.h1.hubpage": "{0} gada balvas „Viesiem patīk” ieguvējas!",
  "lbg.section_theme_world_winners.spa_hotel": "Spa naktsmītņu balvas ieguvējas",
  "category.maps": "Kartes",
  "lbg.h1.destination_theme.family": "Ģimenēm piemērotu naktsmītņu balvas ieguvējas — {0}",
  "lbg.section_theme_world_winners.cleanliness": "Tīrāko naktsmītņu balvas ieguvējas",
  "lbg.section_theme_world_winners.vip_access": "„VIP Access” naktsmītņu balvas ieguvējas",
  "trust_module.get_reward_night.night_you_stay": "par katrām 10 uzturēšanās naktīm",
  "modified": "apstrādāts",
  "lbg.section_properties": "Balvas ieguvējas — {0}",
  "trust_module.header": "Kāpēc rezervēt tīmekļvietnē Hotels.com?",
  "hub.category.h2.luxury": "Vislabākie luksusa ceļojumi valstī: {0}",
  "lbg.theme.ski": "Slēpošanas naktsmītnes",
  "lbg.h1.destination_theme.spa_hotel": "Spa naktsmītņu balvas ieguvējas — {0}",
  "lbg.h2.most_wanted": "Viesi īpaši iesaka šīs naktsmītnes kā lieliskas vietas.",
  "top10_hotels_incomplete_alert": "Šobrīd ir ļoti liels pieprasījums, tāpēc pašlaik dažas naktsmītnes nav redzamas. <span>Atvainojamies par sagādātajām neērtībām!</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>bieži ceļo</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Māju dzīvokļu naktsmītņu balvas ieguvējas",
  "loved_by_guests_collection_in": "{0} — kolekcija „Viesiem patīk”",
  "title.suffix": "{0} ceļveži",
  "hub.category.h2.maps": "{0} — noderīgas kartes",
  "opening_hours": "Darba laiks",
  "hub.category.h2.photos": "Vislabākie fotografēšanas ceļojumi valstī: {0}",
  "lbg.theme.most_wanted": "Iecienītākās naktsmītnes",
  "blog.stories_container": "Raksti no līdzstrādniekiem",
  "hub.category.h2.budget": "Vislabākie budžeta ceļojumi valstī: {0}",
  "lbg.section_properties_theme.business": "Darījumiem piemērotu naktsmītņu balvas ieguvējas — {0}",
  "not_sure_where_to_stay": "Vai nevarat izvēlēties nakšņošanas vietu?",
  "category.information": "Informācija"
};