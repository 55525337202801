export default {
  "current_deal": "Tawaran semasa",
  "lbg.section_properties_theme.pet_friendly": "Pemenang anugerah mesra haiwan kesayangan dalam {0}",
  "address": "Alamat",
  "hub.homepage.h2": "Andai masa bukan satu halangan...",
  "search_hotels_near_this_landmark": "Cari hotel<span class=\"extra-label\"> berdekatan mercu tanda ini</span>",
  "hub.category.h1.things_to_do": "Aktiviti Menarik di {0}",
  "lbg.h2.vip_access": "Hartanah VIP Access telah dipilih untuk penginapan yang dinilai tinggi serta pengalaman yang lebih bermutu.",
  "lbg.theme.cleanliness": "Kebersihan",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "Muatkan lebih banyak",
  "link_long_form_to_listiicle_multiple": "{0} - salah sebuah sorotan untuk {1} dan {2} (Baca tentang {3} di sini)",
  "lbg.section_properties_theme.boutique": "Pemenang anugerah butik dalam {0}",
  "lbg.h2.luxury": "Terokai kemewahan yang luar biasa. Itu sebabnya tetamu suka menginap di hartanah ini.",
  "hub.category.h1.information": "Maklumat berkenaan {0}",
  "families": "Keluarga",
  "trending_now": "Popular sekarang",
  "lbg.from": "Dari",
  "hub.category.h1.road_trips": "Pengembaraan Jalan Raya terbaik di {0}",
  "lbg.h2.cleanliness": "Hartanah ini mempunyai skor penilaian tetamu keseluruhan yang bagus serta skor tetamu yang tinggi untuk kebersihan.",
  "single": "Bujang",
  "couples": "Pasangan",
  "trust_module.price_guarantee.find_out_more": "ketahui lebih lanjut",
  "top_destinations_in": "Destinasi popular di {0}",
  "hub.category.h1.couples": "Perjalanan Pasangan di {0}",
  "hub.category.h2.tours": "Lawatan terbaik di {0}",
  "hub.category.h1.photos": "Perjalanan Fotografi di {0}",
  "category.photos": "Foto",
  "hub.category.h1.budget": "Perjalanan Bajet di {0}",
  "hub.category.h2.ski": "Destinasi ski terbaik di {0}",
  "lbg.section_theme_world_winners.most_wanted": "Pemenang anugerah most wanted",
  "lbg.theme.home_and_apartments": "Rumah & Pangsapuri",
  "hub.category.h1.luxury": "Perjalanan Mewah di {0}",
  "hub.category.h2.adventure": "Perjalanan aktif paling popular di {0}",
  "hotels_in": "Hotel di {0}",
  "lbg.section_search_winners": "Cari lebih banyak pemenang anugerah",
  "see_more_hotels_in": "Lihat lebih banyak hotel di {0}",
  "hub.category.h1.beach": "Pantai di {0}",
  "open": "Buka:",
  "enter_your_destination": "Masukkan destinasi anda",
  "best_of": "Terbaik di {0}",
  "top10_hotels_missing_properties": "Sesetengah hartanah mungkin tidak kelihatan buat masa ini. Segala kesulitan amat dikesali.",
  "adventure": "Kembara",
  "lbg.h1.destination_theme.sustainable": "Pemenang Anugerah Mapan dalam {0}",
  "luxury": "Mewah",
  "expand_map_view": "Kembangkan paparan peta",
  "book_now": "Tempah sekarang",
  "lbg.h1.destination_theme.ski": "Pemenang Anugerah Ski dalam {0}",
  "hub.category.h1.food": "Makanan dan Tempat Makan di {0}",
  "category.blogs": "Blog",
  "budget": "Bajet",
  "popular_neighbourhoods_in": "Kejiranan popular di {0}",
  "hub.category.h1.unusual": "Permata Tersembunyi dan Tempat Unik di {0}",
  "see_all_accommodation_in": "Lihat penginapan dalam {0}",
  "hub.category.h1.adventure": "Perjalanan Aktif di {0}",
  "category_highlight.see_all.shopping": "Lebih lanjut tentang membeli-belah",
  "lbg.section_most_popular_destination": "Destinasi paling popular",
  "lbg.section_properties_theme.luxury": "Pemenang anugerah mewah dalam {0}",
  "unusual": "Luar biasa",
  "category.shopping": "Beli-belah",
  "make_the_most_of_your_trip": "Manfaatkan perjalanan anda dengan sepenuhnya",
  "x_review": "{0,choice,0#|1#{0} ulasan|2#{0} ulasan|2<{0} ulasan|4<{0} ulasan|10#{0} ulasan|10<{0} ulasan}",
  "hub.category.h1.single": "Perjalanan Solo di {0}",
  "hub.category.h2.custom": "Popular sekarang",
  "lbg.section_theme_world_winners.lgbt": "Pemenang anugerah LGBTQ+",
  "lbg.section_award_winners_theme_other": "Tema pemenang anugerah lain dalam {0}",
  "lbg.supplier_collected_charges": "+ {0} caj perlu dibayar di hartanah",
  "in_destination": " di {0}",
  "lbg.section_properties_theme.vip_access": "Pemenang anugerah VIP Access di {0}",
  "link_long_form_to_listiicle_single": "{0} - salah sebuah sorotan untuk {1} (Baca tentang {2} di sini)",
  "lbg.section_theme_world_winners.boutique": "Pemenang anugerah butik",
  "lbg.section_properties_theme.spa_hotel": "Pemenang anugerah spa dalam {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Pemenang anugerah merangkumi semua",
  "hub.category.h2": "Panduan perjalanan di {0}",
  "lbg.properties.price_tooltip": "Ini harga terendah untuk semalam di laman kami untuk 14 hari akan datang.",
  "hub.category.h2.road_trips": "Pengembaraan Jalan Raya terbaik di {0}",
  "hub.category.h1.ski": "Destinasi ski di {0}",
  "food": "Makanan",
  "lbg.theme.pet_friendly": "Mesra Haiwan Kesayangan",
  "lbg.theme.business": "Business",
  "hub.category.h1.nature": "Perjalanan Terbaik untuk Menikmati Alam Semula Jadi di {0}",
  "map": "Peta",
  "category_highlight.see_all.information": "Maklumat lanjut",
  "hotel_prices_in": "Harga hotel di {0}",
  "hub.category.h1.nightlife": "Hiburan malam di {0}",
  "trust_module.free_cancellation.most_hotels": "untuk kebanyakan hotel*",
  "also_popular_in": "Turut popular di {0}",
  "lbg.h1.destination_theme.business": "Pemenang Anugerah Business dalam {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Pemenang anugerah mesra haiwan kesayangan",
  "hub.category.h1.maps": "Peta {0}",
  "lbg.section_properties_theme.most_wanted": "Pemenang anugerah most wanted dalam {0}",
  "category.nightlife": "Hiburan malam",
  "search_hotels_in_this_area": "Cari hotel<span class=\"extra-label\"> di kawasan ini</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 undian|2#2 undian|2<{1} undian|4<{1} undian|10#10 undian|10<{1} undian})",
  "hub.category.h2.business": "Perjalanan perniagaan paling popular di {0}",
  "category.things_to_do": "Aktiviti menarik",
  "destination_travel_guide_by_category": "{0} Panduan Perjalanan mengikut Kategori",
  "hub.category.h1.families": "Perjalanan Keluarga di {0}",
  "lbg.section_theme_world_winners.ski": "Pemenang anugerah ski",
  "you_may_also_like": "Anda juga mungkin berminat",
  "lbg.theme.sustainable": "Mapan",
  "hub.category.h2.unusual": "Permata tersembunyi dan tempat unik paling popular di {0}",
  "hub.category.h2.football": "Sokong pasukan bola sepak anda dan manfaatkan kunjungan anda!",
  "top_hotel": "Hotel Terkemuka",
  "lbg.h1.destination_theme.luxury": "Pemenang Anugerah Mewah dalam {0}",
  "top10_hotels_empty_alert": "Kami sedang mengalami masalah teknikal. <span>Segala kesulitan amat dikesali.</span>",
  "special_deal": "Tawaran Istimewa",
  "lbg.h2.sustainable": "Cintai alam kita! Lihat hartanah ini yang boleh menjadikan penginapan lebih menakjubkan.",
  "where_to_stay_in": "Tempat menginap di {0}",
  "start_planning_your_trip": "Mula merancang perjalanan anda",
  "good_for": "Baik untuk:",
  "category.hotels": "Hotel",
  "x_accommodation_guide": "Panduan Penginapan di {0}",
  "lbg.theme.luxury": "Mewah",
  "hub.category.h2.sports": "Sukan dan aktiviti paling popular di {0}",
  "lbg.section_theme_world_winners.lgbtq": "Pemenang anugerah LGBTQ",
  "see_more_neighbourhoods": "Lihat lebih banyak kejiranan",
  "category_highlight.title.nightlife": "Tempat untuk dikunjungi & aktiviti malam di {0}",
  "lbg.h1.destination_theme.lgbt": "Pemenang anugerah LGBTQ+ di {0}",
  "hub.category.h1.blogs": "Blog Perjalanan {0}",
  "lbg.h2.business": "Hartanah ini telah dinilai tinggi oleh pengembara perniagaan.",
  "hotels": "Hotel",
  "lbg.h2.pet_friendly": "Tetamu amat mengesyorkan hartanah ini kerana mengalu-alukan anda serta haiwan kesayangan anda.",
  "popular_landmarks_nearby": "Mercu tanda popular berdekatan",
  "popular_extractions_in": "Tarikan popular di {0}",
  "lbg.h2.all_inclusive": "Hartanah dinilai tinggi ini menghilangkan keresahan anda.",
  "travel_guide_by_category_header": "{0} terbaik di {1}",
  "lbg.travel_between": "Perjalanan antara {0} - {1}",
  "keep_exploring": "Teruskan meneroka",
  "what_you_need_to_know_before_you_go": "Perkara yang Perlu Diketahui Sebelum Berkunjung",
  "read_it": "Baca",
  "view_on_map": "Lihat pada peta",
  "lbg.h1.destination_theme.lgbtq": "Pemenang anugerah LGBTQ di {0}",
  "lbg.h2.beach": "Tetamu menggemari penginapan pantai, terutamanya di hartanah ini.",
  "lbg.h1.destination_theme.vip_access": "Pemenang anugerah VIP Access di {0}",
  "see_all_accommodation": "Lihat semua penginapan",
  "shoppers": "Pembeli-belah",
  "whats_around": "tempat berdekatan {0}",
  "group": "Kumpulan",
  "hub.category.h2.couples": "Perjalanan pasangan paling popular di {0}",
  "hub.category.h1.shopping": "Beli-belah di {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Komuniti Perjalanan</span>",
  "hub.category.h2.group": "Perjalanan kumpulan paling popular di {0}",
  "listicle_legal_msg": "Artikel ini termasuk pendapat pasukan editorial Go Guides Hotels.com memberi pampasan kepada pengarang bagi penulisan mereka yang muncul di laman ini; pampasan tersebut mungkin termasuk perjalanan dan lain-lain kos.",
  "lbg.h1.destination_theme.cleanliness": "Pemenang anugerah kebersihan di {0}",
  "menu.more": "Lagi",
  "lbg.section_properties_theme.lgbtq": "Pemenang anugerah LGBTQ di {0}",
  "lbg.section_award_winners_destination": "Pemenang anugerah mengikut destinasi",
  "lbg.terms_conditions": "Terma & Syarat",
  "hub.category.h2.nightlife": "Aktiviti malam yang terbaik di {0}",
  "hub.neighbourhood.h2": "Apakah aktiviti menarik di {0}",
  "lbg.section_award_winners_theme_destination": "Pemenang anugerah mengikut tema dalam {0}",
  "lbg.section_award_world_winners": "Pemenang anugerah di seluruh dunia",
  "things_to_see_and_do_in_other_cities": "Perkara untuk dilihat dan dilakukan di bandar lain",
  "button.backToTop": "Balik ke atas",
  "lbg.section_theme_world_winners.family": "Pemenang anugerah family",
  "hotels_near": "Hotel berdekatan {0}",
  "category.activity__sports_": "Aktiviti",
  "trust_module.get_reward_night": "Dapatkan malam reward",
  "hub.category.h1.business": "Perjalanan Perniagaan di {0}",
  "category.food": "Makanan",
  "other_popular_destinations_in": "Destinasi popular lain di {0}",
  "browse_by_category": "Semak imbas {0} mengikut kategori",
  "see_more_attactions": "Lihat lebih banyak tarikan",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "Maklumat lanjut tentang {0}",
  "lbg.h1.destination_theme.most_wanted": "Pemenang Anugerah Most Wanted dalam {0}",
  "see_all_properties_in": "Lihat hartanah di {0}",
  "category.tours": "Khidmat pelancongan",
  "hub.category.h1.sports": "Sukan dan Aktiviti di {0}",
  "where_will_you_go_next": "Ke mana destinasi anda yang seterusnya?",
  "cities": "Bandar",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Lihat panduan",
  "lbg.section_properties_theme.ski": "Pemenang anugerah ski dalam {0}",
  "related_stories": "Kisah berkaitan",
  "hub.destination.h2": "Perkara yang perlu diketahui sebelum berkunjung",
  "show_less": "Tunjukkan sedikit",
  "hub.category.h1.football": "Panduan Utama ke {0} untuk Peminat Sejati Bola Sepak",
  "hub.category.h2.food": "Makanan dan tempat makanan yang terbaik {0}",
  "fallback_language_alert": "Maaf, halaman ini belum diterjemahkan ke dalam bahasa anda...",
  "category_highlight.title.information": "Maklumat berguna",
  "neighbourhood_guides_for_other_cities": "Panduan kejiranan untuk bandar lain",
  "lbg.h2.hubpage": "Hartanah menakjubkan ini mempunyai skor penilaian tetamu yang tinggi serta banyak ulasan.",
  "travel_advisory.title": "Peringatan perjalanan",
  "lbg.section_properties_theme.all_inclusive": "Pemenang anugerah merangkumi semua dalam {0}",
  "lbg.h2.boutique": "Jika anda menyukai hartanah butik, tetamu amat mengesyorkan penginapan di sini.",
  "lbg.section_properties_theme.family": "Pemenang anugerah family dalam {0}",
  "hub.category.h2.families": "Perjalanan keluarga terbaik di {0}",
  "from": "daripada",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Pemenang anugerah LGBTQ+ di {0}",
  "x_star": "{0,choice,0#|1#{0} bintang|1.5#{0} bintang|2#{0} bintang|2.5#{0} bintang|3#{0} bintang|3.5#{0} bintang|4#{0} bintang|4.5#{0} bintang|5#{0} bintang}",
  "trust_module.free_cancellation": "Pembatalan percuma",
  "lbg.section_properties_theme.home_and_apartments": "Pemenang anugerah rumah & pangsapuri dalam {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Penulis Aktif</span>",
  "things_to_see_and_do_in": "Perkara untuk dilihat dan dilakukan <span class=\"next-line\">di {0}</span>",
  "lbg.check_price": "Semak harga",
  "hub.category.h2.blogs": "oleh Pengembara berpengalaman",
  "lbg.h2.home_and_apartments": "Mencari penginapan dengan keselesaan seperti di rumah? Lihat hartanah yang dinilai tinggi ini.",
  "hub.category.h2.information": "Maklumat berguna tentang {0}",
  "search_hotels_in": "Cari hotel<span class=\"extra-label\"> di {0}</span>",
  "hub.category.h2.nature": "Perjalanan Terbaik untuk Menikmati Alam Semula Jadi di {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Pemenang Anugerah Rumah & Pangsapuri dalam {0}",
  "photo_by": "foto oleh {0}",
  "more_things_to_do": "Aktiviti menarik lain",
  "lbg.section_properties_theme.beach": "Pemenang anugerah pantai dalam {0}",
  "read_less": "Baca kurang",
  "lbg.h2.spa_hotel": "Bersantai dan berehat di hartanah dinilai tinggi ini yang terkenal dengan spa mereka.",
  "second_fold_related_stories.see_also": "Lihat juga",
  "hub.category.h2.history": "Tempat bersejarah paling popular di {0}",
  "lbg.section_properties_theme.cleanliness": "Pemenang anugerah kebersihan di {0}",
  "lbg.h1.destination_theme.boutique": "Pemenang Anugerah Butik dalam {0}",
  "nightlife_b595ae4": "Hiburan malam",
  "lbg.h1.destination_theme.beach": "Pemenang Anugerah Pantai dalam {0}",
  "trust_module.free_cancellation.disclaimer": "Sesetengah hotel memerlukan anda untuk melakukan pembatalan sekurang-kurangnya 24-48 jam sebelum daftar masuk. Butiran lanjut di laman.",
  "lbg.h2.family": "Hartanah ini dinilai tinggi oleh tetamu dan sesuai untuk keluarga.",
  "lbg.h2.lgbtq": "Hartanah mesra LGBTQ ini telah dinilai tinggi oleh tetamu.",
  "hub.category.h1.group": "Perjalanan Kumpulan di {0}",
  "popular_stories": "Kisah popular",
  "toc": "Kandungan",
  "hub.category.h2.shopping": "Beli-belah yang terbaik di {0}",
  "lbg.section_theme_world_winners.beach": "Pemenang anugerah pantai",
  "page_score": "Skor halaman ini",
  "lbg.section_theme_world_winners.sustainable": "Pemenang anugerah mapan",
  "lbg.theme.boutique": "Butik",
  "trust_module.price_guarantee": "Jaminan harga kami",
  "lbg.h1.destination_theme.all_inclusive": "Pemenang Anugerah Merangkumi Semua dalam {0}",
  "top_destinations": "Destinasi popular",
  "hotel_prices_near": "Harga hotel berhampiran {0}",
  "lbg.score.cleanliness": "Kebersihan:",
  "x_stars": "{0,choice,0#|1#{0} bintang|1<{0} bintang|4<{0} bintang|10<{0} bintang}",
  "lbg.theme.all_inclusive": "Merangkumi Semua",
  "hub.category.h1.tours": "Lawatan dan Kunjungan Balik Hari di {0}",
  "lbg.section_award_winners_theme": "Pemenang anugerah mengikut tema",
  "guest_writer": "{0} <span>&#124;</span> <span>Penulis Tamu</span>",
  "start_planning_your_x_trip": "Mula merancang perjalanan anda ke {0}",
  "lbg.section_theme_world_winners.business": "Pemenang anugerah business",
  "trending_now_in": "Kini popular di {0}",
  "history": "Sejarah",
  "search_hotels": "Cari hotel",
  "lbg.terms_conditions_details": "Kriteria untuk sebarang hartanah yang dipilih sebagai pemenang anugerah pada {0} adalah berdasarkan skor penilaian tetamu yang tinggi pada {1} dan minimum 50 ulasan tetamu.",
  "see_nearby_properties": "Lihat hartanah berdekatan",
  "hub.category.h1.history": "Tempat Bersejarah di {0}",
  "location": "Lokasi:",
  "lbg.section_theme_world_winners.luxury": "Pemenang anugerah mewah",
  "category_highlight.see_all.nightlife": "Lebih lanjut tentang hiburan malam",
  "also_popular": "Turut popular",
  "hub.category.h2.things_to_do": "Perkara terbaik untuk dilakukan di {0}",
  "lbg.theme.family": "Family",
  "lbg.h1.destination_theme.pet_friendly": "Pemenang Anugerah Mesra Haiwan Kesayangan dalam {0}",
  "category_highlight.title.shopping": "Tempat membeli-belah & apa perlu dibeli di {0}",
  "price": "Harga:",
  "hub.category.h2.beach": "Pantai terbaik di {0}",
  "neighbourhoods_in": "kawasan sekitar di {0}",
  "business": "Perniagaan",
  "photo": "Foto",
  "blog.see_all_stories": "Lihat semua kisah",
  "category_highlight.see_all.food": "Lebih lanjut tentang makanan & tempat makan",
  "see_all_properties": "Lihat semua hartanah",
  "lbg.theme.beach": "Pantai",
  "what_to_see_and_do_in": "Apakah tarikan & aktiviti di {0}",
  "hub.category.h2.single": "Perjalanan solo paling popular di {0}",
  "attractions_in": "Tarikan di {0}",
  "read_more": "Baca selanjutnya",
  "show_more": "Tunjukkan lebih banyak",
  "hotels_near_this_landmark": "Hotel berdekatan mercu tanda ini",
  "check_dates": "Semak tarikh",
  "featured_stories_and_fun_stuff": "Artikel pilihan & perkara menyeronokkan",
  "phone": "Telefon:",
  "lbg.section_search_header": "Cari pemenang anugerah",
  "category_highlight.title.food": "Di mana & apa perlu dimakan di {0}",
  "see_all_things_to_do": "Lihat semua aktiviti menarik",
  "lbg.section_properties_theme.sustainable": "Pemenang anugerah mapan dalam {0}",
  "lbg.h1.hubpage": "{0} Pemenang Anugerah Loved by Guests!",
  "lbg.section_theme_world_winners.spa_hotel": "Pemenang anugerah spa",
  "category.maps": "Peta",
  "lbg.h1.destination_theme.family": "Pemenang Anugerah Family dalam {0}",
  "lbg.section_theme_world_winners.cleanliness": "Pemenang anugerah kebersihan",
  "lbg.section_theme_world_winners.vip_access": "Pemenang anugerah VIP Access",
  "trust_module.get_reward_night.night_you_stay": "untuk setiap 10 malam anda menginap",
  "modified": "diubah suai",
  "lbg.section_properties": "Pemenang anugerah dalam {0}",
  "trust_module.header": "Mengapa tempah dengan Hotels.com?",
  "hub.category.h2.luxury": "Perjalanan mewah paling popular di {0}",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Pemenang Anugerah Spa dalam {0}",
  "lbg.h2.most_wanted": "Tetamu amat mengesyorkan hartanah ini untuk penginapan yang sempurna.",
  "top10_hotels_incomplete_alert": "Kami sedang mengalami permintaan yang tinggi, jadi sesetengah hartanah mungkin tidak muncul buat masa ini. <span>Segala kesulitan amat dikesali.</span>",
  "compulsive_traveller": "{0} <span>|</span> <span>Pengembara Kompulsif</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Pemenang anugerah rumah & pangsapuri",
  "loved_by_guests_collection_in": "Koleksi Loved by Guests di {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "Peta berguna bagi {0}",
  "opening_hours": "Waktu operasi",
  "hub.category.h2.photos": "Perjalanan fotografi paling popular di {0}",
  "lbg.theme.most_wanted": "Most Wanted",
  "blog.stories_container": "Kisah daripada penyumbang",
  "hub.category.h2.budget": "Perjalanan bajet Paling Popular di {0}",
  "lbg.section_properties_theme.business": "Pemenang anugerah business dalam {0}",
  "not_sure_where_to_stay": "Tidak pasti tempat untuk menginap?",
  "category.information": "Maklumat"
};