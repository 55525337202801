export default {
  "current_deal": "현재 요금",
  "lbg.section_properties_theme.pet_friendly": "{0}의 반려동물 동반 가능 어워드 수상 숙소",
  "address": "주소:",
  "hub.homepage.h2": "스마트한 여행을 위한 길잡이",
  "search_hotels_near_this_landmark": "<span class=\"extra-label\">이 랜드마크 주변</span> 호텔 검색",
  "hub.category.h1.things_to_do": "{0}의 즐길거리",
  "lbg.h2.vip_access": "탁월한 서비스를 제공하는 VIP Access 숙박 시설을 확인해 보세요.",
  "lbg.theme.cleanliness": "청결",
  "lbg.theme.lgbtq": "성소수자",
  "load_more": "더 보기",
  "link_long_form_to_listiicle_multiple": "{0} - {1} 및 {2}에 소개된 하이라이트(여기에서 {3}에 대해 자세히 알아보기)",
  "lbg.section_properties_theme.boutique": "{0}의 부티크 어워드 수상 숙소",
  "lbg.h2.luxury": "진정한 럭셔리를 누려보세요. 고객들이 사랑한 숙박 시설을 확인해 보세요.",
  "hub.category.h1.information": "{0}에 대한 정보",
  "families": "가족",
  "trending_now": "인기 여행지",
  "lbg.from": "최저가",
  "hub.category.h1.road_trips": "{0} 인기 로드트립 명소",
  "lbg.h2.cleanliness": "전반적으로 우수한 고객 평점과 특히 청결에 있어 고객들로부터 높은 점수를 받은 숙박 시설을 확인해 보세요.",
  "single": "싱글",
  "couples": "커플",
  "trust_module.price_guarantee.find_out_more": "자세히 알아보기",
  "top_destinations_in": "{0}의 인기 여행지",
  "hub.category.h1.couples": "{0} 커플 여행",
  "hub.category.h2.tours": "{0}의 인기 투어",
  "hub.category.h1.photos": "{0} 사진 여행",
  "category.photos": "사진",
  "hub.category.h1.budget": "저렴한 {0} 여행",
  "hub.category.h2.ski": "{0}의 인기 스키 여행지",
  "lbg.section_theme_world_winners.most_wanted": "최고 인기 어워드 수상 숙소",
  "lbg.theme.home_and_apartments": "주택 및 아파트",
  "hub.category.h1.luxury": "{0} 럭셔리 여행",
  "hub.category.h2.adventure": "{0}에서 인기 있는 액티브한 여행",
  "hotels_in": "{0} 호텔",
  "lbg.section_search_winners": "더 많은 수상 숙소 찾아보기",
  "see_more_hotels_in": "{0} 호텔 더 보기",
  "hub.category.h1.beach": "{0}의 해변",
  "open": "운영 시간:",
  "enter_your_destination": "목적지를 입력해 주세요",
  "best_of": "{0} 여행 인기 즐길거리",
  "top10_hotels_missing_properties": "현재 일부 숙박 시설이 표시되지 않을 수 있습니다. 불편을 드려 죄송합니다.",
  "adventure": "어드벤처",
  "lbg.h1.destination_theme.sustainable": "{0}의 친환경 어워드 수상 숙소",
  "luxury": "럭셔리",
  "expand_map_view": "지도 보기 확대",
  "book_now": "지금 예약",
  "lbg.h1.destination_theme.ski": "{0}의 스키 어워드 수상 숙소",
  "hub.category.h1.food": "{0}의 음식과 맛집",
  "category.blogs": "블로그",
  "budget": "알뜰 여행",
  "popular_neighbourhoods_in": "{0}에서 인기 있는 지역",
  "hub.category.h1.unusual": "{0}의 숨겨진 보석 및 색다른 장소",
  "see_all_accommodation_in": "{0}의 숙박 시설 보기",
  "hub.category.h1.adventure": "액티브한 {0} 여행",
  "category_highlight.see_all.shopping": "쇼핑 정보 더 보기",
  "lbg.section_most_popular_destination": "최고 인기 여행지",
  "lbg.section_properties_theme.luxury": "{0}의 럭셔리 어워드 수상 숙소",
  "unusual": "이색 체험",
  "category.shopping": "쇼핑",
  "make_the_most_of_your_trip": "여행을 최대한 즐기는 방법",
  "x_review": "{0,choice,0#|1#{0}개 이용 후기|2#{0}개 이용 후기|2<{0}개 이용 후기|4<{0}개 이용 후기|10#{0}개 이용 후기|10<{0}개 이용 후기}",
  "hub.category.h1.single": "{0} 나홀로 여행",
  "hub.category.h2.custom": "HOT 트렌드",
  "lbg.section_theme_world_winners.lgbt": "성소수자 환영 어워드 수상 숙소",
  "lbg.section_award_winners_theme_other": "{0}의 기타 테마 수상 숙소",
  "lbg.supplier_collected_charges": "+ {0} 현장 결제",
  "in_destination": "{0}",
  "lbg.section_properties_theme.vip_access": "{0}의 VIP Access 어워드 수상 숙소",
  "link_long_form_to_listiicle_single": "{0} - {1}에 소개된 하이라이트(여기에서 {2}에 대해 자세히 알아보기)",
  "lbg.section_theme_world_winners.boutique": "부티크 어워드 수상 숙소",
  "lbg.section_properties_theme.spa_hotel": "{0}의 스파 어워드 수상 숙소",
  "lbg.section_theme_world_winners.all_inclusive": "올 인클루시브 어워드 수상 숙소",
  "hub.category.h2": "{0} 여행 가이드",
  "lbg.properties.price_tooltip": "이 요금은 다음 14일 동안 저희 사이트에서 1박당 가장 저렴한 요금이에요.",
  "hub.category.h2.road_trips": "{0} 인기 로드트립 명소",
  "hub.category.h1.ski": "{0}의 스키 여행지",
  "food": "음식",
  "lbg.theme.pet_friendly": "반려동물 동반 가능",
  "lbg.theme.business": "출장",
  "hub.category.h1.nature": "자연 속 휴식처가 있는 {0} 인기 여행지",
  "map": "지도",
  "category_highlight.see_all.information": "더 보기",
  "hotel_prices_in": "{0} 호텔 가격",
  "hub.category.h1.nightlife": "{0}의 나이트라이프",
  "trust_module.free_cancellation.most_hotels": "대부분의 호텔*",
  "also_popular_in": "{0}의 기타 인기 여행지",
  "lbg.h1.destination_theme.business": "{0}의 출장 여행 어워드 수상 숙소",
  "lbg.section_theme_world_winners.pet_friendly": "반려동물 동반 가능 어워드 수상 숙소",
  "hub.category.h1.maps": "{0} 지도",
  "lbg.section_properties_theme.most_wanted": "{0}의 최고 인기 어워드 수상 숙소",
  "category.nightlife": "나이트라이프",
  "search_hotels_in_this_area": "<span class=\"extra-label\">이 지역</span>의 호텔 검색",
  "votes_score_and_count": "<span>{0}</span>({1,choice,0#|1#1표|2#2표|2<{1}표|4<{1}표|10#10표|10<{1}표})",
  "hub.category.h2.business": "{0}의 인기 비즈니스 여행",
  "category.things_to_do": "관광 명소",
  "destination_travel_guide_by_category": "{0} 여행 유형별 맞춤 가이드",
  "hub.category.h1.families": "{0}에서 즐기는 가족 여행",
  "lbg.section_theme_world_winners.ski": "스키 어워드 수상 숙소",
  "you_may_also_like": "좋아하실 만한 다른 스토리",
  "lbg.theme.sustainable": "친환경",
  "hub.category.h2.unusual": "{0}의 인기 숨겨진 보석 및 색다른 장소",
  "hub.category.h2.football": "좋아하는 축구 팀을 응원하고 여행을 최대한 즐기세요!",
  "top_hotel": "인기 호텔",
  "lbg.h1.destination_theme.luxury": "{0}의 럭셔리 어워드 수상 숙소",
  "top10_hotels_empty_alert": "현재 기술적 문제가 발생했습니다. <span>불편을 드려 죄송합니다.</span>",
  "special_deal": "특가 요금",
  "lbg.h2.sustainable": "친환경 숙박 시설에서 지구와 환경을 생각하며 숙박도 의미 있게 즐겨보세요.",
  "where_to_stay_in": "{0}의 숙박 정보",
  "start_planning_your_trip": "여행을 계획해 보세요!",
  "good_for": "추천:",
  "category.hotels": "호텔",
  "x_accommodation_guide": "{0} 숙박 시설 가이드",
  "lbg.theme.luxury": "럭셔리",
  "hub.category.h2.sports": "{0}의 인기 스포츠 및 액티비티",
  "lbg.section_theme_world_winners.lgbtq": "성소수자 환영 어워드 수상 숙소",
  "see_more_neighbourhoods": "주변 지역 더 보기",
  "category_highlight.title.nightlife": "{0}의 나이트라이프 및 즐기기 좋은 곳",
  "lbg.h1.destination_theme.lgbt": "{0}의 성소수자 환영 어워드 수상 숙소",
  "hub.category.h1.blogs": "{0} 여행 블로그",
  "lbg.h2.business": "비즈니스 여행객들로부터 높은 평점을 받은 숙박 시설을 확인해 보세요.",
  "hotels": "호텔",
  "lbg.h2.pet_friendly": "고객들이 추천한 인기 반려동물 동반 가능 숙박 시설을 확인해 보세요.",
  "popular_landmarks_nearby": "주변의 인기 랜드마크",
  "popular_extractions_in": "{0}의 인기 명소",
  "lbg.h2.all_inclusive": "인기 숙박 시설에서 모든 스트레스를 날려보세요.",
  "travel_guide_by_category_header": "{1}의 인기 {0}",
  "lbg.travel_between": "여행 기간: {0} ~ {1}",
  "keep_exploring": "계속 둘러보기",
  "what_you_need_to_know_before_you_go": "여행 전에 꼭 알아둘 정보",
  "read_it": "더 보기",
  "view_on_map": "지도에서 보기",
  "lbg.h1.destination_theme.lgbtq": "{0}의 성소수자 환영 어워드 수상 숙소",
  "lbg.h2.beach": "해변 휴가를 좋아하는 고객님들이 특히 사랑한 숙박 시설을 확인해 보세요.",
  "lbg.h1.destination_theme.vip_access": "{0}의 VIP Access 어워드 수상 숙소",
  "see_all_accommodation": "숙박 시설 모두 보기",
  "shoppers": "쇼핑",
  "whats_around": "{0} 주변 명소",
  "group": "단체",
  "hub.category.h2.couples": "{0}의 인기 커플 여행",
  "hub.category.h1.shopping": "{0}의 쇼핑 정보",
  "travel_community": "{0} <span>&#124;</span> <span>여행 커뮤니티</span>",
  "hub.category.h2.group": "{0}의 인기 단체 여행",
  "listicle_legal_msg": "이 기사에는 Go Guides 편집팀의 의견이 담겨 있습니다. Hotels.com은 본 사이트에 기사를 게시한 저자에게 보상을 제공하며, 보상의 형태는 여행 또는 기타 비용을 포함할 수 있습니다.",
  "lbg.h1.destination_theme.cleanliness": "{0}의 청결 어워드 수상 숙소",
  "menu.more": "더 보기",
  "lbg.section_properties_theme.lgbtq": "{0}의 성소수자 환영 어워드 수상 숙소",
  "lbg.section_award_winners_destination": "목적지별 수상 숙소",
  "lbg.terms_conditions": "이용약관",
  "hub.category.h2.nightlife": "{0}의 인기 나이트라이프",
  "hub.neighbourhood.h2": "{0}에서 즐길거리",
  "lbg.section_award_winners_theme_destination": "{0}의 테마별 수상 숙소",
  "lbg.section_award_world_winners": "전 세계 어워드 수상 숙소",
  "things_to_see_and_do_in_other_cities": "다른 도시의 볼거리와 즐길거리",
  "button.backToTop": "맨 위로 돌아가기",
  "lbg.section_theme_world_winners.family": "가족 여행 어워드 수상 숙소",
  "hotels_near": "{0} 인근 호텔",
  "category.activity__sports_": "즐길거리",
  "trust_module.get_reward_night": "리워드 숙박 혜택",
  "hub.category.h1.business": "{0} 비즈니스 여행",
  "category.food": "음식",
  "other_popular_destinations_in": "{0}의 기타 인기 여행지",
  "browse_by_category": "{0}의 유형별 즐길거리",
  "see_more_attactions": "관광명소 더 보기",
  "lbg.theme.lgbt": "성소수자",
  "more_information_about": "{0}에 대한 자세한 정보",
  "lbg.h1.destination_theme.most_wanted": "{0}의 최고 인기 어워드 수상 숙소",
  "see_all_properties_in": "{0}의 숙박 시설 보기",
  "category.tours": "투어",
  "hub.category.h1.sports": "{0}의 스포츠 및 액티비티",
  "where_will_you_go_next": "다음엔 어디로 떠나세요?",
  "cities": "도시",
  "lbg.theme.spa_hotel": "스파",
  "see_guide": "가이드 보기",
  "lbg.section_properties_theme.ski": "{0}의 스키 어워드 수상 숙소",
  "related_stories": "관련 스토리",
  "hub.destination.h2": "여행 전에 꼭 알아둘 정보",
  "show_less": "간단히 보기",
  "hub.category.h1.football": "축구 팬을 위한 필수 {0} 가이드",
  "hub.category.h2.food": "{0}의 인기 먹거리",
  "fallback_language_alert": "죄송합니다. 이 페이지는 아직 번역되지 않았어요.",
  "category_highlight.title.information": "유용한 정보",
  "neighbourhood_guides_for_other_cities": "다른 도시의 지역 정보 가이드",
  "lbg.h2.hubpage": "이용 후기가 많고 고객 평점이 높은 인기 숙박 시설을 확인해 보세요.",
  "travel_advisory.title": "여행 알림",
  "lbg.section_properties_theme.all_inclusive": "{0}의 올 인클루시브 어워드 수상 숙소",
  "lbg.h2.boutique": "부티크 숙박 시설을 좋아하신다면 분명 마음에 드실 거예요.",
  "lbg.section_properties_theme.family": "{0}의 가족 여행 어워드 수상 숙소",
  "hub.category.h2.families": "{0}에서 즐기는 추천 가족 여행",
  "from": "최저가",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "{0}의 성소수자 환영 어워드 수상 숙소",
  "x_star": "{0,choice,0#|1#{0}성급|1.5#{0}성급|2#{0}성급|2.5#{0}성급|3#{0}성급|3.5#{0}성급|4#{0}성급|4.5#{0}성급|5#{0}성급}",
  "trust_module.free_cancellation": "무료 취소",
  "lbg.section_properties_theme.home_and_apartments": "{0}의 주택 및 아파트 어워드 수상 숙소",
  "contributing_writer": "{0} <span>&#124;</span> <span>기고 작가</span>",
  "things_to_see_and_do_in": "<span class=\"next-line\">{0}의</span> 볼거리와 즐길거리",
  "lbg.check_price": "요금 확인",
  "hub.category.h2.blogs": "경험 많은 여행객들이 작성",
  "lbg.h2.home_and_apartments": "집처럼 편한 곳을 찾으세요? 높은 평점을 받은 인기 숙박 시설을 확인해 보세요.",
  "hub.category.h2.information": "{0}에 대한 꿀정보",
  "search_hotels_in": "<span class=\"extra-label\">{0}</span> 호텔 검색",
  "hub.category.h2.nature": "자연과 함께하는 {0} 인기 여행지",
  "lbg.h1.destination_theme.home_and_apartments": "{0}의 주택 및 아파트 어워드 수상 숙소",
  "photo_by": "사진 제공: {0}",
  "more_things_to_do": "즐길거리 더 보기",
  "lbg.section_properties_theme.beach": "{0}의 해변 어워드 수상 숙소",
  "read_less": "간략히 보기",
  "lbg.h2.spa_hotel": "인기 스파 숙박 시설에서 여유롭게 휴식을 즐겨보세요.",
  "second_fold_related_stories.see_also": "기타 관련 스토리",
  "hub.category.h2.history": "{0}의 인기 역사 명소",
  "lbg.section_properties_theme.cleanliness": "{0}의 청결 어워드 수상 숙소",
  "lbg.h1.destination_theme.boutique": "{0}의 부티크 어워드 수상 숙소",
  "nightlife_b595ae4": "나이트라이프",
  "lbg.h1.destination_theme.beach": "{0}의 해변 어워드 수상 숙소",
  "trust_module.free_cancellation.disclaimer": "일부 호텔은 최소 체크인 24~48시간 전까지 취소해야 합니다. 자세한 내용은 사이트에서 확인해 주세요.",
  "lbg.h2.family": "가족 여행에 완벽한 인기 숙박 시설을 확인해 보세요.",
  "lbg.h2.lgbtq": "인기 성소수자 환영 숙박 시설을 확인해 보세요.",
  "hub.category.h1.group": "{0} 단체 여행",
  "popular_stories": "인기 스토리",
  "toc": "내용",
  "hub.category.h2.shopping": "{0}의 인기 쇼핑",
  "lbg.section_theme_world_winners.beach": "해변 어워드 수상 숙소",
  "page_score": "이 페이지의 점수",
  "lbg.section_theme_world_winners.sustainable": "친환경 어워드 수상 숙소",
  "lbg.theme.boutique": "부티크",
  "trust_module.price_guarantee": "가격 보장",
  "lbg.h1.destination_theme.all_inclusive": "{0}의 올 인클루시브 어워드 수상 숙소",
  "top_destinations": "추천 여행지",
  "hotel_prices_near": "{0} 주변 호텔 가격",
  "lbg.score.cleanliness": "청결도:",
  "x_stars": "{0,choice,0#|1#{0}성급|1<{0}성급|4<{0}성급|10<{0}성급}",
  "lbg.theme.all_inclusive": "올 인클루시브",
  "hub.category.h1.tours": "{0}의 현지 투어",
  "lbg.section_award_winners_theme": "테마별 수상 숙소",
  "guest_writer": "{0} <span>&#124;</span> <span>초대 작가</span>",
  "start_planning_your_x_trip": "{0} 여행을 계획해 보세요!",
  "lbg.section_theme_world_winners.business": "출장 여행 어워드 수상 숙소",
  "trending_now_in": "{0} 관련 유용한 최신 정보",
  "history": "역사",
  "search_hotels": "호텔 검색하기",
  "lbg.terms_conditions_details": "{0} 어워드 수상 숙소는 {1}년에 높은 고객 평점을 기록하고 이용 후기가 50개 이상인 숙박 시설을 기준으로 선정되었습니다.",
  "see_nearby_properties": "주변 숙박 시설 보기",
  "hub.category.h1.history": "{0}의 역사 명소",
  "location": "주소:",
  "lbg.section_theme_world_winners.luxury": "럭셔리 어워드 수상 숙소",
  "category_highlight.see_all.nightlife": "나이트라이프 더 보기",
  "also_popular": "기타 인기 여행지",
  "hub.category.h2.things_to_do": "{0}의 인기 즐길거리/볼거리",
  "lbg.theme.family": "가족 여행",
  "lbg.h1.destination_theme.pet_friendly": "{0}의 반려동물 동반 가능 어워드 수상 숙소",
  "category_highlight.title.shopping": "{0}에서 살 것과 쇼핑하기 좋은 곳",
  "price": "요금:",
  "hub.category.h2.beach": "{0}의 인기 해변",
  "neighbourhoods_in": "{0}의 세부 지역",
  "business": "출장",
  "photo": "사진",
  "blog.see_all_stories": "스토리 모두 보기",
  "category_highlight.see_all.food": "음식 및 맛집 더 보기",
  "see_all_properties": "숙박 시설 모두 보기",
  "lbg.theme.beach": "해변",
  "what_to_see_and_do_in": "{0}의 볼거리 및 놀거리",
  "hub.category.h2.single": "{0}의 인기 나홀로 여행",
  "attractions_in": "{0}의 명소",
  "read_more": "더 보기",
  "show_more": "더 보기",
  "hotels_near_this_landmark": "이 랜드마크 주변 호텔",
  "check_dates": "날짜 확인",
  "featured_stories_and_fun_stuff": "추천 스토리 및 즐길거리",
  "phone": "전화:",
  "lbg.section_search_header": "수상 숙소 찾아보기",
  "category_highlight.title.food": "{0}의 맛집 및 먹거리",
  "see_all_things_to_do": "즐길거리 모두 보기",
  "lbg.section_properties_theme.sustainable": "{0}의 친환경 어워드 수상 숙소",
  "lbg.h1.hubpage": "{0} 고객’s PICK 어워드 수상 숙박 시설",
  "lbg.section_theme_world_winners.spa_hotel": "스파 어워드 수상 숙소",
  "category.maps": "지도",
  "lbg.h1.destination_theme.family": "{0}의 가족 여행 어워드 수상 숙소",
  "lbg.section_theme_world_winners.cleanliness": "청결 어워드 수상 숙소",
  "lbg.section_theme_world_winners.vip_access": "VIP Access 어워드 수상 숙소",
  "trust_module.get_reward_night.night_you_stay": "10박을 숙박할 때마다",
  "modified": "수정됨",
  "lbg.section_properties": "{0}의 어워드 수상 숙소",
  "trust_module.header": "Hotels.com에서 예약해야 하는 이유",
  "hub.category.h2.luxury": "{0}의 인기 럭셔리 여행",
  "lbg.theme.ski": "스키",
  "lbg.h1.destination_theme.spa_hotel": "{0}의 스파 어워드 수상 숙소",
  "lbg.h2.most_wanted": "완벽한 숙박으로 고객들이 추천한 인기 숙박 시설을 확인해 보세요.",
  "top10_hotels_incomplete_alert": "현재 이용자가 급증하여 일부 숙박 시설이 표시되지 않을 수 있습니다. <span>불편을 드려 죄송합니다.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>여행 마니아</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "주택 및 아파트 어워드 수상 숙소",
  "loved_by_guests_collection_in": "{0}의 고객’s PICK 상품",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "유용한 {0} 지도",
  "opening_hours": "운영 시간:",
  "hub.category.h2.photos": "{0}의 인기 사진 여행",
  "lbg.theme.most_wanted": "최고 인기",
  "blog.stories_container": "블로거 스토리",
  "hub.category.h2.budget": "{0}에서 인기 있는 저렴한 여행",
  "lbg.section_properties_theme.business": "{0}의 출장 여행 어워드 수상 숙소",
  "not_sure_where_to_stay": "마음에 드는 호텔 찾기",
  "category.information": "정보"
};