import React from "react";
import { checkValue } from "components/functions";
import { UitkSecondaryButton } from "uitk-react-button";
import { UitkHeading } from 'uitk-react-text';
import { useStores } from "utils/useStore";
import { getCategoryHeader } from "src/components/utils/helper";
import { UitkLink } from 'uitk-react-link';
import { localization } from 'src/utils/localization';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import BucketCardDefault from "../top-destination/sub-component/bucket-card-default";
import BucketOneCard from "../top-destination/sub-component/bucket-one-card";
import BucketThreeCard from "../top-destination/sub-component/bucket-three-card";
import BucketTwoCard from "../top-destination/sub-component/bucket-two-card";
var GoGuidesCategoryHighLightStoriesComponent = function (props) {
  var _a;
  var category = props.category,
    _b = props.maxStories,
    maxStories = _b === void 0 ? 5 : _b;
  var _c = useStores(),
    goGuidesStore = _c.goGuidesStore,
    context = _c.context;
  var contentData = goGuidesStore.fetchCategoryHighLightStories(goGuidesStore.pageUrlData, category, maxStories);
  var loc = new localization();
  var isMobile = ((_a = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _a === void 0 ? void 0 : _a.mobile) && context.deviceInformation.type === 'MOBILE_PHONE';
  var categoryData = {};
  var destinationName = '';
  var pageType = '';
  var categoryCode = '';
  var snippetText = '';
  var seeAllLink = '';
  var items = [];
  var mainClass = '';
  // we want to track restaurant as food as it more relevant
  var trackingCategory = category == 'RESTAURANT' ? 'FOOD' : category;
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'category highlight: ' + trackingCategory.toLowerCase()
    }
  };
  if (checkValue(contentData)) {
    categoryData = contentData.categoryData;
    destinationName = contentData.destinationName;
    pageType = contentData.pageType;
    if (checkValue(categoryData)) {
      var _d = contentData.categoryData,
        tmpCategoryCode = _d.categoryCode,
        tmpSnippetText = _d.snippetText,
        tmpSeeAllLink = _d.seeAllLink,
        tmpItems = _d.items;
      categoryCode = checkValue(tmpCategoryCode) ? tmpCategoryCode : '';
      snippetText = checkValue(tmpSnippetText) ? tmpSnippetText : '';
      seeAllLink = checkValue(tmpSeeAllLink) ? tmpSeeAllLink.targetUrl : '';
      items = checkValue(tmpItems) ? tmpItems : [];
      if (categoryCode == 'THINGS_TO_DO') {
        mainClass = 'main';
        if (isMobile) {
          items = items.slice(0, 6);
        }
      }
    }
    var headerName = getCategoryHeader(categoryCode, pageType);
    var bucketHeader = loc.getLocalizedText(headerName, destinationName);
    var subTitle = categoryCode == 'THINGS_TO_DO' ? loc.getLocalizedText('featured_stories_and_fun_stuff') : '';
    var readMoreLabel = loc.getLocalizedText('read_more');
    var showMoreLabel = loc.getLocalizedText('show_more');
    var renderBucket = function () {
      var bucketComponents = {
        1: BucketOneCard,
        2: BucketTwoCard,
        3: BucketThreeCard,
        4: BucketTwoCard
      };
      var BucketComponent = bucketComponents[items.length] || BucketCardDefault;
      return React.createElement(BucketComponent, {
        data: items,
        stories: true
      });
    };
    return React.createElement(React.Fragment, null, checkValue(items) && items.length > 0 && React.createElement(EgClickstreamImpressionTracker, {
      eventData: impressionTrackingObject
    }, React.createElement("section", {
      className: "category-highlight ".concat(mainClass)
    }, React.createElement("div", {
      className: "container-wrap row"
    }, React.createElement("div", {
      className: "row-inner col-12 line-up"
    }, React.createElement("div", {
      className: "section-header-wrapper"
    }, React.createElement(UitkHeading, {
      size: 5,
      tag: "h2"
    }, bucketHeader), React.createElement("span", {
      className: "sub-header"
    }, subTitle), snippetText && React.createElement("div", {
      className: "header-description"
    }, React.createElement("p", {
      dangerouslySetInnerHTML: {
        __html: snippetText
      }
    }), seeAllLink && React.createElement(UitkLink, {
      className: "read-more",
      inline: true
    }, React.createElement("a", {
      href: seeAllLink
    }, readMoreLabel)))), React.createElement("div", {
      className: "item-list-page-wrap"
    }, renderBucket()), (categoryCode === 'THINGS_TO_DO' ? seeAllLink && items.length >= 9 : seeAllLink && items.length >= 3) && React.createElement("div", {
      className: "see-all-btn-wrap"
    }, React.createElement(UitkSecondaryButton, {
      className: "see-all-link",
      tag: "a",
      type: "button",
      href: seeAllLink
    }, showMoreLabel)))))));
  }
};
export default GoGuidesCategoryHighLightStoriesComponent;