export default {
  "current_deal": "ดีลที่มีตอนนี้",
  "lbg.section_properties_theme.pet_friendly": "ที่พักที่ชนะรางวัลประเภทสัตว์เลี้ยงเข้าพักได้ - {0}",
  "address": "ที่อยู่",
  "hub.homepage.h2": "อยากไปหลายที่ แต่มีเวลาน้อยนิด...",
  "search_hotels_near_this_landmark": "ค้นหาโรงแรม<span class=\"extra-label\">ใกล้สถานที่สำคัญแห่งนี้</span>",
  "hub.category.h1.things_to_do": "กิจกรรมใน {0}",
  "lbg.h2.vip_access": "ที่พัก VIP Access คือที่พักที่ได้รับคะแนนการเข้าพักสูงและมอบประสบการณ์เหนือระดับให้ผู้เข้าพัก",
  "lbg.theme.cleanliness": "ความสะอาด",
  "lbg.theme.lgbtq": "LGBTQ",
  "load_more": "ดูเพิ่มเติม",
  "link_long_form_to_listiicle_multiple": "{0} - หนึ่งในไฮไลท์การเดินทางของ {1} และ {2} (อ่านเพิ่มเติมเกี่ยวกับ {3} ที่นี่)",
  "lbg.section_properties_theme.boutique": "ที่พักที่ชนะรางวัลประเภทบูติก - {0}",
  "lbg.h2.luxury": "ความหรูหราในทุกรายละเอียดของการเข้าพัก หาคำตอบด้วยตัวเองว่าทำไมที่พักเหล่านี้ได้รับความที่นิยมสูง",
  "hub.category.h1.information": "ข้อมูลเกี่ยวกับ{0}",
  "families": "ครอบครัว",
  "trending_now": "เรื่องราวน่ารู้",
  "lbg.from": "เริ่มที่",
  "hub.category.h1.road_trips": "โร้ดทริปสุดฟินใน {0}",
  "lbg.h2.cleanliness": "ที่พักเหล่านี้มีคะแนนจากผู้เข้าพักโดยรวมดีมาก และโดดเด่นที่สุดในด้านความสะอาด",
  "single": "คนโสด",
  "couples": "คู่รัก",
  "trust_module.price_guarantee.find_out_more": "ดูเพิ่มเติม",
  "top_destinations_in": "จุดหมายปลายทางยอดนิยมใน {0}",
  "hub.category.h1.couples": "ทริปสำหรับคู่รักใน {0}",
  "hub.category.h2.tours": "ทัวร์แนะนำใน {0}",
  "hub.category.h1.photos": "ทริปสำหรับคนรักการถ่ายภาพใน {0}",
  "category.photos": "ภาพถ่าย",
  "hub.category.h1.budget": "ทริปราคาประหยัดใน {0}",
  "hub.category.h2.ski": "ทริปสกียอดนิยมที่ {0}",
  "lbg.section_theme_world_winners.most_wanted": "ที่พักที่ชนะรางวัลประเภท Most Wanted",
  "lbg.theme.home_and_apartments": "บ้านและอพาร์ทเมนท์",
  "hub.category.h1.luxury": "ทริปสุดหรูใน {0}",
  "hub.category.h2.adventure": "ทริปสำหรับสายกิจกรรมที่ดีที่สุดใน {0}",
  "hotels_in": "โรงแรมใน {0}",
  "lbg.section_search_winners": "ค้นหาที่พักที่ชนะรางวัลเพิ่มเติม",
  "see_more_hotels_in": "ดูโรงแรมใน {0} เพิ่มเติม",
  "hub.category.h1.beach": "ชายหาดใน {0}",
  "open": "เปิด:",
  "enter_your_destination": "ป้อนจุดหมายปลายทาง",
  "best_of": "รวมทิปเที่ยว {0}",
  "top10_hotels_missing_properties": "เราไม่สามารถแสดงที่พักบางแห่งได้ในขณะนี้ ขออภัยในความไม่สะดวก",
  "adventure": "ผจญภัย",
  "lbg.h1.destination_theme.sustainable": "ที่พักที่ชนะรางวัลประเภทเป็นมิตรต่อสิ่งแวดล้อม - {0}",
  "luxury": "หรูหรา",
  "expand_map_view": "ขยายแผนที่",
  "book_now": "จองเลย",
  "lbg.h1.destination_theme.ski": "ที่พักที่ชนะรางวัลประเภทสกี - {0}",
  "hub.category.h1.food": "ร้านอาหารแนะนำใน {0}",
  "category.blogs": "บล็อก",
  "budget": "ประหยัด",
  "popular_neighbourhoods_in": "ย่านยอดนิยมใน {0}",
  "hub.category.h1.unusual": "ที่เที่ยวแปลกใหม่ใน {0}",
  "see_all_accommodation_in": "ดูที่พักใน {0}",
  "hub.category.h1.adventure": "ทริปสำหรับสายกิจกรรมใน {0}",
  "category_highlight.see_all.shopping": "แหล่งช้อปเพิ่มเติม",
  "lbg.section_most_popular_destination": "จุดหมายปลายทางยอดนิยม",
  "lbg.section_properties_theme.luxury": "ที่พักที่ชนะรางวัลประเภทหรูหรา - {0}",
  "unusual": "แปลกใหม่",
  "category.shopping": "แหล่งช้อปปิ้ง",
  "make_the_most_of_your_trip": "เที่ยวอย่างไรให้คุ้ม",
  "x_review": "{0,choice,0#|1#{0} รีวิว|2#{0} รีวิว|2<{0} รีวิว|4<{0} รีวิว|10#{0} รีวิว|10<{0} รีวิว}",
  "hub.category.h1.single": "ทริปเที่ยวคนเดียวใน {0}",
  "hub.category.h2.custom": "ฮิตติดเทรนด์",
  "lbg.section_theme_world_winners.lgbt": "ที่พักที่ชนะรางวัลประเภท LGBTQ+",
  "lbg.section_award_winners_theme_other": "ที่พักที่ชนะรางวัลประเภทอื่นๆ - {0}",
  "lbg.supplier_collected_charges": "+ ค่าธรรมเนียมที่ต้องจ่ายที่ที่พัก {0}",
  "in_destination": "ใน {0}",
  "lbg.section_properties_theme.vip_access": "ที่พักที่ชนะรางวัลประเภท VIP Access - {0}",
  "link_long_form_to_listiicle_single": "{0} - หนึ่งในไฮไลท์การเดินทางของ {1} (อ่านเพิ่มเติมเกี่ยวกับ {2} ที่นี่)",
  "lbg.section_theme_world_winners.boutique": "ที่พักที่ชนะรางวัลประเภทบูติก",
  "lbg.section_properties_theme.spa_hotel": "ที่พักที่ชนะรางวัลประเภทสปา - {0}",
  "lbg.section_theme_world_winners.all_inclusive": "ที่พักที่ชนะรางวัลประเภทราคารวมทุกอย่าง",
  "hub.category.h2": "คู่มือท่องเที่ยว {0}",
  "lbg.properties.price_tooltip": "ราคานี้เป็นราคาต่อคืนที่ถูกที่สุดบนเว็บไซต์ของเราในช่วง 14 วันข้างหน้า",
  "hub.category.h2.road_trips": "โร้ดทริปสุดฟินใน {0}",
  "hub.category.h1.ski": "ทริปสกีที่ {0}",
  "food": "อาหาร",
  "lbg.theme.pet_friendly": "สัตว์เลี้ยงเข้าพักได้",
  "lbg.theme.business": "ธุรกิจ",
  "hub.category.h1.nature": "ทริปสัมผัสธรรมชาติใน {0}",
  "map": "แผนที่",
  "category_highlight.see_all.information": "ข้อมูลเพิ่มเติม",
  "hotel_prices_in": "ราคาโรงแรมใน {0}",
  "hub.category.h1.nightlife": "แหล่งท่องเที่ยวยามค่ำคืนใน{0}",
  "trust_module.free_cancellation.most_hotels": "(โรงแรมส่วนใหญ่)*",
  "also_popular_in": "ปลายทางยอดนิยมอื่นๆ ใน {0}",
  "lbg.h1.destination_theme.business": "ที่พักที่ชนะรางวัลประเภทธุรกิจ - {0}",
  "lbg.section_theme_world_winners.pet_friendly": "ที่พักที่ชนะรางวัลประเภทสัตว์เลี้ยงเข้าพักได้",
  "hub.category.h1.maps": "แผนที่ {0}",
  "lbg.section_properties_theme.most_wanted": "ที่พักที่ชนะรางวัลประเภท Most Wanted - {0}",
  "category.nightlife": "ไนท์ไลฟ์",
  "search_hotels_in_this_area": "ค้นหาโรงแรม<span class=\"extra-label\">ในบริเวณนี้</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 โหวต|2#2 โหวต|2<{1} โหวต|4<{1} โหวต|10#10 โหวต|10<{1} โหวต})",
  "hub.category.h2.business": "ทริปเพื่อธุรกิจที่ดีที่สุดใน {0}",
  "category.things_to_do": "แหล่งท่องเที่ยว",
  "destination_travel_guide_by_category": "คู่มือท่องเที่ยว {0} แบ่งตามประเภท",
  "hub.category.h1.families": "ทริปครอบครัว - {0}",
  "lbg.section_theme_world_winners.ski": "ที่พักที่ชนะรางวัลประเภทสกี",
  "you_may_also_like": "คุณน่าจะสนใจ",
  "lbg.theme.sustainable": "เป็นมิตรต่อสิ่งแวดล้อม",
  "hub.category.h2.unusual": "ที่เที่ยวแปลกใหม่ที่ดีที่สุดใน {0}",
  "hub.category.h2.football": "เชียร์ทีมฟุตบอลสุดรักไปพร้อมกับเก็บที่เที่ยวให้ครบจบในทริปเดียว!",
  "top_hotel": "โรงแรมยอดเยี่ยม",
  "lbg.h1.destination_theme.luxury": "ที่พักที่ชนะรางวัลประเภทหรูหรา - {0}",
  "top10_hotels_empty_alert": "เกิดปัญหาขัดข้องทางเทคนิค <span>ขออภัยในความไม่สะดวก</span>",
  "special_deal": "ดีลพิเศษ",
  "lbg.h2.sustainable": "ต้องการสนับสนุนที่พักที่ใส่ใจการรักษาสิ่งแวดล้อมอย่างยั่งยืน? ลองดูตัวเลือกที่พักเหล่านี้ที่ใส่ใจการรักษ์โลกของเรา",
  "where_to_stay_in": "พักที่ไหนดีใน {0}",
  "start_planning_your_trip": "เริ่มต้นวางแผนการเดินทาง",
  "good_for": "จุดเด่น:",
  "category.hotels": "โรงแรม",
  "x_accommodation_guide": "คู่มือเลือกที่พักใน {0}",
  "lbg.theme.luxury": "หรูหรา",
  "hub.category.h2.sports": "กีฬาและกิจกรรมที่ดีที่สุดใน {0}",
  "lbg.section_theme_world_winners.lgbtq": "ที่พักที่ชนะรางวัลประเภท LGBTQ",
  "see_more_neighbourhoods": "ดูย่านน่าสนใจเพิ่มเติม",
  "category_highlight.title.nightlife": "เที่ยวอะไรที่ไหนดียามค่ำคืนใน {0}",
  "lbg.h1.destination_theme.lgbt": "ที่พักที่ชนะรางวัลประเภท LGBTQ+ - {0}",
  "hub.category.h1.blogs": "บล็อกท่องเที่ยว{0}",
  "lbg.h2.business": "ที่พักเหล่านี้ได้รับคะแนนสูงในกลุ่มนักเดินทางธุรกิจ",
  "hotels": "โรงแรม",
  "lbg.h2.pet_friendly": "ผู้เข้าพักต่างเลือกให้ที่พักเหล่านี้เป็นที่พักที่คุณต้องประทับใจหากพาเจ้าปุกปุยไปด้วย",
  "popular_landmarks_nearby": "สถานที่สำคัญในบริเวณใกล้เคียง",
  "popular_extractions_in": "สถานที่ท่องเที่ยวยอดนิยมใน {0}",
  "lbg.h2.all_inclusive": "ที่พักคะแนนผู้เข้าพักสูงเหล่านี้คือนิยามของคำว่าที่พักผ่อนอย่างแท้จริง",
  "travel_guide_by_category_header": "{0}ที่ดีที่สุดใน {1}",
  "lbg.travel_between": "เข้าพักระหว่าง {0} - {1}",
  "keep_exploring": "ที่เที่ยวเพิ่มเติม",
  "what_you_need_to_know_before_you_go": "อ่านข้อมูลเพิ่มเติม ก่อนเริ่มเดินทาง",
  "read_it": "อ่านต่อ",
  "view_on_map": "ดูแผนที่",
  "lbg.h1.destination_theme.lgbtq": "ที่พักที่ชนะรางวัลประเภท LGBTQ - {0}",
  "lbg.h2.beach": "ผู้เข้าพักชอบที่พักริมหาด และที่พักเหล่านี้คือตัวเลือกยอดฮิต",
  "lbg.h1.destination_theme.vip_access": "ที่พักที่ชนะรางวัลประเภท VIP Access - {0}",
  "see_all_accommodation": "ดูที่พักทั้งหมด",
  "shoppers": "ขาช้อป",
  "whats_around": "เที่ยวรอบ {0}",
  "group": "กลุ่ม",
  "hub.category.h2.couples": "ทริปสำหรับคู่รักที่ดีที่สุดใน {0}",
  "hub.category.h1.shopping": "ช้อปปิ้งใน{0}",
  "travel_community": "{0} <span>&#124;</span> <span>ชุมชนการท่องเที่ยว</span>",
  "hub.category.h2.group": "ทริปสำหรับหมู่คณะที่ดีที่สุดใน {0}",
  "listicle_legal_msg": "บทความนี้ประกอบด้วยความคิดเห็นของทีมบรรณาธิการ Go Guides Hotels.com จ่ายค่าตอบแทนให้ผู้เขียนบทความลงบนเว็บไซต์นี้ ค่าตอบแทนดังกล่าวอาจรวมถึงค่าใช้จ่ายด้านการเดินทางและอื่นๆ",
  "lbg.h1.destination_theme.cleanliness": "ที่พักที่ชนะรางวัลประเภทความสะอาด - {0}",
  "menu.more": "อื่นๆ",
  "lbg.section_properties_theme.lgbtq": "ที่พักที่ชนะรางวัลประเภท LGBTQ - {0}",
  "lbg.section_award_winners_destination": "ที่พักที่ชนะรางวัล เรียงตามที่ตั้ง",
  "lbg.terms_conditions": "ข้อกำหนดและเงื่อนไข",
  "hub.category.h2.nightlife": "ที่เที่ยวกลางคืนแนะนำใน {0}",
  "hub.neighbourhood.h2": "{0} มีอะไรน่าสนใจ?",
  "lbg.section_award_winners_theme_destination": "ที่พักที่ชนะรางวัล เรียงตามประเภท - {0}",
  "lbg.section_award_world_winners": "ที่พักที่ชนะรางวัลทั่วโลก",
  "things_to_see_and_do_in_other_cities": "สถานที่ท่องเที่ยวและกิจกรรมในเมืองอื่น",
  "button.backToTop": "กลับไปด้านบนสุด",
  "lbg.section_theme_world_winners.family": "ที่พักที่ชนะรางวัลประเภทครอบครัว",
  "hotels_near": "โรงแรมใกล้ {0}",
  "category.activity__sports_": "กิจกรรม",
  "trust_module.get_reward_night": "รับคืนเข้าพัก Rewards",
  "hub.category.h1.business": "ทริปเพื่อธุรกิจใน {0}",
  "category.food": "อาหาร",
  "other_popular_destinations_in": "สถานที่ท่องเที่ยวยอดนิยมอื่นๆ ใน {0}",
  "browse_by_category": "เที่ยว {0} ง่ายๆ ตามสไตล์ที่คุณชอบ",
  "see_more_attactions": "ดูสถานที่ท่องเที่ยวเพิ่มเติม",
  "lbg.theme.lgbt": "LGBTQ+",
  "more_information_about": "ข้อมูลเพิ่มเติมเกี่ยวกับ {0}",
  "lbg.h1.destination_theme.most_wanted": "ที่พักที่ชนะรางวัลประเภท Most Wanted - {0}",
  "see_all_properties_in": "ดูที่พักใน {0}",
  "category.tours": "ทัวร์",
  "hub.category.h1.sports": "กีฬาและกิจกรรมใน {0}",
  "where_will_you_go_next": "จัดทริปไปไหนดี ลองดูที่นี่!",
  "cities": "เมือง",
  "lbg.theme.spa_hotel": "สปา",
  "see_guide": "ดูคำแนะนำ",
  "lbg.section_properties_theme.ski": "ที่พักที่ชนะรางวัลประเภทสกี - {0}",
  "related_stories": "เรื่องอื่นที่น่าสนใจ",
  "hub.destination.h2": "สิ่งที่เราอยากบอกก่อนออกเดินทาง",
  "show_less": "ดูน้อยลง",
  "hub.category.h1.football": "คู่มือเที่ยว {0} สุดปังฉบับแฟนบอลตัวจริง",
  "hub.category.h2.food": "ร้านอาหารแนะนำใน {0}",
  "fallback_language_alert": "ขออภัยค่ะ หน้านี้ยังไม่มีการแปลเป็นภาษาของคุณ",
  "category_highlight.title.information": "ข้อมูลน่ารู้",
  "neighbourhood_guides_for_other_cities": "ดูย่านน่าพักในเมืองอื่นๆ",
  "lbg.h2.hubpage": "ที่พักที่น่าพักเหล่านี้ได้รับคะแนนจากผู้เข้าพักสูงและมีรีวิวการเข้าพักมากมาย",
  "travel_advisory.title": "การแจ้งเตือนการเดินทาง",
  "lbg.section_properties_theme.all_inclusive": "ที่พักที่ชนะรางวัลประเภทราคารวมทุกอย่าง - {0}",
  "lbg.h2.boutique": "หากคุณมองหาที่พักเรียบหรูแนวบูทีค ลองดูตัวเลือกนี้ที่ผู้เข้าพักของเราแนะนำ",
  "lbg.section_properties_theme.family": "ที่พักที่ชนะรางวัลประเภทครอบครัว - {0}",
  "hub.category.h2.families": "ทริปครอบครัวแนะนำ - {0}",
  "from": "เริ่มที่",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "ที่พักที่ชนะรางวัลประเภท LGBTQ+ - {0}",
  "x_star": "{0,choice,0#|1#{0} ดาว|1.5#{0} ดาว|2#{0} ดาว|2.5#{0} ดาว|3#{0} ดาว|3.5#{0} ดาว|4#{0} ดาว|4.5#{0} ดาว|5#{0} ดาว}",
  "trust_module.free_cancellation": "ยกเลิกฟรี",
  "lbg.section_properties_theme.home_and_apartments": "ที่พักที่ชนะรางวัลประเภทบ้านและอพาร์ทเมนท์ - {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>นักเขียนประจำ</span>",
  "things_to_see_and_do_in": "สถานที่ท่องเที่ยวและกิจกรรม<span class=\"next-line\">ใน {0}</span>",
  "lbg.check_price": "ตรวจสอบราคา",
  "hub.category.h2.blogs": "โดยกูรูนักเดินทาง",
  "lbg.h2.home_and_apartments": "มองหาที่พักที่ให้ความรู้สึกอบอุ่นราวกับบ้านตัวเอง ดูที่พักเหล่านี้ที่ได้คะแนนสูงจากผู้เข้าพัก",
  "hub.category.h2.information": "ข้อมูลน่ารู้เกี่ยวกับ {0}",
  "search_hotels_in": "ค้นหาโรงแรม<span class=\"extra-label\">ใน {0}</span>",
  "hub.category.h2.nature": "ทริปสัมผัสธรรมชาติใน {0}",
  "lbg.h1.destination_theme.home_and_apartments": "ที่พักที่ชนะรางวัลประเภทบ้านและอพาร์ทเมนท์ - {0}",
  "photo_by": "ภาพโดย {0}",
  "more_things_to_do": "กิจกรรมเพิ่มเติม",
  "lbg.section_properties_theme.beach": "ที่พักที่ชนะรางวัลประเภทชายหาด - {0}",
  "read_less": "อ่านน้อยลง",
  "lbg.h2.spa_hotel": "การพักผ่อนที่เรียกได้ว่าเป็นการพักผ่อนอย่างแท้จริง กับที่พักคะแนนนิยมสูงในด้านบริการสปา",
  "second_fold_related_stories.see_also": "อย่าลืมแวะอ่าน",
  "hub.category.h2.history": "ที่เที่ยวทางประวัติศาสตร์ที่ดีที่สุดใน {0}",
  "lbg.section_properties_theme.cleanliness": "ที่พักที่ชนะรางวัลประเภทความสะอาด - {0}",
  "lbg.h1.destination_theme.boutique": "ที่พักที่ชนะรางวัลประเภทบูติก - {0}",
  "nightlife_b595ae4": "ไนท์ไลฟ์",
  "lbg.h1.destination_theme.beach": "ที่พักที่ชนะรางวัลประเภทชายหาด - {0}",
  "trust_module.free_cancellation.disclaimer": "โรงแรมบางแห่งกำหนดให้ต้องยกเลิกการจองอย่างน้อย 24-48 ชั่วโมงก่อนเช็คอิน โปรดดูรายละเอียดที่เว็บไซต์",
  "lbg.h2.family": "ผู้เข้าพักยกให้ที่พักเหล่านี้เหมาะสำหรับครอบครัว",
  "lbg.h2.lgbtq": "ที่พักที่ต้อนรับผู้เข้าพัก LGBTQ เหล่านี้ได้รับคะแนนสูงจากผู้ที่เคยเข้าพักมาแล้ว",
  "hub.category.h1.group": "ทริปสำหรับหมู่คณะใน {0}",
  "popular_stories": "เรื่องราวที่เราอยากบอกต่อ",
  "toc": "เนื้อหา",
  "hub.category.h2.shopping": "แหล่งช้อปปิ้งแนะนำใน {0}",
  "lbg.section_theme_world_winners.beach": "ที่พักที่ชนะรางวัลประเภทชายหาด",
  "page_score": "คะแนนหน้านี้",
  "lbg.section_theme_world_winners.sustainable": "ที่พักที่ชนะรางวัลประเภทเป็นมิตรต่อสิ่งแวดล้อม",
  "lbg.theme.boutique": "บูติก",
  "trust_module.price_guarantee": "เรารับประกันราคา",
  "lbg.h1.destination_theme.all_inclusive": "ที่พักที่ชนะรางวัลประเภทราคารวมทุกอย่าง  - {0}",
  "top_destinations": "เมืองยอดนิยม",
  "hotel_prices_near": "ราคาโรงแรมใกล้ {0}",
  "lbg.score.cleanliness": "ความสะอาด:",
  "x_stars": "{0,choice,0#|1#{0} ดาว|1<{0} ดาว|4<{0} ดาว|10<{0} ดาว}",
  "lbg.theme.all_inclusive": "ราคารวมทุกอย่าง",
  "hub.category.h1.tours": "ทัวร์และทริปไปเช้าเย็นกลับใน {0}",
  "lbg.section_award_winners_theme": "ที่พักที่ชนะรางวัล เรียงตามประเภท",
  "guest_writer": "{0} <span>&#124;</span> <span>นักเขียนรับเชิญ</span>",
  "start_planning_your_x_trip": "วางแผนการเดินทางสำหรับทริป {0} ของคุณ",
  "lbg.section_theme_world_winners.business": "ที่พักที่ชนะรางวัลประเภทธุรกิจ",
  "trending_now_in": "เรื่องราวน่ารู้ใน {0}",
  "history": "ประวัติศาสตร์",
  "search_hotels": "ค้นหาโรงแรม",
  "lbg.terms_conditions_details": "เกณฑ์ที่ใช้ตัดสินที่พักที่ได้รับรางวัลในปี {0} อ้างอิงจากคะแนนที่สูงที่สุดจากผู้เข้าพัก และรีวิวผู้เข้าพักอย่างน้อย 50 รีวิวในปี {1}",
  "see_nearby_properties": "ดูที่พักในละแวกใกล้เคียง",
  "hub.category.h1.history": "ที่เที่ยวทางประวัติศาสตร์ใน {0}",
  "location": "ที่ตั้ง:",
  "lbg.section_theme_world_winners.luxury": "ที่พักที่ชนะรางวัลประเภทหรูหรา",
  "category_highlight.see_all.nightlife": "สีสันแห่งไนท์ไลฟ์เพิ่มเติม",
  "also_popular": "จุดหมายปลายทางยอดนิยมอื่นๆ",
  "hub.category.h2.things_to_do": "กิจกรรมแนะนำใน {0}",
  "lbg.theme.family": "ครอบครัว",
  "lbg.h1.destination_theme.pet_friendly": "ที่พักที่ชนะรางวัลประเภทสัตว์เลี้ยงเข้าพักได้ - {0}",
  "category_highlight.title.shopping": "ซื้ออะไรที่ไหนดีใน {0}",
  "price": "ราคา:",
  "hub.category.h2.beach": "ชายหาดที่ดีที่สุดใน {0}",
  "neighbourhoods_in": "ย่านน่าสนใจใน {0}",
  "business": "ธุรกิจ",
  "photo": "ถ่ายรูป",
  "blog.see_all_stories": "ดูทั้งหมด",
  "category_highlight.see_all.food": "อาหารอร่อยๆ และร้านแนะนำเพิ่มเติม",
  "see_all_properties": "ดูที่พักทั้งหมด",
  "lbg.theme.beach": "ชายหาด",
  "what_to_see_and_do_in": "ทำอะไรที่ไหนดีใน {0}",
  "hub.category.h2.single": "ทริปเที่ยวคนเดียวที่ดีที่สุดใน {0}",
  "attractions_in": "สถานที่ท่องเที่ยวใน {0}",
  "read_more": "อ่านเพิ่มเติม",
  "show_more": "ดูเพิ่มเติม",
  "hotels_near_this_landmark": "โรงแรมใกล้ที่นี่",
  "check_dates": "ตรวจสอบวันที่",
  "featured_stories_and_fun_stuff": "เรื่องราวน่าสนใจและกิจกรรมสนุกๆ",
  "phone": "โทร:",
  "lbg.section_search_header": "ค้นหาที่พักที่ชนะรางวัล",
  "category_highlight.title.food": "กินอะไรที่ไหนดีใน {0}",
  "see_all_things_to_do": "ดูกิจกรรมทั้งหมด",
  "lbg.section_properties_theme.sustainable": "ที่พักที่ชนะรางวัลประเภทเป็นมิตรต่อสิ่งแวดล้อม - {0}",
  "lbg.h1.hubpage": "ที่พักในดวงใจผู้เข้าพักประจำปี {0}",
  "lbg.section_theme_world_winners.spa_hotel": "ที่พักที่ชนะรางวัลประเภทสปา",
  "category.maps": "แผนที่",
  "lbg.h1.destination_theme.family": "ที่พักที่ชนะรางวัลประเภทครอบครัว - {0}",
  "lbg.section_theme_world_winners.cleanliness": "ที่พักที่ชนะรางวัลประเภทความสะอาด",
  "lbg.section_theme_world_winners.vip_access": "ที่พักที่ชนะรางวัลประเภท VIP Access",
  "trust_module.get_reward_night.night_you_stay": "เมื่อเข้าพักครบทุก 10 คืน",
  "modified": "เวอร์ชั่นแก้ไข",
  "lbg.section_properties": "ที่พักที่ชนะรางวัล - {0}",
  "trust_module.header": "ทำไมต้องจองกับ Hotels.com",
  "hub.category.h2.luxury": "ทริปสุดหรูที่ดีที่สุดใน {0}",
  "lbg.theme.ski": "สกี",
  "lbg.h1.destination_theme.spa_hotel": "ที่พักที่ชนะรางวัลประเภทสปา - {0}",
  "lbg.h2.most_wanted": "ผู้เข้าพักยกให้ที่พักเหล่านี้เป็นที่พักสมบูรณ์แบบในทุกด้าน",
  "top10_hotels_incomplete_alert": "เนื่องจากมีผู้ใช้งานจำนวนมาก เราจึงไม่สามารถแสดงรายการที่พักบางแห่งได้ในขณะนี้ <span>ขออภัยในความไม่สะดวก</span>",
  "compulsive_traveller": "{0} <span>|</span> <span>นักเดินทางตัวยง</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "ที่พักที่ชนะรางวัลประเภทบ้านและอพาร์ทเมนท์",
  "loved_by_guests_collection_in": "คอลเลกชันที่พัก Loved by Guests ใน {0}",
  "title.suffix": "{0} - Go Guides",
  "hub.category.h2.maps": "แผนที่ {0}",
  "opening_hours": "เวลาเปิดทำการ",
  "hub.category.h2.photos": "ทริปสำหรับคนรักการถ่ายภาพที่ดีที่สุดใน {0}",
  "lbg.theme.most_wanted": "Most Wanted",
  "blog.stories_container": "เรื่องราวจากบล็อกเกอร์",
  "hub.category.h2.budget": "ทริปราคาประหยัดที่ดีที่สุดใน {0}",
  "lbg.section_properties_theme.business": "ที่พักที่ชนะรางวัลประเภทธุรกิจ - {0}",
  "not_sure_where_to_stay": "พักที่ไหนดี? ",
  "category.information": "ข้อมูลข่าวสาร"
};