import { checkValue } from 'components/functions';
import React from 'react';
import { UitkSubheading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import KeepExploringCountry from './keep-exploring-country';
import KeepExploringContinent from './keep-exploring-continent';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { getLocalizedText } from 'src/utils/helper';
var GoGuidesKeepExploringComponent = function (props) {
  var goGuidesStore = useStores().goGuidesStore;
  var isHomePage = props.isHomePage;
  var contentData = goGuidesStore.fetchKeepExploring(goGuidesStore.pageUrlData);
  if (!checkValue(contentData)) {
    return null;
  }
  var destinationList = contentData.destinationList,
    worldLevel = contentData.worldLevel;
  var trackingObject = {
    event: {
      event_name: 'keep_exploring.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience
  };
  return React.createElement(React.Fragment, null, checkValue(destinationList) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: trackingObject
  }, React.createElement("section", {
    className: "destinations-list"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12"
  }, React.createElement("div", {
    className: "destinations-list-container"
  }, React.createElement(UitkSubheading, {
    tag: "h2",
    className: ""
  }, getLocalizedText('keep_exploring')), React.createElement(KeepExploringCountry, {
    listing: destinationList
  }), React.createElement(KeepExploringContinent, {
    listing: destinationList,
    isHomePage: isHomePage,
    worldLevel: worldLevel
  })))))));
};
export default GoGuidesKeepExploringComponent;