var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { checkValue } from 'components/functions';
import React from 'react';
import { useStores } from 'utils/useStore';
import FeaturedStoriesInner from './featured-stories-inner';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
var GoGuidesFeaturedStoriesComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchFeaturedStories(goGuidesStore.pageUrlData);
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'trending now'
    }
  };
  return React.createElement(React.Fragment, null, checkValue(contentData) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement(FeaturedStoriesInner, __assign({}, contentData))));
};
export default GoGuidesFeaturedStoriesComponent;